import { Trans } from "@lingui/macro";
import { HiDotsVertical } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { RiShareBoxFill } from "react-icons/ri";
import "./PositionDropdown.css";
import { Menu } from "components/MenuDropdown";
import cx from "classnames";

function PositionDropdown({ handleEditCollateral, handleShare, handleMarketSelect }) {
  return (
    <Menu.Menu placement="bottom-end" className="tailwind">
      <Menu.Button>
        {
          ({ open, ref }) => (
            <button
              ref={ref}
              className={cx("PositionDropdown-dots-icon text-textColor/[0.7] dark:hover:bg-[#ffffff]/[0.1] hover:bg-[#000000]/[0.1]", { "dark:bg-[#ffffff]/[0.1] bg-[#000000]/[0.1]": open })}
            >
              <HiDotsVertical fontSize={20} fontWeight={700} />
            </button>
          )
        }
      </Menu.Button>
      <Menu.Items>
        <Menu.Item>
          <button
            onClick={() => {
              handleEditCollateral();
            }}
          >
            <AiOutlineEdit fontSize={16} />
            <p>
              <Trans>Edit Collateral</Trans>
            </p>
          </button>
        </Menu.Item>
        <Menu.Item>
          <button
            onClick={() => {
              handleMarketSelect();
            }}
          >
            <BiSelectMultiple fontSize={16} />
            <p>
              <Trans>Select Market</Trans>
            </p>
          </button>
        </Menu.Item>
        <Menu.Item>
          <button
            onClick={() => {
              handleShare();
            }}
          >
            <RiShareBoxFill fontSize={16} />
            <p>
              <Trans>Share Position</Trans>
            </p>
          </button>
        </Menu.Item>
      </Menu.Items>
    </Menu.Menu>
  );
}

export default PositionDropdown;
