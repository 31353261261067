import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import PalmToken from "img/PalmToken.svg";
import cx from "classnames";
import { useHistoricalData } from "lib/api/useHistoricalData";
import { subDays, format } from "date-fns";
import { CHART_PERIODS_PALM } from "./constants";
import { useTokenPrices } from "lib/api/useTokenPrices";
import LightweightChart from "./LightweightChart";

function __PalmChart() {
  const palmTokenPrice = useTokenPrices("PALM");
  const [, setPalmHoveredPrice] = useState<number | undefined>();
  const [startPeriod, setStartPeriod] = useState(CHART_PERIODS_PALM[0].value);
  const [daysOff, setDaysOff] = useState(1);
  const [interval, setInterval] = useState("5m");
  const endDate = new Date();
  const startDate = subDays(endDate, daysOff);
  const startDateString = format(startDate, "yyyy-MM-dd");
  const endDateString = format(endDate, "yyyy-MM-dd");
  const { historicalData, isLoading, isError } = useHistoricalData("PALM", startDateString, endDateString, interval);
  const formattedHistoricalData = React.useMemo(() => {
    return (historicalData ?? { quotes: [] }).quotes.map((quote) => {
      const timestamp = new Date(quote.timestamp).getTime();
      return {
        time: timestamp / 1000,
        value: quote.quote.USD.price,
      };
    });
  }, [historicalData]);

  useEffect(() => {
    if (startPeriod === "day") {
      setInterval(() => "5m");
      setDaysOff(1);
    } else if (startPeriod === "week") {
      setInterval(() => "hourly");
      setDaysOff(7);
    } else if (startPeriod === "month") {
      setInterval(() => "3h");
      setDaysOff(30);
    }
  }, [startPeriod]);

  const isActiveLightweightChartPeriod = (period) => {
    return startPeriod === period;
  };

  return (
    <div className="w-full flex flex-col gap-[2.5rem]">
      <div className="flex-col flex gap-[2.5rem] mb-[2rem]">
        <div className="flex-col flex">
          <div className="flex items-center gap-3 mb-5">
            <img className="w-[4.5rem] h-[4.5rem] " src={PalmToken} alt="PLP" />
            <p className="text-[2.0rem] font-semibold">
              <Trans>PALM Price</Trans>
            </p>
          </div>

          <p className="text-[3.6rem] font-semibold">$0.00</p>
        </div>
      </div>

      <div className="flex-col flex border-b dark:border-border border-[#D4DAE7]">
        <LightweightChart
          historicalData={formattedHistoricalData}
          isLoading={isLoading}
          isError={isError}
          setHoveredPrice={setPalmHoveredPrice}
          lastPrice={palmTokenPrice.price}
        />
      </div>
      <div className="flex md:justify-end justify-center">
        {CHART_PERIODS_PALM.map((period) => (
          <span
            key={period.value}
            onClick={() => setStartPeriod(period.value)}
            className={cx(
              "w-[5rem] h-[3rem] text-inactive cursor-pointer leading-[3rem] align-middle text-center rounded-[1rem]",
              {
                "bg-background-3 text-textColor": isActiveLightweightChartPeriod(period.value),
              }
            )}
          >
            {period.label}
          </span>
        ))}
      </div>
    </div>
  );
}

export const PalmChart = React.memo(__PalmChart);
