import React from "react";
import cx from "classnames";
import "./Tab.css";

export default function Tab(props) {
  const { options, option, setOption, onChange, type = "block", className, optionLabels } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", "tailwind",  type, className)}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        const [labelName, labelNumber] = label.replace(
          /([^\d]*)\((\d+)\)/g,
          '$1/$2'
        ).split('/');

        return (
          <div
            className={cx("Tab-option", "muted", { active: opt === option })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            <span>
              {labelName}
            </span>

            {
              labelNumber && (
                <span className="Position-tab-number bg-background-6 dark:bg-background-3">
                  {labelNumber}
                </span>
              )
            }
          </div>
        );
      })}
    </div>
  );
}
