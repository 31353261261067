import Modal from "components/Modal/Modal";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { ImSpinner2 } from "react-icons/im";
import { AiOutlineEdit } from "react-icons/ai";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { getLiquidationPrice, USD_DECIMALS, INCREASE } from "lib/legacy";
import NetValueTooltip from "./NetValueTooltip";
import { bigNumberify, formatAmount } from "lib/numbers";
import Button from "components/Button/Button";
import { TokenUtils } from "components/TokenUtils";
import { PositionModalType } from "./constants";


const PositionDetailsModal = ({
  open,
  setIsVisible,
  positionsMap,
  positionKey,
  editPosition,
  hideActions,
  showPnlAfterFees,
  getOrdersForPosition,
  orders,
  nativeTokenAddress,
  account,
  setPositionToShare,
  setIsPositionShareModalOpen,
  sellPosition,
}) => {
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;

  const liquidationPrice = position ? getLiquidationPrice(position) : bigNumberify("0");
  const hasPositionProfit = position && position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
  const positionDelta = position
    ? position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0)
    : 0;

  const positionOrders = position && getOrdersForPosition(account, position, orders, nativeTokenAddress);


  if (position)
    return (
      <Modal
        isVisible={open}
        setIsVisible={setIsVisible}
        label={
          <div className="tailwind isolate flex items-center justify-center gap-2.5">
            <TokenUtils.Logo token={position.indexToken} className="w-[3rem]"></TokenUtils.Logo>

            <div className="flex flex-col items-start">
              <div className="text-[1.8rem] text-textColor font-bold">
                {TokenUtils.getSymbol(position.indexToken)}{" "}
                {position.hasPendingChanges && <ImSpinner2 className="animate-spin position-loading-icon" />}
              </div>
              {position.leverage && (
                <div className="text-[1.2rem] font-normal text-inactive">
                  {"Leverage " + formatAmount(position.leverage, 4, 2, true)}x&nbsp;
                </div>
              )}
            </div>
          </div>
        }
      >
        <div className="tailwind isolate flex flex-col gap-4 text-[1.4rem] text-inactive">
          <div className="h-px w-full bg-border mb-5" />
          <div className="flex items-center justify-between">
            <div>
              <Trans>Side</Trans>
            </div>
            <span
              className={cx("Exchange-list-side", {
                long: position.isLong,
                short: !position.isLong,
              })}
            >
              {position.isLong ? t`Long` : t`Short`}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Size</Trans>
            </div>
            <div className="text-textColor">${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Position Margin</Trans>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-textColor">${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}</div>
              {!hideActions && (
                <span className="edit-icon" onClick={() => editPosition(position)}>
                  <AiOutlineEdit fontSize={16} />
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Entry Price</Trans>
            </div>
            <div className="text-textColor">
              ${formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Mark Price</Trans>
            </div>
            <div className="text-textColor">
              ${formatAmount(position.markPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Liq. Price</Trans>
            </div>
            <div className="text-warning">
              ${formatAmount(liquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>PnL</Trans>
            </div>
            <span
              className={cx("Exchange-list-info-label", {
                positive: hasPositionProfit && positionDelta.gt(0),
                negative: !hasPositionProfit && positionDelta.gt(0),
                muted: positionDelta.eq(0),
              })}
            >
              {position.deltaStr} ({position.deltaPercentageStr})
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Net Value</Trans>
            </div>
            <div className="text-textColor">
              <NetValueTooltip isMobile position={position} />
            </div>
          </div>
          <div className="h-px w-full bg-border my-5" />
          <div className="flex items-center justify-between">
            <div>
              <Trans>Orders</Trans>
            </div>
            <div className="text-textColor">
              {positionOrders.length === 0 && "None"}
              {positionOrders.map((order) => {
                const orderText = () => (
                  <>
                    {order.triggerAboveThreshold ? ">" : "<"}{" "}
                    {formatAmount(order.triggerPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}:
                    {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
                  </>
                );
                if (order.error) {
                  return (
                    <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                      <Tooltip
                        className="order-error"
                        handle={orderText()}
                        handleClassName="plain"
                        renderContent={() => <span className="negative">{order.error}</span>}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                      {orderText()}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Button
              variant="minor-with-border"
              size="sm"
              disabled={position.size.eq(0)}
              onClick={() => editPosition(position)}
              className="w-full"
            >
              <Trans>Margin</Trans>
            </Button>

            <Button
              variant="minor-with-border"
              size="sm"
              disabled={position.size.eq(0)}
              onClick={() => sellPosition(position, PositionModalType.ADD)}
              className="w-full"
            >
              <Trans>Add TP/SL</Trans>
            </Button>

            <Button
              variant="minor-with-border"
              size="sm"
              onClick={() => {
                setPositionToShare(position);
                setIsPositionShareModalOpen(true);
              }}
              disabled={position.size.eq(0)}
              className="w-full"
            >
              <Trans>Share</Trans>
            </Button>
          </div>
        </div>
      </Modal>
    );

  return null;
};

export default PositionDetailsModal;
