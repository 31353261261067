import { useState } from "react";
import './styles.css'

type Props = {
  shortMessage?: React.ReactNode,
  fullMessage?: React.ReactNode,
}
export function ToastifyDebug(props: Props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="Toastify-debug">
      {!open && props.shortMessage && <div className="Toastify-debug-content">{props.shortMessage}</div>}

      {
        props.fullMessage && (
          <>
            <span className="Toastify-debug-button" onClick={() => setOpen((old) => !old)}>
              {open ? "Hide error" : "Show error"}
            </span>
      
            {open && <div className="Toastify-debug-content">{props.fullMessage}</div>}
          </>
        )
      }
    </div>
  );
}
