import React, { useEffect, useState } from "react";
import "./Position.css";
import PositionsList from "components/Exchange/PositionsList";
import Select from "react-select";
import PaginationRightIcon from "img/PaginationRight.svg";
import PaginationLeftIcon from "img/PaginationLeft.svg";
import { useMinExecutionFee } from "domain/legacy";
import { useLocalStorageByChainId } from "lib/localStorage";
import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import { ReactComponent as Filtericon } from "img/Filtericon.svg";

const Position = ({
  savedIsPnlInLeverage,
  savedShowPnlAfterFees,
  connectWallet,
  active,
  account,
  library,
  chainId,
  positions,
  nativeTokenAddress,
  positionsMap,
  infoTokens,
  whitelistedTokens,
  positionDataError,
  positionData,
  pendingPositions,
  setPendingPositions,
  orders,
  totalTokenWeights,
  pendingTxns,
  setPendingTxns,
  setMarket,
  activeFilter,
}) => {
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const [selectedToken, setSelectedToken] = useState("All");
  const [selectedIsLong, setSelectedIsLong] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterVisible, setFilterVisible] = useState(false)
  const [firstFilterActive, setFirstFilterActive] = useState(0)
  const [secondFilterActive, setSecondFilterActive] = useState(0)
  const [filterCount, setFilterCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const positionsPerPage = 5;

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trades";

  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);

  const positionsDataIsLoading = active && !positionData && !positionDataError;


  useEffect(() => {
    setFilterCount(firstFilterActive + secondFilterActive)
  }, [selectedToken, selectedIsLong, firstFilterActive, secondFilterActive]);

  const handleTokenChange = (selectedOption) => {
    if (selectedOption.value === 'All') {
      setFirstFilterActive(0); 
    } else {
      setFirstFilterActive(1);
    }
    setSelectedToken(selectedOption.value);
  };

  const handleIsLongChange = (selectedOption) => {
    if (selectedOption.value === 'All') {
      setSecondFilterActive(0); 
    } else {
      setSecondFilterActive((prevCount) => prevCount + 1); 
    }
    setSelectedIsLong(selectedOption.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredPositions = positions.filter((position) => {
    if (selectedToken !== "All" && position.indexToken.address !== selectedToken) {
      return false;
    }
    if (selectedIsLong !== "All" && position.isLong !== (selectedIsLong === "true")) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    if (activeFilter === "long") {
      setSelectedIsLong("true");
    } else if (activeFilter === "short") {
      setSelectedIsLong("false");
    } else {
      return;
    }
  }, [activeFilter]);

  const totalPositions = filteredPositions.length;


  useEffect(() => {
    if (totalPositions > 0) {
      setTotalPages(Math.ceil(totalPositions / positionsPerPage));
    } else {
      setTotalPages(1);
    }
  }, [totalPositions, positionsPerPage]);

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens
  );

  const options = [
    { value: "All", label: "All" },
    ...indexTokens.map((token, index) => ({
      value: token.address,
      label: token.name,
    })),
  ];

  const isLongOptions = [
    { value: "All", label: "All" },
    { value: "true", label: "Long" },
    { value: "false", label: "Short" },
  ];

  const pageOptions = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));


  const handlePageSelect = (selectedOption) => {
    setCurrentPage(selectedOption?.value || 1);
  };

  let flagOrdersEnabled = true;

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, TRADES].filter(Boolean);

  let [, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);

  return (
    <div className="default-container tailwind w-full pt-[5rem] flex flex-col pb-[10rem] gap-[2rem] h-full overflow-y-auto">
      <div className="flex flex-col gap-[0.5rem]">
      <div className="flex gap-[0.5rem] items-center">
          <span className="text-textColor text-[2.4rem]">Open Positions</span>
          {positions.length > 0 && (
              <div className="h-[2.2rem] min-w-[2.2rem] bg-background-3 rounded-full text-center items-center flex justify-center text-sm muted">
              <span>{positions.length}</span>
            </div>
          )}
        </div>
        <span className="muted">View your open Positions</span>
      </div>

      <div className="flex md:hidden w-full">
        <button className="w-full bg-background-3 h-[4rem] rounded-full flex flex-row items-center px-[2rem] justify-between" onClick={()=> {setFilterVisible(true)}}>
          <span className="muted">{filterCount} Filtes Applied</span>
          <Filtericon className="fill-inactive h-[1.4rem]"/>
        </button>
      </div>

      <div className="hidden md:flex flex-row gap-[2rem]">
        <Select
          value={options.find((option) => option.value === selectedToken)}
          onChange={handleTokenChange}
          options={options}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "16.3rem",
              height: "3.5rem",
              borderRadius: "var(--border-radius-sm)",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-border)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",

              color: "#white",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />
        <Select
          value={isLongOptions.find((option) => option.value === selectedIsLong)}
          onChange={handleIsLongChange}
          options={isLongOptions}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "16.3rem",
              height: "3.5rem",
              borderRadius: "var(--border-radius-sm)",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-border)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",
              color: "textColor",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />
      </div>
      <Modal
        className="Connecting-wallet-modal"
        isVisible={isFilterVisible}
        setIsVisible={setFilterVisible}
        label={t`Filters`}
      >
      <div className="flex flex-col gap-[1rem] min-h-[25rem]">
        <span className="muted">Market</span>
        <Select
          value={options.find((option) => option.value === selectedToken)}
          onChange={handleTokenChange}
          options={options}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "100%",
              height: "3.5rem",
              borderRadius: "var(--border-radius-sm)",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-border)",
              color: "textColor",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",

              color: "textColor",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "textColor",
            }),
          }}
        />
        <span className="muted">Sides</span>
        <Select
          value={isLongOptions.find((option) => option.value === selectedIsLong)}
          onChange={handleIsLongChange}
          options={isLongOptions}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "100%",
              height: "3.5rem",
              borderRadius: "var(--border-radius-sm)",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-border)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),

            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              color: "textColor",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",

              color: "textColor",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />
      </div>
    </Modal>

      <PositionsList
        positionsDataIsLoading={positionsDataIsLoading}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        setListSection={setListSection}
        setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
        setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
        isWaitingForPluginApproval={isWaitingForPluginApproval}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        positions={filteredPositions}
        positionsMap={positionsMap}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        flagOrdersEnabled={flagOrdersEnabled}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        chainId={chainId}
        nativeTokenAddress={nativeTokenAddress}
        orders={orders}
        showPnlAfterFees={savedShowPnlAfterFees}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        totalTokenWeights={totalTokenWeights}
        connectWallet={connectWallet}
        setMarket={setMarket}
      />

      <div className="w-full flex flex-row items-center justify-between">
        <span className="muted">Total: {totalPages} pages</span>

        <div className="flex gap-[1rem]">
          <div className="flex items-center gap-[1rem]">
            <span className="muted hidden md:flex">The page you are on</span>
            <Select
              value={pageOptions.find((option) => option.value === currentPage)}
              className="hidden md:flex"
              onChange={handlePageSelect}
              options={pageOptions}
              components={{ IndicatorSeparator: () => null }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: "7rem",
                  height: "2.5rem",
                  backgroundColor: "var(--pallete-background-1)",
                  border: "1px solid var(--pallete-border)",
                  borderRadius: "var(--border-radius-sm)",
                  color: "var(--pallete-border)",
                  boxShadow: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? "var(--pallete-border)" : "var(--pallete-background-1)",
                  cursor: "pointer",
                  color: "textColor",
                  "&:active": {
                    backgroundColor: "var(--pallete-background-1)",
                    outline: "none",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-1)",
                  color: "textColor",
                  border: "1px solid var(--pallete-border)",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "var(--pallete-inactive)",
                }),
              }}
            />
          </div>

          <div className="flex items-center gap-[1rem]">
            <button
              className="bg-transparent border-none"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={PaginationLeftIcon} alt="pagination" />
            </button>
            <span className="PageNumber">{currentPage}</span>
            <button
              className="bg-transparent border-none"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <img src={PaginationRightIcon} alt="pagination" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Position;
