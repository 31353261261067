import { useChainId } from "lib/chains";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { useTokenContract } from "pages/Event/hooks/blockchain/contracts/useTokenContract";
import { BigNumber } from "ethers";
import React from "react";

export const useTokenBalance = (tokenAddress: string, holderAddress: string) => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);

  const fetcherKey = React.useMemo(() => {
    return account ? [`Token:allowance:${isActive}`, chainId, tokenAddress, holderAddress] : null;
  }, [account, isActive, chainId, tokenAddress, holderAddress]);

  const query = useSWR<{ balance: BigNumber }>(
    fetcherKey,
    async ([_key, _chainId, _tokenAddress, _holderAddress]: [
      key: string,
      chainId: number,
      tokenAddress: string,
      holderAddress: string
    ]) => {
      const [balance] = await Promise.all([tokenContract.balanceOf(holderAddress)]);

      return {
        balance,
      };
    }
  );

  const balance = React.useMemo(() => {
    return query.data?.balance;
  }, [query.data]);

  return {
    balance,
  };
};
