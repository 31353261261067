import React from "react";
import ShortIcon from "img/ShortIcon.svg";
import LongIcon from "img/LongIcon.svg";

import cx from "classnames";

import "./Tab.css";

export default function ExchangeTab(props) {
  const { options, option, setOption, onChange, optionLabels } = props;

  const iconMap = {
    short: ShortIcon,
    long: LongIcon,
  };

  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div className={cx("Tab", "exchange")}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        const Icon = iconMap[label.toLowerCase()];

        return (
          <div
            className={cx("Tab-option transition-effect", "muted", label, {
              active: opt === option,
            })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            <div className="Tab-option-swapbox">
              {label}
              {Icon && (
                <img
                  src={Icon}
                  alt={label}
                  className={cx("Tab-option-icon  transition-effect", label, {
                    active: opt === option,
                  })}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
