import { ChainId, TOKENS } from "config/tokens";
import { Token } from "domain/tokens";
import { cx } from "lib/cx";
import useThemeSwitcher from "lib/useThemeSwitcher";

type TokenSymbolParam = Token | undefined
type TokenSymbolResult<TToken extends TokenSymbolParam> = (TToken extends Token ? string : null)

function getTokenSymbol<T extends TokenSymbolParam>(token?: T): TokenSymbolResult<T> {
  if (!token) {
    return undefined as any as TokenSymbolResult<T>
  }
  const symbol = token.isWrapped ? token.baseSymbol : token.symbol
  return symbol.toUpperCase() as any as TokenSymbolResult<T>
}

const DEFAULT_PRICE_DECIMALS = 2;

const getPriceDecimals = (symbol: string, chainId: ChainId) => {
  try {
    return TOKENS[chainId].find((el) => el.symbol === symbol)?.priceDecimals!
  } catch {
    return DEFAULT_PRICE_DECIMALS
  }
}

const getTokenByWrappedOne = (token: Token): Token => {
  if (token.isWrapped && token.chainId in TOKENS) {
    const sameChainTokens = TOKENS[token.chainId]

    return sameChainTokens.find(el => el.symbol === token.baseSymbol)
  }
  return token
}

const getTokenName = (token: Token): string => {
  return getTokenByWrappedOne(token).name
}

const filterVisibleTokens = (tokens: Token[]): Token[] => {
  return tokens.filter((token) => {
    if (token.isTempHidden) {
      return false
    }
    if (token.isWrapped) {
      return true
    }

    const haveWrappedAlternative = tokens.find(otherToken => token.symbol === otherToken.baseSymbol)

    return !haveWrappedAlternative;
  });
}

type TokenLogoProps = {
  token: Token,
  className?: string,
}

const TokenLogo = ({ token, className }: TokenLogoProps) => {
  const { isWhiteTheme } = useThemeSwitcher();

  const symbol = getTokenSymbol(token)
  const tokenImage = ((): string | undefined => {
    try {
      if (isWhiteTheme) {
        return require("./icons/light/" + symbol + '.svg') || require("./icons/dark/" + symbol + '.svg') as string
      }
      return require("./icons/dark/" + symbol + '.svg') || require("./icons/light/" + symbol + '.svg') as string
    } catch {
      return undefined
    }
  })()

  return <div className={cx('relative aspect-square', className)}>
    <img src={tokenImage} alt={token.name} className="absolute top-0 left-0 w-full aspect-square rounded-full"/>
  </div>
}

type TokenSymbolProps = {
  token: Token,
  className?: string,
}

const TokenSymbol = ({ token, className }: TokenSymbolProps) => {
  return <span className={cx(className)}>{getTokenSymbol(token)}</span>
}

type TokenNameProps = {
  token: Token,
  className?: string,
}

const TokenName = ({ token, className }: TokenNameProps) => {
  return <span className={cx(className)}>{getTokenName(token)}</span>
}

export const TokenUtils = {
  getSymbol: getTokenSymbol,
  getName: getTokenName,
  filterVisible: filterVisibleTokens,
  getPriceDecimals,
  Logo: TokenLogo,
  Symbol: TokenSymbol,
  Name: TokenName,
}
