import { ReactNode } from "react";
import { Helmet } from "react-helmet";

type Props = {
  title?: string;
  children: ReactNode;
};

const defaultProps = {
  title: "PalmSwap | Decentralized Perpetual Exchange",
} satisfies Partial<Props>;

function SEO(_props: Props) {
  const props = Object.assign({}, defaultProps, _props);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta property="og:title" content={props.title} />
      </Helmet>

      {props.children}
    </>
  );
}

export default SEO;
