import React from "react";
import { usePopper } from "react-popper";
import Portal from "components/Common/Portal";
import debounce from "lodash/debounce";
import { OffsetsFunction } from "@popperjs/core/lib/modifiers/offset";
import cx from 'classnames';

type Props = {
  children?: React.ReactNode;
  /**
   *  @deprecated use `children` property instead
   */
  handle?: React.ReactNode | (() => JSX.Element);
  /**
   *  @deprecated pass an element with `className` in the `children` property instead
   */
  handleClassName?: string;
  /**
   *  @deprecated pass an element with `className` in the `renderContent` property instead
   */
  className?: string;
  renderContent?: () => React.ReactNode;
  disabled?: boolean;
  /**
   *  @deprecated use `disabled` instead
   */
  isHandlerDisabled?: boolean;
};

export function Tooltip(props: Props) {
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
  
  const offsetFn: OffsetsFunction = React.useCallback(({ placement, reference, popper }) => {
    return [0, 5];
  }, []);
  
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offsetFn,
        },
      },
    ],
  });
  const [isHovered, setIsHovered] = React.useState(false);
  const [isTooltipDisplayed, setIsTooltipDisplayed] = React.useState(false);

  const disabled = props.disabled ?? props.isHandlerDisabled

  const displayAsDisabled = React.useMemo(() => {
    return disabled || typeof props.renderContent !== "function";
  }, [disabled, props.renderContent]);

  const onMouseEnter = () => {
    if (displayAsDisabled) {
      return;
    }
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const hideTooltipDebounced = debounce(() => {
    if (isHovered) {
      return;
    }
    setIsTooltipDisplayed(false);
  }, 100);

  React.useEffect(() => {
    if (isHovered) {
      setIsTooltipDisplayed(true);
    } else {
      hideTooltipDebounced();
    }
  }, [isHovered, hideTooltipDebounced]);

  React.useEffect(() => {
    if (!displayAsDisabled) {
      setIsTooltipDisplayed(false);
    }
  }, [displayAsDisabled]);

  return (
    <>
      <div
        tabIndex={0}
        ref={setReferenceElement}
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        onFocus={onMouseEnter}
        onBlur={onMouseLeave}
        className={cx("tailwind cursor-help", props.className)}
      >
        <div className={props.handleClassName}>
          {
            props.children ?? (typeof props.handle === 'function' ? props.handle() : props.handle)
          }
        </div>
      </div>

      <Portal>
        {isTooltipDisplayed && (
          <div className="tailwind" {...attributes.popper}>
            <div
              ref={setPopperElement}
              style={styles.popper}
              className="z-[1002]"
              onPointerEnter={onMouseEnter}
              onPointerLeave={onMouseLeave}
            >
              <div className="rounded-sm px-[2rem] py-[1.5rem] bg-toast-background text-[1.2rem] leading-[1.5rem] text-inactive shadow-[0_3px_5px_0_rgba(0,0,0,0.4)] max-w-[42.7rem]">
                {props.renderContent?.()}
              </div>
            </div>
          </div>
        )}
      </Portal>
    </>
  );
}
