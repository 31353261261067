import { useChainId } from "lib/chains";
import { Token } from "typechain/common/Token";
import { Token__factory } from "typechain/factories/common/Token__factory";
import { getEthersContract } from "lib/contracts/getEthersContract";
import { useWeb3React } from "@web3-react/core";

export const useTokenContract = (contractAddress: string) => {
  const { chainId } = useChainId();
  const { provider } = useWeb3React();
  const contract = getEthersContract<Token>(Token__factory, chainId, contractAddress, provider);

  return contract;
};
