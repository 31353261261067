import { BigNumber } from "ethers";
import { getPalmSubgraph } from "lib/subgraph";
import { useChainId } from "lib/chains";
import { ApolloQueryResult, gql } from "@apollo/client";
import useSWR from "swr";
import { bigNumberify, expandDecimals } from "lib/numbers";
import { getAddress } from "ethers/lib/utils";

type ClosedPositionItem = {
  id: string;
  token: string;
  isLong: boolean;
  leverage: BigNumber | null;
  size: BigNumber;
  entryPrice: BigNumber;
  exitPrice: BigNumber;
  realisedPnl: BigNumber;
  timestamp: string;
};

type ClosedPosition = {
  id: string;
  size: string;
  isLong: boolean;
  account: string;
  indexToken: string;
  exitPrice: string;
  entryPrice: string;
  collateral: string;
  realisedPnl: string;
  timestamp: string;
};

type ClosedPositionsData = {
  closedPositions: ClosedPosition[];
};

const PRECISION_DECIMALS = 4;
const PRECISION = expandDecimals(1, PRECISION_DECIMALS);
const ZERO = BigNumber.from(0);

export const useClosedPositions = (account: string = "", afterId: string = "0"): ClosedPositionItem[] => {
  const { chainId } = useChainId();

  const fetcher = async (): Promise<ClosedPositionItem[]> => {
    const query = gql`
      query tradeHistoryByUser($account: String!, $afterId: String!) {
        closedPositions(first: 1000, where: { account: $account, id_gt: $afterId }) {
          id
          size
          isLong
          account
          indexToken
          exitPrice
          entryPrice
          collateral
          realisedPnl
          timestamp
        }
      }
    `;
    const { data }: ApolloQueryResult<ClosedPositionsData> = await getPalmSubgraph(chainId, "stats").query({
      query,
      variables: { account: account.toLowerCase(), afterId },
    });
    return data.closedPositions.map((item) => {
      const size = bigNumberify(item.size) || ZERO;
      const collateral = bigNumberify(item.collateral);
      return {
        id: item.id,
        token: getAddress(item.indexToken),
        isLong: item.isLong,
        leverage: collateral?.gt(0) ? size.mul(PRECISION).div(collateral) : null,
        entryPrice: bigNumberify(item.entryPrice) || ZERO,
        exitPrice: bigNumberify(item.exitPrice) || ZERO,
        realisedPnl: bigNumberify(item.realisedPnl) || ZERO,
        size,
        timestamp: item.timestamp
      };
    });
  };

  const { data = [] } = useSWR<Awaited<ReturnType<typeof fetcher>>>(
    [`Exchange:closedPositions:${account}:${afterId}`, chainId, account, afterId],
    fetcher,
    {
      refreshInterval: 10 * 1000,
    }
  );

  return data;
};
