import React, { MouseEventHandler, useState } from "react";
import "./AppHeader.css";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import cx from "classnames";
import { NamedLink } from "./HeaderLink";
import { ProtocolDropdown } from "components/Header/ProtocolDropdown/ProtocolDropdown";
import { Menu } from "components/MenuDropdown";
import { ReactComponent as LogoImage } from "img/logo_palmswap.svg";
import useMedia from "react-use/lib/useMedia";
import { useIsDevelopment } from "lib/useIsDevelopment";
import { AccountDrawerSection } from "./AccountDrawerSection";
import { AppHeaderUser } from "./AppHeaderUser";
import Button from "components/Button/Button";
import { useAppUpdate } from "lib/useAppUpdate";
import { AnimatePresence, motion } from "framer-motion";
import Portal from "components/Common/Portal";
import { FiX, FiMenu } from "react-icons/fi";
import shevronDown from "img/shevron-down.svg";
import { isInsideTag } from "components/Header/utils/isInsideTag";
import { useIsTradePage } from "components/Header/utils/useIsTradePage";
import { useBodyScrollHidden } from "components/Header/utils/useBodyScrollHidden";

import { WelcomeBar } from "./WelcomeBar";

type Props = {
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
  savedSlippageAmount: number;
  setSavedSlippageAmount: (value: number) => void;
  savedShouldShowPositionLines: boolean;
  setSavedShouldShowPositionLines: (value: boolean) => void;
  tradingLayout: string;
  setTradingLayout: (value: string) => void;
};

export function AppHeader({
  disconnectAccountAndCloseSettings,
  openSettings,
  savedSlippageAmount,
  setSavedSlippageAmount,
  savedShouldShowPositionLines,
  setSavedShouldShowPositionLines,
  tradingLayout,
  setTradingLayout,
}: Props) {
  const isDevelopment = useIsDevelopment();
  const appUpdate = useAppUpdate();
  const isTradePage = useIsTradePage();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  useBodyScrollHidden(isDrawerVisible);

  const showNavLastMinus3 = useMedia("(min-width: 950px)");
  const showNavLastMinus2 = useMedia("(min-width: 1050px)");
  // Should be used on two last nav elements for better UX
  const showNavLastMinus1 = useMedia("(min-width: 1150px)");

  const showNavItem = React.useMemo(() => {
    return [showNavLastMinus3, showNavLastMinus2, showNavLastMinus1];
  }, [showNavLastMinus3, showNavLastMinus2, showNavLastMinus1]);

  const showNavItemAt = (index: number) => showNavItem.slice(index)[0];

  const onDrawerClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isInsideTag(e.target as HTMLElement, ["button", "a"])) {
      setIsDrawerVisible(false);
    }
  };

  const [welcomeModalVisible, setWelcomeModalVisible] = useState(true);

  return (
    <div className={cx("tailwind")}>
      <div className="border-b border-border relative">
        {welcomeModalVisible && <WelcomeBar setWelcomeModalVisible={setWelcomeModalVisible} />}
        <div
          className={cx("w-full flex items-center h-[6.2rem] gap-[1rem] px-[1.5rem] bg-transparent", {
            "bg-background-4-hover dark:bg-[#101014]": isTradePage,
          })}
        >
          <HeaderLogo className="max-lg:block hidden shrink-0" />

          <HeaderTitleAndLogo className="hidden lg:block shrink-0" />

          <div className="hidden lg:block flex-auto @container/links">
            <div className="flex items-center gap-[3rem] @[80rem]/links:gap-[4rem]">
              <ProtocolDropdown />

              {!isDevelopment && (
                <>
                  <NamedLink.Portfolio variant="header" />
                  <NamedLink.Trade variant="header" />
                  <NamedLink.Earn variant="header" />

                  {showNavItemAt(-2) && <NamedLink.Buy variant="header" />}
                  {showNavItemAt(-1) && <NamedLink.Referral variant="header" />}
                  {showNavItemAt(-1) && <NamedLink.Event variant="header" />}

                  {(!showNavItemAt(-2) || !showNavItemAt(-1)) && (
                    <Menu.Menu placement="bottom-end">
                      <Menu.Button>
                        {({ open, ref }) => (
                          <button
                            ref={ref}
                            className={cx(
                              "inline-flex rounded-sm items-center gap-2 cursor-pointer self-stretch text-white",
                              {
                                "font-bold": open,
                                "hover:opacity-60 focus-visible:opacity-60": !open,
                              }
                            )}
                          >
                            <div className="flex-1">
                              <Trans>More</Trans>
                            </div>

                            <div className="w-[1.4rem] h-[1.4rem] border-0 flex items-center justify-center shrink-0">
                              <img src={shevronDown} alt="icon" />
                            </div>
                          </button>
                        )}
                      </Menu.Button>

                      <Menu.Items>
                        {!showNavItemAt(-2) && <NamedLink.Buy variant="header-menu" />}
                        {!showNavItemAt(-1) && <NamedLink.Referral variant="header-menu" />}
                        {!showNavItemAt(-1) && <NamedLink.Event variant="header-menu" />}
                      </Menu.Items>
                    </Menu.Menu>
                  )}
                </>
              )}
              {isDevelopment && (
                <>
                  <NamedLink.Portfolio variant="header" />
                  <NamedLink.Trade variant="header" />
                  <NamedLink.Earn variant="header" />

                  {showNavItemAt(-2) && <NamedLink.Buy variant="header" />}
                  {showNavItemAt(-1) && <NamedLink.Referral variant="header" />}
                  {showNavItemAt(-1) && <NamedLink.Event variant="header" />}

                  {(!showNavItemAt(-2) || !showNavItemAt(-1)) && (
                    <Menu.Menu placement="bottom-end">
                      <Menu.Button>
                        {({ open, ref }) => (
                          <button
                            ref={ref}
                            className={cx(
                              "inline-flex rounded-sm items-center gap-2 cursor-pointer self-stretch text-white",
                              {
                                "font-bold": open,
                                "hover:opacity-60 focus-visible:opacity-60": !open,
                              }
                            )}
                          >
                            <div className="flex-1">
                              <Trans>More</Trans>
                            </div>

                            <div className="w-[1.4rem] h-[1.4rem] border-0 flex items-center justify-center shrink-0">
                              <img src={shevronDown} alt="icon" />
                            </div>
                          </button>
                        )}
                      </Menu.Button>

                      <Menu.Items>
                        {!showNavItemAt(-2) && <NamedLink.Buy variant="header-menu" />}
                        {!showNavItemAt(-1) && <NamedLink.Referral variant="header-menu" />}
                        {!showNavItemAt(-1) && <NamedLink.Event variant="header-menu" />}
                      </Menu.Items>
                    </Menu.Menu>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="max-lg:block hidden flex-auto" />

          {appUpdate.haveAppUpdate && (
            <Button variant="silver" size="sm" className="shrink-0" onClick={appUpdate.updateApp}>
              <Trans>Update Available</Trans>
            </Button>
          )}

          <div className="shrink-0">
            <AppHeaderUser
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
              openSettings={openSettings}
              savedSlippageAmount={savedSlippageAmount}
              setSavedSlippageAmount={setSavedSlippageAmount}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
              tradingLayout={tradingLayout}
              setTradingLayout={setTradingLayout}
            />
          </div>

          <div className="max-lg:block hidden shrink-0">
            <button className="flex justify-center items-center" onClick={() => setIsDrawerVisible(true)}>
              <div className="text-textColor text-[2rem] opacity-70 hover:opacity-90">
                <FiMenu />
              </div>
            </button>
          </div>
        </div>
      </div>

      <Portal>
        <div className="tailwind">
          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                className="fixed inset-0 bg-[rgba(9,_9,_12,_0.65)] h-[100vh]"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  hidden: { opacity: 0, animation: "ease-in" },
                  visible: { opacity: 1, animation: "ease-out" },
                }}
                transition={{ duration: 0.1 }}
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
              ></motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                className="hidden-scrollbar bg-background-1 fixed inset-0 pb-[env(safe-area-inset-bottom)] w-[30.4rem] overflow-y-auto max-w-full"
                initial="hidden"
                animate="visible"
                exit="hidden"
                onClick={onDrawerClick}
                variants={{
                  hidden: { x: "-100%", animation: "ease-in" },
                  visible: { x: 0, animation: "ease-out" },
                }}
                transition={{ duration: 0.2 }}
              >
                <div className="h-[6.2rem] flex items-center z-[3] ml-[1.6rem] justify-between">
                  <HeaderTitleAndLogo />

                  <div className="ml-[1.2rem] mr-[1.2rem]">
                    <button className="flex justify-center items-center" onClick={() => setIsDrawerVisible(false)}>
                      <div className="text-white text-[2rem] opacity-70 hover:opacity-90">
                        <FiX />
                      </div>
                    </button>
                  </div>
                </div>

                {!isDevelopment && (
                  <>
                    <NamedLink.Portfolio variant="drawer" />
                    <NamedLink.Trade variant="drawer" />
                    <NamedLink.Earn variant="drawer" />
                    <NamedLink.Buy variant="drawer" />
                    <NamedLink.Referral variant="drawer" />
                    <NamedLink.Event variant="drawer" />
                  </>
                )}
                {isDevelopment && (
                  <>
                    <NamedLink.Portfolio variant="drawer" />
                    <NamedLink.Trade variant="drawer" />
                    <NamedLink.Earn variant="drawer" />
                    <NamedLink.Buy variant="drawer" />
                    <NamedLink.Referral variant="drawer" />
                    <NamedLink.Event variant="drawer" />
                  </>
                )}

                <div className="buttom-container">
                  <AccountDrawerSection disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Portal>
    </div>
  );
}

type HeaderTitleAndLogoProps = { className?: string };

const HeaderTitleAndLogo = (props: HeaderTitleAndLogoProps) => {
  return (
    <div className={cx(props.className)}>
      <Link className="no-underline !flex items-center gap-[0.8rem] select-none" to="/">
        <LogoImage className="fill-textColor h-[3.1rem]" />

        <div className="text-textColor font-logo leading-[1.5] text-[2rem] font-normal uppercase">Palmswap</div>
      </Link>
    </div>
  );
};

type HeaderLogoProps = { className?: string };

const HeaderLogo = (props: HeaderLogoProps) => {
  return (
    <div className={cx(props.className)}>
      <LogoImage className="fill-textColor h-[3.1rem]" />
    </div>
  );
};
