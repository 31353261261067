import { useWeb3React } from "@web3-react/core";
import { utils } from "ethers";
import { executeRoute, getBridgeChains, getBridgeTokens, getTokenBalance, sendRouteRequest } from "./helpers/lifiSdk";
import React, { useEffect, useState } from "react";
import "./Bridge.css";
import Button from "components/Button/Button";
import { BridgeChain, TransactionData, Token, TokenBalanceResponseType } from "./BridgeTypes";
import { ReactComponent as ShevronDown } from "img/arrowDownWhite.svg";
import { ReactComponent as GasSymbol } from "img/gasSymbol.svg";

function Bridge({ onFocus, onBlur }) {
  // Get all the bridgeChains and bridgeTokens
  const [bridgeChains, setBridgeChains] = useState<BridgeChain[]>([]);
  const [bridgeTokens, setBridgeTokens] = useState<Token>();
  const { account, provider } = useWeb3React();

  let signer = provider?.getSigner();

  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // Function to filter options based on the search query
  const filterOptions = (options) => {
    return options.filter((option) => option.name.toLowerCase().includes(searchQuery));
  };

  const convertToWei = (amount, token) => {
    const amountInWei = utils.parseUnits(amount, token?.decimals);
    return amountInWei.toString();
  };

  const convertToDollar = (amount, decimals) => {
    const toAmountInWei = amount;
    const toAmount = utils.formatUnits(toAmountInWei, decimals);
    const formattedAmount = parseFloat(toAmount).toFixed(2);
    return formattedAmount;
  };

  useEffect(() => {
    async function fetchBridgeChains() {
      try {
        const result = await getBridgeChains();
        setBridgeChains(result as any);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching bridge chains:", error);
      }
    }
    fetchBridgeChains();
  }, []);

  useEffect(() => {
    async function fetchBridgeTokens() {
      try {
        const result = await getBridgeTokens();
        setBridgeTokens(result?.tokens as Token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching bridge chains:", error);
      }
    }
    fetchBridgeTokens();
  }, []);

  // Need to use hard coded value because it would not set selectedFromChain
  const ethChain: BridgeChain = {
    key: "eth",
    chainType: "EVM",
    chain: "ETH",
    id: 1,
    coin: "ETH",
    logoURI: "https://raw.githubusercontent.com/lifinance/types/main/src/assets/icons/chains/ethereum.svg",
    mainnet: true,
    metamask: {
      blockExplorerUrls: ["https://etherscan.io/"],
      chainId: "0x1",
      chainName: "Ethereum Mainnet",
      nativeCurrency: {
        decimals: 18,
        name: "ETH",
        symbol: "ETH",
      },
      rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    },
    multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
    name: "Ethereum",
    nativeToken: {
      address: "0x0000000000000000000000000000000000000000",
      chainId: 1,
      symbol: "ETH",
      decimals: 18,
      name: "ETH",
    },
    tokenlistUrl: "https://gateway.ipfs.io/ipns/tokens.uniswap.org",
  };
  // Bridge FromValues
  const [bridgeFromChains, setBridgeFromChains] = useState<BridgeChain[]>();
  const [bridgeFromTokens, setBridgeFromTokens] = useState<Token>();
  const [selectedFromChain, setSelectedFromChain] = useState<BridgeChain>(ethChain);
  const [selectedFromToken, setSelectedFromToken] = useState<Token>();
  const [bridgeFromAmount, setBridgeFromAmount] = useState("");
  const [tokenBalance, setTokenBalance] = useState("0.00");
  const [gasBalance, setGasBalance] = useState("0.00");

  const [bridgeToChains, setBridgeToChains] = useState<BridgeChain>(bridgeChains[4]);
  const [bridgeToTokens, setBridgeToTokens] = useState<Token>();
  const [selectedToToken, setSelectedToToken] = useState<Token>();
  const [bridgeToAmount, setBridgeToAmount] = useState("");

  const [bridgeResponse, setBridgeResponse] = useState<TransactionData>();
  const [estTime, setEstTime] = useState<number>(0);
  const [gasGost, setGasCost] = useState<string>("0.00");
  const [gasTokenPrice, setGasTokenCost] = useState("0.00");
  const [gasToken, setGasToken] = useState<Token>();
  const [searchQuery, setSearchQuery] = useState("");

  // Dropdowns
  const [isFromBridgeDropdownOpen, setIsFromBridgeDropdownOpen] = useState(false);
  const [isFromTokenDropdownOpen, setIsFromTokenDropdownOpen] = useState(false);
  const [isToTokenDropdownOpen, setIsToTokenDropdownOpen] = useState(false);

  const [warning, setWarning] = useState<boolean>(false);
  const [warningMsg, setWarningMsg] = useState<string>("");

  //Fetch the token balance of any token on any chain

  useEffect(() => {
    async function fetchTokenBalance() {
      try {
        const res = (await getTokenBalance(account, selectedFromToken)) as TokenBalanceResponseType;
        if (res) {
          const formattedBalance = Number(res.amount).toFixed(2);
          setTokenBalance(formattedBalance);
        } else {
          // eslint-disable-next-line no-console
          console.error("Error fetching token balance");
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching token balance:", error);
      }
    }

    async function fetchGasBalance() {
      try {
        if (bridgeFromTokens) {
          const res = (await getTokenBalance(account, bridgeFromTokens[0])) as TokenBalanceResponseType;
          if (res) {
            const formattedBalance = Number(res.amount).toString();

            setGasBalance(formattedBalance);
            setGasTokenCost(res.priceUSD);
          } else {
            // eslint-disable-next-line no-console
            console.error("Error fetching Gas balance");
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching Gas balance:", error);
      }
    }

    fetchGasBalance();
    fetchTokenBalance();
  }, [account, selectedFromToken, bridgeFromTokens]);

  useEffect(() => {
    setBridgeFromChains(bridgeChains);
    setBridgeToChains(bridgeChains[4]);

    if (bridgeTokens) {
      const filteredTokens = bridgeTokens[56].filter(
        (token) =>
          token.address === "0x0000000000000000000000000000000000000000" ||
          token.address === "0x55d398326f99059fF775485246999027B3197955"
      );
      setBridgeToTokens(filteredTokens);
      setSelectedToToken(filteredTokens[0]);

      if (selectedFromChain && bridgeTokens) {
        const bridgeFromTokensArray = bridgeTokens[selectedFromChain!.id];
        setBridgeFromTokens(bridgeFromTokensArray as Token);
        setSelectedFromToken(bridgeFromTokensArray[0]);
        setGasToken(bridgeFromTokensArray[0]);
      }
    }
  }, [bridgeChains, bridgeTokens, selectedFromChain]);

  // Create a warning if the user does not have any enough gas
  useEffect(() => {
    const gbalance = parseFloat(gasBalance);
    const gost = parseFloat(gasGost);
    const tokenPrice = parseFloat(gasTokenPrice);

    const minGas = gost / tokenPrice;
    if (minGas > gbalance) {
      setWarning(true);
      setIsBridgeDisabled(true);
      setWarningMsg(
        `You don't have enough gas to complete the transaction. You need to add at least: ${minGas} ${gasToken?.name}`
      );
    } else {
      setWarning(false);
      setIsBridgeDisabled(false);
    }

    const tBalance = parseFloat(tokenBalance);
    const fromAmount = parseFloat(bridgeFromAmount);

    if (tBalance < fromAmount) {
      setWarning(true);
      setIsBridgeDisabled(true);
      setWarningMsg(`You do not have enough funds in your wallet`);
    } else {
      setWarning(false);
      setIsBridgeDisabled(false);
    }
  }, [gasBalance, gasGost, gasTokenPrice, tokenBalance, bridgeFromAmount, gasToken]);

  // Set the default values for the ToValues, only use BSC for chain and only use BNB and USDT as Tokens
  // We dont need selectedToBridge because we only BSC is available and it already set

  const toggleDropdown = (dropdownType) => {
    switch (dropdownType) {
      case "fromBridge":
        setIsFromBridgeDropdownOpen(!isFromBridgeDropdownOpen);
        setIsFromTokenDropdownOpen(false);
        break;
      case "fromToken":
        setIsFromTokenDropdownOpen(!isFromTokenDropdownOpen);
        setIsFromBridgeDropdownOpen(false);
        setIsToTokenDropdownOpen(false);

        break;
      case "toToken":
        setIsToTokenDropdownOpen(!isToTokenDropdownOpen);
        setIsFromBridgeDropdownOpen(false);
        setIsFromTokenDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  const handleSelectChangeBridge = (chain: BridgeChain) => {
    setSelectedFromChain(chain);
    setIsFromBridgeDropdownOpen(false);
  };

  const handleFromTokenChange = (token: Token) => {
    setSelectedFromToken(token);
    setIsFromTokenDropdownOpen(false);
  };

  const handleToTokenChange = (token: Token) => {
    setSelectedToToken(token);
    setIsToTokenDropdownOpen(false);
  };

  const convertTime = (seconds) => {
    const minutes = Math.ceil(seconds / 60);
    return minutes;
  };

  const onInputChangeBridge = (event) => {
    const inputAmount = event.target.value;
    if (inputAmount === "") {
      setBridgeFromAmount("");
      setBridgeToAmount("0.00");
      setGasCost("0.00");
      setEstTime(0);
      setIsBridgeDisabled(true);
      setBridgeButtonMessage("Bridge");
      return;
    } else {
      setBridgeFromAmount(inputAmount);
      setIsBridgeDisabled(true);
      setBridgeButtonMessage("Fetching...");
      const amountInWei = convertToWei(inputAmount, selectedFromToken);
      if (bridgeFromAmount !== "0") {
        sendRouteRequest({
          fromChainId: selectedFromChain.id,
          fromAmount: amountInWei,
          fromTokenAddress: selectedFromToken?.address,
          toChainId: bridgeToChains.id,
          fromAddress: account,
          toAddress: account,
          toTokenAddress: selectedToToken?.address,
        })
          ?.then((res) => {
            const response = res.routes[0];
            setBridgeResponse(response as TransactionData);
            convertTime(Math.round(response.steps[0].estimate.executionDuration));

            setEstTime(convertTime(Math.round(response.steps[0].estimate.executionDuration)));
            setGasCost(response.gasCostUSD as string);
            const toAmountInDollar = convertToDollar(response.toAmount, selectedToToken?.decimals);
            setBridgeToAmount(toAmountInDollar);
            setIsBridgeDisabled(false);
            setBridgeButtonMessage("Bridge");
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error fetching bridge:", error);
          });
      }
    }
  };

  const [isBridgeDisabled, setIsBridgeDisabled] = useState(true);
  const [bridgeButtonMessage, setBridgeButtonMessage] = useState("Bridge");

  const actionBridge = () => {
    executeRoute(signer, bridgeResponse, selectedFromChain.metamask.chainId);
  };

  return (
    <div className="tailwind">
      <div className="Wallets-description">
        You will need USDT to fund your account balance and ETH for gas fees. USDT will automatically be credited to
        your account balance.
      </div>
      <div className="Wallet-btns">
        <div className="BridgeItemWrapper">
          <div className="Bridge-dropdown-wrapper">
            <div className="Bridge-from-wrapper">
              <p>Bridge from</p>
              <button onClick={() => toggleDropdown("fromBridge")} className="bridge-button-selector">
                <img src={selectedFromChain?.logoURI} alt="" className="bridge-image" />

                {selectedFromChain?.name}
                <ShevronDown className="stroke-textColor" />
              </button>
            </div>
            {isFromBridgeDropdownOpen && (
              <div className="dropdown-wrapper">
                <div className="dropdown-options">
                  <div className="dropdown-search">
                    <input
                      type="text"
                      placeholder="Search chains..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      className="Bridge-search-input"
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    <div className="line"></div>
                  </div>
                  <ul>
                    {filterOptions(bridgeFromChains).map((chain, index) => (
                      <li key={index} onClick={() => handleSelectChangeBridge(chain)}>
                        {chain.logoURI && <img src={chain.logoURI} alt="logo" className="bridge-image" />}
                        {chain.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="Bridge-swap-section currency-swapbox">
            <div className="Bridge-swap-section-bottom ">
              <input
                type="number"
                placeholder="Send"
                className="Bridge-swap-input"
                value={bridgeFromAmount}
                onChange={onInputChangeBridge}
                onFocus={onFocus}
                onBlur={onBlur}
              />

              <button onClick={() => toggleDropdown("fromToken")} className="bridge-button">
                {selectedFromToken?.name}
                <ShevronDown className="stroke-textColor w-[1rem]" />
              </button>
            </div>
          </div>
          <div className="dropdown-wrapper">
            {isFromTokenDropdownOpen && (
              <div className="dropdown-wrapper">
                <div className="dropdown-options">
                  <div className="dropdown-search">
                    <input
                      type="text"
                      placeholder="Search tokens..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      className="Bridge-search-input"
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    <div className="line"></div>
                  </div>
                  <ul>
                    {filterOptions(bridgeFromTokens).map((token, index) => (
                      <li key={index} onClick={() => handleFromTokenChange(token)}>
                        {token.logoURI && <img src={token.logoURI} alt="logo" className="bridge-image" />}
                        {token.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className="Bridge-swap-section-bottom-info">
            <div className="muted Exchange-swap-usd">Available Balance</div>
            <div className="align-right clickable">
              {tokenBalance} {selectedFromToken?.name}
            </div>
          </div>
        </div>

        <div className="line" />
        <div className="BridgeItemWrapper">
          <div className="Bridge-dropdown-wrapper">
            <div className="Bridge-from-wrapper">
              <p>Bridge to</p>
              <button className="bridge-button-selector">
                <img src={bridgeToChains?.logoURI} alt="" className="bridge-image" />

                {bridgeToChains?.name}
              </button>
            </div>
          </div>
        </div>
        <div className="Bridge-swap-section currency-swapbox">
          <div className="Bridge-swap-section-bottom">
            <input
              type="number"
              placeholder="0.0"
              className="Bridge-swap-input"
              disabled
              value={bridgeToAmount}
              onFocus={onFocus}
              onBlur={onBlur}
            />

            <button onClick={() => toggleDropdown("toToken")} className="bridge-button">
              {selectedToToken?.name}
              <ShevronDown className="stroke-textColor h-[1rem]" />
            </button>
          </div>
        </div>

        <div className="dropdown-wrapper">
          {isToTokenDropdownOpen && (
            <div className="dropdown-wrapper">
              <div className="dropdown-options">
                <div className="dropdown-search">
                  <input
                    type="text"
                    placeholder="Search tokens..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="Bridge-search-input"
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                  <div className="line"></div>
                </div>
                <ul>
                  {filterOptions(bridgeToTokens).map((token, index) => (
                    <li key={index} onClick={() => handleToTokenChange(token)}>
                      {token.logoURI && <img src={token.logoURI} alt="logo" className="bridge-image" />}
                      {token.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="line" />
        <div className="Bridge-information-wrapper">
          <div className="Bridge-information-header">
            <p>Bridge Summary</p>
          </div>
          <div className="Bridge-info-item">
            <div className="Bridge-info-left">
              <p className="muted">Bridge From</p>

              <img src={selectedFromChain?.logoURI} alt="" className="bridge-image" />
              <p>{selectedFromChain?.name}</p>
            </div>
            <div className="Bridge-info-right">
              {bridgeFromAmount}
              <img src={selectedFromToken?.logoURI} alt="" className="bridge-image" />
            </div>
          </div>
          <div className="Bridge-info-item">
            <div className="Bridge-info-left">
              <p className="muted">Est. Receive on BSC</p>
            </div>
            <div className="Bridge-info-right">
              {bridgeToAmount}
              <img src={selectedToToken?.logoURI} alt="" className="bridge-image" />
            </div>
          </div>
          <div className="Bridge-info-item">
            <div className="Bridge-info-left">
              <p className="muted">Est. Time</p>
            </div>
            <div className="Bridge-info-right">{estTime}m</div>
          </div>
          <div className="Bridge-info-item">
            <div className="Bridge-info-left">
              <p className="muted">Est. Gas</p>
            </div>
            <div className="Bridge-info-right">${gasGost}</div>
          </div>
        </div>
        {warning && (
          <div className="Bridge-warning">
            <div className="Bridge-warning-header">
              <GasSymbol />
              <span>Insufficient balance</span>
            </div>
            <div className="Bridge-warning-info">{warningMsg}</div>
          </div>
        )}

        <Button
          type="button"
          variant="primary-action"
          className="w-full capitalize"
          disabled={isBridgeDisabled}
          onClick={() => actionBridge()}
        >
          {bridgeButtonMessage}
        </Button>
      </div>
    </div>
  );
}

export default Bridge;
