import React, { useCallback, useState } from "react";
import "./StakeV3.scss";
import { t, Trans } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { formatAmount } from "lib/numbers";
import { GLP_DECIMALS } from "lib/constants";
import { QuickContractCallOptions, useQuickContracts } from "lib/contracts";
import { useChainId } from "lib/chains";
import { BigNumberish } from "ethers";
import { getContractAddress } from "config/contracts";
import { getTokenBySymbol } from "config/tokens";
import { addHours, getUnixTime } from "date-fns";
import usePythService from "../../hooks/usePythService";

export const CompoundableTokens = {
  PALM: "PALM",
  PLP: "PLP",
} as const;

type Props = {
  open: boolean;
  setOpen: () => void;
  amount: BigNumberish;
  token: keyof typeof CompoundableTokens;
};

const CompoundModal = ({ open, setOpen, amount, token }: Props) => {
  const { chainId } = useChainId();
  const [isLoading, setIsLoading] = useState(false);
  const quickContracts = useQuickContracts();
  const { priceFeedsUpdateData } = usePythService();

  const compoundContractCall = useCallback(
    async (options: QuickContractCallOptions) => {
      if (token === CompoundableTokens.PLP) {
        const minUsdp = await quickContracts.fetch("Vault", "estimateUSDPOut", [amount]);
        const minPlp = await quickContracts.fetch("PlpManager", "estimatePlpOut", [priceFeedsUpdateData, amount]);
        return quickContracts.call("PlpRewardRouter", "compoundPlp", [priceFeedsUpdateData, minUsdp, minPlp], options);
      } else {
        const [, , minAmountOut] = await quickContracts.fetch("UniswapRouter", "getAmountsOut", [
          amount,
          [
            getTokenBySymbol(chainId, "USDT").address,
            getTokenBySymbol(chainId, "WBNB").address,
            getContractAddress(chainId, "PALM"),
          ],
        ]);
        return quickContracts.call(
          "PalmRewardRouter",
          "compoundPalm",
          [minAmountOut, getUnixTime(addHours(new Date(), 1))],
          options
        );
      }
    },
    [amount, chainId, quickContracts, token, priceFeedsUpdateData]
  );

  const compound = useCallback(async () => {
    setIsLoading(true);
    try {
      await compoundContractCall({
        value: 0,
        sentMsg: t`Compound request submitted.`,
        successMsg: t`Successfully compounded ${formatAmount(amount, GLP_DECIMALS, 2)} USDT`,
        failMsg: t`Compound request failed.`,
        txnSuccessCallback: () => {
          setIsLoading(false);
        },
      });
    } catch (e) {
      setIsLoading(false);
    }
  }, [compoundContractCall, amount]);

  return (
    <Modal isVisible={open} setIsVisible={setOpen} label={t`Compound`} className="tailwind">
      <div>
        <div className="Stake-modal-top-section mb-[2rem">
          <div className="inactive-text text-center mb-3">
            <Trans>Kindly note that there is a transaction fee associated with this action.</Trans>
          </div>
          <div className="flex flex-col items-stretch gap-2 px-6 py-4 border border-border rounded-sm bg-background-3">
            <div className="inactive-text">
              <Trans>Amount</Trans>
            </div>
            <div className="text-4xl my-3">{formatAmount(amount, GLP_DECIMALS, 4, true)} USDT</div>
            <div className="inactive-text">~${formatAmount(amount, GLP_DECIMALS, 4, true)}</div>
          </div>
        </div>
        <div className="Stake-modal-bottom-section">
          <div className="flex justify-between mt-5 mb-3 inactive-text" />
          <Button variant="primary-action" onClick={compound} disabled={isLoading}>
            <Trans>Compound</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompoundModal;
