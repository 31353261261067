export const isInsideTag = (el: HTMLElement | null, tagNames: string[]): boolean => {
  const tagNamesUppercase = tagNames.map(el => el.toUpperCase())

  while(el) {
    const tagName = el.tagName

    if (tagNamesUppercase.includes(tagName)) {
      return true
    }

    el = el.parentElement
  }
  return false
} 
