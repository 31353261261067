import { createSubgraph } from "./utils";
import { ETH_MAINNET, SUPPORTED_CHAIN_IDS } from "config/chains";
import { SUBGRAPH_URLS, SupportedChainSubgraph } from "config/subgraph";

export const chainlinkSubgraph = createSubgraph(ETH_MAINNET, "chainLink");

export function getPalmSubgraph(
  chainId: number,
  subgraph: SupportedChainSubgraph
) {
  if (!SUPPORTED_CHAIN_IDS.includes(chainId)) {
    throw new Error(`Unsupported chain ${chainId}`);
  }

  return createSubgraph(
    chainId as Exclude<
      keyof typeof SUBGRAPH_URLS,
      typeof ETH_MAINNET
    >,
    subgraph as any
  );
}
