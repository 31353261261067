import React, { Fragment, ReactNode } from "react";
import { Menu as HeadlessMenu } from "@headlessui/react";
import cx from "classnames";
import { usePopper } from "react-popper";
import Portal from "components/Common/Portal";
import { Placement } from "@popperjs/core";
import { OffsetsFunction } from "@popperjs/core/lib/modifiers/offset";
import useThemeSwitcher from "lib/useThemeSwitcher";

type MenuContextData = {
  setReferenceElement: (element: HTMLElement | null) => void;
  setPopperElement: (element: HTMLElement | null) => void;
  styles: ReturnType<typeof usePopper>['styles'],
  attributes: ReturnType<typeof usePopper>['attributes'],
}

const MenuContext = React.createContext<MenuContextData | undefined>(undefined);

export const useMenuContext = (): MenuContextData => {
  const context = React.useContext(MenuContext);
  if (!context) {
    throw new Error("`useMenuContext` must be used within a `MenuDropdown`");
  }
  return context;
};

type Props = {
  className?: string;
  children?: React.ReactNode;
  placement: Placement;
};

function MenuDropdown(props: Props) {
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);

  const offsetFn: OffsetsFunction = React.useCallback(({ placement, reference, popper }) => {
    return [0, 7.5];
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    ...props.placement && {
      placement: props.placement,
    },
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offsetFn,
        },
      },
    ],
  });

  return (
    <div className={cx("tailwind relative flex", props.className)}>
      <MenuContext.Provider value={{ setReferenceElement, setPopperElement, styles, attributes }}>
        <HeadlessMenu>
          { props.children }
        </HeadlessMenu>
      </MenuContext.Provider>
    </div>
  );
}

type MenuButtonProps = {
  children: (
    params: {
      open: boolean,
      ref: (element: HTMLElement | null) => void
    }
  ) => JSX.Element;
};

const MenuButton = (props: MenuButtonProps) => {
  const { setReferenceElement } = useMenuContext()

  return (
    <HeadlessMenu.Button as={Fragment}>
      {
        ({ open }) => props.children({open, ref: setReferenceElement})
      }
    </HeadlessMenu.Button>
  )
}

type MenuItemsProps = {
  className?: string;
  children?: ReactNode;
};

const MenuItems = (props: MenuItemsProps) => {
  const { setPopperElement, attributes, styles } = useMenuContext()

  const {isDarkTheme} = useThemeSwitcher() ;

  return (
    <Portal>
      <HeadlessMenu.Items
        as="div"
        className="tailwind"
        {...attributes.popper}
        ref={setPopperElement}
        style={styles.popper}
      >
        <div 
          className={cx("menu-items",props.className, {
            "bg-background-4-hover": !isDarkTheme,
            "bg-[#16171d]": isDarkTheme,
          })}>

 
        
          { props.children }
        </div>
      </HeadlessMenu.Items>
    </Portal>
  )
}

type MenuLabelProps = {
  className?: string;
  children: ReactNode;
};

const MenuLabel = (props: MenuLabelProps) => {
  return (
    <div className={cx([props.className, "menu-label"])}>
      {props.children}
    </div>
  )
}

type MenuItemProps = {
  className?: string;
  children:
    | ReactNode
    | ((params: {close: () => void, className?: string}) => JSX.Element);
};

const MenuItem = (props: MenuItemProps) => {
  return (
    <HeadlessMenu.Item>
      {({ close }) => (
        <>
          {
            typeof props.children === 'function' 
            ? props.children({
              className: cx([props.className, "menu-item"]),
              close: close,
            })
            : React.Children.map(props.children, (child) => {
              if (!React.isValidElement(child)) {
                return child
              }
              return React.cloneElement(child as React.ReactElement<{ className?: string, onClick?: (value: any) => void }>, {
                className: cx([child.props.className, props.className, "menu-item"]),
                onClick: (value: any) => {
                  child.props.onClick?.(value)
                  close()
                }
              });
            })
          }
        </>
      )}
    </HeadlessMenu.Item>
  )
}

export const Menu = {
  Menu: MenuDropdown,
  Button: MenuButton,
  Item: MenuItem,
  Items: MenuItems,
  Label: MenuLabel,
}
