import React from "react";
import cx from "classnames";

type Props = React.ComponentProps<"div"> & {
  children: React.ReactNode;
  className?: string;
};

const ExchangeTable = (props: Props) => {
  return (
    <table className={cx("Exchange-list App-box", props.className)}>
      <tbody>{props.children}</tbody>
    </table>
  );
};

export default ExchangeTable;
