import { UI_VERSION } from "config/env"
import React from "react";
import useSWR from "swr";

type RemoteVersionInfo = {
  version: string
} | undefined

export const useAppUpdate = () => {
  const localVersion = UI_VERSION
  const remoteVersionQuery = useSWR<RemoteVersionInfo>([`version`], {
    async fetcher() {
      return await fetch('/version.json').then(el => el.json()) as RemoteVersionInfo
    },
  });

  const haveAppUpdate = React.useMemo(() => {
    if (
      remoteVersionQuery.data?.version
      && remoteVersionQuery.data?.version > localVersion
    ) {
      return true
    }

    return false
  }, [remoteVersionQuery.data, localVersion])

  const updateApp = () => {
    window.location.reload()
  }

  return {
    haveAppUpdate,
    updateApp,
  }
}
