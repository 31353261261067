import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import PlpToken from "img/plpToken.png";
import cx from "classnames";
import { CHART_PERIODS, CHART_PERIODS_PLP } from "./constants";
import { formatAmount } from "lib/numbers";
import LightweightChart from "./LightweightChart";
import { BigNumber } from "ethers";
import { USDP_DECIMALS } from "lib/legacy";
import { convertPriceToChartData } from "pages/Buy/helpers/generateChartData";
import { usePlpStats } from "domain/stats/useGlpStats";
import parse from "parse-duration";
import { GlpStatPeriod } from "domain/stats/types";

const usePlpTokenPrice = () => {
  const { data: plpPriceHistory } = usePlpStats(1);
  const lastSubgraphPrice = plpPriceHistory[0]?.price || BigNumber.from(1);
  const currentPlpPrice = parseFloat(formatAmount(lastSubgraphPrice, USDP_DECIMALS, 3, true));

  return currentPlpPrice;
};

function __PlpChart() {
  const plpTokenPrice = usePlpTokenPrice();
  const [plpHoveredPrice, setPlpHoveredPrice] = useState<number | undefined>();
  const [startPeriod, setStartPeriod] = useState(CHART_PERIODS_PLP[0].value);

  const periodParams = React.useMemo<{ limit: number; period: GlpStatPeriod }>(() => {
    switch (startPeriod) {
      case CHART_PERIODS.DAY: {
        return {
          limit: Math.floor(parse("1 day", "hour") ?? 0),
          period: "hourly",
        };
      }
      case CHART_PERIODS.WEEK: {
        return {
          limit: Math.floor(parse("1 week", "hour") ?? 0),
          period: "hourly",
        };
      }
      case CHART_PERIODS.MONTH: {
        return {
          limit: Math.floor(parse("1 month", "day") ?? 0),
          period: "daily",
        };
      }
      case CHART_PERIODS.YEAR: {
        return {
          limit: Math.floor(parse("1 year", "day") ?? 0),
          period: "daily",
        };
      }
    }
  }, [startPeriod]);

  const { data: hourlyPlpStats, isLoading, haveError } = usePlpStats(periodParams.limit, periodParams.period);
  const formattedHistoricalData = React.useMemo(() => {
    return convertPriceToChartData(hourlyPlpStats, startPeriod)[0].data.map((el) => {
      return {
        time: new Date(el.x).getTime() / 1000,
        value: parseFloat(formatAmount(el.y, USDP_DECIMALS, USDP_DECIMALS, true)),
      };
    });
  }, [hourlyPlpStats, startPeriod]);

  const isActiveLightweightChartPeriod = (period) => {
    return startPeriod === period;
  };

  return (
    <div className="w-full flex flex-col gap-[2.5rem]">
      <div className="flex-col flex gap-[2.5rem] mb-[2rem]">
        <div className="flex-col flex">
          <div className="flex items-center gap-3 mb-5">
            <img className="w-[4.5rem] h-[4.5rem] " src={PlpToken} alt="PLP" />
            <p className="text-[2.0rem] font-semibold">
              <Trans>PLP Price</Trans>
            </p>
          </div>

          <p className="text-[3.6rem] font-semibold">${(plpHoveredPrice ?? plpTokenPrice).toFixed(4)}</p>
        </div>
      </div>

      <div className="flex-col flex border-b dark:border-border border-[#D4DAE7]">
        <LightweightChart
          historicalData={formattedHistoricalData}
          isLoading={isLoading}
          isError={haveError}
          setHoveredPrice={setPlpHoveredPrice}
          lastPrice={plpTokenPrice}
        />
      </div>
      <div className="flex md:justify-end justify-center">
        {CHART_PERIODS_PLP.map((period) => (
          <span
            key={period.value}
            onClick={() => setStartPeriod(period.value)}
            className={cx(
              "w-[5rem] h-[3rem] text-inactive cursor-pointer leading-[3rem] align-middle text-center rounded-[1rem]",
              {
                "bg-background-3 text-textColor": isActiveLightweightChartPeriod(period.value),
              }
            )}
          >
            {period.label}
          </span>
        ))}
      </div>
    </div>
  );
}

export const PlpChart = React.memo(__PlpChart);
