import { Trans, t } from "@lingui/macro";
import { shortenAddress } from "lib/legacy";
import { getUSDValue } from "../referralsHelper";
import { getExplorerUrl } from "config/chains";
import { formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import usePagination from "./usePagination";
import Pagination from "components/Pagination/Pagination";
import { GradientContainer } from "components/GradientContainer";
import Button from "components/Button/Button";
import { InfoCard, InfoCardList } from "components/InfoCard";
import { EmptyPlaceholder } from "components/EmptyPlaceholder";
import { ParsedTierInfo } from "domain/referrals.types";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { decodeReferralCode, getTrader } from "domain/referrals";
import format from "date-fns/format";
import { useMemo } from "react";
import { TokenUtils } from "components/TokenUtils";

type TradersStatsProps = {
  referralsData: any;
  traderTier: number | undefined;
  chainId: number;
  userReferralCodeString: string;
  onCreateReferralCode: () => void;
  tiersInfo: ParsedTierInfo[] | undefined;
};

function TradersStats({
  referralsData,
  traderTier,
  chainId,
  userReferralCodeString,
  onCreateReferralCode,
  tiersInfo,
}: TradersStatsProps) {
  const { getCurrentData, currentPage, setCurrentPage, pageCount } = usePagination(
    referralsData?.discountDistributions
  );

  const { account } = useWeb3React();
  const { data: trader } = useSWR(
    ["referral", "trader", chainId, account],
    ([, , chainId, account]) => {
      if (!account) {
        return null;
      }
      return getTrader(chainId, account);
    },
    {
      isPaused: () => !account,
    }
  );

  const currentDiscountDistributions = getCurrentData() as any[];
  const tierDiscountInfo = tiersInfo?.map((el) => el.traderDiscount);

  const isReferralCodeDateLoading = useMemo(() => {
    return !trader || !trader?.referralCode || userReferralCodeString !== decodeReferralCode(trader?.referralCode);
  }, [trader, userReferralCodeString]);

  return (
    <div className="">
      <GradientContainer className="mt-8">
        <div className="flex flex-col sm:flex-row gap-4 items-start md:items-center p-8">
          <div className="flex flex-col flex-1">
            <p className="text-[2.4rem] leading-[1.4] font-extrabold font-mazzard text-white">{userReferralCodeString}</p>

            <p className="text-[1.4rem] leading-[1.4] relative text-white">
              {isReferralCodeDateLoading ? (
                <>
                  <br />
                  <div className="skeleton !absolute top-1/2 -translate-y-1/2"></div>
                </>
              ) : (
                t`Applied at ${format(trader!.referralCodeUpdateTimestamp, `MMM d, yyyy`)}`
              )}
            </p>
          </div>

          <Button variant="white" className="min-w-[17rem] flex gap-2" onClick={onCreateReferralCode}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path d="m2.695 14.763-1.262 3.154a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.885L17.5 5.5a2.121 2.121 0 0 0-3-3L3.58 13.42a4 4 0 0 0-.885 1.343z" />
            </svg>

            <span>
              <Trans>Edit Referral Code</Trans>
            </span>
          </Button>
        </div>
      </GradientContainer>

      <InfoCardList className="mt-12">
        <InfoCard
          label={t`Trading Volume`}
          tooltipText={t`Volume traded by this account with an active referral code.`}
          data={getUSDValue(referralsData?.referralTotalStats?.volume)}
          iconType="exchange"
        />
        <InfoCard
          label={t`Discount Rewards`}
          tooltipText={t`Rewards earned by this account as a trader.`}
          data={getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
          iconType="gift"
        />
        <InfoCard
          label={t`Discount`}
          tooltipText={t`Discount for this account as a trader.`}
          data={traderTier && `${tierDiscountInfo?.[traderTier] ?? 0}%`}
          iconType="discount"
        />
      </InfoCardList>

      <div className="reward-history">
        <section className="mt-[5rem] flex items-center justify-between gap-8">
          <div className="flex flex-col gap-4">
            <h1 className="text-[2.4rem] leading-[1.4] font-medium">
              <Trans>Rewards Distribution History</Trans>
            </h1>

            <p className="text-[1.4rem] leading-[1.5] text-inactive flex flex-wrap item-center gap-[1ch]">
              <span>
                <Trans>Rewards are airdropped weekly.</Trans>
              </span>
            </p>
          </div>
        </section>

        <div className="relative @container/table mt-12">
          <table className="w-full">
            <thead>
              <tr>
                {[t`Date`, t`Amount`, t`Transaction ID`, t`Status`].map((item) => (
                  <th
                    className="first:rounded-l-sm last:rounded-r-sm bg-background-3 h-14 text-[1.2rem] leading-[1.5] text-inactive font-normal text-left px-6 first:w-1/4"
                    scope="col"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentDiscountDistributions.length > 0 ? (
                currentDiscountDistributions.map((rebate, index) => {
                  let tokenInfo;
                  try {
                    tokenInfo = getToken(chainId, rebate.token);
                  } catch {
                    tokenInfo = getNativeToken(chainId);
                  }
                  const explorerURL = getExplorerUrl(chainId);
                  return (
                    <tr key={index} className="border-b">
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Date">
                        {formatDate(rebate.timestamp)}
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Amount">
                        {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {TokenUtils.getSymbol(tokenInfo)}
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Transaction ID">
                        <ExternalLink
                          href={explorerURL + `tx/${rebate.transactionHash}`}
                          className="text-white mr-2 d-flex items-center"
                        >
                          {shortenAddress(rebate.transactionHash, 13)}
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                            <path
                              d="M 5.22 14.78 C 5.513 15.072 5.987 15.072 6.28 14.78 L 13.5 7.56 L 13.5 13.25 C 13.5 13.664 13.836 14 14.25 14 C 14.664 14 15 13.664 15 13.25 L 15 5.75 C 15 5.336 14.664 5 14.25 5 L 6.75 5 C 6.336 5 6 5.336 6 5.75 C 6 6.164 6.336 6.5 6.75 6.5 L 12.44 6.5 L 5.22 13.72 C 4.928 14.013 4.928 14.487 5.22 14.78 Z"
                              fill="rgb(108, 114, 132)"
                            ></path>
                          </svg>
                        </ExternalLink>
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Status">
                        <Trans>Received</Trans>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="py-[2rem]">
                    <EmptyPlaceholder message={t`No rewards history yet.`} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination page={currentPage} pageCount={pageCount} onPageChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
}

export default TradersStats;
