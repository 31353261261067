import { gql } from "@apollo/client";
import { BigNumber } from "ethers";

import { getPalmSubgraph } from "lib/subgraph/clients";
import { bigNumberify, expandDecimals } from "lib/numbers";
import { USDP_DECIMALS } from "lib/legacy";
import { GlpStat, GlpStatPeriod } from "./types";
import { useChainId } from "lib/chains";
import useSWR from "swr";

export type PlpPriceStoryItem = {
  timestamp: number;
  price: BigNumber;
};

export const PRECISION = "10000";

const MAX_GRAPH_ITEMS = 1000

export function usePlpStats(
  limit = 1000,
  period: GlpStatPeriod = 'total'
) {
  const { chainId } = useChainId();

  limit = Math.max(
    1,
    Math.floor(
      Math.min(limit, MAX_GRAPH_ITEMS)
    )
  )

  async function getStats(): Promise<PlpPriceStoryItem[]> {
    const client = getPalmSubgraph(chainId, "stats");
    const query = gql`
      query stats($period: String!) {
        glpStats (
          first: ${limit},
          orderBy: id,
          orderDirection: desc,
          where: { period: $period }
        ) {
          id
          aumInUsdg
          glpSupply
          period
        }
      }
    `;

    const res = await client!.query<{ glpStats: GlpStat[] }>({
      query,
      variables: { period },
    });

    const stats = res?.data?.glpStats;

    if (!stats) {
      return []
    }

    const prices = stats.reduce((accumPrices: PlpPriceStoryItem[], stat) => {
      const aumInUsdp = bigNumberify(stat.aumInUsdg);
      const plpSupply = bigNumberify(stat.glpSupply);

      if (aumInUsdp && !aumInUsdp.isZero() && plpSupply && !plpSupply.isZero()) {
        const price = aumInUsdp.mul(PRECISION).div(plpSupply).mul(expandDecimals(1, USDP_DECIMALS)).div(PRECISION);
        return [...accumPrices, { timestamp: Number(stat.id), price }];
      }

      return accumPrices;
    }, []);

    return prices
  }

  const { data, error } = useSWR<PlpPriceStoryItem[]>(["plpStats", chainId, period, limit], {
    fetcher: getStats,
  });

  return {
    data: (data ?? []) as PlpPriceStoryItem[],
    isLoading: !data && !error,
    haveError: !!error,
  }
}
