import React, { useEffect, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";

import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import { usePlpUnstakeCooldown } from "lib/usePlpUnstakeCooldown";
import formatDistanceStrict from "date-fns/formatDistanceStrict";

const AfterBuyPlpModal = ({ open, setOpen, action }: { open: boolean; setOpen: (value: boolean) => void; action: "buy" | "sell" }) => {
  const [hideModal, setHideModal] = useState(false);

  const getTitle = () => {
    if (action === "buy") {
      return t`PLP Staked Successfully`;
    } else return t`PLP Unstaked Successfully`;
  };

  const plpUnstakeCooldown = usePlpUnstakeCooldown()
  const plpUnstakeCooldownText = formatDistanceStrict(plpUnstakeCooldown, 0, { roundingMethod: "ceil" })

  const getInfoText = () => {
    if (action === "buy") {
      return t`Your purchased PLP tokens are now in the staking pool to earn 50% of the trading fees generated by the Palmswap Exchange. You can unstake the PLP tokens at any time. After a cooldown period of ${plpUnstakeCooldownText}, you can sell your PLP tokens.`;
    } else
      return t`Your PLP tokens are now unstaked and in a cool down period, after ${plpUnstakeCooldownText} you can sell your PLP tokens.`;
  };

  const stakeLink = "/earn";

  useEffect(() => {
    if (hideModal) {
      localStorage.setItem("doNotShowModalAfterBuyOrSellPLP", JSON.stringify(hideModal));
    } else {
      localStorage.setItem("doNotShowModalAfterBuyOrSellPLP", JSON.stringify(hideModal));
    }
  }, [hideModal]);

  const isModalVisible = !hideModal && open;

  return (
    <Modal className="Buy-plp-modal" isVisible={isModalVisible} setIsVisible={setOpen} label={`${getTitle()}`}>
      <div className="flex flex-col tailwind isolate">
        <div className="border border-border rounded-[1rem] px-[1.4rem] py-[1.8rem] flex gap-[0.8rem] text-inactive text-[1.4rem] leading-[2.4rem] w-full">
          <HiInformationCircle className="my-[.4rem]"/>
          <div className="w-fit">{getInfoText()}</div>
        </div>
      </div>

      <div className="flex items-center mt-[1.5rem] mb-[3rem]">
        <input
          type="checkbox"
          className="Buy-checkbox"
          id="Buy-checkbox"
          checked={hideModal}
          onChange={() => setHideModal(!hideModal)}
        />
        <label htmlFor="Buy-checkbox">
          <span className="ml-[0.8rem] text-inactive text-[1.4rem whitespace-nowrap">
            <Trans>Don’t show this message again</Trans>
          </span>
        </label>
      </div>

      <Link to={stakeLink} className="no-underline">
        <Button variant={"minor"} className="w-full">
          <Trans>Visit "Earn" page</Trans>
        </Button>
      </Link>
    </Modal>
  );
};

export default AfterBuyPlpModal;
