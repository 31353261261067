import React from "react";
import "./Switch.css";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

type Props = React.ComponentProps<'label'> & {
  checked: boolean,
  onChange: (value: boolean) => void
}

export const Switch = ({ checked, onChange, className }: Props) => {
  const inputId = React.useMemo(() => {
    return uuidv4();
  }, []);

  return (
    <>
      <label htmlFor={inputId} className={cx("switch", className)}>
        <input
          id={inputId}
          role="switch"
          type="checkbox"
          checked={checked}
          aria-checked={checked}
          onChange={(event) => onChange(event.target.checked)}
        />
        <span className="slider" />
      </label>
    </>
  )
};
