import parse from "parse-duration";
import React from "react";
import useInterval from "react-use/lib/useInterval";

const oneSec = parse("1 sec") as number;

const getRoundedTimestamp = () => {
  const now = Date.now();
  return now - (now % oneSec);
};

export const useCurrentTimestamp = (interval: number = 1000) => {
  const [timestamp, setTimestamp] = React.useState(getRoundedTimestamp());

  useInterval(() => {
    setTimestamp(getRoundedTimestamp());
  }, interval);

  return timestamp;
};
