import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import parse from "parse-duration";
import { AddressZero } from "@ethersproject/constants";
import { Epoche } from "pages/Event/events/TradeToEarnEvent/types";
import {
  getEpocheDistributionDay,
  getEpocheEndDay,
  getTotalEpoches,
} from "pages/Event/events/TradeToEarnEvent/utils/epocheDates";
import { BigNumber } from "@ethersproject/bignumber";
import isEqual from "lodash/isEqual";
import useSWR from "swr";
import { useQuickContracts } from "lib/contracts/useQuickContracts";

export const useEpochesHistoryQuery = () => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const usedAccount = account ?? AddressZero;
  const quickContracts = useQuickContracts();

  const fetcher = async (): Promise<{ endedEpoches: Epoche[]; currentDay: number }> => {
    const [tier1StartIndex, endIndex, claimInfo] = await Promise.all([
      quickContracts.fetch("Trade2Earn", "tier1StartIndex", []),
      quickContracts.fetch("Trade2Earn", "endIndex", []),
      quickContracts.fetch("Trade2Earn", "claimInfo", [usedAccount]),
    ]);

    const totalEpoches = getTotalEpoches(tier1StartIndex, endIndex);

    const currentDate = new Date();
    const currentDay = Math.floor(parse(`${currentDate.valueOf()} ms`, "day") ?? 0);

    const lastEndedEpocheIndex = (() => {
      const daysDelta = currentDay - tier1StartIndex.toNumber();

      if (daysDelta < 1) {
        return null;
      }
      if (daysDelta > totalEpoches + 1) {
        return tier1StartIndex.toNumber() + totalEpoches;
      }
      return tier1StartIndex.toNumber() + daysDelta - 1;
    })();

    const endedEpochesIndexes: number[] = (() => {
      if (lastEndedEpocheIndex === null) {
        return [];
      }

      const indexes: number[] = [];
      let i = lastEndedEpocheIndex;
      while (i >= tier1StartIndex.toNumber()) {
        indexes.push(i);
        i -= 1;
      }

      return indexes;
    })();

    const rewardedDaysIndexes: number[] = claimInfo.dayIndices.map((el) => el.toNumber());

    const endedEpoches = endedEpochesIndexes.map((epocheIndex) => {
      const rewardedDayIndex = rewardedDaysIndexes.findIndex((dayIndex) => dayIndex === epocheIndex);

      const endDay = getEpocheEndDay(epocheIndex);
      const rewardDistributionDay = getEpocheDistributionDay(epocheIndex);

      const earnedTp = rewardedDayIndex === -1 ? BigNumber.from(0) : claimInfo.tp[rewardedDayIndex];
      const rewardsPalm = rewardedDayIndex === -1 ? BigNumber.from(0) : claimInfo.rewardPerDay[rewardedDayIndex];
      const epocheClaimed = rewardedDayIndex === -1 ? false : claimInfo.claimedState[rewardedDayIndex];
      const rewardsClaimablePalm = (() => {
        if (rewardedDayIndex === -1) {
          return BigNumber.from(0);
        }
        if (currentDay >= rewardDistributionDay && !epocheClaimed) {
          return rewardsPalm;
        }
        return BigNumber.from(0);
      })();

      const rewardsClaimedPalm = (() => {
        if (rewardedDayIndex === -1) {
          return BigNumber.from(0);
        }
        if (epocheClaimed) {
          return rewardsPalm;
        }
        return BigNumber.from(0);
      })();

      return {
        displayedIndex: epocheIndex - tier1StartIndex.toNumber() + 1,
        index: epocheIndex,
        endDay,
        earnedTp,
        rewardDistributionDay,
        rewardsPalm,
        rewardsClaimablePalm,
        rewardsClaimedPalm,
      } satisfies Epoche;
    });

    return {
      endedEpoches,
      currentDay,
    };
  };

  const query = useSWR([`TradeToEarnEvent:epoches-history:${isActive}`, chainId, usedAccount], fetcher, {
    compare: isEqual,
    refreshInterval: parse("1 min"),
  });

  return query;
};
