import { MAINNET, TESTNET } from "config/chains";
import testnet from "img/ic_bsc.svg";

const ICONS = {
  [MAINNET]: {
    network: testnet,
  },
  [TESTNET]: {
    network: testnet,
  },
};

export function getNetworkIcon<TChainId extends keyof typeof ICONS>(
  chainId: TChainId
) {
  return ICONS[chainId].network
}
