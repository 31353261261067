import React, { useEffect, useRef } from "react";
import { Trans } from "@lingui/macro";
import { helperToast } from "lib/helperToast";
import { getExplorerUrl } from "config/chains";
import { useWeb3React } from "@web3-react/core";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import isEqual from "lodash/isEqual";
import { atom, useAtom } from "jotai";

type Txn = {
  hash: string;
  message: string;
  successCallback?: () => void;
};

const pendingTxnsAtom = atom<Txn[]>([])
let totalInstances = 0

export const usePendingTxns = (): [Txn[], React.Dispatch<React.SetStateAction<Txn[]>>] => {
  const { chainId, provider } = useWeb3React();
  const [pendingTxns, setPendingTxns] = useAtom(pendingTxnsAtom);
  const currentInstanceIndex = useRef(totalInstances)

  useEffect(() => {
    totalInstances += 1

    return () => {
      totalInstances -= 1
    }
  }, [])

  useEffect(() => {
    if (currentInstanceIndex.current !== 0) {
      return
    }

    const checkPendingTxns = async () => {
      const updatedPendingTxns: Txn[] = [];
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i];
        const receipt = await provider?.getTransactionReceipt(pendingTxn.hash);
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.error(
              <div>
                <Trans>
                  Txn failed. <ExternalLink href={txUrl}>View</ExternalLink>
                </Trans>
                <br />
              </div>
            );
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(chainId) + "tx/" + pendingTxn.hash;
            helperToast.success(
              <div>
                {pendingTxn.message}{" "}
                <ExternalLink href={txUrl}>
                  <Trans>View</Trans>
                </ExternalLink>
                <br />
              </div>
            );
          }

          if (pendingTxn.successCallback) {
            pendingTxn.successCallback();
          }

          continue;
        }
        updatedPendingTxns.push(pendingTxn);
      }

      if (!isEqual(updatedPendingTxns, pendingTxns)) {
        setPendingTxns(updatedPendingTxns);
      }
    };

    const interval = setInterval(() => {
      checkPendingTxns();
    }, 2 * 1000);

    return () => clearInterval(interval);
  }, [provider, pendingTxns, chainId, setPendingTxns]);

  return [pendingTxns, setPendingTxns];
};
