import { useRef, useState } from "react";
import { t } from "@lingui/macro";
import "./SettingsDropdown.css";
import { defaultLocale } from "lib/i18n";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import settings from "img/settings.svg";
import SettingsModal from "components/Exchange/Settings";
import { useChainId } from "lib/chains";
import { getConstant } from "config/chains";
import { getTokenBySymbol } from "config/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { HeaderIconButton } from "components/Header/HeaderIconButton";

export default function SettingsDropdown(props: any) {
  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);
  const [activeModal] = useState<string | null>(null);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const chainId = useChainId();

  const isConnectedNetworkUnsupported = props.networkOptions.some((network) => {
    return props.selectorLabel !== network.label;
  });

  const defaultCollateralSymbol = getConstant(chainId.chainId, "defaultCollateralSymbol");

  const [shortCollateralAddress] = useLocalStorageByChainId(
    chainId.chainId,
    "Short-Collateral-Address",
    getTokenBySymbol(chainId.chainId, defaultCollateralSymbol).address
  );

  return (
    <DesktopDropdown
      currentLanguage={currentLanguage}
      activeModal={activeModal}
      isSettingsVisible={isSettingsVisible}
      setIsSettingsVisible={setIsSettingsVisible}
      setIsVisible={setIsVisible}
      isVisible={isVisible}
      disableBodyScrollLock={false}
      isConnectedNetworkUnsupported={isConnectedNetworkUnsupported}
      chainId={chainId.chainId}
      tokenAddress={shortCollateralAddress}
      openSettings={props.openSettings}
      setSavedSlippageAmount={props.setSavedSlippageAmount}
      savedSlippageAmount={props.savedSlippageAmount}
      setSlippageAmount={props.setSlippageAmount}
      savedShouldShowPositionLines={props.savedShouldShowPositionLines}
      setSavedShouldShowPositionLines={props.setSavedShouldShowPositionLines}
      tradingLayout={props.tradingLayout}
      setTradingLayout={props.setTradingLayout}
      {...props}
    />
  );
}

function DesktopDropdown({
  isVisible,
  setIsVisible,
  chainId,
  shortCollateralAddress,
  openSettings,
  setDisableBodyScrollLock,
  savedSlippageAmount,
  setSavedSlippageAmount,
  savedShouldShowPositionLines,
  setSavedShouldShowPositionLines,
  disableBodyScrollLock,
  tradingLayout,
  setTradingLayout,
}) {
  return (
    <div>
      <HeaderIconButton
        iconSrc={settings}
        alt="settings"
        onClick={() => setIsVisible(true)}
      ></HeaderIconButton>

      <SettingsModal
        chainId={chainId}
        tokenAddress={shortCollateralAddress}
        disableBodyScrollLock={disableBodyScrollLock}
        setDisableBodyScrollLock={setDisableBodyScrollLock}
        isVisible={isVisible}
        openSettings={openSettings}
        savedSlippageAmount={savedSlippageAmount}
        setSavedSlippageAmount={setSavedSlippageAmount}
        setIsVisible={setIsVisible}
        label={t`Settings`}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
        tradingLayout={tradingLayout}
        setTradingLayout={setTradingLayout}
      />
    </div>
  );
}
