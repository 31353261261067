import React, { InputHTMLAttributes } from "react";
import cx from "classnames";
import "./InputSearch.css";

type Props = (
  & Omit<
    InputHTMLAttributes<HTMLInputElement>,
    (
      | 'type'
      | 'spellCheck'
    )
  >
  & {}
)

export default function InputSearch(props: Props) {
  return (
    <input
      {...props}
      type="search"
      spellCheck="false"
      className={cx("InputSearch", props.className)}
    />
  );
}
