import React from 'react';
import cx from 'classnames';

import useThemeSwitcher from 'lib/useThemeSwitcher';
import { THEMES } from 'lib/themes';
import { Trans } from '@lingui/macro';

export default function ThemeModalContant() {
  const { setTheme, theme } = useThemeSwitcher();

  const themeOptions = Object.entries(THEMES).map(([themeKey, themeInfo]) => {
    const themeDisplayName = themeInfo.name.charAt(0).toUpperCase() + themeInfo.name.slice(1);
    return (
      <div
        key={themeKey}
        onClick={() => {
          setTheme(themeInfo.name);
        }}
        className={cx('network-dropdown-menu-item menu-item gas-modal-item', {
          active: theme === themeInfo.name,
        })}
      >
        <div className="menu-item-group">
          <span className="network-dropdown-item-label menu-item-label">
            <Trans>{themeDisplayName} Theme</Trans>
          </span>
        </div>
        <div className={`${themeInfo.class}-theme-box`}></div>
      </div>
    );
  });

  return <>{themeOptions}</>;
}
