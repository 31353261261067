import { BigNumber, ethers } from "ethers";
import { DUST_BNB, OrderOption, PRECISION } from "lib/constants";
import { getExplorerUrl } from "config/chains";
import { InfoTokens, TokenInfo } from "./types";
import { expandDecimals } from "lib/numbers";

const { AddressZero } = ethers.constants;

export function getTokenUrl(chainId: number, address: string) {
  if (!address) {
    return getExplorerUrl(chainId);
  }
  return getExplorerUrl(chainId) + "token/" + address;
}

export function getUsd(
  amount: BigNumber | undefined,
  tokenAddress: string,
  max: boolean,
  infoTokens: InfoTokens,
  orderOption?: OrderOption,
  triggerPriceUsd?: BigNumber
) {
  if (!amount) {
    return;
  }
  const info = getTokenInfo(infoTokens, tokenAddress);
  const price = getTriggerPrice(max, info, orderOption, triggerPriceUsd);
  if (!price) {
    return;
  }

  return amount.mul(price).div(expandDecimals(1, info.decimals));
}

export function getTriggerPrice(max: boolean, info: TokenInfo, orderOption?: OrderOption, triggerPriceUsd?: BigNumber) {
  // Limit/stop orders are executed with price specified by user
  if (orderOption && orderOption !== OrderOption.Market && triggerPriceUsd) {
    return triggerPriceUsd;
  }

  // Market orders are executed with current market price
  if (!info) {
    return;
  }
  if (max && !info.maxPrice) {
    return;
  }
  if (!max && !info.minPrice) {
    return;
  }
  return max ? info.maxPrice : info.minPrice;
}

export function getTokenInfo(
  infoTokens: InfoTokens,
  tokenAddress: string,
  replaceNative?: boolean,
  nativeTokenAddress?: string
) {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[AddressZero];
  }

  return infoTokens[tokenAddress];
}

export function shouldRaiseGasError(token: TokenInfo, amount?: BigNumber) {
  if (!amount) {
    return false;
  }
  if (token.address !== AddressZero) {
    return false;
  }
  if (!token.balance) {
    return false;
  }
  if (amount.gte(token.balance)) {
    return true;
  }
  if (token.balance.sub(amount).lt(DUST_BNB)) {
    return true;
  }
  return false;
}

export const replaceNativeTokenAddress = (path: string[], nativeTokenAddress: string) => {
  if (!path) {
    return;
  }

  let updatedPath: string[] = [];

  for (let i = 0; i < path.length; i++) {
    let address = path[i];
    if (address === AddressZero) {
      address = nativeTokenAddress;
    }
    updatedPath.push(address);
  }

  return updatedPath;
};

export function getSpread(p: { minPrice: BigNumber; maxPrice: BigNumber }): BigNumber {
  const diff = p.maxPrice.sub(p.minPrice);
  const division = p.maxPrice.add(p.minPrice).div(2);
  return diff.mul(PRECISION).div(division.eq(0) ? division.add(1) : division);
}
