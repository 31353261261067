import { gasPreference } from "config/chains"
import { useAtom } from "jotai";
import { atomWithLocalStorage } from "lib/jotai/atomWithLocalStorage";

export type GasPreference = keyof typeof gasPreference

export const gasPreferenceAtom = atomWithLocalStorage<GasPreference>('gas-preference', 'standard');

export const useGasPreference = () => {
  return useAtom(gasPreferenceAtom)
}
