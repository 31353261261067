import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useState, useEffect, useMemo } from "react";
import useSWR from "swr";

import ReferralStorage from "abis/ReferralStorage.json";
import { BASIS_POINTS_DIVISOR, MAX_REFERRAL_CODE_LENGTH, isAddressZero, isHashZero } from "lib/legacy";
import { getContractAddress } from "config/contracts";
import { SUPPORTED_CHAIN_IDS } from "config/chains";
import { getPalmSubgraph } from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { REFERRAL_CODE_KEY } from "config/localStorage";
import { getProvider } from "lib/rpc";
import { bigNumberify } from "lib/numbers";
import { ReferralCodeOwner } from "pages/Referrals/types";
import { REGEX_VERIFY_BYTES32 } from "pages/Referrals/referralsHelper";
import { ParsedTierInfo, TierInfo } from "domain/referrals.types";

const DISTRIBUTION_TYPE_REWARDS = "1";
const DISTRIBUTION_TYPE_DISCOUNT = "2";

const getReferralsGraphClient = (chainId: number) => getPalmSubgraph(chainId, "referrals");

export function decodeReferralCode(hexCode: string | null | undefined): string {
  if (!hexCode) {
    return "";
  }
  try {
    return ethers.utils.parseBytes32String(hexCode);
  } catch (ex) {
    let code = "";
    hexCode = hexCode.substring(2);
    for (let i = 0; i < 32; i++) {
      code += String.fromCharCode(parseInt(hexCode.substring(i * 2, i * 2 + 2), 16));
    }
    return code.trim();
  }
}

export function encodeReferralCode(code) {
  let final = code.replace(/[^\w_]/g, ""); // replace everything other than numbers, string  and underscor to ''
  if (final.length > MAX_REFERRAL_CODE_LENGTH) {
    return ethers.constants.HashZero;
  }
  return ethers.utils.formatBytes32String(final);
}

async function getCodeOwnersData(network, account, codes: string[] = []) {
  if (codes.length === 0 || !account || !network) {
    return undefined;
  }
  const query = gql`
    query allCodes($codes: [String!]!) {
      referralCodes(where: { code_in: $codes }) {
        owner
        id
      }
    }
  `;
  return getReferralsGraphClient(network)
    .query({ query, variables: { codes } })
    .then(({ data }) => {
      const { referralCodes } = data;
      const codeOwners = referralCodes.reduce((acc, cv) => {
        acc[cv.id] = cv.owner;
        return acc;
      }, {});
      return codes.map((code): ReferralCodeOwner => {
        const owner = codeOwners[code];
        return {
          code,
          codeString: decodeReferralCode(code),
          owner,
          isTaken: Boolean(owner),
          isTakenByCurrentUser: owner && account && owner.toLowerCase() === account.toLowerCase(),
        };
      });
    });
}

export function useUserCodesOnAllChain(account) {
  const [data, setData] = useState<any>(null);
  const query = gql`
    query referralCodesOnAllChain($account: String!) {
      referralCodes(first: 1000, where: { owner: $account }) {
        code
      }
    }
  `;
  useEffect(() => {
    async function main() {
      const allChainsCodes = await Promise.all(
        SUPPORTED_CHAIN_IDS.map((chainId) => {
          return getReferralsGraphClient(chainId)
            .query({ query, variables: { account: (account || "").toLowerCase() } })
            .then(({ data }) => {
              return data.referralCodes.map((c) => c.code);
            });
        })
      );
      const allChainsCodeOwners = await Promise.all(
        SUPPORTED_CHAIN_IDS.map(async (chainId, chainIdIndex) => {
          const codes = allChainsCodes[chainIdIndex];

          return await getCodeOwnersData(chainId, account, codes);
        })
      );

      const codeOwnersData = {} as Record<(typeof SUPPORTED_CHAIN_IDS)[number], Record<string, ReferralCodeOwner>>;

      SUPPORTED_CHAIN_IDS.forEach((chainId, chainIdIndex) => {
        const codeOwners = allChainsCodeOwners[chainIdIndex] ?? [];

        codeOwnersData[chainId] = codeOwners.reduce((acc, cv) => {
          acc[cv.code] = cv;
          return acc;
        }, {} as any);
      });

      setData(codeOwnersData);
    }

    main();
  }, [account, query]);
  return data;
}

export function useReferralsData(chainId, account) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const ownerOnOtherChain = useUserCodesOnAllChain(account);
  useEffect(() => {
    if (!chainId || !account) {
      setLoading(false);
      return;
    }
    const startOfDayTimestamp = Math.floor(Math.floor(Date.now() / 1000) / 86400) * 86400;

    const query = gql`
      query referralData($typeIds: [String!]!, $account: String!, $timestamp: Int!, $referralTotalStatsId: String!) {
        distributions(
          first: 1000
          orderBy: timestamp
          orderDirection: desc
          where: { receiver: $account, typeId_in: $typeIds }
        ) {
          receiver
          amount
          typeId
          token
          transactionHash
          timestamp
        }
        referrerTotalStats: referrerStats(
          first: 1000
          orderBy: volume
          orderDirection: desc
          where: { period: total, referrer: $account }
        ) {
          referralCode
          volume
          trades
          tradedReferralsCount
          registeredReferralsCount
          totalRebateUsd
          discountUsd
        }
        referrerLastDayStats: referrerStats(
          first: 1000
          where: { period: daily, referrer: $account, timestamp: $timestamp }
        ) {
          referralCode
          volume
          trades
          tradedReferralsCount
          registeredReferralsCount
          totalRebateUsd
          discountUsd
        }
        referralCodes(first: 1000, where: { owner: $account }) {
          code
        }
        referralTotalStats: referralStat(id: $referralTotalStatsId) {
          volume
          discountUsd
        }
        referrerTierInfo: referrer(id: $account) {
          tierId
          id
          discountShare
        }
      }
    `;
    setLoading(true);

    const watchQuery = getReferralsGraphClient(chainId)
      .watchQuery({
        query,
        variables: {
          typeIds: [DISTRIBUTION_TYPE_REWARDS, DISTRIBUTION_TYPE_DISCOUNT],
          account: (account || "").toLowerCase(),
          timestamp: startOfDayTimestamp,
          referralTotalStatsId: account && `total:0:${account.toLowerCase()}`,
        },
        pollInterval: 10 * 1000,
        fetchPolicy: "cache-first",
      })
      .subscribe(
        (res) => {
          const rebateDistributions: any[] = [];
          const discountDistributions: any[] = [];
          res.data.distributions.forEach((d) => {
            const item = {
              timestamp: parseInt(d.timestamp),
              transactionHash: d.transactionHash,
              receiver: ethers.utils.getAddress(d.receiver),
              amount: bigNumberify(d.amount),
              typeId: d.typeId,
              token: ethers.utils.getAddress(d.token),
            };
            if (d.typeId === DISTRIBUTION_TYPE_REWARDS) {
              rebateDistributions.push(item);
            } else {
              discountDistributions.push(item);
            }
          });

          function prepareStatsItem(e) {
            return {
              volume: bigNumberify(e.volume),
              trades: parseInt(e.trades),
              tradedReferralsCount: parseInt(e.tradedReferralsCount),
              registeredReferralsCount: parseInt(e.registeredReferralsCount),
              totalRebateUsd: bigNumberify(e.totalRebateUsd),
              discountUsd: bigNumberify(e.discountUsd),
              referralCode: decodeReferralCode(e.referralCode),
              ownerOnOtherChain: ownerOnOtherChain?.[chainId][e.referralCode],
            };
          }

          function getCumulativeStats(data: any[] = []) {
            return data.reduce(
              (acc, cv) => {
                acc.totalRebateUsd = acc.totalRebateUsd.add(cv.totalRebateUsd);
                acc.volume = acc.volume.add(cv.volume);
                acc.discountUsd = acc.discountUsd.add(cv.discountUsd);
                acc.trades = acc.trades + cv.trades;
                acc.tradedReferralsCount = acc.tradedReferralsCount + cv.tradedReferralsCount;
                acc.registeredReferralsCount = acc.registeredReferralsCount + cv.registeredReferralsCount;
                return acc;
              },
              {
                totalRebateUsd: bigNumberify(0),
                volume: bigNumberify(0),
                discountUsd: bigNumberify(0),
                trades: 0,
                tradedReferralsCount: 0,
                registeredReferralsCount: 0,
              } as any
            );
          }

          let referrerTotalStats = res.data.referrerTotalStats.map(prepareStatsItem);
          setData({
            rebateDistributions,
            discountDistributions,
            referrerTotalStats,
            referrerTierInfo: res.data.referrerTierInfo,
            referrerLastDayStats: res.data.referrerLastDayStats.map(prepareStatsItem),
            cumulativeStats: getCumulativeStats(referrerTotalStats),
            codes: res.data.referralCodes.map((e) => decodeReferralCode(e.code)),
            referralTotalStats: res.data.referralTotalStats
              ? {
                  volume: bigNumberify(res.data.referralTotalStats.volume),
                  discountUsd: bigNumberify(res.data.referralTotalStats.discountUsd),
                }
              : {
                  volume: bigNumberify(0),
                  discountUsd: bigNumberify(0),
                },
          });
        },
        // eslint-disable-next-line no-console
        console.warn,
        () => {
          setLoading(false);
        }
      );
    return () => {
      watchQuery.unsubscribe();
    };
  }, [setData, chainId, account, ownerOnOtherChain]);

  return {
    data: data || null,
    loading,
  };
}

export async function registerReferralCode(chainId, referralCode, library, opts) {
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const referralCodeHex = encodeReferralCode(referralCode);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  return callContract(chainId, contract, "registerCode", [referralCodeHex], opts);
}

export async function setTraderReferralCodeByUser(chainId, referralCode, library, opts) {
  const referralCodeHex = encodeReferralCode(referralCode);
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  const codeOwner = await contract.codeOwners(referralCodeHex);
  if (isAddressZero(codeOwner)) {
    const errorMsg = "Referral code does not exist";
    helperToast.error(errorMsg);
    return Promise.reject(errorMsg);
  }
  return callContract(chainId, contract, "setTraderReferralCodeByUser", [referralCodeHex], opts);
}

export async function getReferralCodeOwner(chainId, referralCode) {
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const provider = getProvider(undefined, chainId);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, provider);
  const codeOwner = await contract.codeOwners(referralCode);
  return codeOwner;
}

export function useUserReferralCode(library, chainId, account: string) {
  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);

  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const { data: onChainCode } = useSWR<string>(
    account && ["ReferralStorage", chainId, referralStorageAddress, "traderReferralCodes", account],
    contractFetcher(library, ReferralStorage)
  );

  const { data: localStorageCodeOwner } = useSWR<string>(
    localStorageCode && REGEX_VERIFY_BYTES32.test(localStorageCode)
      ? ["ReferralStorage", chainId, referralStorageAddress, "codeOwners", localStorageCode]
      : null,
    contractFetcher(library, ReferralStorage)
  );

  const [attachedOnChain, userReferralCode, userReferralCodeString] = useMemo<
    [boolean, string | undefined, string | undefined]
  >(() => {
    if (onChainCode && !isHashZero(onChainCode)) {
      return [true, onChainCode, decodeReferralCode(onChainCode)];
    } else if (
      localStorageCodeOwner &&
      account &&
      !isAddressZero(localStorageCodeOwner) &&
      localStorageCodeOwner.toLowerCase() !== account.toLowerCase()
    ) {
      return [false, localStorageCode ?? undefined, decodeReferralCode(localStorageCode)];
    }
    return [false, undefined, undefined];
  }, [localStorageCode, localStorageCodeOwner, onChainCode, account]);

  return {
    userReferralCode,
    userReferralCodeString,
    attachedOnChain,
  };
}

export function useReferrerTier(library, chainId, account) {
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const { data: referrerTier, mutate: mutateReferrerTier } = useSWR<number>(
    account && [`ReferralStorage:referrerTiers`, chainId, referralStorageAddress, "referrerTiers", account],
    contractFetcher(library, ReferralStorage)
  );
  return {
    referrerTier,
    mutateReferrerTier,
  };
}

export function useCodeOwner(library, chainId, account, code) {
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");
  const { data: codeOwner, mutate: mutateCodeOwner } = useSWR(
    account && code && [`ReferralStorage:codeOwners`, chainId, referralStorageAddress, "codeOwners", code],
    contractFetcher(library, ReferralStorage)
  );
  return {
    codeOwner,
    mutateCodeOwner,
  };
}

const parseTierInfo = (info: TierInfo | undefined): ParsedTierInfo => {
  const totalRebate = BigNumber.from(info?.totalRebate ?? 0).toNumber() / BASIS_POINTS_DIVISOR;
  const discountShare = BigNumber.from(info?.discountShare ?? 0).toNumber() / BASIS_POINTS_DIVISOR;

  const referrerRebateRate = totalRebate * (1 - discountShare);
  const traderDiscountRate = totalRebate * discountShare;

  const result = {
    referrerRebate: parseFloat((referrerRebateRate * 100).toFixed(2)),
    traderDiscount: parseFloat((traderDiscountRate * 100).toFixed(2)),
  };

  return result;
};

export function useTiersInfo(library, chainId, account, maxTierId = 5) {
  const referralStorageAddress = getContractAddress(chainId, "ReferralStorage");

  const tierFetchers = useMemo(() => {
    const tiers = Array(maxTierId)
      .fill(null)
      .map((el, index) => index);

    return tiers.map((tier) => {
      return (args) => contractFetcher<TierInfo>(library, ReferralStorage)([...args, tier]);
    });
  }, [library, maxTierId]);

  const query = useSWR<ParsedTierInfo[]>(
    account && [`ReferralStorage:tiers`, chainId, referralStorageAddress, "tiers"],
    async (args) => {
      const tiersInfo = (await Promise.all(tierFetchers.map((f) => f(args)))) satisfies TierInfo[];

      const initedTiersInfo = tiersInfo.filter((el, index) => {
        return index === 0 || BigNumber.from(el.totalRebate).toNumber() > 0;
      });

      return initedTiersInfo.map((el) => parseTierInfo(el));
    }
  );

  return {
    data: query.data,
    mutate: query.mutate,
  };
}

export async function validateReferralCodeExists(referralCode, chainId) {
  const referralCodeBytes32 = encodeReferralCode(referralCode);
  const referralCodeOwner = await getReferralCodeOwner(chainId, referralCodeBytes32);
  return !isAddressZero(referralCodeOwner);
}

export function useAffiliateCodes(chainId, account) {
  const [affiliateCodes, setAffiliateCodes] = useState({ code: null, success: false });
  const query = gql`
    query userReferralCodes($account: String!) {
      referrerTotalStats: referrerStats(
        first: 1000
        orderBy: volume
        orderDirection: desc
        where: { period: total, referrer: $account }
      ) {
        referralCode
      }
    }
  `;
  useEffect(() => {
    if (!chainId) return;
    getReferralsGraphClient(chainId)
      .query({ query, variables: { account: account?.toLowerCase() } })
      .then((res) => {
        const parsedAffiliateCodes = res?.data?.referrerTotalStats.map((c) => decodeReferralCode(c?.referralCode));
        setAffiliateCodes({ code: parsedAffiliateCodes[0], success: true });
      });
    return () => {
      setAffiliateCodes({ code: null, success: false });
    };
  }, [chainId, query, account]);
  return affiliateCodes;
}

export async function getTrader(
  chainId: number,
  account: string
): Promise<
  | {
      id: string;
      referralCode: string;
      referralCodeUpdateTimestamp: number;
    }
  | undefined
> {
  const query = gql`
    query ($account: String!) {
      traders(where: { id: $account }) {
        id
        referralCode
        referralCodeUpdateTimestamp
      }
    }
  `;

  return getReferralsGraphClient(chainId)
    .query({ query, variables: { account: account.toLowerCase() }, fetchPolicy: "network-only" })
    .then((res) => {
      const traderRaw = res.data.traders[0] as
        | {
            id: string;
            referralCode: string;
            referralCodeUpdateTimestamp: string;
          }
        | undefined;

      if (!traderRaw) return;

      return {
        id: traderRaw.id,
        referralCode: traderRaw.referralCode,
        referralCodeUpdateTimestamp: parseInt(traderRaw.referralCodeUpdateTimestamp) * 1000,
      };
    });
}
