import { gql } from "@apollo/client";
import { getPalmSubgraph } from "lib/subgraph/clients";
import { BigNumber } from "ethers";
import { getUnixTime, subHours } from "date-fns";

import { HourlyVolumeByToken } from "./types";
import { useChainId } from "lib/chains";
import useSWR from "swr";

function getLast24RangeTimestamps(hours: number) {
  const now = new Date()
  const endTimestamp = getUnixTime(now)
  const startTimestamp = getUnixTime(subHours(now, hours))

  return ({
    startTimestamp, endTimestamp
  })
}

export function useHourlyVolumeByToken(indexToken: string, hours: number | undefined = 24) {
  const { chainId } = useChainId();

  async function fetcher() {
    const client = getPalmSubgraph(chainId, "stats");

    const { startTimestamp, endTimestamp } = getLast24RangeTimestamps(hours)


    const query = gql`
      {
        hourlyVolumeByTokens(where:{
          timestamp_gte: ${startTimestamp},
          timestamp_lte: ${endTimestamp},
        }) {
          id
          tokenB
          margin
          liquidation
        }
      }
    `;

    const res = await client.query<{ hourlyVolumeByTokens: HourlyVolumeByToken[] }>({
        query,
        fetchPolicy: "network-only"
    })

    const volumesBytToken =  res.data.hourlyVolumeByTokens.filter(({ tokenB }) => {
      return tokenB.toLowerCase() === indexToken.toLowerCase()
    })

    const volume = volumesBytToken.reduce((acc, item) => {
      return acc.add(item.margin).add(item.liquidation);
    }, BigNumber.from(0));

    return {
      volume,
    };
  }

  const swr = useSWR<Awaited<ReturnType<typeof fetcher>>>([
    "Exchange:24h-volume", chainId, indexToken, hours], fetcher, {
    refreshInterval: 15000,
  });

  return swr;
}
