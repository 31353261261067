import { t, Trans } from "@lingui/macro";
import React from "react";
import cx from "classnames";
import { atom, useAtom } from "jotai";
import { Switch } from "components/Switch/Switch";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { TipIcon } from "components/TipIcon";
import format from "date-fns/format";
import { PALM_DECIMALS, TP_DECIMALS } from "lib/constants";
import { formatBigAmount } from "pages/Event/utils/formatAmount";
import Button from "components/Button/Button";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { EmptyPlaceholder } from "components/EmptyPlaceholder";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { Epoche, GeneralInfo, UserInfo } from "pages/Event/events/TradeToEarnEvent/types";
import { getTotalEpoches } from "pages/Event/events/TradeToEarnEvent/utils/epocheDates";
import { useEpochesHistoryQuery } from "pages/Event/events/TradeToEarnEvent/hooks/useEpochesHistoryQuery";
import Loader from "components/Common/Loader";
import { useEventClaim } from "pages/Event/events/TradeToEarnEvent/hooks/useEventClaim";
import parse from "parse-duration";

export const showOnlyEpochesWithRewardsAtom = atom(false);

type Props = React.ComponentProps<"div"> & {
  generalInfo: GeneralInfo;
  userInfo: UserInfo;
}

export const EpocheHistorySection = React.memo(function _EpocheHistorySection (props: Props) {
  const { generalInfo } = props

  const [showOnlyEpochesWithRewards, setShowOnlyEpochesWithRewards] = useAtom(showOnlyEpochesWithRewardsAtom)

  const connectWalletUi = useConnectWalletUi();
  const totalEpoches = getTotalEpoches(
    generalInfo.tier1StartIndex.toNumber(),
    generalInfo.endIndex.toNumber()
  )

  const epochesHistoryQuery = useEpochesHistoryQuery()
  const eventClaim = useEventClaim();

  const currentDay = epochesHistoryQuery.data?.currentDay
  const epoches = React.useMemo(() => {
    return epochesHistoryQuery.data?.endedEpoches ?? []
  }, [epochesHistoryQuery.data])

  const displayedEpoches = React.useMemo<Epoche[]>(() => {
    if (showOnlyEpochesWithRewards) {
      return (epoches).filter(epoche => epoche.rewardsPalm.gt(0))
    }
    return (epoches)
  }, [epoches, showOnlyEpochesWithRewards])

  const DisplayedEpochesActionButton = React.useCallback((props: React.ComponentProps<'button'>): JSX.Element | null => {
    if (connectWalletUi.isNotConnected) {
      return (
        <Button variant="primary-action" onClick={() => connectWalletUi.connectWallet()} size="lg" className={props.className}>
          <Trans>Connect Wallet</Trans>
        </Button>
      )
    }

    if (displayedEpoches.every(epoche => epoche.rewardsPalm.eq(0))) {
      return null
    }

    if (displayedEpoches.some(epoche => epoche.rewardsClaimablePalm.gt(0))) {
      return (
        <Button
          variant="lime"
          onClick={() => eventClaim.claimAll()}
          size="lg"
          className={props.className}
          disabled={eventClaim.isMutating}
        >
          <Trans>Claim Multiple</Trans>
        </Button>
      )
    }

    const nextClaimEpoche = (
      currentDay
      && displayedEpoches
        .find(epoche => {
          return epoche.rewardsPalm.gt(0) && epoche.rewardDistributionDay.valueOf() >= currentDay
        })
    ) || null

    if (nextClaimEpoche) {
      return (
        <Button variant="lime-inverted" size="lg" colorfulDisabled disabled className={props.className}>
          <span className="flex items-center gap-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M 13.363 3.322 C 13.49 3.419 13.573 3.562 13.595 3.719 C 13.616 3.877 13.574 4.037 13.478 4.164 L 7.078 12.564 C 6.973 12.702 6.813 12.787 6.64 12.799 C 6.468 12.81 6.298 12.747 6.176 12.624 L 2.576 9.024 C 2.356 8.788 2.362 8.419 2.591 8.191 C 2.819 7.962 3.188 7.956 3.424 8.176 L 6.539 11.29 L 12.523 3.437 C 12.724 3.174 13.1 3.122 13.363 3.322 Z" fill="#98e178"></path></svg>

            <Trans>Next claim in {getFormattedDistanceByDay(nextClaimEpoche.rewardDistributionDay)}</Trans>
          </span>
        </Button>
      )
    }
    return null
  }, [connectWalletUi, currentDay, displayedEpoches, eventClaim])

  const EpochesList = React.useCallback(() => {
    if (!epochesHistoryQuery.data) {
      return (
        <div className="mt-[3rem] h-[10rem] relative">
          <Loader></Loader>
        </div>
      )
    }
    if (displayedEpoches.length === 0) {
      return (
        <EmptyPlaceholder message={t`No epoches to display.`} />
      )
    }

    return (
      <ul className="mt-[3rem] flex flex-col items-stretch gap-6">
        {
          displayedEpoches.map(epoche => (
            <EpochesListItem
              epoche={epoche}
              key={epoche.index}
              eventClaim={eventClaim}
              totalEpoches={totalEpoches}
            ></EpochesListItem>
          ))
        }
      </ul>
    )
  }, [displayedEpoches, epochesHistoryQuery.data, eventClaim, totalEpoches])

  return (
    <div className={cx(props.className, '@container/history-section')}>
      <header className="flex gap-8 justify-between flex-col @[50rem]/history-section:items-center @[50rem]/history-section:flex-row">
        <div className="flex flex-col gap-4">
          <h1 className="text-[2.4rem] leading-[1.4] font-medium">
            <Trans>
              Epoch History
            </Trans>
          </h1>

          <div className="flex gap-4 items-center">
            <Switch
              className={'shrink-0'}
              checked={showOnlyEpochesWithRewards}
              onChange={() => setShowOnlyEpochesWithRewards(!showOnlyEpochesWithRewards)}
            />

            <p className="text-[1.4rem] leading-[1.5] text-inactive">
              <Trans>
                Show epoches in which you received rewards only.
              </Trans>
            </p>
          </div>
        </div>

        <DisplayedEpochesActionButton className="min-w-[15rem] shrink-0"/>
      </header>

      <EpochesList></EpochesList>
    </div>
  )
})

type EpocheHistoryItemStatProps = {
  label: string;
  data: React.ReactNode;
  tooltipText?: React.ReactNode;
};

const EpocheHistoryItemStat = (props: EpocheHistoryItemStatProps) => {
  return (
    <div className="flex flex-col gap-[.2rem]">
        <div className={cx("flex gap-2 items-center")}>
          <span className="text-inactive text-[1.4rem] leading-[1.5]">{props.label}</span>

          {props.tooltipText && (
            <Tooltip renderContent={() => props.tooltipText} disabled={!props.tooltipText}>
              <TipIcon />
            </Tooltip>
          )}
        </div>

        <div className="text-[1.6rem] leading-[1.5] font-medium tabular-nums">{props.data}</div>
    </div>
  );
};

const EpocheItemActionButton = (
  {
    epoche,
    className,
    eventClaim,
  }: React.ComponentProps<'button'> & {
    epoche: Epoche,
    eventClaim: ReturnType<typeof useEventClaim>
  }
): JSX.Element | null => {
  if (epoche.rewardsPalm.eq(0)) {
    return null
  }
  if (epoche.rewardsClaimablePalm.gt(0)) {
    return (
      <Button
        variant="lime"
        onClick={() => eventClaim.claim(epoche)}
        size="lg"
        className={cx(className)}
        disabled={eventClaim.isMutating}
      >
        <Trans>Claim Reward</Trans>
      </Button>
    )
  }
  if (epoche.rewardsClaimedPalm.lt(epoche.rewardsPalm)) {
    return (
      <Button variant="lime-inverted" className={cx(className)} size="lg" colorfulDisabled disabled>
        <span className="flex items-center gap-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M 13.363 3.322 C 13.49 3.419 13.573 3.562 13.595 3.719 C 13.616 3.877 13.574 4.037 13.478 4.164 L 7.078 12.564 C 6.973 12.702 6.813 12.787 6.64 12.799 C 6.468 12.81 6.298 12.747 6.176 12.624 L 2.576 9.024 C 2.356 8.788 2.362 8.419 2.591 8.191 C 2.819 7.962 3.188 7.956 3.424 8.176 L 6.539 11.29 L 12.523 3.437 C 12.724 3.174 13.1 3.122 13.363 3.322 Z" fill="#98e178"></path></svg>

          <Trans>In {getFormattedDistanceByDay(epoche.rewardDistributionDay)}</Trans>
        </span>
      </Button>
    )
  }
  return (
    <Button variant="lime-inverted" className={cx(className)} size="lg" colorfulDisabled disabled>
      <span className="flex items-center gap-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M 13.363 3.322 C 13.49 3.419 13.573 3.562 13.595 3.719 C 13.616 3.877 13.574 4.037 13.478 4.164 L 7.078 12.564 C 6.973 12.702 6.813 12.787 6.64 12.799 C 6.468 12.81 6.298 12.747 6.176 12.624 L 2.576 9.024 C 2.356 8.788 2.362 8.419 2.591 8.191 C 2.819 7.962 3.188 7.956 3.424 8.176 L 6.539 11.29 L 12.523 3.437 C 12.724 3.174 13.1 3.122 13.363 3.322 Z" fill="#98e178"></path></svg>

        <Trans>Claimed</Trans>
      </span>
    </Button>
  )
}

const EpochesListItem = React.memo(function(
  {
    epoche,
    totalEpoches,
    eventClaim,
  }: {
    epoche: Epoche,
    totalEpoches: number,
    eventClaim: ReturnType<typeof useEventClaim>
  }) {
  return (
    <li key={epoche.index}>
      <div className="bg-background-5-v2-only rounded-[2.5rem] border p-[2.5rem_2.5rem_2rem_2.5rem] overflow-hidden">
        <div className="flex flex-col gap-[2rem] @[50rem]/history-section:gap-[4rem] @[50rem]/history-section:flex-row @[50rem]/history-section:items-end">
          <div className="flex-1 min-w-0">
            <div className="flex items-start gap-4 @[50rem]/history-section:-mr-[4rem]">
              <p className="text-[2.4rem] leading-[1.4] font-extrabold font-mazzard text-textColor">
                <Trans>Epoch {epoche.displayedIndex}</Trans>
              </p>

              <div className="py-[.1rem] px-[1.3rem] rounded-[1rem] bg-main/10 text-main text-[1.2rem] leading-[2.4rem] font-medium">
                <Trans>Epoch {epoche.displayedIndex}/{totalEpoches}</Trans>
              </div>
            </div>

            <div className="mt-4 relative -mx-[2.5rem] @[50rem]/history-section:mr-0">
              <div className="absolute bg-gradient-to-l from-transparent to-background-5-v2-only pointer-events-none w-[2.5rem] h-full top-0 left-0"></div>

              <div className="overflow-y-auto">
                <div className="flex items-start gap-[3rem] @[95rem]/history-section:gap-[5rem] @[110rem]/history-section:gap-[8rem] whitespace-nowrap px-[2.5rem]">
                  <EpocheHistoryItemStat
                    label={t`Epoch Ended`}
                    data={formatDate(parse(`${epoche.endDay} days`, 'ms') ?? 0)}
                  />

                  <EpocheHistoryItemStat
                    label={t`You earned`}
                    tooltipText={t`Your trading points will be converted into PALM tokens.`}
                    data={`${formatBigAmount(epoche.earnedTp, TP_DECIMALS)} TP`}
                  />

                  <EpocheHistoryItemStat
                    label={t`Your rewards`}
                    data={`${formatBigAmount(epoche.rewardsPalm, PALM_DECIMALS)} PALM`}
                  />

                  <EpocheHistoryItemStat
                    label={t`Claimed Rewards`}
                    data={`${formatBigAmount(epoche.rewardsClaimedPalm, PALM_DECIMALS)} PALM`}
                  />

                  <EpocheHistoryItemStat
                    label={t`Claimable Rewards`}
                    data={`${formatBigAmount(epoche.rewardsClaimablePalm, PALM_DECIMALS)} PALM`}
                  />
                </div>
              </div>

              <div className="absolute bg-gradient-to-r from-transparent to-background-5-v2-only pointer-events-none w-[2.5rem] h-full top-0 right-0"></div>
            </div>
          </div>

          {
            epoche.rewardsPalm.gt(0) && (
              <div className="shrink-0 @[50rem]/history-section:justify-end relative flex flex-col items-stretch">
                <EpocheItemActionButton
                  epoche={epoche}
                  className="min-w-[17.6rem]"
                  eventClaim={eventClaim}
                />
              </div>
            )
          }
        </div>
      </div>
    </li>
  )
})

const formatDate = (date: Date | number): string => {
  return format(date, 'MMM d, yyyy HH:mm O');
};

const getFormattedDistanceByDay = (day: number): string => {
  return formatDistanceToNowStrict(
    parse(`${day} days`, 'ms') ?? 0, { unit: 'hour', roundingMethod: 'ceil' }
  )
}
