function hasMetaMaskWalletExtension() {
  return !!window.ethereum;
}

export function hasCoinBaseWalletExtension() {
  const e = window.ethereum as any

  if (!e?.providers && !e?.isCoinbaseWallet) {
    return false;
  }

  return e.isCoinbaseWallet || e.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
}

export const walletsSupport = {
  metamask: hasMetaMaskWalletExtension(),
  coinbase: hasCoinBaseWalletExtension(),
}
