import { Trans } from "@lingui/macro";
import Button from "components/Button/Button";
import { GradientContainer } from "components/GradientContainer";

type EnterReferralCodeProps = {
  active: boolean;
  connectWallet: () => void;
  onCreateReferralCode: () => void;
  callAfterSuccess?: () => void;
};

function EnterReferralCode({ active, connectWallet, onCreateReferralCode }: EnterReferralCodeProps) {
  return (
    <>
      <GradientContainer className="mt-8">
        <div className="flex flex-col sm:flex-row gap-4 items-start md:items-center p-8">
          <div className="flex flex-col flex-1">
            <p className="text-[2.4rem] leading-[1.4] font-extrabold font-mazzard text-white">
              <Trans>Enter Referral Code</Trans>
            </p>

            <p className="text-[1.4rem] leading-[1.4] text-white">
              <Trans>Please input a referral code to benefit from fee discounts.</Trans>
            </p>
          </div>

          <div className="">
            {active ? (
              <Button variant="white" className="min-w-[17rem]" onClick={onCreateReferralCode}>
                <Trans>Enter Referral Code</Trans>
              </Button>
            ) : (
              <Button variant="white" onClick={connectWallet} className="min-w-[17rem]">
                <Trans>Connect Wallet</Trans>
              </Button>
            )}
          </div>
        </div>
      </GradientContainer>
    </>
  );
}

export default EnterReferralCode;
