import { LiFi } from "@lifi/sdk";
import { t } from "@lingui/macro";
import { ethers } from "ethers";
import { helperToast } from "lib/helperToast";
import { TokenBalanceResponseType } from "../BridgeTypes";

export const getBridgeTokens = async () => {
  try {
    const lifi = new LiFi({
      integrator: "PalmSwap",
    });

    const tokensResponse = await lifi.getTokens();
    return tokensResponse;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching tokens:", error);
    return null;
  }
};

export const getBridgeChains = async () => {
  try {
    const lifi = new LiFi({
      integrator: "Palmswap",
    });

    const result = await lifi.getChains();

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching tokens:", error);
    return null;
  }
};

export const sendRouteRequest = ({
  fromChainId,
  fromAmount,
  fromTokenAddress,
  toChainId,
  fromAddress,
  toAddress,
  toTokenAddress,
}) => {
  try {
    const lifi = new LiFi({
      integrator: "Palmswap",
    });

    const routeRequest = {
      fromChainId: fromChainId,
      fromAmount: fromAmount,
      fromTokenAddress: fromTokenAddress,
      toChainId: toChainId,
      fromAddress: fromAddress,
      toAddress: toAddress,
      toTokenAddress: toTokenAddress,
      options: {
        slippage: 0.03,
        allowSwitchChain: true,
      },
    };

    const routeResponse = lifi.getRoutes(routeRequest);

    return routeResponse;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching Li-Fi data:", error);
    return;
  }
};

export const getTokenBalance = async (walletAddress, tokensByChain): Promise<TokenBalanceResponseType> => {
  try {
    const lifi = new LiFi({
      integrator: "PalmSwap",
    });

    const tokenBalancesByChain = (await lifi.getTokenBalance(walletAddress, tokensByChain)) as TokenBalanceResponseType;
    return tokenBalancesByChain;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching token balances for chains:", error);
    return {} as TokenBalanceResponseType;
  }
};

interface EthereumProvider {
  request: (...args: any[]) => Promise<any>;
}
interface Window {
  ethereum?: EthereumProvider;
}
declare let window: Window;

const switchChainHook = async (requiredChainId: string | number) => {
  try {
    const ethereum = window.ethereum;
    if (typeof ethereum === "undefined" || !("request" in ethereum)) {
      throw new Error("MetaMask or similar wallet is not available.");
    }

    // Use the MetaMask RPC API to switch chains
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: requiredChainId.toString(16) }],
    });

    // Build a new provider for the new chain
    const newProvider = new ethers.providers.Web3Provider(ethereum);

    // Return the associated Signer for the new chain
    return newProvider.getSigner();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error switching chains:", error);
    return undefined;
  }
};

// executing a route
export const executeRoute = async (signer, route, chainId) => {
  try {
    const lifi = new LiFi({
      integrator: "Palmswap",
    });
    const executionSettings = {
      switchChainHook: async () => switchChainHook(chainId),
    };

    helperToast.info(t`Please sign the transaction`);

    const routeResponse = await lifi.executeRoute(signer, route, executionSettings as any);

    helperToast.success(t`Bridge transaction confirmed`);

    return routeResponse;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error executing route with chain switch:", error);
    helperToast.error(t`Error executing bridge: ` + error);

    return null;
  }
};
