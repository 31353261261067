import { Trans, t } from "@lingui/macro";
import { shortenAddress } from "lib/legacy";
import { getExplorerUrl } from "config/chains";
import { bigNumberify, formatAmount } from "lib/numbers";
import { getNativeToken, getToken } from "config/tokens";
import { formatDate } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Pagination from "components/Pagination/Pagination";
import Button from "components/Button/Button";
import cx from "classnames";
import { GradientContainer } from "components/GradientContainer";
import { InfoCard, InfoCardList } from "components/InfoCard";
import { useChainId } from "lib/chains";
import { AffiliatesStatsTable } from "pages/Referrals/components/AffiliatesStatsTable";
import usePagination from "pages/Referrals/components/usePagination";
import { getTierIdDisplay, getUSDValue } from "pages/Referrals/referralsHelper";
import { EmptyPlaceholder } from "components/EmptyPlaceholder";
import { ParsedTierInfo } from "domain/referrals.types";
import { TokenUtils } from "components/TokenUtils";

type AffiliatesStatsProps = {
  referralsData: any;
  onCreateReferralCode: () => void;
  setRecentlyAddedCodes: any;
  recentlyAddedCodes: any;
  tiersInfo: ParsedTierInfo[] | undefined;
};

function AffiliatesStats({ referralsData, onCreateReferralCode, recentlyAddedCodes, tiersInfo }: AffiliatesStatsProps) {
  const tierRebateInfo = tiersInfo?.map((el) => el.referrerRebate);
  const tierDiscountInfo = tiersInfo?.map((el) => el.traderDiscount);

  const { chainId } = useChainId();

  const { cumulativeStats, rebateDistributions, referrerTierInfo } = referralsData;
  const {
    currentPage: currentRebatePage,
    getCurrentData: getCurrentRebateData,
    setCurrentPage: setCurrentRebatePage,
    pageCount: rebatePageCount,
  } = usePagination(rebateDistributions);

  const currentRebateData = getCurrentRebateData() as any;

  const tierId = referrerTierInfo?.tierId;

  let referrerRewards = bigNumberify(0);
  if (cumulativeStats && cumulativeStats.totalRebateUsd && cumulativeStats.discountUsd) {
    referrerRewards = cumulativeStats.totalRebateUsd.sub(cumulativeStats.discountUsd);
  }
  return (
    <div className={cx(["referral-body-container"])}>
      <GradientContainer className="mt-8">
        <div className="flex flex-col sm:flex-row gap-4 items-start md:items-center p-8">
          <div className="flex flex-col flex-1">
            <p className="text-[2.4rem] leading-[1.4] font-extrabold font-mazzard text-white">
              <Trans>Tier {getTierIdDisplay(tierId)}</Trans>
            </p>

            <p className="text-[1.4rem] leading-[1.4]">
              <Trans>
                {tierDiscountInfo?.[tierId] ?? 0}% discount for traders, {tierRebateInfo?.[tierId] ?? 0}% commission for
                the referrer.
              </Trans>
            </p>
          </div>

          <div className="">
            <ExternalLink href="https://forms.gle/ybDKE6ZxwJmyxPM86">
              <Button variant="white" className="gap-2 min-w-[17rem]">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M17.768 7.793a.75.75 0 0 1-1.06-.025L12.75 3.622v10.003a5.375 5.375 0 0 1-10.75 0V10.75a.75.75 0 0 1 1.5 0v2.875a3.875 3.875 0 0 0 7.75 0V3.622L7.293 7.768a.75.75 0 0 1-1.086-1.036l5.25-5.5a.75.75 0 0 1 1.085 0l5.25 5.5a.75.75 0 0 1-.024 1.06z"
                    clipRule="evenodd"
                  ></path>
                </svg>

                <Trans>Upgrade Tier</Trans>
              </Button>
            </ExternalLink>
          </div>
        </div>
      </GradientContainer>

      <InfoCardList className="mt-12">
        <InfoCard
          label={t`Total Traders Referred`}
          tooltipText={t`Amount of active traders you referred.`}
          data={cumulativeStats?.registeredReferralsCount || "0"}
          iconType="add-user"
        />
        <InfoCard
          label={t`Total Trading Volume`}
          tooltipText={t`Volume traded by your referred traders.`}
          data={getUSDValue(cumulativeStats?.volume)}
          iconType="exchange"
        />
        <InfoCard
          label={t`Total Rewards`}
          tooltipText={t`Rewards earned by this account as an affiliate.`}
          data={getUSDValue(referrerRewards, 4)}
          iconType="gift"
        />
      </InfoCardList>

      <section className="mt-[5rem] flex items-center justify-between gap-8">
        <div className="flex flex-col gap-4">
          <h1 className="text-[2.4rem] leading-[1.4] font-medium">
            <Trans>Referral Codes</Trans>
          </h1>

          <p className="text-[1.4rem] leading-[1.5] text-inactive flex flex-wrap item-center gap-[1ch]">
            <span>
              <Trans>You have the option to create multiple referral codes to have a better overview.</Trans>
            </span>
          </p>
        </div>

        <Button variant="primary-action" className="gap-2" onClick={onCreateReferralCode}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path
              d="M 8.6 3.8 C 8.6 3.469 8.331 3.2 8 3.2 C 7.669 3.2 7.4 3.469 7.4 3.8 L 7.4 7.4 L 3.8 7.4 C 3.469 7.4 3.2 7.669 3.2 8 C 3.2 8.331 3.469 8.6 3.8 8.6 L 7.4 8.6 L 7.4 12.2 C 7.4 12.531 7.669 12.8 8 12.8 C 8.331 12.8 8.6 12.531 8.6 12.2 L 8.6 8.6 L 12.2 8.6 C 12.531 8.6 12.8 8.331 12.8 8 C 12.8 7.669 12.531 7.4 12.2 7.4 L 8.6 7.4 Z"
              fill="hsl(0, 0%, 100%)"
            ></path>
          </svg>

          <Trans>Create</Trans>
        </Button>
      </section>

      <AffiliatesStatsTable
        className="mt-12"
        recentlyAddedCodes={recentlyAddedCodes}
        referralsData={referralsData}
        chainId={chainId}
      />

      <div className="reward-history">
        <section className="mt-[5rem] flex items-center justify-between gap-8">
          <div className="flex flex-col gap-4">
            <h1 className="text-[2.4rem] leading-[1.4] font-medium">
              <Trans>Rewards Distribution History</Trans>
            </h1>

            <p className="text-[1.4rem] leading-[1.5] text-inactive flex flex-wrap item-center gap-[1ch]">
              <span>
                <Trans>Rewards are airdropped weekly.</Trans>
              </span>
            </p>
          </div>
        </section>
        <div className="relative @container/table mt-12">
          <table className="w-full">
            <thead>
              <tr>
                {[t`Date`, t`Amount`, t`Transaction ID`, t`Status`].map((item) => (
                  <th
                    className="first:rounded-l-sm last:rounded-r-sm bg-background-3 h-14 text-[1.2rem] leading-[1.5] text-inactive font-normal text-left px-6 first:w-1/4"
                    scope="col"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRebateData.length > 0 ? (
                currentRebateData.map((rebate, index) => {
                  let tokenInfo;
                  try {
                    tokenInfo = getToken(chainId, rebate.token);
                  } catch {
                    tokenInfo = getNativeToken(chainId);
                  }
                  const explorerURL = getExplorerUrl(chainId);
                  return (
                    <tr key={rebate.timestamp} className="border-b">
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Date">
                        {formatDate(rebate.timestamp)}
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Amount">
                        {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {TokenUtils.getSymbol(tokenInfo)}
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Transaction ID">
                        <ExternalLink
                          href={explorerURL + `tx/${rebate.transactionHash}`}
                          className="text-white mr-2 d-flex items-center"
                        >
                          {shortenAddress(rebate.transactionHash, 13)}
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                            <path
                              d="M 5.22 14.78 C 5.513 15.072 5.987 15.072 6.28 14.78 L 13.5 7.56 L 13.5 13.25 C 13.5 13.664 13.836 14 14.25 14 C 14.664 14 15 13.664 15 13.25 L 15 5.75 C 15 5.336 14.664 5 14.25 5 L 6.75 5 C 6.336 5 6 5.336 6 5.75 C 6 6.164 6.336 6.5 6.75 6.5 L 12.44 6.5 L 5.22 13.72 C 4.928 14.013 4.928 14.487 5.22 14.78 Z"
                              fill="rgb(108, 114, 132)"
                            ></path>
                          </svg>
                        </ExternalLink>
                      </td>
                      <td className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]" data-label="Status">
                        <Trans>Received</Trans>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="py-[2rem]">
                    <EmptyPlaceholder message={t`No rewards history yet.`} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={currentRebatePage}
          pageCount={rebatePageCount}
          onPageChange={(page) => setCurrentRebatePage(page)}
        />
      </div>
    </div>
  );
}

export default AffiliatesStats;
