import { PRODUCTION_PREVIEW_KEY } from "./localStorage";
import memoize from "lodash/memoize";

const UI_VERSION_DEFAULT = String(Math.floor(Date.now() / 1e3));

export const UI_VERSION = process.env.REACT_APP_UI_VERSION ?? UI_VERSION_DEFAULT;

export const IS_TOUCH = typeof window !== "undefined" && "ontouchstart" in window;

export const WSS_BACKEND_URL = process.env.REACT_APP_BACKEND ?? "";

const isDevelopmentHost = memoize((host: string) => {
  const notProductionPrefixes = ["dev.", "testnet.", "localhost:"];

  return notProductionPrefixes.some((prefix) => {
    return host.startsWith(prefix);
  });
});

export function isDevelopment() {
  if (typeof window === "undefined") {
    return true;
  }
  const isProductionPreview = Boolean(localStorage.getItem(PRODUCTION_PREVIEW_KEY) === "true");

  return isDevelopmentHost(window.location.host) && !isProductionPreview;
}

export function isLocal() {
  return window.location.host.startsWith("localhost:");
}
