import Modal from "components/Modal/Modal"
import cx from "classnames";
import React from "react";
import { getAddress } from "ethers/lib/utils";
import { format, fromUnixTime } from "date-fns";
import { Trans, t } from "@lingui/macro";
import { getTokenInfo } from "domain/tokens/utils";
import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import { TokenUtils } from "components/TokenUtils";

const TradeHistoryDetailsModal = (
  {
    open,
    setIsVisible,
    trade,
    infoTokens
  }
) => {

  if (trade) {
    const indexToken = getTokenInfo(infoTokens, getAddress(trade.token));

    return (
      <Modal
        isVisible={open}
        setIsVisible={setIsVisible}
        label={
          <div className="tailwind isolate flex items-center justify-center gap-2.5">
            <TokenUtils.Logo token={indexToken} className="w-[3rem]"></TokenUtils.Logo>

            <div className="flex flex-col items-start">
              <div className="text-[1.8rem] text-textColor font-bold">{TokenUtils.getSymbol(indexToken)}</div>
              {trade.leverage && (
                <div className="text-[1.2rem] font-normal text-inactive">
                  {"Leverage " + formatAmount(trade.leverage, 4, 2, true)}x&nbsp;
                </div>
              )}
            </div>
          </div>
        }
      >

        <div className="tailwind isolate flex flex-col gap-4 text-[1.4rem] text-inactive">
          <div className="h-px w-full bg-border mb-5" />
          <div className="flex items-center justify-between">
            <div><Trans>Side</Trans></div>
            <span className={cx({ long: trade.isLong, short: !trade.isLong })}>
              {trade.isLong ? t`Long` : t`Short`}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div><Trans>Size</Trans></div>
            <div className="text-textColor">${formatAmount(trade.size, USD_DECIMALS, 4, true)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div><Trans>Action</Trans></div>
            <div className="text-textColor">{trade.type}</div>
          </div>
          <div className="flex items-center justify-between">
            <div><Trans>Type</Trans></div>
            <div className="text-textColor">{trade.action}</div>
          </div>
          <div className="flex items-center justify-between">
            <div><Trans>Price</Trans></div>
            <div className="text-textColor">${formatAmount(trade.price, USD_DECIMALS, 2, true)}</div>
          </div>
          <div className="flex items-center justify-between">
            <div><Trans>Date</Trans></div>
            <div className="text-textColor">{format(fromUnixTime(trade.timestamp), "PPpp")}</div>
          </div>
        </div>
      </Modal>
    )
  }

  return null
}

export default TradeHistoryDetailsModal
