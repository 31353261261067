import { getProvider } from "../rpc";
import { getEthersContractByProvider } from "lib/contracts/getEthersContract";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { ContractByName, ContractName, getContractAddress, getContractFactory } from "config/contracts";
import { CallContractOptions, callContract } from "lib/contracts/callContract";
import { usePendingTxns } from "domain/transactions/usePendingTxns";
import { contractFetcherCall } from "lib/contracts/contractFetcher";
import { MulticallRequestConfig, useMulticall } from "../multicall";
import { useMemo } from "react";

type FilterMethods<T extends Object> = {
  [Key in keyof T as T[Key] extends (...args: any) => any ? Key : never]: T[Key];
};

export type QuickContractCallOptions = Omit<CallContractOptions, "setPendingTxns"> | undefined;

export function useQuickMulticall(
  name: string,
  config: { contractName: ContractName; method: string; params: any[] }[]
) {
  const { chainId } = useChainId();
  const request = useMemo<Promise<MulticallRequestConfig<any>>>(async () => {
    let requestConfig = {};
    for (let i = 0; i < config.length; i++) {
      const item = config[i];
      const contractAddress = getContractAddress(chainId, item.contractName);
      const contractFactory = await getContractFactory(item.contractName)();
      requestConfig[`${i}`] = {
        contractAddress,
        contractName: item.contractName,
        methodName: item.method,
        abi: contractFactory.abi,
        calls: {
          current: {
            methodName: item.method,
            params: item.params,
          },
        },
      };
    }
    return requestConfig;
  }, [chainId, config]);
  // TODO need to fix the return type for autocomplete
  return useMulticall(chainId, name, {
    key: [null],
    request: () => request,
    parseResponse: (res, req) => {
      let result: any[] = [];
      for (const key in res) {
        const item = res[key];
        const data = item.current.returnValues.length === 1 ? item.current.returnValues[0] : item.current.returnValues;
        result.push(data);
      }
      return result;
    },
  });
}

export function useQuickContracts() {
  const { chainId } = useChainId();
  const { provider: library } = useWeb3React();
  const [, setPendingTxns] = usePendingTxns();

  async function quickCall<
    TContractName extends ContractName,
    TContract extends ContractByName<TContractName>,
    TMethodName extends keyof FilterMethods<TContract>,
    TMethod extends TContract[TMethodName],
    TMethodParams extends TMethod extends (...args: any) => any ? Parameters<TMethod> : never,
    TMethodReturnType extends TMethod extends (...args: any) => any ? ReturnType<TMethod> : never
  >(
    contractName: TContractName,
    method: TMethodName,
    params: TMethodParams,
    options?: QuickContractCallOptions
  ): Promise<Awaited<TMethodReturnType>> {
    const contractAddress = getContractAddress(chainId, contractName);
    const contractFactory = (await getContractFactory(contractName)()) as any;
    const provider = getProvider(library, chainId);
    const contract = getEthersContractByProvider(contractFactory, provider, contractAddress);

    return callContract(chainId, contract, method as any, params, {
      setPendingTxns,
      ...options,
    });
  }

  async function quickFetch<
    TContractName extends ContractName,
    TContract extends ContractByName<TContractName>,
    TMethodName extends keyof FilterMethods<TContract>,
    TMethod extends TContract[TMethodName],
    TMethodParams extends TMethod extends (...args: any) => any ? Parameters<TMethod> : never,
    TMethodReturnType extends TMethod extends (...args: any) => any ? ReturnType<TMethod> : never
  >(contractName: TContractName, method: TMethodName, params: TMethodParams): Promise<Awaited<TMethodReturnType>> {
    const contractAddress = getContractAddress(chainId, contractName);
    const contractFactory = (await getContractFactory(contractName)()) as any;

    return contractFetcherCall(library, chainId, contractFactory, contractName, contractAddress, method as any, params);
  }

  return {
    /**
     *  @description shows error notifications and doesn't use fallback providers
     */
    call: quickCall,
    /**
     *  @description uses fallback providers and doesn't show notifications
     */
    fetch: quickFetch,
  };
}
