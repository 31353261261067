import { initializeConnector } from '@web3-react/core'
import { Connection, ConnectionType } from 'lib/wallets/connections'
import { MAINNET, SUPPORTED_CHAIN_IDS, TESTNET, getRpcUrl } from 'config/chains'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { helperToast } from 'lib/helperToast'

export const WALLET_CONNECT_SUPPORTED_CHAINS = [
  MAINNET
]

export function assertSupportedWalletConnectChain(chainId: number) {
  if (!WALLET_CONNECT_SUPPORTED_CHAINS.includes(chainId)) {
    throw new Error(`ChainId ${chainId} is not supported by wallet connect`)
  }
}

export function buildWalletConnectConnector(): Connection {
  const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: "1f9112cf5a570b95c05d270834a2d1cc",
          showQrModal: true,
          chains: [SUPPORTED_CHAIN_IDS[0]],
          optionalChains: SUPPORTED_CHAIN_IDS,
          rpcMap: {
            [MAINNET]: getRpcUrl(MAINNET)!,
            [TESTNET]: getRpcUrl(TESTNET)!,
          },
        },
        onError(error) {
          helperToast.error(error.message);
        }
      })
  )

  return {
    connector: walletConnectV2,
    hooks,
    type: ConnectionType.WALLET_CONNECT,
  }
}
