import React from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { LoadableEventLayout } from "pages/Event/components/LoadableEventLayout";
import { EpocheHistorySection } from "pages/Event/events/TradeToEarnEvent/components/EpocheHistorySection";
import { useEventGeneralInfoQuery } from "pages/Event/events/TradeToEarnEvent/hooks/useEventGeneralInfoQuery";
import { GeneralInfo, UserInfo } from "pages/Event/events/TradeToEarnEvent/types";
import { useEventUserInfoQuery } from "pages/Event/events/TradeToEarnEvent/hooks/useEventUserInfoQuery";
import { Trans } from "@lingui/macro";
import Button from "../../../../components/Button/Button";
import { EVENT_DOCUMENTATION_LINK } from "./constants";
import totalUser from "../../../../img/trade_event_total_participants.png";
import tradeEventArrowRight from "../../../../img/trade_event_arrow_right.svg";
import tradeEventArrowLeftUp from "../../../../img/trade_event_arrow_left_up.svg";
import tradeEventArrowLeftDown from "../../../../img/trade_event_arrow_left_down.svg";
import palmToken from "../../../../img/PalmToken.svg";

export type TierDetails = {
  tier: BigNumber;
  rewardPerToken: BigNumber;
  estimatedApr: BigNumber;
  totalRewards: BigNumber;
  startAmount: BigNumber;
  endAmount: BigNumber;
};

type TradeToEarnEventProps = {
  label: string;
  description?: string;
};

export const TradeToEarnEvent = (props: TradeToEarnEventProps) => {
  const eventGeneralInfoQuery = useEventGeneralInfoQuery();
  const eventUserInfoQuery = useEventUserInfoQuery();

  const isLoading = React.useMemo(() => {
    return !eventGeneralInfoQuery.data || !eventUserInfoQuery.data;
  }, [eventGeneralInfoQuery, eventUserInfoQuery]);

  return (
    <LoadableEventLayout isLoading={isLoading}>
      {eventGeneralInfoQuery.data && eventUserInfoQuery.data && (
        <TradeToEarnEventDummy
          {...props}
          generalInfo={eventGeneralInfoQuery.data}
          userInfo={eventUserInfoQuery.data}
        ></TradeToEarnEventDummy>
      )}
    </LoadableEventLayout>
  );
};

type TradeToEarnEventDummyProps = {
  label: string;
  description?: string;
  generalInfo: GeneralInfo;
  userInfo: UserInfo;
};

const TradeToEarnEventDummy = React.memo((props: TradeToEarnEventDummyProps) => {
  const { description, generalInfo, userInfo } = props;

  return (
    <>
      <div className="flex items-center justify-center py-[4rem] gap-1">
        <div className="hidden lg:flex flex-column items-end w-1/4">
          <img src={tradeEventArrowRight} alt="arrow" className="relative -top-14" />
          <div className="relative right-1/4 -top-28 rounded-full bg-border border-transparent p-3 pr-5 flex justify-center items-center gap-3 text-[12px]">
            <img src={totalUser} alt="token" className="w-12" />
            <Trans>Over 1K+ participants</Trans>
          </div>
        </div>
        <div className="flex flex-column lg:w-2/4">
          <h1 className="text-[6rem] font-bold text-main text-center uppercase font-family-mazzard">
            <Trans>Trade to earn</Trans>
            <br />
            <Trans>event is over</Trans>
          </h1>
          <p className="mt-3 text-center">{description}</p>
          <div className="flex justify-center">
            <Button className="my-4" variant="primary-action" to={EVENT_DOCUMENTATION_LINK} newTab>
              <Trans>View Documentation</Trans>
            </Button>
          </div>
        </div>
        <div className="hidden lg:flex flex-column gap-32 w-1/4">
          <div className="flex items-start">
            <img src={tradeEventArrowLeftUp} alt="arrow" className="relative" />
            <div className="rounded-full bg-border border-transparent p-3 pr-5 flex justify-center items-center gap-3 text-[12px]">
              <img src={palmToken} alt="token" className="w-12" />
              <Trans>33M Epoch allocation</Trans>
            </div>
          </div>
          <div className="flex items-start">
            <img src={tradeEventArrowLeftDown} alt="arrow" className="relative" />
            <div className="rounded-full bg-border border-transparent p-6 text-[12px]">
              <Trans>146 Epochs in total</Trans>
            </div>
          </div>
        </div>
      </div>

      <EpocheHistorySection className="mt-[5rem]" generalInfo={generalInfo} userInfo={userInfo}></EpocheHistorySection>
    </>
  );
});
