import useSWR from "swr";
import { getGatewayApiUrl } from "./getGatewayApiUrl";
import { useCallback } from "react";
import { PALM_DECIMALS, PLP_DECIMALS, USD_DECIMALS } from "lib/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";
import { expandDecimals } from "lib/numbers";
import { usePlpPrice } from "pages/Event/hooks/blockchain/usePlpPrice";

type TokenPrice = {
  price: number;
  lastRequestedAt: number;
};

export const useTokenPrices = (token: string) => {
  const {
    data: tokenPrice = {
      price: 0,
      lastRequestedAt: 0,
    },
  } = useSWR<TokenPrice>(
    [`FetchTokenPrices:${token}`, token],
    ([, token]) =>
      fetch(`${getGatewayApiUrl()}/prices/${token}`)
        .then((res) => res.json())
        .then((data) => data as TokenPrice),
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  return tokenPrice;
};

export const usePalmPrice = () => {
  const { price: palmPrice } = useTokenPrices("PALM");

  return palmPrice;
};

export const useGetUsdAmountFromPalmAmount = () => {
  const palmPrice = usePalmPrice();

  const getUsdAmountFromPalmAmount = useCallback(
    (palmAmount: BigNumber): string => {
      return formatUnits(
        parseUnits(String(palmPrice), USD_DECIMALS).mul(palmAmount).div(expandDecimals(1, PALM_DECIMALS)),
        USD_DECIMALS
      );
    },
    [palmPrice]
  );

  return getUsdAmountFromPalmAmount;
};

export const useGetUsdAmountFromPlpAmount = () => {
  const plpPrice = usePlpPrice();

  const getUsdAmountFromPlpAmount = useCallback(
    (plpAmount: BigNumber): string => {
      return formatUnits(
        parseUnits(String(plpPrice.formatted), USD_DECIMALS).mul(plpAmount).div(expandDecimals(1, PLP_DECIMALS)),
        USD_DECIMALS
      );
    },
    [plpPrice]
  );

  return getUsdAmountFromPlpAmount;
};
