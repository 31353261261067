import React, { useEffect, useState } from "react";
import { Trans, t } from "@lingui/macro";
import { HiInformationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

import "./StakeV3.scss";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import "./Stake.css";

import { usePlpUnstakeCooldown } from "lib/usePlpUnstakeCooldown";
import formatDistanceStrict from "date-fns/formatDistanceStrict";

export const STAKE_ACTION = "STAKE_ACTION";
export const UNSTAKE_ACTION = "UNSTAKE_ACTION";
export const PALM_TOKEN = "PALM_TOKEN";
export const PLP_TOKEN = "PLP_TOKEN";

const StakeModal = ({ open, setOpen, stakeModalData }) => {
  const [hideModal, setHideModal] = useState(JSON.parse(localStorage.getItem("doNotShowModalForBuyingPLP")) || false);

  const { action, token } = stakeModalData;
  const isStaking = action === STAKE_ACTION;
  const tokenName = token === PLP_TOKEN ? "PLP" : "PALM";
  const actionName = isStaking ? t`Stake` : t`Unstake`;
  const stakeLink = "/buy";

  const plpUnstakeCooldown = usePlpUnstakeCooldown();
  const plpUnstakeCooldownText = formatDistanceStrict(plpUnstakeCooldown, 0, { roundingMethod: "ceil" });

  const plpTokenInfoText = isStaking
    ? t`To stake PLP tokens, you need to buy them on the Buy page. Once you buy PLP, they will be automatically added into the staking pool and earn 50% of the trading fees generated by the Palmswap Exchange. You can unstake the PLP tokens at any time. After a cooldown period of ${plpUnstakeCooldownText}, you can sell your PLP tokens.`
    : t`To unstake PLP tokens, you need to unstake them on the Buy page. After a cooldown period of ${plpUnstakeCooldownText}, you can sell your PLP tokens. The rewards you have received so far can be claimed even after you have unstaked PLP, but you will not receive any new rewards.`;

  useEffect(() => {
    if (hideModal) {
      localStorage.setItem("doNotShowModalForBuyingPLP", JSON.stringify(hideModal));
    } else {
      localStorage.setItem("doNotShowModalForBuyingPLP", JSON.stringify(hideModal));
    }
  }, [hideModal]);

  const closeModal = () => {
    setOpen(!open);
  };

  return (
    <Modal
      className="Connect-wallet-modal tailwind"
      isVisible={open}
      setIsVisible={closeModal}
      label={`${actionName} ${tokenName}`}
    >
      <div className="Earn-modal">
        <div className="Stake-modal-top-section">
          <div className="Stake-modal-info">
            <div>
              <HiInformationCircle className="inactive-text my-[.4rem]" />
            </div>
            <div className="text-inactive text-[1.4rem] leading-[2.4rem]">{plpTokenInfoText}</div>
          </div>
        </div>

        {!isStaking && (
          <dl className="mt-[2rem] text-inactive text-[1.4rem] leading-[2.4rem] flex justify-between items-center">
            <dt>
              <Trans>Unstake cooldown</Trans>
            </dt>

            <dd>{plpUnstakeCooldownText}</dd>
          </dl>
        )}

        <div className="flex items-center mt-[2rem] mb-[3rem]">
          <input
            type="checkbox"
            className="Stake-checkbox"
            id="Stake-checkbox"
            checked={hideModal}
            onChange={() => setHideModal(!hideModal)}
          />
          <label htmlFor="Stake-checkbox">
            <span className="ml-[0.8rem] isolate tailwind text-inactive text-[1.4rem whitespace-nowrap">
              <Trans>Don’t show this message again</Trans>
            </span>
          </label>
        </div>
        <Button variant="primary-action" rounded className="w-full rounded">
          <Link className="w-full" to={stakeLink}>
            <Trans>Visit "Buy" page</Trans>
          </Link>
        </Button>
      </div>
    </Modal>
  );
};

export default StakeModal;
