import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TV_CHART_RELOAD_TIMESTAMP_KEY, TV_SAVE_LOAD_CHARTS_KEY, TRADINGVIEW_CHART_COLOR } from "config/localStorage";
import { useLocalStorage, useMedia } from "react-use";
import { defaultChartProps, DEFAULT_PERIOD, disabledFeaturesOnMobile } from "./constants";
import { ChartData, IChartingLibraryWidget, IPositionLineAdapter } from "../../charting_library";
import { getObjectKeyFromValue } from "domain/tradingview/utils";
import { SaveLoadAdapter } from "./SaveLoadAdapter";
import { SUPPORTED_RESOLUTIONS, TV_CHART_RELOAD_INTERVAL } from "config/tradingview";
import { isChartAvailabeForToken } from "config/tokens";
import { TVDataProvider } from "domain/tradingview/TVDataProvider";
import Loader from "components/Common/Loader";
import { useLocalStorageReactive, useLocalStorageSerializeKey } from "lib/localStorage";
import { CHART_PERIODS } from "lib/legacy";
import ChartTradingPanel from "./ChartTradingPanel";
import { Token } from "domain/tokens";
import { useAtom } from "jotai";
import {
  isAdvancedChartEnabledAtom,
  isChartTradingEnabledAtom,
  isChartTradingEnabledOnMobileAtom,
  isChartFullScreenAtom,
} from "pages/Exchange/state";
import useThemeSwitcher from "lib/useThemeSwitcher";
import fullScreenIcon from "img/fullScreen.svg";
import { usePythDatafeed } from "domain/tradingview/usePythDatafeed";
import { savedChartAtom } from "./state";

type ChartLine = {
  price: number;
  title: string;
  isLong: boolean;
};

type Props = {
  symbol: string;
  chainId: number;
  savedShouldShowPositionLines: boolean;
  chartLines: ChartLine[];
  onSelectToken: (token: Token) => void;
  dataProvider?: TVDataProvider;
  deltaPercentage: number;
};

export default function TVChartContainer({
  symbol,
  chainId,
  savedShouldShowPositionLines,
  chartLines,
  onSelectToken,
  dataProvider,
  deltaPercentage,
}: Props) {
  const isMobile = useMedia("(max-width: 550px)");

  let [period, setPeriod] = useLocalStorageSerializeKey([chainId, "Chart-period"], DEFAULT_PERIOD);

  const [, setIsAdvancedChartEnabled] = useAtom(isAdvancedChartEnabledAtom);

  useEffect(() => {
    if (isMobile) {
      setIsAdvancedChartEnabled(false);
    } else {
      setIsAdvancedChartEnabled(true);
    }
  }, [isMobile, setIsAdvancedChartEnabled]);

  const [isAdvancedChartEnabled] = useAtom(isAdvancedChartEnabledAtom);
  const [isChartFullScreen, setIsChartFullScreen] = useAtom(isChartFullScreenAtom);
  const [savedChart, setSavedChart] = useAtom(savedChartAtom);
  const [isChartTradingEnabled] = useAtom(isMobile ? isChartTradingEnabledOnMobileAtom : isChartTradingEnabledAtom);

  if (!period || !(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }

  const { isWhiteTheme, isDarkTheme, isGrayTheme } = useThemeSwitcher();
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);
  const [chartReady, setChartReady] = useState(false);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const [tvCharts, setTvCharts] = useLocalStorage<ChartData[] | undefined>(TV_SAVE_LOAD_CHARTS_KEY, []);
  const { datafeed, resetCache } = usePythDatafeed();
  const memoizedSymbol = useMemo(() => symbol, [symbol]);

  const positiveColor = "#00c075";
  const negativeColor = "#fd4040";

  let [chartColor] = useLocalStorageReactive(TRADINGVIEW_CHART_COLOR, false);

  const drawLineOnChart = useCallback(
    (title: string, price: number, isLong: boolean, isLiquidation?: boolean) => {
      if (chartReady && tvWidgetRef.current?.activeChart?.().dataReady()) {
        const chart = tvWidgetRef.current?.activeChart?.();

        const positionLine = chart.createPositionLine({ disableUndo: true });

        let chartColor = isLong ? positiveColor : negativeColor;

        if (isLiquidation) {
          chartColor = negativeColor;
        }

        return positionLine
          .setText(title)
          .setPrice(price)
          .setQuantity("")
          .setLineStyle(1)
          .setLineLength(1)
          .setBodyFont(`normal 12pt "Relative", sans-serif`)
          .setBodyTextColor("#fff")
          .setLineColor(chartColor)
          .setBodyBackgroundColor("#2b2f35")
          .setBodyBorderColor("#2b2f35");
      }
    },
    [chartReady]
  );

  /* Tradingview charting library only fetches the historical data once so if the tab is inactive or system is in sleep mode
  for a long time, the historical data will be outdated. */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        localStorage.setItem(TV_CHART_RELOAD_TIMESTAMP_KEY, Date.now().toString());
      } else {
        const tvReloadTimestamp = Number(localStorage.getItem(TV_CHART_RELOAD_TIMESTAMP_KEY));
        if (tvReloadTimestamp && Date.now() - tvReloadTimestamp > TV_CHART_RELOAD_INTERVAL) {
          if (resetCache) {
            resetCache();
            tvWidgetRef.current?.activeChart()?.resetData();
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [resetCache]);

  useEffect(
    function updateLines() {
      const lines: (IPositionLineAdapter | undefined)[] = [];
      if (savedShouldShowPositionLines && chartReady) {
        chartLines.forEach((order) => {
          lines.push(drawLineOnChart(order.title, order.price, order.isLong));
        });
      }
      return () => {
        lines.forEach((line) => line?.remove());
      };
    },
    [chartLines, savedShouldShowPositionLines, drawLineOnChart, chartReady]
  );

  useEffect(() => {
    if (chartReady && tvWidgetRef.current && symbol !== tvWidgetRef.current?.activeChart()?.symbol()) {
      if (isChartAvailabeForToken(chainId, symbol)) {
        tvWidgetRef.current.setSymbol(symbol, tvWidgetRef.current.activeChart().resolution(), () => {});
      }
    }
  }, [symbol, chartReady, period, chainId]);

  const priceLineColor = useMemo(() => {
    if (deltaPercentage > 0) {
      return positiveColor;
    } else {
      return negativeColor;
    }
  }, [deltaPercentage]);

  useEffect(() => {
    const widgetOptions = {
      debug: false,
      symbol: memoizedSymbol, // Using useMemo to avoid unnecessary re-renders on symbol change, but get access to new value of symbol when market is changing
      datafeed: datafeed,
      auto_save_delay: 5,
      theme: isWhiteTheme ? "Light" : "Dark",
      withdateranges: defaultChartProps.withdateranges,
      container: chartContainerRef.current,
      library_path: defaultChartProps.library_path,
      locale: defaultChartProps.locale,
      loading_screen: defaultChartProps.loading_screen,
      enabled_features: defaultChartProps.enabled_features.concat(
        isAdvancedChartEnabled ? [] : ["hide_left_toolbar_by_default"]
      ),
      disabled_features: isMobile
        ? defaultChartProps.disabled_features.concat(disabledFeaturesOnMobile)
        : defaultChartProps.disabled_features,
      client_id: defaultChartProps.clientId,
      user_id: defaultChartProps.userId,
      fullscreen: false,
      autosize: defaultChartProps.autosize,
      custom_css_url: defaultChartProps.custom_css_url,
      overrides: {
        ...defaultChartProps.overrides,
        "mainSeriesProperties.priceLineColor": priceLineColor,
      },
      interval: getObjectKeyFromValue(period, SUPPORTED_RESOLUTIONS),
      favorites: defaultChartProps.favorites,
      custom_formatters: defaultChartProps.custom_formatters,
      saved_data: savedChart,
      study_templates: true,
      load_last_chart: true,
      save_load_adapter: new SaveLoadAdapter(chainId, tvCharts, setTvCharts, onSelectToken),
      toolbar_bg: isWhiteTheme ? "#fff" : isDarkTheme ? "#101014" : isGrayTheme ? "#0f1014" : "#0f1014",
      studies_access: {
        type: "black",
        tools: [
          {
            name: "Volume",
          },
        ],
      },
    };

    tvWidgetRef.current = new window.TradingView.widget(widgetOptions);

    const saveChart = () => {
      tvWidgetRef.current?.save((savedData) => {
        setSavedChart(savedData);
      });
    };

    tvWidgetRef.current?.headerReady().then(function () {
      // Check if the user is on mobile before creating the button
      if (!isMobile) {
        var button = tvWidgetRef.current?.createButton({
          align: "right",
        });

        const svgIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgIcon.setAttribute("width", "16");
        svgIcon.setAttribute("height", "16");

        const image = document.createElementNS("http://www.w3.org/2000/svg", "image");

        button?.setAttribute("title", "Expand Chart");

        image.setAttribute("width", "16");
        image.setAttribute("height", "16");
        image.setAttribute("href", fullScreenIcon);

        svgIcon.appendChild(image);
        button?.appendChild(svgIcon);
        button?.addEventListener("click", () => {
          setIsChartFullScreen(!isChartFullScreen); // Toggle full-screen state#
          setChartReady(false);
        });
      }
    });

    tvWidgetRef.current?.onChartReady(function () {
      setChartReady(true);
      tvWidgetRef.current!.applyOverrides({
        "paneProperties.background": isWhiteTheme ? "#ffffff" : chartColor ? "#0f1014" : "#16171d",
        "scalesProperties.textColor": isWhiteTheme ? "#000000" : "#fff",
        "paneProperties.vertGridProperties.color": isWhiteTheme ? "rgba(242,242,242,255)" : "rgba(240, 243, 250, 0.06)",
        "paneProperties.horzGridProperties.color": isWhiteTheme ? "rgba(242,242,242,255)" : "rgba(240, 243, 250, 0.06)",
        "scalesProperties.lineColor": isWhiteTheme ? "#e3e3e3" : "#2b2f35",
      });

      tvWidgetRef.current?.subscribe("drawing_event", saveChart);
      tvWidgetRef.current?.subscribe("study_event", saveChart);

      tvWidgetRef.current
        ?.activeChart()
        ?.onIntervalChanged()
        ?.subscribe(null, (interval) => {
          if (SUPPORTED_RESOLUTIONS[interval]) {
            const period = SUPPORTED_RESOLUTIONS[interval];
            setPeriod(period);
          }
        });

      tvWidgetRef.current?.activeChart()?.dataReady(() => {
        setChartDataLoading(false);
        setChartReady(true);
      });
    });

    return () => {
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();

        tvWidgetRef.current = null;
        setChartReady(false);
        setChartDataLoading(true);
      }
    };
    // We don't want to re-initialize the chart when the symbol changes. This will make the chart flicker.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, isAdvancedChartEnabled, isWhiteTheme, chartColor, isChartFullScreen, priceLineColor]);

  return (
    <div className="tv-chart-container">
      {chartDataLoading ? <Loader /> : isChartTradingEnabled && <ChartTradingPanel />}

      <div
        style={{ visibility: !chartDataLoading ? "visible" : "hidden" }}
        ref={chartContainerRef}
        className="TVChartContainer ExchangeChart-bottom-content"
      />
    </div>
  );
}
