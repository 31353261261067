import { gql } from "@apollo/client";
import { BigNumber } from "ethers";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";

import { getPalmSubgraph } from "lib/subgraph/clients";
import { bigNumberify } from "lib/numbers";
import { GlobalStakeEarnInfo, UserStakeEarnInfo } from "./types";
import { useChainId } from "lib/chains";

export function useStakeEarnStats() {
  const { chainId } = useChainId();
  const { account } = useWeb3React();

  async function totalFetcher() {
    const client = getPalmSubgraph(chainId, "raw");

    const query = gql`
      {
        globalStakeInfo(id: "GlobalStakeInfo") {
          palmRewardsEarned
          plpRewardsEarned
        }
      }
    `;

    const res = await client!.query<GlobalStakeEarnInfo>({ query });
    const palmClaimedTotal = res?.data?.globalStakeInfo?.palmRewardsEarned;
    const plpClaimedTotal = res?.data?.globalStakeInfo?.plpRewardsEarned;

    return {
      palmClaimedTotal: bigNumberify(palmClaimedTotal) ?? BigNumber.from(0),
      plpClaimedTotal: bigNumberify(plpClaimedTotal) ?? BigNumber.from(0),
    };
  }

  async function userFetcher() {
    const client = getPalmSubgraph(chainId, "raw");
    const accountString = account ? account.toLowerCase() : "";

    const query = gql`
      query stakesByUser($account: String!) {
        palmStake(id: $account) {
          rewardsEarned
        }
        plpStake(id: $account) {
          rewardsEarned
        }
      }
    `;

    const res = await client!.query<UserStakeEarnInfo>({
      query,
      variables: { account: accountString },
    });
    const claimPalms = res?.data?.palmStake;
    const claimPlps = res?.data?.plpStake;

    return {
      palmClaimedUser: bigNumberify(claimPalms.rewardsEarned) ?? BigNumber.from(0),
      plpClaimedUser: bigNumberify(claimPlps.rewardsEarned) ?? BigNumber.from(0),
    };
  }

  const userData = useSWR<Awaited<ReturnType<typeof userFetcher>>>(
    ["Earn:user-earned", chainId, account],
    userFetcher,
    {
      refreshInterval: 15000,
    }
  );

  const totalData = useSWR<Awaited<ReturnType<typeof totalFetcher>>>(["Earn:total-earned", chainId], totalFetcher, {
    refreshInterval: 15000,
  });

  return {
    palmClaimedTotal: totalData.data?.palmClaimedTotal || BigNumber.from(0),
    plpClaimedTotal: totalData.data?.plpClaimedTotal || BigNumber.from(0),
    palmClaimedUser: userData.data?.palmClaimedUser || BigNumber.from(0),
    plpClaimedUser: userData.data?.plpClaimedUser || BigNumber.from(0),
  };
}
