import React from "react";
import { Trans } from "@lingui/macro";
import cx from "classnames";
import ExternalLink from "components/ExternalLink/ExternalLink";
import shevronDown from "img/shevron-down-circle.svg";
import { Menu } from "components/MenuDropdown";

export function ProtocolDropdown({ className }: { className?: string }) {
  return (
    <div className={cx("relative", className)}>
      <Menu.Menu placement="bottom-start">
        <Menu.Button>
          {({ open, ref }) => (
            <button
              ref={ref}
              className={cx(
                "inline-flex rounded-sm items-center px-4 gap-4 cursor-pointer h-[3.6rem] border w-[12.8rem] transition",
                {
                  "bg-background-3": open,
                  "hover:bg-background-3": !open,
                }
              )}
            >
              <div className="flex-1">Synthetic</div>

              <div className="w-[1.8rem] h-[1.8rem] rounded-full border-0 bg-background-3 flex items-center justify-center shrink-0">
                <img src={shevronDown} alt="" />
              </div>
            </button>
          )}
        </Menu.Button>

        <Menu.Items>
          <Menu.Label>
            <Trans>Select Protocol</Trans>
          </Menu.Label>

          <Menu.Item>
            {({ className }) => (
              <>
                <button className={className}>
                  <span className="ml-[0.8rem]">
                    <Trans>Synthetic</Trans>
                  </span>
                </button>
              </>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => (
              <>
                <ExternalLink href="https://app.palmswap.org" className={cx(className, "no-underline")} target="_self">
                  <span className="ml-[0.8rem]">
                    <Trans>dvAMM</Trans>
                  </span>
                </ExternalLink>
              </>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu.Menu>
    </div>
  );
}
