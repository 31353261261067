import { Contract, ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { getFallbackProvider, getProvider } from "../rpc";
import parse from "parse-duration";
import { ContractFactory, getEthersContractByProvider } from "lib/contracts/getEthersContract";

const CONTRACT_CALL_RETRY_TIMEOUT = parse("5 sec") ?? 0;

/**
 *  @deprecated use `useQuickContracts` (preferrable) or `contractFetcherCall` instead
 */
export const contractFetcher =
  <T>(library: Web3Provider | undefined, contractInfo: any, additionalArgs?: any[]) =>
  (args: any[]): Promise<T> => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;
    const provider = getProvider(library, chainId);

    const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

    const address = arg0;
    const contract = new ethers.Contract(address, contractInfo.abi, provider);
    const contractCall = getContractCall(contract, method, [...params, ...(additionalArgs ?? [])]);

    let shouldCallFallback = true;

    const handleFallback = async (resolve, reject, error) => {
      if (!shouldCallFallback) {
        return;
      }
      // prevent fallback from being called twice
      shouldCallFallback = false;

      const fallbackProvider = getFallbackProvider(chainId);
      if (!fallbackProvider) {
        reject(error);
        return;
      }

      // eslint-disable-next-line no-console
      console.info("using fallbackProvider for", method);

      const contract = new ethers.Contract(address, contractInfo.abi, fallbackProvider);
      const fallbackContractCall = getContractCall(contract, method, [...params, ...(additionalArgs ?? [])]);

      fallbackContractCall
        .then((result) => resolve(result))
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("fallback fetcher error", id, contractInfo.contractName, method, e);
          reject(e);
        });
    };

    return new Promise(async (resolve, reject) => {
      contractCall
        .then((result) => {
          shouldCallFallback = false;
          resolve(result);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("fetcher error", id, contractInfo.contractName, method, e);
          handleFallback(resolve, reject, e);
        });

      setTimeout(() => {
        handleFallback(resolve, reject, "contractCall timeout");
      }, CONTRACT_CALL_RETRY_TIMEOUT);
    });
  };

function getContractCall<TContract extends Contract, TMethod extends keyof TContract>(
  contract: TContract,
  method: TMethod,
  params: Parameters<TContract[TMethod]>
): ReturnType<TContract[TMethod]> {
  return contract[method](...params);
}

const FALLBACK_ATTEMPS = 3;

export function contractFetcherCall<TContract extends Contract, TMethod extends keyof TContract>(
  library: Web3Provider | undefined,
  chainId,
  contractFactory: ContractFactory<TContract>,
  contractName: string,
  contractAddress: string,
  method: TMethod,
  params: Parameters<TContract[TMethod]>
): Promise<ReturnType<TContract[TMethod]>> {
  const initedParams = (params ?? []) as Parameters<TContract[TMethod]>;
  const provider = getProvider(library, chainId);
  const contract = getEthersContractByProvider(contractFactory, provider, contractAddress);
  const contractCall = getContractCall(contract, method, initedParams);

  let fallbackCountdown = FALLBACK_ATTEMPS;

  const handleFallback = async (resolve, reject, error) => {
    const fallbackProvider = getFallbackProvider(chainId);
    if (fallbackCountdown <= 0 || !fallbackProvider) {
      reject(error);
      return;
    }

    // eslint-disable-next-line no-console
    console.info("using fallbackProvider for", method);
    fallbackCountdown -= 1;

    const fallbackContract = getEthersContractByProvider(contractFactory, fallbackProvider, contractAddress);
    const fallbackContractCall = getContractCall(fallbackContract, method, initedParams);

    fallbackContractCall
      .then((result) => resolve(result))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn("fallback fetcher error", contractName, method, error);
        reject(error);
      });
  };

  return new Promise(async (resolve, reject) => {
    contractCall
      .then((result) => {
        fallbackCountdown -= 1;
        resolve(result);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn("fetcher error", contractName, method, error);
        handleFallback(resolve, reject, error);
      });

    setTimeout(() => {
      handleFallback(resolve, reject, "contractCall timeout");
    }, CONTRACT_CALL_RETRY_TIMEOUT);
  });
}
