import { useWeb3React } from "@web3-react/core";
import { AddressDropdown } from "components/Header/AddressDropdown/AddressDropdown";
import { ConnectWalletButton, ConnectWalletButtonMobile } from "components/Common/ConnectWalletButton";
import React, { useCallback, useEffect } from "react";
import connectWalletImg from "img/ic_wallet_24.svg";
import connectWalletMobile from "img/connectWalletMobile.svg";
import { getAccountUrl } from "lib/legacy";
import { Trans } from "@lingui/macro";
import SettingsDropdown from "../SettingsDropdown/SettingsDropdown";
import LanguagePopupHome from "../SettingsDropdown/LanguagePopupHome";
import SocialsDropdown from "components/Header/SocialsDropdown/SocialsDropdown";
import { getChainName, MAINNET, TESTNET } from "config/chains";
import { useChainId } from "lib/chains";
import { isDevelopment } from "config/env";
import { getNetworkIcon } from "config/icons";
import cx from "classnames";
import { switchNetwork } from "lib/wallets/connections";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { ReactComponent as SunIcon } from "img/SunIcon.svg";
import { ReactComponent as MoonIcon } from "img/MoonIcon.svg";
import useThemeSwitcher from "lib/useThemeSwitcher";
import { THEMES } from "lib/themes";

type Props = {
  openSettings: () => void;
  small?: boolean;
  disconnectAccountAndCloseSettings: () => void;
  savedSlippageAmount: number;
  setSavedSlippageAmount: (value: number) => void;
  savedShouldShowPositionLines: any;
  setSavedShouldShowPositionLines: any;
  tradingLayout: string;
  setTradingLayout: (value: string) => void;
  className?: string;
};

const NETWORK_OPTIONS = [
  !isDevelopment() && {
    label: getChainName(MAINNET),
    value: MAINNET,
    icon: getNetworkIcon(MAINNET),
    color: "#264f79",
  },
  isDevelopment() && {
    label: getChainName(TESTNET),
    value: TESTNET,
    icon: getNetworkIcon(TESTNET),
    color: "#264f79",
  },
].filter(Boolean);

export function AppHeaderUser({
  openSettings,
  disconnectAccountAndCloseSettings,
  savedSlippageAmount,
  setSavedSlippageAmount,
  savedShouldShowPositionLines,
  setSavedShouldShowPositionLines,
  className,
  tradingLayout,
  setTradingLayout,
}: Props) {
  const { rawChainId } = useChainId();
  const { isActive, account, connector } = useWeb3React();
  const showConnectionOptions = true;
  const { setIsWalletModalVisible, connectWallet } = useConnectWalletUi();

  useEffect(() => {
    if (isActive) {
      setIsWalletModalVisible(false);
    }
  }, [isActive, setIsWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === rawChainId) {
        return;
      }
      return switchNetwork(option.value, connector);
    },
    [rawChainId, connector]
  );

  const rawSelectorLabel = getChainName(rawChainId);

  const { isDarkTheme, isWhiteTheme, setTheme } = useThemeSwitcher();

  if (!isActive || !account) {
    return (
      <div className={cx("", className)}>
        <div className={cx("flex items-center gap-[1rem]")}>
          {isWhiteTheme && (
            <button
              className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
              onClick={() => setTheme(THEMES.dark.name)}
            >
              <MoonIcon className="fill-[#6C7284] w-[1.8rem]" />
            </button>
          )}
          {isDarkTheme && (
            <button
              className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
              onClick={() => setTheme(THEMES.light.name)}
            >
              <SunIcon className="fill-[#6C7284] w-[1.8rem]" />
            </button>
          )}
          <SettingsDropdown
            savedSlippageAmount={savedSlippageAmount}
            networkOptions={NETWORK_OPTIONS}
            selectorLabel={rawSelectorLabel}
            setSavedSlippageAmount={setSavedSlippageAmount}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
            savedShouldShowPositionLines={savedShouldShowPositionLines}
            setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
            tradingLayout={tradingLayout}
            setTradingLayout={setTradingLayout}
          />

          <ConnectWalletButtonMobile
            onClick={connectWallet}
            imgSrc={connectWalletMobile}
            className="max-lg:block hidden"
          />

          <ConnectWalletButton onClick={connectWallet} imgSrc={connectWalletImg} className="hidden lg:block">
            <Trans>Connect Wallet</Trans>
          </ConnectWalletButton>

          <SocialsDropdown />
        </div>
      </div>
    );
  }

  const accountUrl = getAccountUrl(rawChainId, account);

  return (
    <div className={cx("flex items-center gap-[0.5rem]", className)}>
      {showConnectionOptions ? (
        <>
          {isWhiteTheme && (
            <button
              className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
              onClick={() => setTheme(THEMES.dark.name)}
            >
              <MoonIcon className="fill-[#6C7284] w-[1.8rem]" />
            </button>
          )}
          {isDarkTheme && (
            <button
              className="h-[3.8rem] aspect-square transition-colors rounded-sm hover:bg-background-2 flex items-center justify-center"
              onClick={() => setTheme(THEMES.light.name)}
            >
              <SunIcon className="fill-[#6C7284] w-[1.8rem]" />
            </button>
          )}
          <SettingsDropdown
            networkOptions={NETWORK_OPTIONS}
            selectorLabel={rawSelectorLabel}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
            savedSlippageAmount={savedSlippageAmount}
            setSavedSlippageAmount={setSavedSlippageAmount}
            savedShouldShowPositionLines={savedShouldShowPositionLines}
            setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
            tradingLayout={tradingLayout}
            setTradingLayout={setTradingLayout}
          />

          <AddressDropdown
            account={account}
            accountUrl={accountUrl}
            disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          />

          <SocialsDropdown />
        </>
      ) : (
        <LanguagePopupHome />
      )}
    </div>
  );
}
