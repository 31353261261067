import { ReactNode } from "react";
import "./ConnectWalletButton.scss";
type ConnectWalletButtonMobileProps = {
  imgSrc: string;
  onClick?: () => void;
  className?: string;
};

export function ConnectWalletButtonMobile({ imgSrc, onClick, className }: ConnectWalletButtonMobileProps) {
  return (
    <div className={className}>
      <button className="connect-wallet-btn connect-wallet-btn-mobile" onClick={onClick}>
        {imgSrc && <img className="btn-icon" src={imgSrc} alt="Connect Wallet" />}
      </button>
    </div>
  );
}

type ConnectWalletButtonProps = {
  imgSrc: string;
  children: ReactNode;
  onClick: () => void;
  className?: string;
};

export function ConnectWalletButton({ imgSrc, children, onClick, className }: ConnectWalletButtonProps) {
  return (
    <div className={className}>
      <button className="connect-wallet-btn" onClick={onClick}>
        {imgSrc && <img className="btn-icon" src={imgSrc} alt="Connect Wallet" />}
        <span className="btn-label">{children}</span>
      </button>
    </div>
  );
}
