import React from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

type BluredEffectProps = {
  isHidden?: boolean;
};

const blurWrapperVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 3 },
  },
};

const BluredEffect = React.memo((props: BluredEffectProps) => {
  return (
    <div className="tailwind">
      <AnimatePresence>
        {!props.isHidden && (
          <motion.div
            className="pointer-events-none"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={blurWrapperVariants}
          >
            <div className="@container/blurred-effect">
              <div className="-translate-y-1/2 h-[62rem] absolute inset-x-0 top-0 mx-auto max-w-[185rem] opacity-[0.45]">
                <div className="inset-x-8 inset-y-0 absolute">
                  <div className="absolute left-1/2 -translate-x-1/2 top-0 w-[114rem] max-w-full h-[48.2rem] bg-gradient-radial from-main to-[rgba(171,171,171,0)] blur-[10rem]"></div>
                </div>

                <div className="h-[62rem] absolute inset-x-0 top-0 mx-auto max-w-[178rem]">
                  <div className="absolute w-1/2 h-1/2 top-1/2 left-0 rotate-[30deg] -translate-y-1/2 transform-gpu bg-gradient-radial from-[#5a9dba] to-black] blur-[7rem]"></div>

                  <div className="absolute w-1/2 h-1/2 top-1/2 right-0 -rotate-[30deg] -translate-y-1/2 transform-gpu bg-gradient-radial from-[#263fc5] to-black] blur-[7rem]"></div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

const pagesWhitelist = ["/", "/buy", "/earn", "/referral", "/event"];

export const DynamicBlurredEffect = () => {
  const location = useLocation();

  const isPageWhitelisted = React.useMemo(() => {
    return pagesWhitelist.some((page) => page.startsWith(location.pathname));
  }, [location.pathname]);

  return <BluredEffect isHidden={!isPageWhitelisted} />;
};
