import { format, getUnixTime, subDays, subHours, subMonths } from "date-fns";
import { BigNumber } from "ethers";

import { PlpPriceStoryItem } from "domain/stats/useGlpStats";
import { CHART_PERIODS } from "../constants";

export const convertPriceToChartData = (priceHistory: PlpPriceStoryItem[], interval: CHART_PERIODS) => {
  return [
    {
      id: "PLP price",
      color: "hsl(43, 70%, 50%)",
      data: generateChartData(interval, priceHistory),
    },
  ];
};

const getPriceForTimestamp = (currentTimestamp: Date, priceHistory: PlpPriceStoryItem[]): BigNumber | null => {
  const currentUnixTimestamp = getUnixTime(currentTimestamp);

  let closestPrice: BigNumber | null = null;
  let closestDiff: number | null = null;

  for (const { timestamp: priceChangeTimestamp, price } of priceHistory) {
    const diff = currentUnixTimestamp - priceChangeTimestamp;

    if (diff >= 0 && (closestDiff === null || diff <= closestDiff)) {
      closestPrice = price;
      closestDiff = diff;
    }
  }

  return closestPrice || null;
};

const formatChartDate = (date: Date, period?: CHART_PERIODS) => {
  return format(date, "yyyy-MM-dd HH:mm:ss");
};

const generateChartData = (interval: CHART_PERIODS, priceHistory: PlpPriceStoryItem[]) => {
  const currentDate = new Date();
  const data: { x: string; y: BigNumber }[] = [];

  switch (interval) {
    case CHART_PERIODS.DAY:
      for (let i = 0; i < 24; i++) {
        const date = subHours(currentDate, i);
        const formattedDate = formatChartDate(date);
        const price = getPriceForTimestamp(date, priceHistory);

        if (price) {
          data.push({ x: formattedDate, y: price });
        }
      }
      break;
    case CHART_PERIODS.WEEK:
      for (let i = 0; i < 7; i++) {
        const date = subDays(currentDate, i);
        const formattedDate = formatChartDate(date);
        const price = getPriceForTimestamp(date, priceHistory);

        if (price) {
          data.push({ x: formattedDate, y: price });
        }
      }
      break;
    case CHART_PERIODS.MONTH:
      for (let i = 0; i < 30; i++) {
        const date = subDays(currentDate, i);
        const formattedDate = formatChartDate(date);
        const price = getPriceForTimestamp(date, priceHistory);

        if (price) {
          data.push({ x: formattedDate, y: price });
        }
      }
      break;
    case CHART_PERIODS.YEAR:
      for (let i = 0; i < 12; i++) {
        const date = subMonths(currentDate, i);

        for (let i = 0; i < 30; i++) {
          const day = subDays(date, i);
          const formattedDate = formatChartDate(day);
          const price = getPriceForTimestamp(day, priceHistory);

          if (price) {
            data.push({ x: formattedDate, y: price });
          }
        }
      }
      break;
  }

  return data.reverse();
};
