export const SELECTED_NETWORK_LOCAL_STORAGE_KEY = "SELECTED_NETWORK";
export const WALLET_LINK_LOCALSTORAGE_PREFIX = "-walletlink";
export const SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY = "eagerconnect";
export const CURRENT_PROVIDER_LOCALSTORAGE_KEY = "currentprovider";
export const SETTINGS_LOCALSTORAGE_TRADING_LAYOUT_KEY = "trading_layout";
export const LANGUAGE_LOCALSTORAGE_KEY = "LANGUAGE_KEY";
export const TRADINGVIEW_CHART_COLOR = "tradingview_chart_color";
export const NOTIFICATION_LOCALSTORAGE_POSITION_KEY = "notification_position";
export const SLIPPAGE_BPS_KEY = "Exchange-swap-slippage-basis-points-v3";
export const IS_PNL_IN_LEVERAGE_KEY = "Exchange-swap-is-pnl-in-leverage";
export const SHOW_PNL_AFTER_FEES_KEY = "Exchange-swap-show-pnl-after-fees";
export const DISABLE_ORDER_VALIDATION_KEY = "disable-order-validation";
export const SHOULD_SHOW_POSITION_LINES_KEY = "Exchange-swap-should-show-position-lines";
export const REFERRAL_CODE_KEY = "palmswap-referralCode";
export const REFERRAL_TAB_KEY = "palmswap-referralTab";
export const TV_SAVE_LOAD_CHARTS_KEY = "tv-save-load-charts";
export const TV_CHART_RELOAD_TIMESTAMP_KEY = "tv-chart-reload-timestamp";
export const PRODUCTION_PREVIEW_KEY = "production-preview";
export const FAVORITES_LOCAL_STORAGE_KEY = "Favorites";
export const THEME_LOCAL_STORAGE_KEY = "dark";

export const getSubgraphUrlKey = (chainId: number, subgraph: string) => `subgraphUrl:${chainId}:${subgraph}`;
