import * as constants from "@ethersproject/constants";
import { BigNumber } from "ethers";

const { AddressZero } = constants;

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};

export function getPositionQuery(tokens, nativeTokenAddress, tokenPrices) {
  const collateralTokens: string[] = [];
  const indexTokens: string[] = [];
  const isLong: boolean[] = [];
  const offChainTokenPrices: BigNumber[] = [];

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      isLong.push(false);
      collateralTokens.push(stableToken.address);
      isLong.push(true);
      const tokenAddress = getTokenAddress(token, nativeTokenAddress);
      indexTokens.push(tokenAddress);
      indexTokens.push(tokenAddress);
      offChainTokenPrices.push(tokenPrices[tokenAddress]);
      offChainTokenPrices.push(tokenPrices[tokenAddress]);
    }
  }

  return { collateralTokens, indexTokens, isLong, offChainTokenPrices };
}
