import { ethers } from "ethers";
import sample from "lodash/sample";
import { isDevelopment } from "./env";

const { parseEther } = ethers.utils;

export const MAINNET = 56;
export const TESTNET = 97;
export const ETH_MAINNET = 1;
export const AVALANCHE = 43114;
export const AVALANCHE_FUJI = 43113;
export const ARBITRUM = 42161;
export const ARBITRUM_TESTNET = 421611;

export const DEFAULT_CHAIN_ID = isDevelopment() ? TESTNET : MAINNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;
export const SUPPORTED_CHAIN_IDS = [isDevelopment() ? TESTNET : MAINNET];

export const IS_NETWORK_DISABLED = {
  [MAINNET]: false,
  [TESTNET]: false,
};

export const CHAIN_NAMES_MAP = {
  [MAINNET]: "BSC",
  [TESTNET]: "BSC Testnet",
};

export const gasPreference = {
  standard: "3000000000",
  fast: "5000000000",
  aggressive: "7000000000",
};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [MAINNET]: gasPreference,
  [TESTNET]: gasPreference,
};

/**
 *  @description use only for test networks
 */
export const MAX_GAS_PRICE_MAP = {
  [TESTNET]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [MAINNET]: 3, // 3 USD
  [TESTNET]: 3, // 3 USD
};

const constants = {
  [MAINNET]: {
    nativeTokenSymbol: "BNB",
    wrappedTokenSymbol: "WBNB",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,
    /**
     *  @deprecated don't use because we removed swaps
     */
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [TESTNET]: {
    nativeTokenSymbol: "BNB",
    wrappedTokenSymbol: "WBNB",
    defaultCollateralSymbol: "USDT",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,
    /**
     *  @deprecated don't use because we removed swaps
     */
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
};

export const RPC_PROVIDERS = {
  [ETH_MAINNET]: ["https://rpc.ankr.com/eth"],
  [MAINNET]: ["https://dark-thrumming-meadow.bsc.quiknode.pro"],
  [TESTNET]: ["https://nd-372-144-016.p2pify.com/4c46192b4088a09d11fc2130318df020"],
};

export const RPC_WS_PROVIDERS = {
  [MAINNET]: ["wss://dark-thrumming-meadow.bsc.quiknode.pro"],
  [TESTNET]: ["wss://ws-nd-372-144-016.p2pify.com/4c46192b4088a09d11fc2130318df020"],
};

export const FALLBACK_PROVIDERS = {
  [MAINNET]: [
    "https://rpc.ankr.com/bsc",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed4.ninicoin.io",
    "https://bsc-dataseed1.bnbchain.org",
    "https://bsc-dataseed2.bnbchain.org",
    "https://bsc-dataseed3.bnbchain.org",
    "https://bsc-dataseed4.bnbchain.org",
  ],
  [TESTNET]: [
    "https://bsc-testnet.publicnode.com",
    "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
    "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
    "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
    "https://bsc-testnet.public.blastapi.io",
    "https://rpc.ankr.com/bsc_testnet_chapel",
  ],
};

type NetworkMetadata = {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrls: {
    http: string[];
    ws: string[];
  };
  blockExplorerUrls: string[];
};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {
  [MAINNET]: {
    chainId: "0x" + MAINNET.toString(16),
    chainName: "BSC",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: {
      http: [...RPC_PROVIDERS[MAINNET], ...FALLBACK_PROVIDERS[MAINNET]],
      ws: RPC_WS_PROVIDERS[MAINNET],
    },
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [TESTNET]: {
    chainId: "0x" + TESTNET.toString(16),
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: {
      http: [...RPC_PROVIDERS[TESTNET], ...FALLBACK_PROVIDERS[TESTNET]],
      ws: RPC_WS_PROVIDERS[TESTNET],
    },
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number | undefined) {
  if (!chainId || !CHAIN_NAMES_MAP[chainId]) {
    return undefined;
  }
  return CHAIN_NAMES_MAP[chainId];
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getExplorerUrl(chainId) {
  if (chainId === MAINNET) {
    return "https://bscscan.com/";
  } else if (chainId === TESTNET) {
    return "https://testnet.bscscan.com/";
  }
  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
