export enum ErrorCode {
  PoolExceeded = "POOL_EXCEEDED",
  Buffer = "BUFFER",
  MaxUSDP = "MAX_USDP",
  InvalidLiqPrice = "INVALID_LIQ_PRICE",
}

export enum ErrorDisplayType {
  Modal = "MODAL",
  Tooltip = "TOOLTIP",
}

export enum PositionModalType {
  ADD = "add",
  CLOSE = "close",
}

export const DARK_THEME = "DARK";
export const LIGHT_THEME = "LIGHT";
