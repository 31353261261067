import cx from "classnames";
import React from "react";

type Props = React.ComponentProps<'button'> & {
  iconSrc: string,
  alt: string,
  active?: boolean
}

export const HeaderIconButton = React.forwardRef<HTMLButtonElement, Props>(({ iconSrc, alt, className, active, ...restProps }, ref) => {
  return (
    <button
      ref={ref}
      className={cx(
        "h-[3.8rem] aspect-square transition-colors rounded-sm flex",
        {
          'hover:bg-background-2': !active,
          'bg-background-2': active
        },
        className
      )}
      {...restProps}>
      <img src={iconSrc} alt={alt} className="w-[1.8rem] m-auto"/>
    </button>
  )
})
