import React from "react";
import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import externalLink from "img/ic_new_link_16.svg";
import copy from "img/ic_copy_16.svg";
import Button from "components/Button/Button";
import { helperToast } from "lib/helperToast";
import { useWeb3React } from "@web3-react/core";
import { getAccountUrl, useENS } from "lib/legacy";
import { useChainId } from "lib/chains";
import { useCopyToClipboard } from "react-use";
import { getConnectionLabel } from "lib/wallets/connections";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { Avatar } from "components/Avatar";
import tradingPointsIcon from "./icons/tradingPoints.svg";
import { useEventUserInfoQuery } from "pages/Event/events/TradeToEarnEvent/hooks/useEventUserInfoQuery"
import { formatBigAmount } from "lib/formatAmount";
import { TP_DECIMALS } from "lib/constants"
import { expandDecimals } from "lib/numbers";
import { NavLink } from "react-router-dom";
import { useAtom } from "jotai";
import { isBridgeModalVisibleAtom } from "components/Bridge/state";
import bridgeIcon from "img/bridge.svg";



type AccountDrawerSectionProps = {
  disconnectAccountAndCloseSettings?: () => void;
};

export const AccountDrawerSection = (props: AccountDrawerSectionProps) => {
  const { isActive, account, connector } = useWeb3React();
  const { ensName } = useENS(account);
  const { rawChainId } = useChainId();
  const connectWalletUi = useConnectWalletUi()

  const accountUrl = getAccountUrl(rawChainId, account);
  const userInfoQuery = useEventUserInfoQuery()

  const [, copyToClipboard] = useCopyToClipboard();
  const [, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);


  return (
    <>
      {isActive ? (
        <div className="flex flex-col items-stretch gap-[1.5rem]">
          <div className="flex space-between">
            <p className="text-[1.4rem]">
              <Trans>Account </Trans>
              <span className="wallet-provider">
                <Trans>({ getConnectionLabel(connector) })</Trans>
              </span>
            </p>
          </div>

          <NavLink
            to={"/event"}
            className="rounded-sm px-4 h-[3.6rem] border inline-flex items-center justify-between gap-4 transition hover:bg-background-3"
          >
            <p className="text-inactive text-[1.4rem] leading-[2.4rem]">
              <Trans>Trading Points</Trans>
            </p>

            {
              userInfoQuery.data
                ? (
                  <div className="flex items-center gap-[.5rem]">
                    <span className="text-[1.4rem] leading-[2.4rem] text-textColor">
                      {formatBigAmount(
                        userInfoQuery.data.dailyUserTp,
                        TP_DECIMALS,
                        undefined,
                        {
                          mantissa: 2,
                          average: userInfoQuery.data.dailyUserTp.gt(expandDecimals(100000, TP_DECIMALS))
                        }
                      )}
                    </span>

                    <div className="bg-main w-[1.8rem] aspect-square rounded-full flex items-center justify-center pointer-events-none select-none">
                      <img src={tradingPointsIcon} alt="Trading Points" className="w-[1.2rem]"></img>
                    </div>
                  </div>
                )
                : <div className="skeleton"></div>
            }
          </NavLink>

          <div className="flex space-between items-center gap-[1rem]">
            {(isActive && account) && (
              <Avatar
                username={account}
                className="h-[4rem] aspect-square"
              />
            )}

            <div className="break-all">
              <span className="text-textColor strong text-[1.4rem] leading-[2.4rem]">{ensName || account}</span>
            </div>
          </div>

          <div className="flex items-stretch gap-5 h-[2.4rem]">
            <div className="flex gap-[.3rem] items-center text-[1.4rem] text-inactive">
              <ExternalLink href={accountUrl} className="no-underline">
                <p className="address-link transition">View in Explorer</p>
              </ExternalLink>

              <div className="h-[2rem] aspect-square flex items-center justify-center">
                <img src={externalLink} alt="Open address in explorer" />
              </div>
            </div>

            <button
              className="flex gap-[.3rem] items-center text-[1.4rem] text-inactive"
              onClick={() => {
                copyToClipboard(account as any);
                helperToast.success(t`Address copied to your clipboard`);
              }}
            >
              <p>
                <Trans>Copy Address</Trans>
              </p>

              <div className="h-[2rem] aspect-square flex items-center justify-center">
                <img src={copy} alt="Copy user address" />
              </div>
            </button>
          </div>

          <button
             className="w-full h-[4.5rem] bg-main rounded-[1.5rem] flex flex-row justify-between items-center px-[1rem]"
             onClick={() => setIsBridgeModalVisible(true) }>
              <div className="flex flex-row text-white text-[1.3rem] ">
                <img src={bridgeIcon} alt="bridgeIcon" />
                <p>
                  <Trans>Bridging (Cross-Chain Deposit)</Trans>
                </p>
              </div>
              <div className="p-[0.5rem] bg-gradient-to-r from-[#D2CEFE] to-[#ECEAF7] text-main rounded-[4px] text-[1.2rem] ">
                <p>New</p>
              </div>
          </button>

          <Button
            variant="secondary"
            size="lg"
            className="w-full"
            disabled={false}
            type="submit"
            onClick={props.disconnectAccountAndCloseSettings}
          >
            <Trans>
              Disconnect
            </Trans>
          </Button>
        </div>
      ) : (
        <Button
          variant="primary-action"
          size="lg"
          className="w-full"
          disabled={false}
          type="submit"
          onClick={connectWalletUi.connectWallet}
        >
          <Trans>
            Connect Wallet
          </Trans>
        </Button>
      )}
    </>
  )
}
