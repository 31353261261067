import { BigNumber } from "ethers";
import { formatUnits } from "@ethersproject/units";
import { PLP_DECIMALS } from "lib/constants";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import useSWR from "swr";
import { useQuickContracts } from "lib/contracts";

export const usePlpBalance = () => {
  const { isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();

  const usedAddress = account || PLACEHOLDER_ACCOUNT;

  const { data: plpBalance = BigNumber.from(0) } = useSWR<BigNumber>(
    [`PlpSwap:plpBalance:${isActive}`, chainId, usedAddress],
    () => {
      return quickContracts.fetch("FeePlpTracker", "stakedAmounts", [usedAddress]);
    }
  );

  return {
    amount: plpBalance,
    formatted: formatUnits(plpBalance, PLP_DECIMALS),
    decimals: PLP_DECIMALS,
  };
};
