import { useCallback } from "react";
import useSWR from "swr";
import { getGatewayApiUrl } from "./getGatewayApiUrl";

type HistoricalData = {
  quotes: {
    timestamp: string;
    quote: {
      USD: {
        price: number;
        volume_24h: number;
        market_cap: number;
        total_supply: number;
        circulating_supply: number;
        timestamp: string;
      };
    };
  }[];
  id: number;
  name: string;
  symbol: string;
  is_active: number;
  is_fiat: number;
};

export const useHistoricalData = (token: string, start: string, end: string, interval: string) => {
  const fetchHistoricalData = useCallback(
    () =>
      fetch(`${getGatewayApiUrl()}/prices/${token}/historical?start=${start}&end=${end}&interval=${interval}`)
        .then((res) => res.json())
        .then((data) => data as HistoricalData),
    [token, start, end, interval]
  );

  const { data: historicalData, error: historicalDataError } = useSWR<HistoricalData>(
    [`FetchHistoricalData:${token}:${start}:${end}:${interval}`],
    fetchHistoricalData
  );

  return {
    historicalData,
    isLoading: !historicalData && !historicalDataError,
    isError: !!historicalDataError,
  };
};
