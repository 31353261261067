import { getReferralCodeTakenStatus } from "pages/Referrals/referralsHelper";
import React from "react";

const REFERRAL_CODE_TAKEN_STATUS = {
  OK: "ok",
  TAKEN: "taken",
  CHECKING: "checking",
} as const;

type ReferralCodeTakenStatus = (typeof REFERRAL_CODE_TAKEN_STATUS)[keyof typeof REFERRAL_CODE_TAKEN_STATUS];

export const useReferralCodeTakenStatus = (
  account: string | null | undefined,
  referralCode: string,
  error: string,
  chainId: number | undefined
): ReferralCodeTakenStatus => {
  const [status, setStatus] = React.useState<ReferralCodeTakenStatus>(REFERRAL_CODE_TAKEN_STATUS.OK);

  React.useEffect(() => {
    let cancelled = false;
    const checkCodeTakenStatus = async () => {
      if (error || !referralCode) {
        setStatus(REFERRAL_CODE_TAKEN_STATUS.OK);
        return;
      }
      const { status: takenStatus } = await getReferralCodeTakenStatus(account, referralCode, chainId);
      // ignore the result if the referral code to check has changed
      if (cancelled) {
        return;
      }
      if (takenStatus === "none") {
        setStatus(REFERRAL_CODE_TAKEN_STATUS.OK);
      } else {
        setStatus(REFERRAL_CODE_TAKEN_STATUS.TAKEN);
      }
    };
    setStatus(REFERRAL_CODE_TAKEN_STATUS.CHECKING);
    checkCodeTakenStatus();
    return () => {
      cancelled = true;
    };
  }, [account, referralCode, error, chainId]);

  return status;
};
