import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import parse from "parse-duration";
import { AddressZero } from "@ethersproject/constants";
import useSWR from "swr";
import isEqual from "lodash/isEqual";
import { useQuickContracts } from "lib/contracts/useQuickContracts";

export const useEventUserInfoQuery = () => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const usedAccount = account ?? AddressZero;

  const quickContracts = useQuickContracts();

  const fetcher = async () => {
    const [claimInfo] = await Promise.all([quickContracts.fetch("Trade2Earn", "claimInfo", [usedAccount])]);

    const currentDate = new Date();
    const currentDay = Math.floor(parse(`${currentDate.valueOf()} ms`, "day") ?? 0);
    const currentEpocheIndex = currentDay;

    const [dailyUserTp] = await Promise.all([
      quickContracts.fetch("Trade2Earn", "dailyUserTp", [usedAccount, currentEpocheIndex]),
    ]);

    return {
      dailyUserTp,
      claimInfo,
    };
  };

  const query = useSWR([`TradeToEarnEvent:user-info:${isActive}`, chainId, usedAccount], fetcher, {
    compare: isEqual,
    refreshInterval: parse("1 min"),
  });

  return query;
};
