import { useEffect } from "react";

export const useBodyScrollHidden = (isHidden: Boolean) => {
  useEffect(() => {
    if (isHidden) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isHidden]);
}
