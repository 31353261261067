import numbro from "numbro";
import { BigNumberish, utils } from "ethers";

type FormattingOptions = {
  thousandSeparated?: boolean,
  mantissa?: number,
  trimMantissa?: boolean,
  average?: boolean,
  totalLength?: number,
}

export const formatAmount = (
  amount: number | string,
  { thousandSeparated = true, mantissa = 2, trimMantissa = true, average = false, totalLength = undefined }: FormattingOptions = {}
) => {
  return numbro(amount).format({
    thousandSeparated,
    mantissa,
    trimMantissa,
    average,
    ...totalLength && {
      totalLength
    },
  });
};

export const formatBigAmount = (amount: BigNumberish | undefined, decimals, defaultValue?: string, options: FormattingOptions = {}) => {
  if (!defaultValue) {
    defaultValue = "-";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  return formatAmount(utils.formatUnits(amount, decimals), options);
};
