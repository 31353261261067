import cx from "classnames";
import { ReactNode } from "react";

type Props = {
  label: string;
  children?: ReactNode;
  value?: string;
  isTop?: boolean;
  isWarning?: boolean;
  className?: string
};

export default function ExchangeInfoRow(props: Props) {
  const { label, children, value, isTop, isWarning, className } = props;

  return (
    <div className={cx("Exchange-info-row", className,  { "top-line": isTop })}>
      <div className="Exchange-info-label">{label}</div>
      <div className={`align-right gap-2 ${isWarning ? "Exchange-info-value-warning" : ""}`}>{children || value}</div>
    </div>
  );
}
