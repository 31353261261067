import { getConstant } from "config/chains";
import { getContractAddress } from "config/contracts";
import { useMinExecutionFee } from "domain/legacy";
import { getUsd, useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";

export const useExecutionFee = ({ isMarketOrder }: { isMarketOrder: boolean }) => {
  const { isActive, provider } = useWeb3React();
  const { chainId } = useChainId();
  const { infoTokens } = useInfoTokens();
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    provider,
    isActive,
    chainId,
    infoTokens
  );

  const INCREASE_ORDER_EXECUTION_GAS_FEE = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const executionFee = INCREASE_ORDER_EXECUTION_GAS_FEE;
  const executionFeeUsd = getUsd(executionFee, nativeTokenAddress, false, infoTokens);

  const currentExecutionFee = isMarketOrder ? minExecutionFee : executionFee;
  const currentExecutionFeeUsd = isMarketOrder ? minExecutionFeeUSD : executionFeeUsd;

  return {
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    executionFee,
    executionFeeUsd,
    currentExecutionFee,
    currentExecutionFeeUsd,
  };
};
