import { Trans, t } from "@lingui/macro";
import React from "react";
import cx from "classnames";
import { TradeToEarnEvent } from "pages/Event/events/TradeToEarnEvent/TradeToEarnEvent";

const EVENT_IDS = {
  TRADE_TO_EARN: "TRADE_TO_EARN",
} as const;

type EventId = keyof typeof EVENT_IDS;

type EventsData = {
  [key in EventId]: {
    label: string;
    description?: string;
  };
};

const Event = () => {
  const displayedEventIds = ["TRADE_TO_EARN"] satisfies EventId[];

  const [selectedEventId, setSelectedEventId] = React.useState<EventId>(displayedEventIds[0]);

  const eventsData = React.useMemo(() => {
    return {
      TRADE_TO_EARN: {
        label: t`Event Overview`,
        description: t`Thank you for participating. You can review the event overview below.`,
      },
    } satisfies EventsData;
  }, []);

  return (
    <div className="tailwind flex items-stretch h-full isolate">
      {displayedEventIds.length >= 2 && (
        <aside className="w-[26rem] absolute border-r h-full p-[2rem]">
          <nav>
            <div className="text-[1.4rem] leading-[2.4rem] text-inactive mb-4">
              <Trans>Active Events</Trans>
            </div>

            <ul className="flex flex-col items-stretch gap-2">
              {displayedEventIds.map((id) => (
                <li className="relative" key={id}>
                  <button
                    className={cx([
                      "h-[4.2rem] rounded-sm hover:bg-white hover:bg-opacity-[7%] flex items-center px-4 w-full",
                      {
                        "bg-white bg-opacity-[7%]": id === selectedEventId,
                      },
                    ])}
                    onClick={() => setSelectedEventId(id)}
                  >
                    {eventsData[id].label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      )}

      <div
        className={cx([
          "flex-1 px-8 py-[4rem] flex @container/page-content",
          {
            "ml-[26rem]": displayedEventIds.length >= 2,
          },
        ])}
      >
        <div className="justify-center flex-1 mx-auto max-w-[114rem] flex flex-col items-stretch min-w-0">
          {selectedEventId === "TRADE_TO_EARN" && (
            <>
              <TradeToEarnEvent
                label={eventsData[selectedEventId].label}
                description={eventsData[selectedEventId].description}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Event;
