import "./Checkbox.css";
import checkedIcon from "img/checked.png";

export default function Checkbox() {
  return (
    <div className="Checkbox-checked">
      <img src={checkedIcon} alt="checked" className="checked-icon" />
    </div>
  );
}
