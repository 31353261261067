import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import parse from "parse-duration";
import useSWR from "swr";
import isEqual from "lodash/isEqual";
import { useQuickContracts } from "lib/contracts/useQuickContracts";

export const useEventGeneralInfoQuery = () => {
  const { chainId } = useChainId();
  const { isActive } = useWeb3React();

  const quickContracts = useQuickContracts();

  const fetcher = async () => {
    const [tier1StartIndex, tier2StartIndex, endIndex, tier1DailyReward, tier2DailyReward] = await Promise.all([
      quickContracts.fetch("Trade2Earn", "tier1StartIndex", []),
      quickContracts.fetch("Trade2Earn", "tier2StartIndex", []),
      quickContracts.fetch("Trade2Earn", "endIndex", []),
      quickContracts.fetch("Trade2Earn", "tier1DailyReward", []),
      quickContracts.fetch("Trade2Earn", "tier2DailyReward", []),
    ]);

    const currentDate = new Date();
    const currentDay = Math.floor(parse(`${currentDate.valueOf()} ms`, "day") ?? 0);
    const currentEpocheIndex = currentDay;

    const [dailyTotalTp] = await Promise.all([
      quickContracts.fetch("Trade2Earn", "dailyTotalTp", [currentEpocheIndex]),
    ]);

    return {
      tier1StartIndex,
      tier2StartIndex,
      endIndex,
      tier1DailyReward,
      tier2DailyReward,
      dailyTotalTp,
    };
  };

  const query = useSWR([`TradeToEarnEvent:general-info:${isActive}`, chainId], fetcher, {
    compare: isEqual,
    refreshInterval: parse("1 min"),
  });

  return query;
};
