export enum CHART_PERIODS {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export const CHART_PERIODS_PLP = [
  {
    value: CHART_PERIODS.DAY,
    label: "1D",
  },
  {
    value: CHART_PERIODS.WEEK,
    label: "1W",
  },
  {
    value: CHART_PERIODS.MONTH,
    label: "1M",
  },
  {
    value: CHART_PERIODS.YEAR,
    label: "1Y",
  },
];

export const CHART_PERIODS_PALM = [
  {
    value: CHART_PERIODS.DAY,
    label: "1D",
  },
  {
    value: CHART_PERIODS.WEEK,
    label: "1W",
  },
  {
    value: CHART_PERIODS.MONTH,
    label: "1M",
  },
];
