import { useMemo } from "react";
import { identicon } from "minidenticons";
import cx from "classnames"

const IdenticonImg = ({ username, saturation, lightness, ...props }) => {
    const svgURI = useMemo(
      () => "data:image/svg+xml;utf8," + encodeURIComponent(identicon(username, saturation, lightness)),
      [username, saturation, lightness]
    );
    return <img src={svgURI} alt={username} {...props} />;
  };
  
  export const Avatar = ({ username, className }: { username: string, className?: string }) => {
    return (
      <div className={cx(className, 'relative')}>
        <IdenticonImg
          username={username}
          saturation="80"
          lightness="50"
          width="40"
          height="40"
          className="rounded-full bg-[#00338C] block absolute w-full h-full"
        />
      </div>
    )
  }
