import React, { useEffect } from "react";
import Draggable from "react-draggable";
import cx from "classnames";
import { useAtom } from "jotai";
import {
  useLeverageOption,
  useLeverageOptionInput,
  fromValueAtom,
  toValueAtom,
  useOnFromValueChange,
  isChartTradingEnabledAtom,
  isAdvancedChartEnabledAtom,
  canOpenConfirmingModalAtom,
  isConfirmingAtom,
  useToTokenAddress,
  useSwapOption,
} from "pages/Exchange/state";
import { getTokenInfo, useInfoTokens } from "domain/tokens";
import { LONG } from "lib/legacy";
import { Trans } from "@lingui/macro";
import useThemeSwitcher from "lib/useThemeSwitcher";
import { TokenUtils } from "components/TokenUtils";

const ChartTradingPanel = () => {
  const [, setIsChartTradingEnabled] = useAtom(isChartTradingEnabledAtom);
  const [isAdvancedChartEnabled] = useAtom(isAdvancedChartEnabledAtom);
  const [, setIsConfirming] = useAtom(isConfirmingAtom);
  const [swapOption] = useSwapOption();

  const [toTokenAddress] = useToTokenAddress();
  const { infoTokens } = useInfoTokens();
  const toToken = getTokenInfo(infoTokens, toTokenAddress);

  const [leverageOption] = useLeverageOption();
  const { handleLeverageBlur, handleLeverageInput, minLeverage, maxLeverage } = useLeverageOptionInput(toToken);
  const [fromValue] = useAtom(fromValueAtom);
  const [toValue] = useAtom(toValueAtom);
  const onFromValueChange = useOnFromValueChange();
  const [canOpenConfirmingModal] = useAtom(canOpenConfirmingModalAtom);

  const [isDragging, setIsDragging] = React.useState(false);

  useEffect(() => {
    if (isDragging) {
      document.querySelector("#root")?.classList.add("pointer-events-none");
      document.body.classList.add("!cursor-grabbing");
    } else {
      document.querySelector("#root")?.classList.remove("pointer-events-none");
      document.body.classList.remove("!cursor-grabbing");
    }

    return () => {
      document.querySelector("#root")?.classList.remove("pointer-events-none");
      document.body.classList.remove("!cursor-grabbing");
    };
  }, [isDragging]);

  const fitPosition = ({ x, y }: { x: number; y: number }) => {
    x = Math.max(x, isAdvancedChartEnabled ? 60 : 5);
    y = Math.max(x, 70);
    return { x, y };
  };

  const initialPosition = fitPosition({ x: 0, y: 0 });

  const { isWhiteTheme } = useThemeSwitcher();

  return (
    <div className="tailwind">
      <Draggable
        handle=".draggableIcon"
        bounds=".tv iframe"
        defaultPosition={initialPosition}
        onStart={() => setIsDragging(true)}
        onStop={() => setIsDragging(false)}
        grid={[1, 1]}
      >
        <div
          className={cx(
            "rounded-[1.5rem] py-4 px-10 border border-solid border-border absolute top-0 left-0 flex gap-2 items-stretch h-[5rem] box-content z-[1]",
            {
              "bg-background-5-v2-only": isWhiteTheme,
              "bg-background-1": !isWhiteTheme,
            }
          )}
        >
          <div
            className={cx(
              "draggableIcon absolute top-1/2 -translate-y-1/2 left-5 -translate-x-1/2 text-inactive hover:text-white/90 cursor-grab active:cursor-grabbing",
              {
                "text-white/90 pointer-events-auto": isDragging,
              }
            )}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
              <path
                d="M 1.4 3.325 C 1.4 3.035 1.635 2.8 1.925 2.8 L 12.075 2.8 C 12.365 2.8 12.6 3.035 12.6 3.325 C 12.6 3.615 12.365 3.85 12.075 3.85 L 1.925 3.85 C 1.635 3.85 1.4 3.615 1.4 3.325 Z M 1.4 7 C 1.4 6.71 1.635 6.475 1.925 6.475 L 12.075 6.475 C 12.365 6.475 12.6 6.71 12.6 7 C 12.6 7.29 12.365 7.525 12.075 7.525 L 1.925 7.525 C 1.635 7.525 1.4 7.29 1.4 7 Z M 1.4 10.675 C 1.4 10.385 1.635 10.15 1.925 10.15 L 12.075 10.15 C 12.365 10.15 12.6 10.385 12.6 10.675 C 12.6 10.965 12.365 11.2 12.075 11.2 L 1.925 11.2 C 1.635 11.2 1.4 10.965 1.4 10.675 Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>

          <button
            className={cx(
              "disabled:bg-opacity-25 rounded-[1rem] w-[8rem] transition-effect flex flex-col justify-center items-center text-[1.2rem] leading-[1.5] text-center",
              {
                "bg-green": swapOption === LONG,
                "bg-red": swapOption !== LONG,
              }
            )}
            onClick={() => setIsConfirming(true)}
            disabled={!toValue || !canOpenConfirmingModal}
          >
            {swapOption === LONG ? <Trans>Long</Trans> : <Trans>Short</Trans>}

            <span className="font-semibold">
              {/* TODO: improve displaying of small and large numbers */}
              <span>{Number(toValue || 0).toFixed(3)}</span>
              <span> {TokenUtils.getSymbol(toToken)}</span>
            </span>
          </button>

          <label className="flex flex-col gap-2 text-[1.2rem] rounded-[1rem] leading-[1.5] bg-background-3 border border-solid border-border px-6 py-2 w-[9rem]">
            <span className="text-inactive">Collateral</span>
            <input
              type="number"
              min="0"
              placeholder="0.0"
              className=""
              value={fromValue}
              onChange={onFromValueChange}
            />
          </label>

          <label className="flex flex-col gap-2 text-[1.2rem] rounded-[1rem] leading-[1.5] bg-background-3 border border-solid border-border px-6 py-2 w-[9rem]">
            <span className="text-inactive">Leverage</span>

            <input
              min={minLeverage}
              max={maxLeverage}
              value={leverageOption}
              onChange={handleLeverageInput}
              onBlur={handleLeverageBlur}
            />
          </label>

          {/* TODO: open short modal */}
          {/* <button
            className={cx("bg-red disabled:bg-opacity-25 rounded-[1rem] w-[8rem] transition-effect flex flex-col justify-center items-center text-[1.2rem] leading-[1.5] text-center")}
            onClick={() => setIsConfirming(true)}
            disabled={!toValue || !canOpenConfirmingModal}
          >
            <span>Short</span>
            <span className="font-semibold">
              <span>{Number(toValue || 0).toFixed(3)}</span>
              <span> {TokenUtils.getSymbol(toToken)}</span>
            </span>
          </button> */}

          <button
            className="absolute top-1/2 -translate-y-1/2 right-5 translate-x-1/2 text-inactive hover:text-white/90"
            onClick={() => setIsChartTradingEnabled(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
              <path
                d="M 3.005 3.005 C 3.278 2.732 3.722 2.732 3.995 3.005 L 7 6.01 L 10.005 3.005 C 10.181 2.823 10.441 2.75 10.686 2.814 C 10.931 2.878 11.122 3.069 11.186 3.314 C 11.25 3.559 11.177 3.819 10.995 3.995 L 7.99 7 L 10.995 10.005 C 11.26 10.28 11.256 10.716 10.986 10.986 C 10.716 11.256 10.28 11.26 10.005 10.995 L 7 7.99 L 3.995 10.995 C 3.72 11.26 3.284 11.256 3.014 10.986 C 2.744 10.716 2.74 10.28 3.005 10.005 L 6.01 7 L 3.005 3.995 C 2.732 3.722 2.732 3.278 3.005 3.005 Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </Draggable>
    </div>
  );
};

export default ChartTradingPanel;
