import { JsonRpcSigner, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Web3Provider } from "@ethersproject/providers/lib/web3-provider";
import { Contract } from "ethers";
import { getProvider } from "lib/rpc";

export type ContractFactory<T> = {
  connect: (address: string, provider: JsonRpcSigner | StaticJsonRpcProvider) => T;
};

/**
 *  @description get a typesafe version of a contract
 *  @deprecated use `getEthersContractByProvider` instead
 */
export const getEthersContract = <T extends Contract>(
  factory: ContractFactory<T>,
  chainId: number,
  address: string,
  library: Web3Provider | undefined
): T => {
  const provider = getProvider(library, chainId);
  const contract = factory.connect(address, provider);

  return contract;
};

type Provider = JsonRpcSigner | StaticJsonRpcProvider;

/**
 *  @description similar to `getEthersContract` but with a `provider` parameter to support fallback providers
 */
export const getEthersContractByProvider = <TContract extends Contract>(
  factory: ContractFactory<TContract>,
  provider: Provider,
  address: string
): TContract => {
  const contract = factory.connect(address, provider);

  return contract;
};
