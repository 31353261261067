import { Tooltip } from "components/TooltipV2/Tooltip";
import { TipIcon } from "components/TipIcon";
import React, { ReactNode } from "react";
import cx from "classnames";

type InfoCardListProps = React.ComponentProps<"div">;

export const InfoCardList = (props: InfoCardListProps) => {
  return (
    <div {...props} className={cx([props.className, "@container/info-card-list"])}>
      <ul className="grid gap-6 grid-cols-1 @[66rem]/info-card-list:gap-12 @[66rem]/info-card-list:grid-cols-2 @[102rem]/info-card-list:grid-cols-3">
        {React.Children.map(props.children ?? [], (child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    </div>
  );
};

type InfoCardIconType = "add-user" | "exchange" | "gift" | "discount" | "bank";

type InfoCardIconProps = {
  type: InfoCardIconType;
};

const InfoCardIcon = React.memo((props: InfoCardIconProps) => {
  switch (props.type) {
    case "add-user": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
          <path
            d="M 14.3 6.5 C 14.3 8.654 12.554 10.4 10.4 10.4 C 8.246 10.4 6.5 8.654 6.5 6.5 C 6.5 4.346 8.246 2.6 10.4 2.6 C 12.554 2.6 14.3 4.346 14.3 6.5 Z M 3.4 21.356 C 2.879 21.032 2.592 20.438 2.66 19.829 C 3.156 15.932 6.472 13.011 10.4 13.011 C 14.328 13.011 17.644 15.932 18.14 19.829 C 18.216 20.436 17.917 21.025 17.399 21.355 C 15.31 22.694 12.881 23.403 10.4 23.4 C 7.919 23.404 5.489 22.695 3.4 21.356 Z M 21.125 7.475 C 21.125 6.937 20.688 6.5 20.15 6.5 C 19.612 6.5 19.175 6.937 19.175 7.475 L 19.175 10.075 L 16.575 10.075 C 16.037 10.075 15.6 10.512 15.6 11.05 C 15.6 11.588 16.037 12.025 16.575 12.025 L 19.175 12.025 L 19.175 14.625 C 19.175 15.163 19.612 15.6 20.15 15.6 C 20.688 15.6 21.125 15.163 21.125 14.625 L 21.125 12.025 L 23.725 12.025 C 24.263 12.025 24.7 11.588 24.7 11.05 C 24.7 10.512 24.263 10.075 23.725 10.075 L 21.125 10.075 Z"
            fill="rgb(106, 63, 233)"
          ></path>
        </svg>
      );
    }
    case "exchange": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
          <path
            d="M 17.16 2.912 C 16.984 3.102 16.891 3.353 16.901 3.612 C 16.91 3.87 17.022 4.114 17.212 4.29 L 19.942 6.825 L 8.775 6.825 C 8.237 6.825 7.8 7.262 7.8 7.8 C 7.8 8.338 8.237 8.775 8.775 8.775 L 19.942 8.775 L 17.212 11.31 C 16.817 11.676 16.794 12.293 17.16 12.688 C 17.526 13.083 18.143 13.106 18.538 12.74 L 23.088 8.515 C 23.287 8.33 23.4 8.071 23.4 7.8 C 23.4 7.529 23.287 7.27 23.088 7.085 L 18.538 2.86 C 18.348 2.684 18.097 2.591 17.838 2.601 C 17.58 2.61 17.336 2.722 17.16 2.912 Z M 8.84 13.312 C 8.664 13.122 8.42 13.01 8.162 13.001 C 7.903 12.991 7.652 13.084 7.462 13.26 L 2.912 17.485 C 2.713 17.67 2.6 17.929 2.6 18.2 C 2.6 18.471 2.713 18.73 2.912 18.915 L 7.462 23.14 C 7.857 23.506 8.474 23.483 8.84 23.088 C 9.206 22.693 9.183 22.076 8.788 21.71 L 6.058 19.175 L 17.225 19.175 C 17.763 19.175 18.2 18.738 18.2 18.2 C 18.2 17.662 17.763 17.225 17.225 17.225 L 6.058 17.225 L 8.788 14.69 C 8.978 14.514 9.09 14.27 9.099 14.012 C 9.109 13.753 9.016 13.502 8.84 13.312 Z"
            fill="rgb(106, 63, 233)"
          ></path>
        </svg>
      );
    }
    case "gift": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26">
          <path
            d="M 12.025 3.9 L 4.55 3.9 C 3.473 3.9 2.6 4.773 2.6 5.85 L 2.6 12.025 L 6.975 12.025 C 5.664 10.617 5.703 8.424 7.063 7.063 C 8.423 5.702 10.616 5.661 12.025 6.971 Z M 2.6 13.975 L 2.6 20.15 C 2.6 21.227 3.473 22.1 4.55 22.1 L 12.025 22.1 L 12.025 15.761 C 10.979 17.854 8.84 19.176 6.5 19.175 C 5.962 19.175 5.525 18.738 5.525 18.2 C 5.525 17.662 5.962 17.225 6.5 17.225 C 8.458 17.225 10.16 15.88 10.612 13.975 Z M 13.975 22.1 L 21.45 22.1 C 22.527 22.1 23.4 21.227 23.4 20.15 L 23.4 13.975 L 15.388 13.975 C 15.84 15.88 17.542 17.225 19.5 17.225 C 20.038 17.225 20.475 17.662 20.475 18.2 C 20.475 18.738 20.038 19.175 19.5 19.175 C 17.16 19.176 15.021 17.854 13.975 15.761 Z M 23.4 12.025 L 23.4 5.85 C 23.4 4.773 22.527 3.9 21.45 3.9 L 13.975 3.9 L 13.975 6.971 C 15.384 5.661 17.577 5.702 18.937 7.063 C 20.297 8.424 20.336 10.617 19.026 12.025 Z M 17.555 8.444 C 17.25 8.139 16.837 7.967 16.406 7.967 C 15.975 7.967 15.562 8.139 15.257 8.444 C 14.789 8.91 14.573 9.742 14.53 10.65 C 14.517 10.946 14.522 11.226 14.535 11.463 C 14.773 11.476 15.053 11.482 15.349 11.467 C 16.257 11.424 17.089 11.207 17.555 10.741 C 18.189 10.106 18.189 9.078 17.555 8.444 Z M 10.743 8.443 C 10.106 7.827 9.092 7.836 8.465 8.463 C 7.838 9.089 7.829 10.103 8.445 10.741 C 8.913 11.209 9.745 11.424 10.651 11.469 C 10.947 11.482 11.227 11.476 11.465 11.463 C 11.478 11.225 11.484 10.946 11.47 10.65 C 11.427 9.742 11.21 8.91 10.742 8.443 Z"
            fill="rgb(106, 63, 233)"
          ></path>
        </svg>
      );
    }
    case "discount": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path
            d="M 5.916 1.572 C 9.957 1.073 14.043 1.073 18.084 1.572 C 19.414 1.743 20.407 2.879 20.4 4.22 L 20.4 21.9 C 20.4 22.209 20.241 22.497 19.979 22.662 C 19.717 22.827 19.389 22.845 19.11 22.711 L 15.75 21.098 L 12.39 22.711 C 12.144 22.83 11.856 22.83 11.61 22.711 L 8.25 21.098 L 4.89 22.711 C 4.611 22.845 4.283 22.827 4.021 22.662 C 3.759 22.497 3.6 22.209 3.6 21.9 L 3.6 4.22 C 3.6 2.897 4.567 1.739 5.916 1.572 Z M 16.536 8.736 C 16.866 8.381 16.857 7.829 16.514 7.486 C 16.171 7.143 15.619 7.134 15.264 7.464 L 7.464 15.264 C 7.223 15.488 7.124 15.826 7.206 16.145 C 7.287 16.464 7.536 16.713 7.855 16.794 C 8.174 16.876 8.512 16.777 8.736 16.536 Z M 14.4 15.6 C 15.063 15.6 15.6 15.063 15.6 14.4 C 15.6 13.737 15.063 13.2 14.4 13.2 C 13.737 13.2 13.2 13.737 13.2 14.4 C 13.2 15.063 13.737 15.6 14.4 15.6 Z"
            fill="rgb(106, 63, 233)"
          ></path>
          <path
            d="M 10.8 9.6 C 10.8 10.263 10.263 10.8 9.6 10.8 C 8.937 10.8 8.4 10.263 8.4 9.6 C 8.4 8.937 8.937 8.4 9.6 8.4 C 10.263 8.4 10.8 8.937 10.8 9.6 Z"
            fill="#1f1b32"
          ></path>
        </svg>
      );
    }
    case "bank": {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path
            d="M 11.609 2.49 C 11.856 2.371 12.144 2.371 12.391 2.49 L 21.091 6.69 C 21.518 6.899 21.709 7.405 21.526 7.844 C 21.343 8.284 20.85 8.504 20.4 8.348 L 20.4 19.8 L 20.7 19.8 C 21.197 19.8 21.6 20.203 21.6 20.7 C 21.6 21.197 21.197 21.6 20.7 21.6 L 3.3 21.6 C 2.803 21.6 2.4 21.197 2.4 20.7 C 2.4 20.203 2.803 19.8 3.3 19.8 L 3.6 19.8 L 3.6 8.348 C 3.15 8.504 2.657 8.284 2.474 7.844 C 2.291 7.405 2.482 6.899 2.909 6.69 Z M 9 11.7 C 9 11.203 8.597 10.8 8.1 10.8 C 7.603 10.8 7.2 11.203 7.2 11.7 L 7.2 18.3 C 7.2 18.797 7.603 19.2 8.1 19.2 C 8.597 19.2 9 18.797 9 18.3 Z M 12.9 11.7 C 12.9 11.203 12.497 10.8 12 10.8 C 11.503 10.8 11.1 11.203 11.1 11.7 L 11.1 18.3 C 11.1 18.797 11.503 19.2 12 19.2 C 12.497 19.2 12.9 18.797 12.9 18.3 Z M 16.8 11.7 C 16.8 11.203 16.397 10.8 15.9 10.8 C 15.403 10.8 15 11.203 15 11.7 L 15 18.3 C 15 18.797 15.403 19.2 15.9 19.2 C 16.397 19.2 16.8 18.797 16.8 18.3 Z"
            fill="rgb(106, 63, 233)"
          ></path>
          <path
            d="M 13.2 7.2 C 13.2 7.863 12.663 8.4 12 8.4 C 11.337 8.4 10.8 7.863 10.8 7.2 C 10.8 6.537 11.337 6 12 6 C 12.663 6 13.2 6.537 13.2 7.2 Z"
            fill="#231f3a"
          ></path>
        </svg>
      );
    }
    default: {
      throw new Error("Unsupported icon type");
    }
  }
});

type InfoCardProps = {
  label: string;
  data: ReactNode;
  secondaryData?: ReactNode;
  icon?: ReactNode;
  iconType?: InfoCardIconType;
  tooltipText?: ReactNode;
  theme?: "default" | "event";
};

export const InfoCard = React.memo((props: InfoCardProps) => {
  return (
    <div className="bg-background-5-v2-only rounded-[2.5rem] border py-[1.8rem] px-[2.5rem]">
      <div className="flex flex-row justify-between items-start gap-8">
        <div
          className={cx("flex flex-col", {
            "gap-4": !props.theme || props.theme === "default",
            "gap-2": props.theme === "event",
          })}
        >
          <div
            className={cx("text-[2.4rem] leading-[1.4]", {
              "font-medium": !props.theme || props.theme === "default",
              "font-extrabold font-mazzard order-2": props.theme === "event",
            })}
          >
            {props.data}
          </div>

          <h3 className={cx("flex gap-2 items-center")}>
            <span className="text-inactive text-[1.4rem] leading-[1.5]">{props.label}</span>

            {props.tooltipText && (
              <Tooltip renderContent={() => props.tooltipText} disabled={!props.tooltipText}>
                <TipIcon />
              </Tooltip>
            )}
          </h3>

          {props.secondaryData && (
            <div
              className={cx("text-inactive text-[1.4rem] leading-[1.5]", {
                "order-3": props.theme === "event",
              })}
            >
              {props.secondaryData}
            </div>
          )}
        </div>

        {(props.iconType ?? props.icon) && (
          <div className="bg-main/10 h-[5rem] aspect-square flex items-center justify-center rounded-full">
            <div className="h-[2.6rem] w-[2.6rem]">
              {props.iconType ? <InfoCardIcon type={props.iconType}></InfoCardIcon> : props.icon}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
