import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { Menu } from "components/MenuDropdown";
import { Trans } from "@lingui/macro";

type Props = {
  className?: string;
  exact?: boolean;
  to: string;
  children?: ReactNode;
  onClick?: () => void;
};

function HeaderLink({
  className,
  exact,
  to,
  children,
  ...restProps
}: Props) {
  return (
    <NavLink {...restProps} activeClassName="active" className={cx(className)} exact={exact} to={to}>
      {children}
    </NavLink>
  );
}

// onClick and className are required of the 'header-menu' variant
type LinkVariant = 'header' | 'drawer' | 'header-menu'

type LocalHeaderLinkProps = React.ComponentProps<typeof HeaderLink> & {
  variant: LinkVariant;
};

const LocalHeaderLink = ({ variant, className, children, ...restProps }: LocalHeaderLinkProps) => {
  return (
    <>
      {
        variant === 'header-menu'
          ? (
            <Menu.Item className={className}>
              <HeaderLink
                {...restProps}
              >
                {children}
              </HeaderLink>
            </Menu.Item>
          )
          : (
            <HeaderLink
              {...restProps}
              className={cx(className, {
                '[&:not(.active)]:hover:opacity-60 [&:not(.active)]:hover:text-textColor [&.active]:pointer-events-none [&.active]:font-bold [&.active]:text-textColor': variant === "header",
                'text-[1.4rem] leading-[1.8rem] font-normal tracking-[0.1px] text-inactive p-[15px] p-x-[16px] no-underline block hover:bg-[hsla(252,24%,51%,0.14)] hover:text-textColor focus-visible:bg-[hsla(252,24%,51%,0.14)] focus-visible:text-white [&.active]:bg-[hsla(252,24%,51%,0.14)] [&.active]:text-textColor [&.active]:font-bold': variant === "drawer",
              })}
            >
              {children}
            </HeaderLink>
          )
      }
    </>
  );
}

type NamedLinkProps = Omit<LocalHeaderLinkProps, "to">

export const NamedLink = {
  Portfolio: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/portfolio" {...props}>
      <Trans>Portfolio</Trans>
    </LocalHeaderLink>
  ),
  Trade: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/trade" {...props}>
      <Trans>Trade</Trans>
    </LocalHeaderLink>
  ),
  Earn: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/earn" {...props}>
      <Trans>Earn</Trans>
    </LocalHeaderLink>
  ),
  Buy: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/buy" {...props}>
      <Trans>Buy</Trans>
    </LocalHeaderLink>
  ),
  Referral: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/referral" {...props}>
      <Trans>Referral</Trans>
    </LocalHeaderLink>
  ),
  Event: (props: NamedLinkProps) => (
    <LocalHeaderLink to="/event" {...props}>
      <Trans>Event</Trans>
    </LocalHeaderLink>
  ),
}

// Unused links

// const LaunchpadLink = (props) => (
//   <LocalExternalLink
//     href="https://app.palmswap.org/launchpad"
//     {...props}
//   >
//     <Trans>Launchpad</Trans>
//   </LocalExternalLink>
// )
// const ClaimLink = (props) => (
//   <LocalExternalLink
//     href="https://app.palmswap.org/claim"
//     {...props}
//   >
//     <Trans>Claim</Trans>
//   </LocalExternalLink>
// )

// type LocalExternalLinkProps = {
//   className?: string;
//   children: ReactNode;
//   href: string;
//   custom?: boolean;
//   onClick?: () => void;
// };
