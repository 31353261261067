import { ApolloClient, InMemoryCache } from "@apollo/client";
import { EthereumChainSubgraph, SUBGRAPH_URLS, SupportedChainSubgraph, getSubgraphUrl } from "config/subgraph";
import { ETH_MAINNET } from "config/chains";

export function createSubgraph<
  TChainId extends keyof typeof SUBGRAPH_URLS,
  TSubgraph extends (
    TChainId extends typeof ETH_MAINNET ? EthereumChainSubgraph : SupportedChainSubgraph
  )
  >(
  chainId: TChainId,
  subgraph: TSubgraph
) {
  const url = getSubgraphUrl(chainId, subgraph);

  return new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}
