import React from "react";
import cx from "classnames";
import { v4 as uuidv4 } from "uuid";

type Props = Omit<React.ComponentProps<"input">, "onChange" | "value"> & {
  value: string;
  label: string;
  onChange?: (value: string) => void;
};

const TextInput = ({ onChange, className, label, ...restProps }: Props, ref) => {
  const inputRefLocal = React.useRef<HTMLInputElement>();
  const inputRef = React.useMemo(() => {
    return ref ?? inputRefLocal;
  }, [ref]);
  const labelId = React.useMemo(() => {
    return uuidv4();
  }, []);

  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-[1.4rem] leading-[1.5] text-inactive pb-4" id={labelId}>
          {label}
        </label>
      )}

      <input
        ref={inputRef}
        type="text"
        className={cx([
          "px-6 h-[5.5rem] border border-[rgba(255,255,255,0.06)] border-solid rounded-sm bg-background-3 text-textColor",
          "text-[1.4rem] leading-[1.5] placeholder:text-inactive",
          className,
        ])}
        aria-labelledby={labelId}
        onChange={(e) => onChange?.(e.target.value)}
        {...restProps}
      />
    </div>
  );
};

export default React.memo(React.forwardRef(TextInput));
