import React, { useCallback } from "react";
import { Trans, t } from "@lingui/macro";
import Pagination from "components/Pagination/Pagination";
import Button from "components/Button/Button";
import cx from "classnames";
import { BigNumber } from "@ethersproject/bignumber";
import { ReferralStat } from "pages/Referrals/types";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import usePagination from "pages/Referrals/components/usePagination";
import {
  getReferralCodeTradeUrl,
  getTwitterShareUrl,
  getUSDValue,
  isRecentReferralCodeNotExpired,
} from "pages/Referrals/referralsHelper";
import { useClipboard } from "pages/Referrals/hooks/useClipboard";

import { sortByAtom, sortDescAtom} from "pages/Referrals/state";
import { useAtom } from "jotai";
import { orderBy } from "lodash";
import { SortableTableHeader } from "./SortableTableHeader";

type AffiliatesStatsTableProps = {
  className?: string;
  referralsData: any;
  recentlyAddedCodes: any;
  chainId: number;
};

const columnHelper = createColumnHelper<ReferralStat>();

export const AffiliatesStatsTable = React.memo((props: AffiliatesStatsTableProps) => {
  const { referrerTotalStats } = props.referralsData;
  const allReferralCodes = referrerTotalStats.map((c) => c.referralCode.trim());

  const finalAffiliatesTotalStats = React.useMemo(
    () =>
      props.recentlyAddedCodes.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv);
        }
        return acc;
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, props.recentlyAddedCodes]
  );

  const {
    currentPage: currentAffiliatesPage,
    getCurrentData: getCurrentAffiliatesData,
    setCurrentPage: setCurrentAffiliatesPage,
    pageCount: affiliatesPageCount,
  } = usePagination(finalAffiliatesTotalStats);

  const currentAffiliatesData = React.useMemo(() => {
    return getCurrentAffiliatesData() as any;
  }, [getCurrentAffiliatesData]);

  const [sortBy, setSortBy] = useAtom(sortByAtom);
  const [sortDesc, setSortDesc] = useAtom(sortDescAtom);
  


  const currentAffiliatesDataSorted = React.useMemo(() => {
  
    const dataToSort = orderBy(
      currentAffiliatesData,
      [sortBy],
      [sortDesc ? 'desc' : 'asc']
    );
  
    return dataToSort;
  }, [currentAffiliatesData, sortBy, sortDesc]);


  const toggleSort = useCallback((column: string) => {
    if (sortBy === column) {
      setSortDesc(!sortDesc);
    } else {
      setSortBy(column);
      setSortDesc(false);
    }
  }, [sortBy, sortDesc, setSortBy, setSortDesc]);

  const columns = React.useMemo(() => {

    return [
      columnHelper.accessor("referralCode", {
        cell: (info) => info.getValue(),
        header: () => t`Referral Code`,
      }),
      columnHelper.accessor("volume", {
        cell: (info) => getUSDValue(info.getValue()),
        header: () => (
        <SortableTableHeader onToggle={() => toggleSort('volume')}>
          Total Volume
        </SortableTableHeader>
        ),
      }),
      columnHelper.accessor("tradedReferralsCount", {
        cell: (info) => info.getValue(),
        header: () => (
        <SortableTableHeader onToggle={() => toggleSort('tradedReferralsCount')}>
          Traders Referred
       </SortableTableHeader>
        ),
      }),
      columnHelper.accessor("discountUsd", {
        cell: () => null,
        header: () => null,
      }),
      columnHelper.accessor("totalRebateUsd", {
        cell: (info) => {
          const totalRebateUsd = info.row.getValue<ReferralStat["totalRebateUsd"]>("totalRebateUsd");
          const discountUsd = info.row.getValue<ReferralStat["discountUsd"]>("discountUsd");

          let referrerRebate = BigNumber.from(0);
          if (totalRebateUsd && discountUsd) {
            referrerRebate = totalRebateUsd.sub(discountUsd);
          }

          return getUSDValue(referrerRebate, 4);
        },
        header: () => (
        <SortableTableHeader onToggle={() => toggleSort('totalRebateUsd')}>
           Total Rewards
        </SortableTableHeader>
        ),
      }),
      columnHelper.group({
        id: "actions",
        cell: (info) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const clipboard = useClipboard();

          const referralCode = info.row.getValue<ReferralStat["referralCode"]>("referralCode");

          return (
            <div className="gap-4 grid grid-rows-2 @[100rem]/table:grid-rows-none @[100rem]/table:grid-cols-2 whitespace-nowrap">
              <Button
                variant="minor"
                onClick={() => {
                  clipboard.copy(getReferralCodeTradeUrl(referralCode));
                }}
                className="shrink-0"
                disabled={clipboard.isCopied}
              >
                <div className="relative">
                  <span className={cx({ invisible: clipboard.isCopied })}>
                    <Trans>Copy URL</Trans>
                  </span>

                  {clipboard.isCopied && (
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-2 items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                        <path
                          d="M 13.363 3.322 C 13.49 3.419 13.573 3.562 13.595 3.719 C 13.616 3.877 13.574 4.037 13.478 4.164 L 7.078 12.564 C 6.973 12.702 6.813 12.787 6.64 12.799 C 6.468 12.81 6.298 12.747 6.176 12.624 L 2.576 9.024 C 2.356 8.788 2.362 8.419 2.591 8.191 C 2.819 7.962 3.188 7.956 3.424 8.176 L 6.539 11.29 L 12.523 3.437 C 12.724 3.174 13.1 3.122 13.363 3.322 Z"
                          fill="rgb(108, 114, 132)"
                        ></path>
                      </svg>

                      <Trans>Copied</Trans>
                    </div>
                  )}
                </div>
              </Button>

              <Button variant="minor" to={getTwitterShareUrl(referralCode)} newTab className="flex gap-2 shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                  <path
                    d="M 12 2.279 C 11.551 2.478 11.074 2.608 10.586 2.666 C 11.1 2.359 11.485 1.874 11.668 1.304 C 11.185 1.591 10.656 1.793 10.105 1.901 C 9.64 1.405 8.989 1.123 8.308 1.124 C 6.718 1.124 5.551 2.607 5.91 4.147 C 3.93 4.047 2.086 3.113 0.836 1.575 C 0.182 2.699 0.516 4.138 1.597 4.862 C 1.206 4.849 0.824 4.744 0.483 4.554 C 0.456 5.694 1.273 6.761 2.457 6.999 C 2.094 7.097 1.714 7.111 1.345 7.041 C 1.667 8.043 2.592 8.73 3.645 8.75 C 2.613 9.559 1.302 9.926 0 9.77 C 1.126 10.493 2.436 10.877 3.774 10.876 C 8.345 10.876 10.928 7.016 10.771 3.553 C 11.253 3.204 11.669 2.773 12 2.279 Z"
                    fill="#27a2f8"
                  ></path>
                </svg>

                <span>
                  <Trans>Tweet</Trans>
                </span>
              </Button>
            </div>
          );
        },
      }),
    ];
  }, [toggleSort]);

  const table = useReactTable({
    data: currentAffiliatesDataSorted,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="relative @container/table">
      <div className={cx(["max-h-none scroll-smooth custom-scrollbar overflow-y-auto", props.className])}>
        <table className="w-full">
          <thead className="sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (header.id.includes("discountUsd")) {
                    return null;
                  }

                  return (
                    <th
                      key={header.id}
                      className="first:rounded-l-sm last:rounded-r-sm bg-background-3 h-14 text-[1.2rem] leading-[1.5] text-inactive font-normal text-left px-6 first:w-1/4"
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border-b">
                {row.getVisibleCells().map((cell) => {
                  if (cell.id.includes("discountUsd")) {
                    return null;
                  }

                  return (
                    <td key={cell.id} className="text-[1.4rem] leading-[1.5] px-6 py-[1.2rem]">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        page={currentAffiliatesPage}
        pageCount={affiliatesPageCount}
        onPageChange={(page) => setCurrentAffiliatesPage(page)}
      />
    </div>
  );
});
