import { useChainId } from 'lib/chains';
import { useWeb3React } from '@web3-react/core';
import { Trans } from "@lingui/macro";
import { getChainName } from 'config/chains'
import React from 'react'
import { helperToast } from 'lib/helperToast';
import { switchNetwork } from 'lib/wallets/connections';

export function useShowUnsupportedNetworkToast() {
  const { connector } = useWeb3React()
  const { chainId } = useChainId()

  const showUnsupportedNetworkToast = React.useCallback(() => {
    helperToast.info(
      <div>
        <Trans>
          <div>Your wallet is not connected to {getChainName(chainId)}.</div>
          <br />
          <div className="clickable underline text-textColor" onClick={() => switchNetwork(chainId, connector).catch((error) => (helperToast.error(error)))}>
            Switch to {getChainName(chainId)}
          </div>
        </Trans>
      </div>
    );
  }, [chainId, connector])

  return showUnsupportedNetworkToast
}
