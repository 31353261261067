import "./SpinningLoader.css";

function SpinningLoader({ size = "30px", color = "#735BD3" }) {
  const style = {
    height: size,
    width: size,
  };
  return (
    <div style={style}>
      <div className="loader">
        <svg className="circular-loader" viewBox="15 15 30 30">
          <circle className="loader-path" cx="30" cy="30" r="14" fill="none" stroke={color}></circle>
        </svg>
      </div>
    </div>
  );
}

export default SpinningLoader;
