import React from "react";
import { useCopyToClipboard } from "react-use";

export const useClipboard = () => {
  const [text, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = React.useState(false);
  const [isCopiedTimeout, setIsCopiedTimeout] = React.useState<NodeJS.Timeout | number>(0);

  const copy = React.useCallback(
    (textToCopy: string) => {
      copyToClipboard(textToCopy);
      setIsCopied(true);

      clearTimeout(isCopiedTimeout);
      setIsCopiedTimeout(setTimeout(() => setIsCopied(false), 2000));
    },
    [copyToClipboard, isCopiedTimeout]
  );

  return {
    text,
    copy,
    isCopied,
  };
};
