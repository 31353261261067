import React from "react";
import cx from "classnames";

type Props = React.ComponentProps<"div"> & {};

export const TipIcon = (props: Props) => {
  return (
    <div {...props} className={cx(["w-6 h-6 text-inactive flex items-center justify-center", props.className])}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};
