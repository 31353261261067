import { BigNumber, BigNumberish } from "ethers";
import { GeneralInfo } from "pages/Event/events/TradeToEarnEvent/types";

export const getTotalEpoches = (tier1StartIndex: BigNumberish, endIndex: BigNumberish): number => {
  const _tier1StartIndex = BigNumber.from(tier1StartIndex).toNumber();
  const _endIndex = BigNumber.from(endIndex).toNumber();

  return _endIndex - _tier1StartIndex;
};

export const getEpocheEndDay = (epocheIndex: number) => epocheIndex + 1;

export const getEpocheDistributionDay = (epocheIndex: number) => epocheIndex + 2;

export const getNextRewardDistributionDay = ({
  currentDay,
  generalInfo,
}: {
  currentDay: number;
  generalInfo: GeneralInfo | null | undefined;
}): number | null => {
  if (!generalInfo) {
    return null;
  }
  if (currentDay < generalInfo.tier1StartIndex.toNumber()) {
    return null;
  }
  if (currentDay > generalInfo.endIndex.toNumber() + 1) {
    return null;
  }
  // Reward from the current epoche
  if (currentDay === generalInfo.tier1StartIndex.toNumber()) {
    return getEpocheDistributionDay(currentDay);
  }
  // Reward from the previous epoche
  return getEpocheDistributionDay(currentDay - 1);
};

export const getCurrentEpocheIndex = ({
  currentDay,
  generalInfo,
}: {
  currentDay: number;
  generalInfo: GeneralInfo | null | undefined;
}): number | null => {
  if (!generalInfo) {
    return null;
  }
  if (currentDay < generalInfo.tier1StartIndex.toNumber()) {
    return null;
  }
  if (currentDay > generalInfo.endIndex.toNumber()) {
    return null;
  }

  const daysDelta = currentDay - generalInfo.tier1StartIndex.toNumber();

  return daysDelta;
};

export const getCurrentEpocheTier = ({
  currentDay,
  generalInfo,
}: {
  currentDay: number;
  generalInfo: GeneralInfo | null | undefined;
}): number | null => {
  if (!generalInfo) {
    return null;
  }
  if (currentDay < generalInfo.tier1StartIndex.toNumber()) {
    return null;
  }
  if (currentDay > generalInfo.endIndex.toNumber() + 1) {
    return null;
  }
  if (currentDay >= generalInfo.tier2StartIndex.toNumber()) {
    return 2;
  }
  return 1;
};

export const getDailyReward = ({
  currentDay,
  generalInfo,
}: {
  currentDay: number;
  generalInfo: GeneralInfo | null | undefined;
}): BigNumber => {
  if (!generalInfo) {
    return BigNumber.from(0);
  }

  const currentEpocheTier = getCurrentEpocheTier({
    currentDay,
    generalInfo,
  });

  switch (currentEpocheTier) {
    case 1:
      return generalInfo.tier1DailyReward;
    case 2:
      return generalInfo.tier2DailyReward;
    default:
      return BigNumber.from(0);
  }
};
