import React from "react";
import cx from "classnames";
import "./Sidebar.css";

import { Link } from "react-router-dom";
import { ReactComponent as OverviewIcon } from 'img/Overview.svg'
import { ReactComponent as PositionIcon } from 'img/Position.svg'
import { ReactComponent as OrderIcon } from 'img/Orders.svg'
import { ReactComponent as HistoryIcon } from 'img/History.svg'


const Sidebar = ({ setActivePage, activePage }) => {
  const handleSetActivePage = (page) => {
    setActivePage(page);
  };

  return (
    <div className="tailwind flex flex-col w-full items-center place-content-evenly md:items-start justify-between md:justify-start md:w-[26rem] md:bg-background-1 bg-background-2 p-[0.5rem] md:p-[1rem] border-t md:border-t-0 bottom-0 md:relative min-h-[5rem] md:h-[calc(100vh-6.2rem)] fixed border-r z-10">
      <span className="mb-[1rem] muted md:flex hidden">User Portfolio</span>
      <div className="flex md:flex-col flex-row w-full text-inactive justify-evenly">
        <div
          className={cx("flex flex-col md:flex-row h-[4.2rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm", {
            'md:bg-border text-textColor' : activePage === "overview",
          })}
          onClick={() => handleSetActivePage("overview")}
        >
          <div className="w-[1.8rem] h-[1.8rem]">
            <OverviewIcon  className={activePage === "overview" ? "fill-textColor" : "fill-inactive"}  />
          </div>
       
          <span>Overview</span>
        </div>
        <div
          className={cx("flex flex-col md:flex-row h-[4.2rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm", {
            'md:bg-border text-textColor' : activePage === "position",
          })}
          onClick={() => handleSetActivePage("position")}
        >
          <div className="w-[1.8rem] h-[1.8rem]">
            <PositionIcon className={activePage === "position" ? "fill-textColor" : "fill-inactive"}  />
          </div>
          <span>Position</span>
        </div>
        <div
          className={cx("flex flex-col md:flex-row h-[4.2rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm", {
            'md:bg-border text-textColor' : activePage === "orders",
          })}
          onClick={() => handleSetActivePage("orders")}
        >
          <div className="w-[1.8rem] h-[1.8rem]">
            <OrderIcon  className={activePage === "orders" ? "fill-textColor" : "fill-inactive"}  />
          </div>
          <span>Orders</span>
        </div>
        <div
          className={cx("flex flex-col md:flex-row h-[4.2rem] w-full gap-[0.5rem] md:gap-[1rem] text-[1rem] md:text-[1.4rem] items-center justify-start cursor-pointer p-[0.5rem] md:p-[1rem] rounded-sm", {
            'md:bg-border text-textColor' : activePage === "history",
          })}
          onClick={() => handleSetActivePage("history")}
        >
          <div className="w-[1.8rem] h-[1.8rem]">
           <HistoryIcon className={activePage === "history" ? "fill-textColor" : "fill-inactive"} />
          </div>
          <span>History</span>
        </div>
      </div>
      <div className="mt-auto bottom-0 w-full md:flex hidden justify-evenly">
        <Link to="/buy">
          <button className="w-[10.5rem] h-[3.5rem] dark:bg-white bg-black rounded-[50rem] text-white dark:text-black transition-effect">Buy PLP</button>
        </Link>
        <Link to="/buy">
          <button className="w-[10.5rem] h-[3.5rem] dark:bg-white bg-black rounded-[50rem] text-white dark:text-black  transition-effect">Buy PALM</button>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
