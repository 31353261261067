import React from "react";
import cx from "classnames";
import "./ExternalLink.scss";

type Props = React.ComponentProps<"a"> & {
  custom?: boolean;
};

function ExternalLink({ href, children, className, target = "_blank", custom, ...restProps }: Props) {
  const classNames = cx({ "link-underline": !custom },'text-textColor',  className);
  return (
    <a {...restProps} href={href} className={classNames} target={target} rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default ExternalLink;
