import React, { useState } from "react";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { useMedia } from "react-use";
import { HiX } from "react-icons/hi";
import { Tooltip } from "components/TooltipV2/Tooltip";
import PositionSeller from "./PositionSeller";
import PositionEditor from "./PositionEditor";
import OrdersToa from "./OrdersToa";
import { ImSpinner2 } from "react-icons/im";
import connectWalletImg from "img/ic_wallet_24.svg";
import {
  getLiquidationPrice,
  getLeverage,
  getOrderError,
  USD_DECIMALS,
  FUNDING_RATE_PRECISION,
  LONG,
  SHORT,
  INCREASE,
  DECREASE,
  importImage,
} from "lib/legacy";
import PositionShare from "./PositionShare";
import PositionDropdown from "./PositionDropdown";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { helperToast } from "lib/helperToast";
import { getUsd } from "domain/tokens/utils";
import { bigNumberify, formatAmount } from "lib/numbers";
import { AiOutlineEdit } from "react-icons/ai";
import useAccountType, { AccountType } from "lib/wallets/useAccountType";
import PositionDetailsModal from "./PositionDetailsModal";
import { ActionsTableScrollLayout } from "components/Exchange/ActionsTableScrollLayout";
import { TokenUtils } from "components/TokenUtils";
import { Position } from "domain/positions/types";
import { FIAT_DECIMALS } from "../../config/formatting";
import { PositionModalType } from "./constants";

const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return [];
  }

  const filteredOrders = orders.filter((order) => {
    const hasMatchingIndexToken =
      order.indexToken === nativeTokenAddress
        ? position.indexToken.isNative
        : order.indexToken === position.indexToken.address;

    const hasMatchingCollateralToken =
      order.collateralToken === nativeTokenAddress
        ? position.collateralToken.isNative
        : order.collateralToken === position.collateralToken.address;

    if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
      return true;
    }

    return false;
  });

  const connectedOrders = filteredOrders.map((order) => {
    order.error = getOrderError(account, order, undefined, position);

    if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
      order.error = t`Order size is bigger than position, will only be executable if position increases`;
    }

    return order;
  });

  return connectedOrders;
};

export default function PositionsList(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positions,
    positionsDataIsLoading,
    positionsMap,
    infoTokens,
    active,
    account,
    library,
    pendingTxns,
    setPendingTxns,
    setListSection,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    setIsWaitingForPluginApproval,
    approveOrderBook,
    isPluginApproving,
    isWaitingForPluginApproval,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    showPnlAfterFees,
    setMarket,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
    hideActions,
    isExchange,
  } = props;

  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);
  const [positionToShare, setPositionToShare] = useState<Position | null>(null);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(false);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(false);
  const [positionClickType, setPositionClickType] = useState<PositionModalType | "">("");
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const accountType = useAccountType();
  const isContractAccount = accountType === AccountType.CONTRACT;
  const [detailsOpen, setDetailsOpen] = useState(false);

  const pickPosition = (position) => {
    setPositionToSellKey(position.key);
    setDetailsOpen(true);
  };

  const editPosition = (position) => {
    setCollateralTokenAddress(position.collateralToken.address);
    setPositionToEditKey(position.key);
    setIsPositionEditorVisible(true);
  };

  const sellPosition = (position, type: PositionModalType) => {
    setPositionToSellKey(position.key);
    setIsPositionSellerVisible(true);
    setIsHigherSlippageAllowed(false);
    if (type) {
      setPositionClickType(type);
    }
  };

  const onPositionClick = (position) => {
    if (hideActions) return;
    const longOrShortText = position.isLong ? t`Long` : t`Short`;
    helperToast.success(t`${longOrShortText} ${TokenUtils.getSymbol(position.indexToken)} market selected`);
    setMarket(position.isLong ? LONG : SHORT, position.indexToken.address);
  };

  const connectWallet = () => {
    if (!active) {
      props.connectWallet();
      return;
    }
  };

  const noPositionIcon = importImage("No-position-order.png");
  const isMobile = useMedia("(max-width: 1100px)");

  return (
    <div className={cx("tailwind", { PositionsList: isExchange })}>
      <PositionEditor
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        isContractAccount={isContractAccount}
      />
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionShareModalOpen && (
        <PositionShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          positionToShare={positionToShare}
          chainId={chainId}
          account={account}
        />
      )}
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isMobile && (
        <PositionDetailsModal
          open={detailsOpen}
          setIsVisible={setDetailsOpen}
          positionKey={positionToSellKey}
          positionsMap={positionsMap}
          editPosition={editPosition}
          hideActions={hideActions}
          showPnlAfterFees={showPnlAfterFees}
          getOrdersForPosition={getOrdersForPosition}
          orders={orders}
          nativeTokenAddress={nativeTokenAddress}
          account={account}
          setPositionToShare={setPositionToShare}
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          sellPosition={sellPosition}
        />
      )}
      {isPositionSellerVisible && (
        <PositionSeller
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          isContractAccount={isContractAccount}
          modalType={positionClickType}
        />
      )}
      <ActionsTableScrollLayout>
        {({ tableClassName }) => (
          <table className={cx(tableClassName, "Exchange-list App-box")}>
            <tbody>
              <tr className="Exchange-list-header">
                <th className="Start">
                  <Trans>Position</Trans>
                </th>

                <th>
                  <Trans>Side</Trans>
                </th>
                <th>
                  <Trans>Size</Trans>
                </th>
                {!isMobile && (
                  <>
                    <th>
                      <Trans>Position Margin</Trans>
                    </th>
                    <th>
                      <Trans>Entry Price</Trans>
                    </th>
                    <th>
                      <Trans>Mark Price</Trans>
                    </th>
                    <th className={!hideActions ? "" : "End"}>
                      <Trans>Liq. Price</Trans>
                    </th>

                    <th>
                      <Trans>PnL / Net Value</Trans>
                    </th>

                    {!hideActions && (
                      <>
                        <th className="">TP/SL</th>
                      </>
                    )}
                  </>
                )}

                {!hideActions && (
                  <>
                    <th className="End">Close</th>
                  </>
                )}
              </tr>
              {positionsDataIsLoading && (
                <tr>
                  <td colSpan={15}>
                    <span className="Exchange-empty-positions-list-note Exchange-list-no-position">
                      <span className="Exchange-empty-positions">
                        <Trans>
                          <p>Loading...</p>
                        </Trans>
                      </span>
                    </span>
                  </td>
                </tr>
              )}
              {positions.length === 0 && !positionsDataIsLoading && active && (
                <tr>
                  <td colSpan={15}>
                    <span className="Exchange-empty-positions-list-note Exchange-list-no-position">
                      <span className="Exchange-empty-positions">
                        <img src={noPositionIcon} alt="No position" className="no-position-icon" />
                        <p>You don't have any positions.</p>
                      </span>
                    </span>
                  </td>
                </tr>
              )}

              {!active && (
                <tr>
                  <td colSpan={15}>
                    <span className="Exchange-empty-positions-list-note Exchange-list-no-position">
                      <div className="Exchange-inactive-wrapper">
                        <p>Please connect your wallet.</p>
                        <button className="Exchange-inactive" onClick={connectWallet}>
                          <img src={connectWalletImg} alt="No position" />
                          <p className="Exchange-connect-wallet">Connect Wallet</p>
                        </button>
                      </div>
                    </span>
                  </td>
                </tr>
              )}
              {positions.map((position: Position) => {
                const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
                const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
                const hasOrderError = !!positionOrders.find((order) => order.error);
                const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
                const positionDelta =
                  position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
                let borrowFeeUSD;

                if (position.collateralToken && position.fundingRate) {
                  const borrowFeeRate = position.fundingRate.mul(position.size).mul(24).div(FUNDING_RATE_PRECISION);
                  borrowFeeUSD = formatAmount(borrowFeeRate, USD_DECIMALS, 2, true);
                }

                return (
                  <tr key={position.key} className="Exchange-list-body">
                    <td
                      className="clickable Exchange-list-item-type"
                      key={position.key}
                      onClick={() => onPositionClick(position)}
                    >
                      <div className="Exchange-list-image-and-title">
                        <TokenUtils.Logo token={position.indexToken} className="w-[3rem] mr-[.5rem]"></TokenUtils.Logo>
                        <div className="Exchange-list-leverage">
                          <div className="Exchange-list-title">
                            <p>{TokenUtils.getSymbol(position.indexToken)}</p>
                            <p>
                              {position.hasPendingChanges && (
                                <ImSpinner2 className="animate-spin position-loading-icon" />
                              )}
                            </p>
                          </div>

                          <div className="Exchange-list-info-label Exchange-list-info-leverage">
                            {position.leverage && (
                              <span className="muted">
                                {"Lvrg " + formatAmount(position.leverage, 4, 2, true)}x&nbsp;
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td onClick={() => pickPosition(position)}>
                      <div>
                        <span className={cx({ long: position.isLong, short: !position.isLong })}>
                          {position.isLong ? t`Long` : t`Short`}
                        </span>
                      </div>
                    </td>
                    <td onClick={() => pickPosition(position)}>
                      <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                    </td>
                    {!isMobile && (
                      <>
                        <td>
                          <div className="position-list-collateral">
                            <Tooltip
                              handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                              handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                              renderContent={() => {
                                return (
                                  <>
                                    {position.hasLowCollateral && (
                                      <div>
                                        <Trans>
                                          WARNING: This position has a low amount of collateral after deducting
                                          borrowing fees, deposit more collateral to reduce the position's liquidation
                                          risk.
                                        </Trans>
                                        <br />
                                        <br />
                                      </div>
                                    )}

                                    <StatsTooltipRow
                                      label={t`Initial Collateral`}
                                      value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                    />
                                    <StatsTooltipRow
                                      label={t`Borrow Fee`}
                                      showDollar={false}
                                      value={`-$${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}`}
                                    />
                                    <StatsTooltipRow
                                      showDollar={false}
                                      label={t`Borrow Fee / Day`}
                                      value={`-$${borrowFeeUSD}`}
                                    />
                                    {!hideActions && (
                                      <>
                                        <br />
                                        <Trans>Use the Edit Collateral icon to deposit or withdraw collateral.</Trans>
                                      </>
                                    )}
                                  </>
                                );
                              }}
                            />
                            {!hideActions && (
                              <span className="edit-icon" onClick={() => editPosition(position)}>
                                <AiOutlineEdit fontSize={16} />
                              </span>
                            )}
                          </div>
                        </td>

                        <td className="clickable" onClick={() => onPositionClick(position)}>
                          ${formatAmount(position.averagePrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                        </td>

                        <td
                          className="clickable Exchange-list-info-mark-price"
                          onClick={() => onPositionClick(position)}
                        >
                          ${formatAmount(position.markPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                        </td>

                        <td
                          className="clickable Exchange-list-info-liquidation-price"
                          onClick={() => onPositionClick(position)}
                        >
                          ${formatAmount(liquidationPrice, USD_DECIMALS, position.indexToken?.priceDecimals, true)}
                        </td>

                        <td className="Exchange-list-info-PnL">
                          <div>
                            {!position.netValue && t`Opening...`}

                            {position.netValue && (
                              <Tooltip
                                handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                                handleClassName="plain"
                                renderContent={() => {
                                  return (
                                    <>
                                      <StatsTooltipRow
                                        label={t`Initial Collateral`}
                                        value={formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                      />
                                      <StatsTooltipRow
                                        label={t`PnL`}
                                        value={position.deltaBeforeFeesStr}
                                        showDollar={false}
                                      />
                                      <StatsTooltipRow
                                        label={t`Borrow Fee`}
                                        showDollar={false}
                                        value={`-$${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}`}
                                      />
                                      <br />
                                      <StatsTooltipRow
                                        label={t`PnL After Fees`}
                                        value={[
                                          position.deltaAfterFeesStr,
                                          `(${position.deltaAfterFeesPercentageStr})`,
                                        ]}
                                        showDollar={false}
                                      />
                                    </>
                                  );
                                }}
                              />
                            )}
                          </div>
                          {position.deltaStr && (
                            <div
                              className={cx("Exchange-list-info-label", {
                                positive: hasPositionProfit && positionDelta.gt(0),
                                negative: !hasPositionProfit && positionDelta.gt(0),
                                muted: positionDelta.eq(0),
                              })}
                            >
                              {position.deltaStr} ({position.deltaPercentageStr})
                            </div>
                          )}
                        </td>

                        <td>
                          <div className="text-center w-max">
                            <button
                              className="Exchange-list-action bg-background-6 dark:bg-background-3"
                              onClick={() => sellPosition(position, PositionModalType.ADD)}
                              disabled={position.size.eq(0)}
                            >
                              <Trans>Add</Trans>
                            </button>
                            {positionOrders.length > 0 && (
                              <div onClick={() => setListSection && setListSection("Orders")}>
                                <Tooltip
                                  handle={t`Orders (${positionOrders.length})`}
                                  handleClassName={cx(
                                    [
                                      "Exchange-list-info-label",
                                      "Exchange-position-list-orders",
                                      "plain",
                                      "clickable",
                                      "underline",
                                      "underline-offset-2",
                                    ],
                                    { muted: !hasOrderError, negative: hasOrderError }
                                  )}
                                  renderContent={() => {
                                    return (
                                      <>
                                        <strong>
                                          <Trans>Active Orders</Trans>
                                        </strong>
                                        {positionOrders.map((order) => {
                                          return (
                                            <div
                                              key={`${order.isLong}-${order.type}-${order.index}`}
                                              className="Position-list-order active-order-tooltip"
                                            >
                                              {order.type === INCREASE ? t`Limit` : t`Trigger`}
                                              {order.triggerAboveThreshold ? " >" : " <"}{" "}
                                              {formatAmount(
                                                order.triggerPrice,
                                                USD_DECIMALS,
                                                position.indexToken?.priceDecimals,
                                                true
                                              )}
                                              :{order.type === INCREASE ? " +" : " -"}$
                                              {formatAmount(order.sizeDelta, USD_DECIMALS, FIAT_DECIMALS, true)}
                                              {order.error && (
                                                <div className="negative active-oredr-error">{order.error}</div>
                                              )}
                                            </div>
                                          );
                                        })}
                                      </>
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                      </>
                    )}

                    <div className="Exchange-position-end">
                      <div className="Exchange-position-close">
                        <button
                          className="Exchange-list-action bg-background-6 dark:bg-background-3"
                          onClick={() => sellPosition(position, PositionModalType.CLOSE)}
                          disabled={position.size.eq(0)}
                        >
                          {!isMobile ? <Trans>Close</Trans> : <HiX />}
                        </button>
                      </div>
                      {!hideActions && !isMobile && (
                        <div className="Exchange-position-dropdown">
                          <PositionDropdown
                            handleEditCollateral={() => {
                              editPosition(position);
                            }}
                            handleShare={() => {
                              setPositionToShare(position);
                              setIsPositionShareModalOpen(true);
                            }}
                            handleMarketSelect={() => {
                              onPositionClick(position);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ActionsTableScrollLayout>
    </div>
  );
}
