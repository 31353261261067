import { THEME_LOCAL_STORAGE_KEY, TRADINGVIEW_CHART_COLOR } from "config/localStorage";
import { atomWithLocalStorage } from "lib/jotai/atomWithLocalStorage";
import { useAtom } from "jotai";
import { THEMES } from "lib/themes";
import { useEffect } from "react";
import { useLocalStorageReactive } from "./localStorage";

const themeAtom = atomWithLocalStorage<string>(THEME_LOCAL_STORAGE_KEY, THEMES.dark.name);

const useThemeSwitcher = () => {
  const [theme, setTheme] = useAtom(themeAtom);
  const isWhiteTheme = theme === THEMES.light.name;
  const isDarkTheme = theme === THEMES.dark.name;
  const isGrayTheme = theme === THEMES.gray.name;

  const [, setChartColor] = useLocalStorageReactive(TRADINGVIEW_CHART_COLOR, false);


  useEffect(() => {
    if (theme === THEMES.gray.name) {
      document.documentElement.classList.remove("light", "dark");
      document.documentElement.classList.add("gray");
      setChartColor(false);
    } else if (theme === THEMES.light.name) {
      document.documentElement.classList.remove("dark", "gray");
      document.documentElement.classList.add("light");
      setChartColor(false);
    } else if (theme === THEMES.dark.name) {
      document.documentElement.classList.remove("light", "gray");
      document.documentElement.classList.add("dark");
      setChartColor(true);
    }
  }, [theme, setChartColor]);

  return { theme, setTheme, isWhiteTheme, isDarkTheme, isGrayTheme};
};

export default useThemeSwitcher;
