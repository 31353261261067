import React, { useCallback, useState } from "react";
import { Trans, t, plural } from "@lingui/macro";
import useSWR from "swr";
import cx from "classnames";
import { HiChevronDown, HiChevronUp, HiQuestionMarkCircle } from "react-icons/hi";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import { useHistory } from "react-router-dom";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import PalmToken from "img/PalmToken.svg";
import "./StakeV3.scss";
import StakeModal from "./StakeModal";
import { UNSTAKE_ACTION, PLP_TOKEN } from "./StakeModal";
import VestModal from "./VestModal";
import docsIcon from "img/docsWhite.svg";
import plpIcon from "img/plpIcon.png";
import ArrowRight from "img/ArrowRight.svg";
import ArrowConnectTwo from "img/ArrowConnectTwo.svg";
import ArrowConnectOne from "img/Stake-card-arrow-third.svg";
import ArrowBottom from "img/ArrowBottom.svg";
import tetherIcon from "img/tetherIcon.png";
import swapCurrency from "img/swapCurrency.svg";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { usePlpPrice } from "pages/Event/hooks/blockchain/usePlpPrice";
import { usePlpBalance } from "pages/Event/hooks/blockchain/usePlpBalance";
import { formatAmount, expandDecimals, bigNumberify } from "lib/numbers";
import { getContractAddress } from "config/contracts";
import { useChainId } from "lib/chains";
import { contractFetcher, callContract } from "lib/contracts";
import { useStakesStats } from "domain/stats/useStakesStats";
import { useStakeEarnStats } from "domain/stats/useStakeEarnStats";
import RewardReader from "abis/RewardReader.json";
import PlpRewardTracker from "abis/PlpRewardTracker.json";
import PalmRewardTracker from "abis/PalmRewardTracker.json";
import Reader from "abis/Reader.json";
import PlpRewardRouterAbi from "abis/PlpRewardRouter.json";
import PalmRewardRouterAbi from "abis/PalmRewardRouter.json";
import Token from "abis/common/Token.json";
import { useInfoTokens } from "domain/tokens";
import { useTokenPrices } from "lib/api/useTokenPrices";
import { GLP_DECIMALS, USD_DECIMALS, BASIS_POINTS_DIVISOR, SECONDS_PER_YEAR, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { getTokenInfo } from "domain/tokens/utils";
import { getTokenBySymbol } from "config/tokens";
import { useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import Button from "components/Button/Button";
import CompoundModal, { CompoundableTokens } from "./CompoundModal";
import { PALM_DECIMALS, USDT_DECIMALS } from "lib/constants";
import { formatDistanceToNowStrict, fromUnixTime, isFuture } from "date-fns";
import { useAreHardcodedZeroAprAndTotalReturn } from "lib/useAreHardcodedZeroAprAndTotalReturn";

const tabsOptions = ["Stake"];

function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["feePlpTracker", "feePalmTracker"];
  const data = {};
  const propsLength = 5;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data;
}

export default function StakeV3(props) {
  const [activeTab, setActiveTab] = useState("Stake");
  const [openPALMAccordion, setOpenPALMAccordion] = useState(true);
  const [openPLPAccordion, setOpenPLPAccordion] = useState(false);
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);
  const [claimingAsset, setClaimingAsset] = useState(null);
  const [openStakeModal, setOpenStakeModal] = useState(false);
  const [openPlpCompoundModal, setOpenPlpCompoundModal] = useState(false);
  const [openPalmCompoundModal, setOpenPalmCompoundModal] = useState(false);
  const [openVestModal, setOpenVestModal] = useState(false);
  const [stakeModalData, setStakeModalData] = useState({ token: null, action: null });
  const [vestModalData] = useState(null);
  const { chainId } = useChainId();
  const { isActive, account, provider } = useWeb3React();
  const history = useHistory();
  const { plpTotalStaked, palmTotalStaked, palmStakeStats, plpStakeStats } = useStakesStats();
  const { palmClaimedTotal, plpClaimedTotal } = useStakeEarnStats();
  const {
    userStaked: palmUserStaked,
    userUnstaked: palmUserUnstaked,
    userCooldownPeriods: palmUserCooldowns,
  } = palmStakeStats;
  const { userUnstaked: plpUserUnstaked, userCooldownPeriods: plpUserCooldowns } = plpStakeStats;
  const areHardcodedZeroAprAndTotalReturn = useAreHardcodedZeroAprAndTotalReturn();

  const handleStakeModal = (token, action) => {
    setStakeModalData({
      token,
      action,
    });
    setOpenStakeModal(true);
  };

  const isTabActive = (tab) => {
    return activeTab === tab;
  };

  const [isTopHovered, setIsTopHovered] = useState(false);

  const handleTopMouseEnter = () => setIsTopHovered(true);
  const handleTopMouseLeave = () => setIsTopHovered(false);

  const [isBottomHovered, setBottomHovered] = useState(false);

  const handleBottomMouseEnter = () => setBottomHovered(true);
  const handleBottomMouseLeave = () => setBottomHovered(false);

  const handleClaimEnter = () => {
    setIsTopHovered(true);
    setBottomHovered(true);
  };
  const handleClaimLeave = () => {
    setIsTopHovered(false);
    setBottomHovered(false);
  };

  const [switchCurrency, setSwitchCurrency] = useState([false, false, false, false]);

  const handleSwitchCurrency = (index) => {
    const updatedSwitchCurrency = [...switchCurrency];
    updatedSwitchCurrency[index] = !updatedSwitchCurrency[index];
    setSwitchCurrency(updatedSwitchCurrency);
  };

  const plpPrice = usePlpPrice();
  const plpBalance = usePlpBalance();
  const { price: palmPrice } = useTokenPrices("PALM");

  const plpBalanceUsd = plpBalance.amount.mul(plpPrice.amount).div(expandDecimals(1, USD_DECIMALS));

  const plpUserUnstakedUsd = plpUserUnstaked.mul(plpPrice.amount).div(expandDecimals(1, USD_DECIMALS));

  const readerAddress = getContractAddress(chainId, "Reader");
  const rewardReaderAddress = getContractAddress(chainId, "RewardReader");
  const plpRewardRouterAddress = getContractAddress(chainId, "PlpRewardRouter");
  const palmRewardRouterAddress = getContractAddress(chainId, "PalmRewardRouter");
  const plpAddress = getContractAddress(chainId, "GLP");
  const feePlpTrackerAddress = getContractAddress(chainId, "FeePlpTracker");
  const feePalmTrackerAddress = getContractAddress(chainId, "FeePalmTracker");
  const feePalmRewardDistributorAddress = getContractAddress(chainId, "FeePalmRewardDistributor");
  const feePlpRewardDistributorAddress = getContractAddress(chainId, "FeePlpRewardDistributor");
  const palmTokenAddress = getContractAddress(chainId, "PALM");
  const tokensForBalanceAndSupplyQuery = [plpAddress, palmTokenAddress];
  const usdtAddress = getTokenBySymbol(chainId, "USDT").address;

  const { data: balancesAndSupplies } = useSWR(
    [
      `StakeV3:getTokenBalancesWithSupplies:${isActive}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, Reader, [tokensForBalanceAndSupplyQuery])
  );

  const rewardTrackersForStakingInfo = [feePlpTrackerAddress, feePalmTrackerAddress];
  const { data: stakingInfo } = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, RewardReader, [rewardTrackersForStakingInfo])
  );

  const claimablePalmQuery = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, feePalmTrackerAddress, "claimable", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PalmRewardTracker, [])
  );
  const claimablePalm = claimablePalmQuery.data ?? BigNumber.from(0);

  const claimablePlpQuery = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, feePlpTrackerAddress, "claimable", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PlpRewardTracker, [])
  );
  const claimablePlp = claimablePlpQuery.data ?? BigNumber.from(0);

  const { data: feePalmTrackerBalance } = useSWR(
    [
      `StakeV3:stakingInfo:${isActive}`,
      chainId,
      usdtAddress,
      "balanceOf",
      feePalmTrackerAddress || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, Token, [])
  );

  const { data: feePlpTrackerBalance } = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, usdtAddress, "balanceOf", feePlpTrackerAddress || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, Token, [])
  );

  const { data: feePalmDistributorBalance } = useSWR(
    [
      `StakeV3:stakingInfo:${isActive}`,
      chainId,
      usdtAddress,
      "balanceOf",
      feePalmRewardDistributorAddress || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, Token, [])
  );

  const { data: feePlpDistributorBalance } = useSWR(
    [
      `StakeV3:stakingInfo:${isActive}`,
      chainId,
      usdtAddress,
      "balanceOf",
      feePlpRewardDistributorAddress || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, Token, [])
  );

  const [redeemingPalm, setIsRedeemingPalm] = useState({});
  const setIsRedeemingPalmByIndex = useCallback(
    (index, value) => setIsRedeemingPalm((prev) => ({ ...prev, [index]: value })),
    [setIsRedeemingPalm]
  );

  const redeemPalm = useCallback(
    (index) => {
      const contract = new ethers.Contract(palmRewardRouterAddress, PalmRewardRouterAbi.abi, provider.getSigner());
      const method = "redeemPalm";
      const params = [[index]];

      setIsRedeemingPalmByIndex(index, true);
      callContract(chainId, contract, method, params, {
        sentMsg: t`Redemption submitted.`,
        failMsg: t`Redemption failed.`,
        successMsg: t`PALM successfully redeemed`,
        setPendingTxns: props.setPendingTxns,
        txnSuccessCallback: () => {
          setIsRedeemingPalmByIndex(index, false);
        },
      }).catch(() => {
        setIsRedeemingPalmByIndex(index, false);
      });
    },
    [palmRewardRouterAddress, setIsRedeemingPalmByIndex, chainId, props.setPendingTxns, provider]
  );

  const palmTotalEarnedSum = areHardcodedZeroAprAndTotalReturn
    ? BigNumber.from(0)
    : palmClaimedTotal.add(feePalmTrackerBalance || 0).add(feePalmDistributorBalance || 0);

  const plpTotalEarnedSum = areHardcodedZeroAprAndTotalReturn
    ? BigNumber.from(0)
    : plpClaimedTotal.add(feePlpTrackerBalance || 0).add(feePlpDistributorBalance || 0);

  const { infoTokens } = useInfoTokens();
  const plpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0);
  const palmBalance = balancesAndSupplies ? balancesAndSupplies[2] : bigNumberify(0);
  const palmSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0);
  const palmPriceBigNum = BigNumber.from(String(Math.floor(palmPrice * 10 ** 18)));

  const palmPriceBigNumUsd = palmPriceBigNum.mul(expandDecimals(1, 30)).div(expandDecimals(1, 18));

  const palmBalanceUsd = palmBalance.mul(palmPriceBigNum).div(expandDecimals(1, GLP_DECIMALS));
  const palmSupplyUsd = palmSupply.mul(palmPriceBigNum).div(expandDecimals(1, GLP_DECIMALS));

  const palmTotalStakedUsd = palmTotalStaked.mul(palmPriceBigNum).div(expandDecimals(1, GLP_DECIMALS));
  const palmUserStakedUsd = palmUserStaked.mul(palmPriceBigNum).div(expandDecimals(1, GLP_DECIMALS));
  const palmUserUnstakedUsd = palmUserUnstaked.mul(palmPriceBigNum).div(expandDecimals(1, GLP_DECIMALS));
  const plpTotalStakedUsd = plpTotalStaked.mul(plpPrice.amount).div(expandDecimals(1, GLP_DECIMALS));

  const stakingData = getStakingData(stakingInfo);
  const collateralTokenAddress = useCollateralTokenAddress();
  const collateralToken = getTokenInfo(infoTokens, collateralTokenAddress);
  const plpSupplyUsd = plpSupply.mul(plpPrice.amount).div(expandDecimals(1, GLP_DECIMALS));

  let totalPalmApr = BigNumber.from(0);
  let totalPlpApr = BigNumber.from(0);

  if (
    stakingData &&
    stakingData.feePalmTracker &&
    stakingData.feePalmTracker.totalSupply &&
    stakingData.feePalmTracker.tokensPerInterval &&
    collateralToken &&
    collateralToken.minPrice &&
    !areHardcodedZeroAprAndTotalReturn
  ) {
    const feePalmSupply = stakingData.feePalmTracker.totalSupply;
    const feePalmSupplyUsd = feePalmSupply.mul(palmPriceBigNumUsd).div(expandDecimals(1, 18));
    const feePalmTrackerAnnualRewardsUsd = stakingData.feePalmTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(collateralToken.minPrice)
      .div(expandDecimals(1, 18));

    const palmAprForNativeToken =
      feePalmSupplyUsd && feePalmSupplyUsd.gt(0)
        ? feePalmTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(feePalmSupplyUsd)
        : BigNumber.from(0);

    totalPalmApr = totalPalmApr.add(palmAprForNativeToken);
  }

  if (
    stakingData &&
    stakingData.feePlpTracker &&
    stakingData.feePlpTracker.tokensPerInterval &&
    collateralToken &&
    collateralToken.minPrice &&
    plpSupplyUsd &&
    plpSupplyUsd.gt(0) &&
    !areHardcodedZeroAprAndTotalReturn
  ) {
    const feePlpTrackerAnnualRewardsUsd = stakingData.feePlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(collateralToken.minPrice)
      .div(expandDecimals(1, 18));
    const feePlpTrackerApr = feePlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(plpSupplyUsd);

    totalPlpApr = totalPlpApr.add(feePlpTrackerApr);
  }

  const plpSupplyFormatted = `${
    switchCurrency[3]
      ? formatAmount(plpSupplyUsd, USD_DECIMALS, 2, true)
      : formatAmount(plpSupply, GLP_DECIMALS, 2, true)
  } ${switchCurrency[3] ? "$" : "PLP"}`;

  const palmSupplyFormatted = `${
    switchCurrency[1]
      ? formatAmount(palmSupplyUsd, GLP_DECIMALS, 2, true)
      : formatAmount(palmSupply, GLP_DECIMALS, 2, true)
  } ${switchCurrency[1] ? "$" : "PALM"}`;

  const plpTotalStakedFormatted = `${
    switchCurrency[2]
      ? formatAmount(plpTotalStakedUsd, USD_DECIMALS, 2, true)
      : formatAmount(plpTotalStaked, GLP_DECIMALS, 2, true)
  } ${switchCurrency[2] ? "$" : "PLP"}`;

  const palmTotalStakedFormatted = `${
    switchCurrency[0]
      ? formatAmount(palmTotalStakedUsd, GLP_DECIMALS, 2, true)
      : formatAmount(palmTotalStaked, GLP_DECIMALS, 2, true)
  } ${switchCurrency[0] ? "$" : "PALM"}`;

  const doNotShowModalForBuyingPLP = JSON.parse(localStorage.getItem("doNotShowModalForBuyingPLP"));

  const handleSellBuyPlp = (action) => {
    if (!doNotShowModalForBuyingPLP) {
      handleStakeModal(PLP_TOKEN, action);
    } else {
      history.push("/buy");
    }
  };

  let totalClaimable = BigNumber.from(0);
  if (claimablePalm) {
    totalClaimable = totalClaimable.add(claimablePalm);
  }
  if (claimablePlp) {
    totalClaimable = totalClaimable.add(claimablePlp);
  }

  const canClaimRewards = React.useMemo(() => {
    if (isClaimingRewards) {
      return false;
    }
    if (claimablePalm && claimablePalm.gt(0)) {
      return true;
    }
    if (claimablePlp && claimablePlp.gt(0)) {
      return true;
    }
    return false;
  }, [claimablePalm, claimablePlp, isClaimingRewards]);

  const claimRewards = async () => {
    if (isClaimingRewards) {
      throw new Error("Already claiming rewards");
    }
    if (!claimablePalm || !claimablePlp) {
      throw new Error("Nothing to claim");
    }
    if (claimablePalm.gt(0) && claimablePalm.gt(claimablePlp)) {
      return await claimPalmRewards();
    }
    if (claimablePlp.gt(0)) {
      return await claimPlpRewards();
    }
    throw new Error("Nothing to claim");
  };

  const claimPalmRewards = async () => {
    if (claimablePalm.isZero()) {
      return;
    }
    setClaimingAsset("PALM");
    setIsClaimingRewards(true);

    const rewardRouterContract = new ethers.Contract(
      palmRewardRouterAddress,
      PalmRewardRouterAbi.abi,
      provider.getSigner()
    );
    const setPendingTxns = props.setPendingTxns;

    await callContract(chainId, rewardRouterContract, "claim", [], {
      sentMsg: t`Claim PALM rewards submitted.`,
      failMsg: t`Claim PALM rewards failed.`,
      successMsg: `${formatAmount(claimablePalm, USDT_DECIMALS, 4, true)} ` + t`USDT claimed`,
      setPendingTxns,
      txnSuccessCallback: () => {
        claimablePalmQuery.mutate(BigNumber.from(0), {
          optimisticData: BigNumber.from(0),
          populateCache: true,
          revalidate: false,
        });
        setIsClaimingRewards(false);
        setClaimingAsset(null);
      },
    }).catch(() => {
      setIsClaimingRewards(false);
      setClaimingAsset(null);
    });
  };

  const claimPlpRewards = async () => {
    if (claimablePlp.isZero()) {
      return;
    }
    setClaimingAsset("PLP");
    setIsClaimingRewards(true);

    const rewardRouterContract = new ethers.Contract(
      plpRewardRouterAddress,
      PlpRewardRouterAbi.abi,
      provider.getSigner()
    );
    const setPendingTxns = props.setPendingTxns;

    await callContract(chainId, rewardRouterContract, "claim", [], {
      sentMsg: t`Claim PLP rewards submitted.`,
      failMsg: t`Claim PLP rewards failed.`,
      successMsg: `${formatAmount(claimablePlp, USDT_DECIMALS, 4, true)} ` + t`USDT claimed`,
      setPendingTxns,
      txnSuccessCallback: () => {
        claimablePlpQuery.mutate(BigNumber.from(0), {
          optimisticData: BigNumber.from(0),
          populateCache: true,
          revalidate: false,
        });
        setIsClaimingRewards(false);
        setClaimingAsset(null);
      },
    }).catch(() => {
      setIsClaimingRewards(false);
      setClaimingAsset(null);
    });
  };

  const getClaimButtonText = () => {
    if (isClaimingRewards) {
      return t`Claiming ${claimingAsset}...`;
    }

    return t`Claim Rewards`;
  };

  const totalRewardsPalm = stakingData?.feePalmTracker?.cumulativeRewards ?? BigNumber.from(0);
  const totalRewardsPlp = stakingData?.feePlpTracker?.cumulativeRewards ?? BigNumber.from(0);

  return (
    <SEO title={getPageTitle("Stake PALM")}>
      <div className="Stake-page page-layout Stake-default-container tailwind">
        <div className="Stake-container ">
          <div className="Main-content">
            <div className="Stake-card Staking-Routes flex-column">
              <div className="Stake-card-header-wrapper">
                <span className="Stake-card-title">
                  <Trans>Staking Routes</Trans>
                </span>

                <a
                  href="https://docs.palmswap.org/"
                  target="_blank"
                  rel="noreferrer"
                  className="Stake-card-docs-button bg-black/5 dark:bg-white/10 hover:bg-main dark:hover:bg-main hover:text-white text-textColor transition-effect"
                >
                  <img src={docsIcon} alt="docs" />{" "}
                  <span>
                    <Trans>View Documentation</Trans>
                  </span>
                </a>
              </div>
              <div className="Stake-card-apr-wrapper">
                <div className="Stake-card-apr">
                  <img src={PalmToken} alt="token" className="Stake-card-token-icon" />
                  <div className="Stake-card-apr-text">
                    <span>APR:</span>
                    <span className="Stake-card-apr-percent">{formatAmount(totalPalmApr, 2, 2, false)}%</span>
                  </div>
                </div>
                <div className="Stake-card-apr">
                  <img src={plpIcon} alt="token" className="Stake-card-token-icon" />
                  <div className="Stake-card-apr-text">
                    <span>APR:</span>
                    <span className="Stake-card-apr-percent">{formatAmount(totalPlpApr, 2, 2, false)}%</span>
                  </div>
                </div>
              </div>

              <div className="Stake-card-route-wrapper">
                <div
                  className={cx("Stake-card-top", { isTopHovered: isTopHovered })}
                  onMouseEnter={handleTopMouseEnter}
                  onMouseLeave={handleTopMouseLeave}
                >
                  <div className="Stake-card-currency Stake-card-currency-first transition-effect">
                    <img src={PalmToken} alt="icon" className="Stake-card-currency-icon Stake-card-palm-icon" />
                    <span>PALM</span>
                  </div>

                  <div className="Stake-card-arrow Stake-card-arrow-first transition-effect">
                    <img src={ArrowRight} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>

                  <div className="Stake-card-infobox bg-background-7/[0.2] Stake-card-infobox-first transition-effect">
                    30% <Trans>of Trading fees</Trans>
                  </div>

                  <div className="Stake-card-arrow Stake-card-arrow-second transition-effect">
                    <img src={ArrowRight} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>

                  <div className="Stake-card-currency Stake-card-currency-second transition-effect">
                    <img src={tetherIcon} alt="icon" className="Stake-card-currency-icon Stake-card-palm-icon" />
                    <span>USDT</span>
                  </div>

                  <div className="Stake-card-arrow-third">
                    <div
                      className={cx("Stake-card-arrow", "Stake-card-arrow-third-one", "transition-effect", {
                        isTopHovered: isTopHovered,
                      })}
                    >
                      <img
                        src={ArrowConnectTwo}
                        alt="arrow"
                        target="_blank"
                        rel="noreferrer"
                        className="ArrowConnectTwo"
                      />
                    </div>

                    <div
                      className={cx("Stake-card-arrow", "Stake-card-arrow-third-two", "transition-effect", {
                        isBottomHovered: isBottomHovered,
                      })}
                    >
                      <img
                        src={ArrowConnectOne}
                        alt="arrow"
                        target="_blank"
                        rel="noreferrer"
                        className="ArrowConnectOne"
                      />
                    </div>
                  </div>

                  <div
                    className={cx("Stake-card-arrow", "Stake-card-arrow-fourth", "transition-effect", {
                      isBottomHovered: isBottomHovered,
                      isTopHovered: isTopHovered,
                    })}
                  >
                    <img src={ArrowRight} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>
                  <div
                    className={cx(
                      "Stake-card-infobox",
                      "Stake-card-infobox-second",
                      "Stake-card-infobox-claim dark:bg-[#98E178]/[0.3] bg-lime dark:text-[#98e178] text-white",
                      "transition-effect",
                      {
                        isBottomHovered: isBottomHovered,
                        isTopHovered: isTopHovered,
                      }
                    )}
                    onMouseEnter={handleClaimEnter}
                    onMouseLeave={handleClaimLeave}
                  >
                    Claim
                  </div>
                </div>

                <div
                  className={cx("Stake-card-center", "transition-effect", {
                    isBottomHovered: isBottomHovered,
                  })}
                  onMouseEnter={handleBottomMouseEnter}
                  onMouseLeave={handleBottomMouseLeave}
                >
                  <div className="Stake-card-currency Stake-card-currency-third transition-effect">
                    <img src={plpIcon} alt="icon" className="Stake-card-currency-icon Stake-card-palm-icon" />
                    <span>PLP</span>
                  </div>

                  <div className="Stake-card-arrow Stake-card-arrow-fifth transition">
                    <img src={ArrowRight} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>

                  <div className="Stake-card-infobox bg-background-7/[0.2] Stake-card-infobox-third transition-effect">
                    40% <Trans>of Trading fees</Trans>
                  </div>

                  <div className="Stake-card-arrow Stake-card-arrow-sixth transition-effect">
                    <img src={ArrowRight} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>

                  <div className="Stake-card-currency Stake-card-currency-fourth transition-effect">
                    <img src={tetherIcon} alt="icon" className="Stake-card-currency-icon Stake-card-palm-icon" />
                    <span>USDT</span>
                  </div>
                </div>

                <div
                  className={cx("Stake-card-bottom", "transition-effect", {
                    isBottomHovered: isBottomHovered,
                  })}
                  onMouseEnter={handleBottomMouseEnter}
                  onMouseLeave={handleBottomMouseLeave}
                >
                  <div className="Stake-card-arrow Stake-card-arrow-seventh transition-effect">
                    <img src={ArrowBottom} alt="arrow" target="_blank" rel="noreferrer" />
                  </div>

                  <div className="Stake-card-bottom-infoboxes">
                    <div className="Stake-card-infobox bg-background-7/[0.2] Stake-card-infobox-fourth">
                      <Tooltip
                        position="center-bottom"
                        renderContent={() => {
                          return (
                            <div className="Stake-card-infobox-tooltip">
                              <Trans>When PLP is minted or redeemed, there is a fee.</Trans>
                            </div>
                          );
                        }}
                      >
                        <div className="Tooltip-handle-wrapper">
                          <Trans>Mint & Burn Fees</Trans> <HiQuestionMarkCircle />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="Stake-card-infobox bg-background-7/[0.2] Stake-card-infobox-fith">
                      <Tooltip
                        position="center-bottom"
                        renderContent={() => {
                          return (
                            <div className="Stake-card-infobox-tooltip">
                              <Trans>Paid hourly by Trader.</Trans>
                            </div>
                          );
                        }}
                      >
                        <div className="Tooltip-handle-wrapper">
                          <Trans>Borrow Fees</Trans> <HiQuestionMarkCircle />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="Stake-card-infobox bg-background-7/[0.2] Stake-card-infobox-sixth">
                      <Tooltip
                        position="center-bottom"
                        renderContent={() => {
                          return (
                            <div className="Stake-card-infobox-tooltip">
                              <Trans>Paid when a trader's position is liquidated.</Trans>
                            </div>
                          );
                        }}
                      >
                        <div className="Tooltip-handle-wrapper">
                          <Trans>Liquidation Fees</Trans> <HiQuestionMarkCircle />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Description-card">
              <div className="Decription-card-page-title-wrapper">
                <div className="Stake-card-title">
                  <Trans>Total Rewards</Trans>
                </div>
                <div className="Description-card-page-subtitle">
                  <Trans>Your Rewards Details</Trans>
                </div>
              </div>

              <div className="Description-card-bottom">
                <div className="Description-card-rewards-wrapper">
                  <div className="Description-rewards-infos">
                    <div className="Description-reward-title">
                      <Trans>PALM Rewards</Trans>
                    </div>
                    <div className="Description-reward-amount">
                      <span>{formatAmount(claimablePalm, GLP_DECIMALS, 2, false)} USDT</span>
                    </div>
                    <div className="Description-reward-amount-usd">
                      ~${formatAmount(claimablePalm, GLP_DECIMALS, 2, false)}
                    </div>
                  </div>
                  <div className="Description-rewards-infos">
                    <div className="Description-reward-title">
                      <Trans>PLP Rewards</Trans>
                    </div>
                    <div className="Description-reward-amount">
                      <span>{formatAmount(claimablePlp, GLP_DECIMALS, 2, false)} USDT</span>
                    </div>
                    <div className="Description-reward-amount-usd">
                      ~${formatAmount(claimablePlp, GLP_DECIMALS, 2, false)}
                    </div>
                  </div>
                </div>
                <div className="Description-card-claim-wrapper">
                  <div className="Description-card-claim-amount">
                    <Trans>Total Amount</Trans> ${formatAmount(totalClaimable, GLP_DECIMALS, 2, false)}
                  </div>
                  <Button
                    variant="lime"
                    disabled={!canClaimRewards}
                    className="!rounded-[1.5rem]"
                    onClick={claimRewards}
                  >
                    {getClaimButtonText()}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="Earn-tabs">
            {tabsOptions.map((opt) => (
              <div className={cx("Earn-tab", { active: isTabActive(opt) })} onClick={() => setActiveTab(opt)} key={opt}>
                {opt}
              </div>
            ))}
            <div className="Earn-tabs-empty" />
          </div>
          <div className="Tab-content flex-column ">
            <div className="inactive-text Earn-tab-description">
              <Trans>Stake PALM and PLP to earn rewards.</Trans>
            </div>
            <div className="Stake-card flex-column">
              <div className="Accordion-head">
                <div className="PLPAccordion">
                  <div className="info-block-logo">
                    <img src={PalmToken} alt="token" className="Token-icon" />
                    <div className="info-block">
                      <div className="Stake-card-title  accordion-title">
                        <Trans>PALM</Trans>
                      </div>
                      <div className=" currency-price">$0.00</div>
                    </div>
                  </div>

                  <div className="two-column-row">
                    <div className="info-block">
                      <div className="gap-container">
                        <Tooltip
                          position="center-bottom"
                          renderContent={() => {
                            return (
                              <div className="Stake-card-infobox-tooltip">
                                <Trans>
                                  APRs are updated weekly on Saturday and will depend on the fees collected for the
                                  week.
                                </Trans>
                              </div>
                            );
                          }}
                        >
                          <div className="Tooltip-handle-wrapper">
                            <div className="inactive-text">APR</div>
                            <HiQuestionMarkCircle className="inactive-text" />
                          </div>
                        </Tooltip>
                      </div>
                      <div className="white-text">{formatAmount(totalPalmApr, 2, 2, false)}%</div>
                    </div>
                    <div className="info-block">
                      <div className="inactive-text">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div className="gap-container">
                        <div className="white-text currency-text">{palmTotalStakedFormatted}</div>
                        <div className="usd-tag" onClick={() => handleSwitchCurrency(0)}>
                          <img src={swapCurrency} alt="swap" />
                          {switchCurrency[0] ? "PALM" : "USD"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two-column-row">
                    <div className="info-block">
                      <div className="inactive-text">
                        <Trans>Total Supply</Trans>
                      </div>
                      <div className="gap-container">
                        <div className="white-text currency-text">{palmSupplyFormatted}</div>
                        <div className="usd-tag" onClick={() => handleSwitchCurrency(1)}>
                          <img src={swapCurrency} alt="swap" />
                          {switchCurrency[1] ? "PALM" : "USD"}
                        </div>
                      </div>
                    </div>
                    <div className="info-block">
                      <div className="gap-container">
                        <Tooltip
                          position="center-bottom"
                          renderContent={() => {
                            return (
                              <div className="Stake-card-infobox-tooltip">
                                <Trans>Total earnings of all users together</Trans>
                              </div>
                            );
                          }}
                        >
                          <div className="Tooltip-handle-wrapper">
                            <div className="inactive-text">
                              <Trans>Total Earned</Trans>
                            </div>
                            <HiQuestionMarkCircle className="inactive-text" />
                          </div>
                        </Tooltip>
                      </div>
                      <div className="white-text">${formatAmount(palmTotalEarnedSum, GLP_DECIMALS, 2, false)}</div>
                    </div>
                  </div>
                </div>
                <div
                  className="Chevron-icon-container shrink-0"
                  onClick={() => setOpenPALMAccordion(!openPALMAccordion)}
                >
                  {openPALMAccordion ? <HiChevronUp /> : <HiChevronDown />}
                </div>
              </div>

              <div
                className={cx("Accordion-body", {
                  openPALMAccordion: openPALMAccordion,
                })}
              >
                <div className="Card-divider"></div>
                <div className="flex-row overflow-x-auto pb-[2rem] Accordion-body-scroll">
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Wallet</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(palmBalance, GLP_DECIMALS, 2, false)} PALM</div>
                    <div className="inactive-text-small">~${formatAmount(palmBalanceUsd, GLP_DECIMALS, 2, false)}</div>
                    <a
                      className="Stake-button Stake-button-white"
                      target="_blank"
                      disabled
                      rel="noopener noreferrer"
                      href={`https://pancakeswap.finance/swap?outputCurrency=${palmTokenAddress}`}
                    >
                      <Trans>Buy PALM</Trans>
                    </a>
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>PALM Staked</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(palmUserStaked, GLP_DECIMALS, 2, false)} PALM</div>
                    <div className="inactive-text-small">
                      ~${formatAmount(palmUserStakedUsd, GLP_DECIMALS, 2, false)}
                    </div>
                    <div className="gap-container">
                      <button className="Stake-button Stake-button-white" disabled onClick={() => {}}>
                        <Trans>+ Stake</Trans>
                      </button>
                      <button className="Stake-button Stake-button-white round-button" disabled onClick={() => {}}>
                        -
                      </button>
                    </div>
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Unstake Balance</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(palmUserUnstaked, PALM_DECIMALS, 2, false)} PALM</div>
                    <div className="inactive-text-small">
                      ~${formatAmount(palmUserUnstakedUsd, PALM_DECIMALS, 2, false)}
                    </div>
                    {palmUserCooldowns && palmUserCooldowns.items.length > 0 && (
                      <Tooltip
                        renderContent={() => (
                          <div className="d-flex flex-col">
                            <div className="pb-5">
                              <Trans>Cooldown periods</Trans> ({palmUserCooldowns.active + palmUserCooldowns.expired}{" "}
                              <Trans>Active</Trans>)
                            </div>
                            {palmUserCooldowns.items.map((item) => {
                              const amountBn = bigNumberify(item.amount) || BigNumber.from("0");
                              return (
                                <div key={item.index} className="d-flex mt-3 justify-stretch">
                                  <div className="d-flex flex-col mr-5">
                                    <div className="text-textColor font-semibold text-[1.4rem]">
                                      {formatAmount(item.amount, PALM_DECIMALS, 2, false)} PALM
                                    </div>
                                    <div>
                                      ~$
                                      {formatAmount(
                                        amountBn.mul(palmPriceBigNum).div(expandDecimals(1, PALM_DECIMALS)),
                                        PALM_DECIMALS,
                                        2,
                                        false
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow">
                                    {isFuture(fromUnixTime(item.expire)) ? (
                                      <Button variant="minor" size="sm" className="rounded-full" disabled>
                                        {formatDistanceToNowStrict(fromUnixTime(item.expire))}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="lime"
                                        size="sm"
                                        className="rounded-full"
                                        onClick={() => redeemPalm(item.index)}
                                        disabled={redeemingPalm[item.index]}
                                      >
                                        <Trans>Unstake PALM</Trans>
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      >
                        <div className="gap-container">
                          <Button variant="minor" className="mt-2">
                            {palmUserCooldowns.active ? (
                              <span>
                                {palmUserCooldowns.active} <Trans>Active</Trans>
                                {palmUserCooldowns.expired ? "," : ""}
                              </span>
                            ) : (
                              ""
                            )}
                            {palmUserCooldowns.expired ? (
                              <span>
                                {palmUserCooldowns.expired} <Trans>Over</Trans>
                              </span>
                            ) : (
                              ""
                            )}
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Rewards</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(claimablePalm, GLP_DECIMALS, 4, false)} USDT</div>
                    <div className="inactive-text-small">~${formatAmount(claimablePalm, GLP_DECIMALS, 4, false)}</div>
                    {claimablePalm && claimablePalm.gt(0) && (
                      <div className="gap-container">
                        <Button variant="lime" className="mt-2" onClick={() => setOpenPalmCompoundModal(true)}>
                          <Trans>Compound</Trans>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Total Rewards Earned</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(totalRewardsPalm, GLP_DECIMALS, 4, false)} USDT</div>
                    <div className="inactive-text-small">
                      ~${formatAmount(totalRewardsPalm, GLP_DECIMALS, 4, false)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Stake-card flex-column">
              <div className="Accordion-head">
                <div className="PLPAccordion">
                  <div className="info-block-logo">
                    <img src={plpIcon} alt="token" className="Token-icon" />
                    <div className="info-block accordion-title">
                      <div className="Stake-card-title">
                        <Trans>PLP</Trans>
                      </div>
                      <div className="white-text currency-price">${formatAmount(plpPrice.amount, 30, 3, false)}</div>
                    </div>
                  </div>
                  <div className="two-column-row">
                    <div className="info-block">
                      <div className="gap-container">
                        <Tooltip
                          position="center-bottom"
                          renderContent={() => {
                            return (
                              <div className="Stake-card-infobox-tooltip">
                                <Trans>
                                  APRs are updated weekly on Saturday and will depend on the fees collected for the
                                  week.
                                </Trans>
                              </div>
                            );
                          }}
                        >
                          <div className="Tooltip-handle-wrapper">
                            <div className="inactive-text">APR</div>
                            <HiQuestionMarkCircle className="inactive-text" />
                          </div>
                        </Tooltip>
                      </div>
                      <div className="white-text">{formatAmount(totalPlpApr, 2, 2, false)}%</div>
                    </div>
                    <div className="info-block">
                      <div className="inactive-text">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div className="gap-container">
                        <div className="white-text currency-text">{plpTotalStakedFormatted}</div>
                        <div className="usd-tag" onClick={() => handleSwitchCurrency(2)}>
                          <img src={swapCurrency} alt="swap" />
                          {switchCurrency[2] ? "PLP" : "USD"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two-column-row">
                    <div className="info-block">
                      <div className="inactive-text">
                        <Trans>Total Supply</Trans>
                      </div>
                      <div className="gap-container">
                        <div className="white-text currency-text">{plpSupplyFormatted}</div>
                        <div className="usd-tag" onClick={() => handleSwitchCurrency(3)}>
                          <img src={swapCurrency} alt="swap" />
                          {switchCurrency[3] ? "PLP" : "USD"}
                        </div>
                      </div>
                    </div>
                    <div className="info-block">
                      <div className="gap-container">
                        <Tooltip
                          position="center-bottom"
                          renderContent={() => {
                            return (
                              <div className="Stake-card-infobox-tooltip">
                                <Trans>Total earnings of all users together</Trans>
                              </div>
                            );
                          }}
                        >
                          <div className="Tooltip-handle-wrapper">
                            <div className="inactive-text">
                              <Trans>Total Earned</Trans>
                            </div>
                            <HiQuestionMarkCircle className="inactive-text" />
                          </div>
                        </Tooltip>
                      </div>
                      <div className="white-text">${formatAmount(plpTotalEarnedSum, GLP_DECIMALS, 2, false)}</div>
                    </div>
                  </div>
                </div>
                <div className="Chevron-icon-container shrink-0" onClick={() => setOpenPLPAccordion(!openPLPAccordion)}>
                  {openPLPAccordion ? <HiChevronUp /> : <HiChevronDown />}
                </div>
              </div>

              <div
                className={cx("Accordion-body", {
                  openPLPAccordion: openPLPAccordion,
                })}
              >
                <div className="Card-divider" />
                <div className="flex-row overflow-x-auto pb-[2rem] Accordion-body-scroll">
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>PLP Staked</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(plpBalance.amount, GLP_DECIMALS, 2, false)} PLP</div>
                    <div className="inactive-text-small">~${formatAmount(plpBalanceUsd, GLP_DECIMALS, 2, false)}</div>
                    <div className="gap-container">
                      <button className="Stake-button Stake-button-white" disabled onClick={() => {}}>
                        <Trans>+ Stake</Trans>
                      </button>
                      <button
                        className="Stake-button Stake-button-white round-button"
                        onClick={() => {
                          handleSellBuyPlp(UNSTAKE_ACTION);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Sell Cooldown</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(plpUserUnstaked, GLP_DECIMALS, 4, false)} PLP</div>
                    <div className="inactive-text-small">
                      ~${formatAmount(plpUserUnstakedUsd, GLP_DECIMALS, 2, false)}
                    </div>
                    {plpUserCooldowns && plpUserCooldowns.items.length > 0 && (
                      <Tooltip
                        renderContent={() => (
                          <div className="d-flex flex-col">
                            <div className="pb-5">
                              <Trans>Cooldown periods</Trans> ({plpUserCooldowns.active + plpUserCooldowns.expired}{" "}
                              <Trans>Active</Trans>)
                            </div>
                            {plpUserCooldowns.items.map((item) => {
                              const amountBn = bigNumberify(item.amount) || BigNumber.from("0");
                              return (
                                <div key={item.index} className="d-flex mt-3 justify-stretch">
                                  <div className="d-flex flex-col mr-5">
                                    <div className="text-white font-semibold text-[1.4rem]">
                                      {formatAmount(item.amount, GLP_DECIMALS, 2, false)} PLP
                                    </div>
                                    <div>
                                      ~$
                                      {formatAmount(
                                        amountBn.mul(plpPrice.amount).div(expandDecimals(1, USD_DECIMALS)),
                                        GLP_DECIMALS,
                                        2,
                                        false
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow">
                                    {isFuture(fromUnixTime(item.expire)) ? (
                                      <Button variant="minor" size="sm" className="rounded-full" disabled>
                                        {formatDistanceToNowStrict(fromUnixTime(item.expire))}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="lime"
                                        size="sm"
                                        className="rounded-full"
                                        onClick={() => history.push("/buy")}
                                      >
                                        <Trans>Sell PLP</Trans>
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      >
                        <div className="gap-container">
                          <Button variant="minor" className="mt-2">
                            {plural(plpUserCooldowns.active, {
                              one: "# Active Period",
                              other: "# Active Periods",
                            })}
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Rewards</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(claimablePlp, GLP_DECIMALS, 4, false)} USDT</div>
                    <div className="inactive-text-small">~${formatAmount(claimablePlp, GLP_DECIMALS, 4, false)}</div>
                    {claimablePlp && claimablePlp.gt(0) && (
                      <div className="gap-container">
                        <Button variant="lime" className="mt-2" onClick={() => setOpenPlpCompoundModal(true)}>
                          <Trans>Compound</Trans>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="info-block">
                    <div className="inactive-text">
                      <Trans>Total Rewards Earned</Trans>
                    </div>
                    <div className="white-text-big">{formatAmount(totalRewardsPlp, GLP_DECIMALS, 4, false)} USDT</div>
                    <div className="inactive-text-small">~${formatAmount(totalRewardsPlp, GLP_DECIMALS, 4, false)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StakeModal
        open={openStakeModal}
        setOpen={setOpenStakeModal}
        setPendingTxns={props.setPendingTxns}
        connectWallet={props.connectWallet}
        stakeModalData={stakeModalData}
      />
      <VestModal open={openVestModal} setOpen={setOpenVestModal} token={vestModalData} />
      <CompoundModal
        open={openPalmCompoundModal}
        setOpen={setOpenPalmCompoundModal}
        amount={claimablePalm}
        token={CompoundableTokens.PALM}
      />
      <CompoundModal
        open={openPlpCompoundModal}
        setOpen={setOpenPlpCompoundModal}
        amount={claimablePlp}
        token={CompoundableTokens.PLP}
      />
    </SEO>
  );
}
