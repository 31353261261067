import useResizeObserver from "@react-hook/resize-observer"
import React from "react"

type ElementSize = {
  height: number,
  width: number,
}

export const useElementSize = (target: { current: HTMLElement | null }) => {
  const [size, setSize] = React.useState<ElementSize | null>(null)

  React.useLayoutEffect(() => {
    if (!target.current) {
      return
    }
    setSize(getElementSize(target.current))
  }, [target])

  // Where the magic happens
  useResizeObserver(target, () => getElementSize(target.current))

  return size
}

export function getElementSize<T extends HTMLElement | null | undefined>(el: T) {
  if (!el) {
    return null
  }
  return {
    height: el.scrollHeight,
    width: el.scrollWidth,
  }
}
