import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { t, Trans } from "@lingui/macro";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import "./styles.css";
import cx from "classnames";
import useSWR from "swr";
import { ethers } from "ethers";
import { BsArrowRight } from "react-icons/bs";
import { HiInformationCircle } from "react-icons/hi";
import { getCollateralTokenAddress, useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useExecutionFee } from "domain/fees/useExecutionFee";
import FeesTooltip from "components/Exchange/FeesTooltip";
import { BigNumber } from "@ethersproject/bignumber";
import { pushAccessNotification } from "components/Exchange/SwapBox/pushAccessNotification";
import { useMedia } from "react-use";
import {
  adjustForDecimals,
  calculatePositionDelta,
  getLeverage,
  getLiquidationPrice,
  getNextToAmount,
  getPositionKey,
  isTriggerRatioInverted,
} from "lib/legacy";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  LEVERAGE_ORDER_OPTIONS,
  LONG,
  MARGIN_FEE_BASIS_POINTS,
  PRECISION,
  SHORT,
  OrderOption,
  SWAP_OPTIONS,
  USD_DECIMALS,
  USDP_DECIMALS,
  MIN_ALLOWED_LEVERAGE,
} from "lib/constants";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED, isSupportedChain } from "config/chains";
import * as Api from "domain/legacy";
import { getContractAddress } from "config/contracts";
import Tab from "components/Tab/Tab";
import ExchangeTab from "components/Tab/ExchangeTab";
import TokenSelector from "components/Exchange/TokenSelector";
import ExchangeInfoRow from "components/Exchange/ExchangeInfoRow";
import ConfirmationBox from "components/Exchange/ConfirmationBox";
import OrdersToa from "components/Exchange/OrdersToa";
import PositionRouterAbi from "abis/PositionRouter.json";
import Token from "abis/common/Token.json";
import longImg from "img/long.svg";
import shortImg from "img/short.svg";
import { useUserReferralCode } from "domain/referrals";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, shouldRaiseGasError } from "domain/tokens";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { helperToast } from "lib/helperToast";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import { expandDecimals, formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { getToken } from "config/tokens";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ErrorCode, ErrorDisplayType } from "components/Exchange/constants";
import Button from "components/Button/Button";
import {
  useLeverageOption,
  useLeverageOptionInput,
  fromValueAtom,
  toValueAtom,
  anchorOnFromAmountAtom,
  useOnToValueChange,
  useOnFromValueChange,
  canOpenConfirmingModalAtom,
  isConfirmingAtom,
} from "pages/Exchange/state";
import { useAtom } from "jotai";
import { useAppUpdate } from "lib/useAppUpdate";
import { TokenUtils } from "components/TokenUtils";
import { PositionRouter } from "typechain/PositionRouter";
import { useTokenBalance } from "pages/Event/hooks/blockchain/useTokenBalance";

const SWAP_ICONS = {
  [LONG]: longImg,
  [SHORT]: shortImg,
};

const MIN_LEVERAGE = MIN_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR;

const leverageMarksMap = {
  20: {
    2: "2x",
    3: "3x",
    5: "5x",
    8: "8x",
    10: "10x",
    12: "12x",
    15: "15x",
    18: "18x",
    20: "20x",
  },
  50: {
    2: "2x",
    5: "5x",
    10: "10x",
    15: "15x",
    20: "20x",
    25: "25x",
    30: "30x",
    35: "35x",
    40: "40x",
    45: "45x",
    50: "50x",
  },
};

const { AddressZero } = ethers.constants;

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

function getNextAveragePrice({ size, sizeDelta, hasProfit, delta, nextPrice, isLong }) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return;
  }
  const nextSize = size.add(sizeDelta);
  let divisor;
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta);
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta);
  }
  if (!divisor || divisor.eq(0)) {
    return;
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor);
  return nextAveragePrice;
}

export default function SwapBox(props) {
  const {
    pendingPositions,
    setPendingPositions,
    infoTokens,
    active,
    library,
    account,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    fromTokens,
    toTokens,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    usdpSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableValidationForTesting,
    minExecutionFee,
  } = props;

  const [leverageOption, setLeverageOption] = useLeverageOption();
  const toToken = getToken(chainId, toTokenAddress);
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
  const { hasLeverageOption, handleLeverageBlur, handleLeverageInput, minLeverage, maxLeverage } =
    useLeverageOptionInput(toTokenInfo);
  const [fromValue, setFromValue] = useAtom(fromValueAtom);
  const [toValue, setToValue] = useAtom(toValueAtom);
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useAtom(anchorOnFromAmountAtom);
  const onToValueChange = useOnToValueChange();
  const onFromValueChange = useOnFromValueChange();
  const [isConfirming, setIsConfirming] = useAtom(isConfirmingAtom);

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account);
  const [inputActive, setInputActive] = useState("");
  const isMobile = useMedia("(max-width: 1100px)");

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const isWhitelistedLoading = false;
  const isWhitelisted = true as boolean;

  React.useEffect(() => {
    if (!account || isWhitelistedLoading) {
      return;
    }

    if (isWhitelisted === false) {
      pushAccessNotification();
    }
  }, [account, isWhitelistedLoading, isWhitelisted]);

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;

  function getTokenLabel() {
    switch (true) {
      case isLong:
        return t`Long`;
      case isShort:
        return t`Short`;
      default:
        return "";
    }
  }

  const [ordersToaOpen, setOrdersToaOpen] = useState(false);

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey<OrderOption>(
    [chainId, "Order-option"],
    OrderOption.Market
  );
  if (!flagOrdersEnabled) {
    orderOption = OrderOption.Market;
  }

  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };

  const isMarketOrder = orderOption === OrderOption.Market;
  const orderOptions = LEVERAGE_ORDER_OPTIONS;
  const orderOptionLabels = {
    [OrderOption.Stop]: t`Trigger`,
    [OrderOption.Market]: t`Market`,
    [OrderOption.Limit]: t`Limit`,
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd: BigNumber = isMarketOrder
    ? BigNumber.from(0)
    : parseValue(triggerPriceValue, USD_DECIMALS) ?? BigNumber.from(0);

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const { currentExecutionFee, currentExecutionFeeUsd } = useExecutionFee({ isMarketOrder });
  const collateralTokenAddress = useCollateralTokenAddress();

  let positionKey;
  if (isLong) {
    positionKey = getPositionKey(account, collateralTokenAddress, toTokenAddress, true, nativeTokenAddress);
  }
  if (isShort) {
    positionKey = getPositionKey(account, collateralTokenAddress, toTokenAddress, false, nativeTokenAddress);
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined;
  const hasExistingPosition = Boolean(existingPosition && existingPosition.size && existingPosition.size.gt(0));

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved;
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false);
      helperToast.success(<div>Leverage enabled!</div>);
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ]);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      helperToast.success(<div>Orders enabled!</div>);
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const routerAddress = getContractAddress(chainId, "Router");
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
  const { data: tokenAllowance } = useSWR<BigNumber>(
    active && [active, chainId, tokenAllowanceAddress, "allowance", account, routerAddress],
    contractFetcher(library, Token)
  );

  const appUpdate = useAppUpdate();

  const fromToken = getToken(chainId, fromTokenAddress);

  const shortCollateralToken = getTokenInfo(infoTokens, collateralTokenAddress);

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

  const { balance: fromBalance } = useTokenBalance(fromTokenAddress, account);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);

  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const needApproval =
    fromTokenAddress !== AddressZero && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);
  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);

  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
  const [triggerRatioValue] = useState("");

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return BigNumber.from(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS) ?? BigNumber.from(0);

    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  const toTokenSymbol = TokenUtils.getSymbol(toToken);
  const fromTokenSymbol = TokenUtils.getSymbol(fromToken);

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      helperToast.success(<div>{fromTokenSymbol} approved!</div>);
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromTokenSymbol,
    isWaitingForApproval,
    fromToken,
  ]);

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress) && toTokens.length > 0) {
      setToTokenAddress(swapOption, toTokens[0].address);
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  useEffect(() => {
    const updateLeverageAmounts = () => {
      if (!hasLeverageOption) {
        return;
      }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR);

          const toTokenPriceUsd = !isMarketOrder && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

          const { feeBasisPoints } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdpSupply
          );

          let fromUsdMinAfterFee = fromUsdMin;

          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          }

          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);

          const toDenominator = BigNumber.from(MARGIN_FEE_BASIS_POINTS)
            .mul(leverageMultiplier)
            .add(BigNumber.from(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR));
          const nextToUsd = toNumerator.div(toDenominator);
          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd);
          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);
          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

      if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR);

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier);

        let _fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

        const nextFromUsd = baseFromAmountUsd.add(_fees);

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice);

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);

        setFromValue(nextFromValue);
      }
    };

    if (isLong || isShort) {
      updateLeverageAmounts();
    }
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    triggerRatio,
    hasLeverageOption,
    usdpSupply,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
    setFromValue,
    setToValue,
  ]);

  let entryMarkPrice;
  let exitMarkPrice;
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice;
  }

  let leverage = BigNumber.from(0);
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const _fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    if (fromUsdMin.sub(_fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(_fees));
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit;

    if (isMarketOrder) {
      nextDelta = existingPosition.delta;
      nextHasProfit = existingPosition.hasProfit;
    } else {
      const data = calculatePositionDelta(triggerPriceUsd, existingPosition);
      nextDelta = data.delta;
      nextHasProfit = data.hasProfit;
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    });
  }

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : BigNumber.from(0),
    collateral: hasExistingPosition ? existingPosition.collateral : BigNumber.from(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : BigNumber.from(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : BigNumber.from(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined;
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice;

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : BigNumber.from(0);
    const sizeDelta = toUsdMax ? toUsdMax : BigNumber.from(0);
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    });
  } else if (hasLeverageOption) {
    leverage = BigNumber.from(Math.floor(parseFloat(leverageOption) * BASIS_POINTS_DIVISOR));
  }

  type ExchangeError = [errorMessage: string | null, errorType?: ErrorDisplayType, errorCode?: ErrorCode];

  // TODO: fix types
  const getExchangeError = useCallback<() => ExchangeError>(() => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return [t`Leverage disabled, pending ${getChainName(chainId)} upgrade`] satisfies ExchangeError;
    }
    if (appUpdate.haveAppUpdate) {
      return [t`Page outdated, please refresh`] satisfies ExchangeError;
    }

    if (!toAmount || toAmount.eq(0)) {
      return [t`Enter an amount`] satisfies ExchangeError;
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
    if (toTokenInfo && toTokenInfo.isStable) {
      const SWAP_OPTION_LABEL = {
        [LONG]: "Longing",
        [SHORT]: "Shorting",
      };
      return [t`${SWAP_OPTION_LABEL[swapOption]} ${toTokenSymbol} not supported`] satisfies ExchangeError;
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (
      !savedShouldDisableValidationForTesting &&
      fromTokenInfo &&
      fromTokenInfo.balance &&
      fromAmount &&
      fromAmount.gt(fromTokenInfo.balance)
    ) {
      return [t`Insufficient ${fromTokenSymbol} balance`] satisfies ExchangeError;
    }

    if (leverage && leverage.eq(0)) {
      return [t`Enter an amount`] satisfies ExchangeError;
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return [t`Enter a price`] satisfies ExchangeError;
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return [t`Min order: 10 USD`] satisfies ExchangeError;
    }

    if (leverage && leverage.lt(MIN_LEVERAGE * BASIS_POINTS_DIVISOR)) {
      return [t`Min leverage: ${MIN_LEVERAGE.toFixed(1)}x`] satisfies ExchangeError;
    }

    if (leverage && leverage.gt(Number(maxLeverage) * BASIS_POINTS_DIVISOR)) {
      return [t`Max leverage: ${Number(maxLeverage).toFixed(1)}x`] satisfies ExchangeError;
    }

    if (!isMarketOrder && entryMarkPrice && !savedShouldDisableValidationForTesting) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return [t`Price above Mark Price`] satisfies ExchangeError;
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return [t`Price below Mark Price`] satisfies ExchangeError;
      }
    }

    if (isLong) {
      if (fromTokenAddress !== toTokenAddress) {
        if (!toTokenInfo.availableAmount) {
          return [t`Liquidity data not loaded`] satisfies ExchangeError;
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdpAmount &&
          fromTokenInfo.maxUsdpAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdpAmount
        ) {
          const usdpFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDP_DECIMALS);
          const nextUsdpAmount = fromTokenInfo.usdpAmount.add(usdpFromAmount);
          if (nextUsdpAmount.gt(fromTokenInfo.maxUsdpAmount)) {
            return [
              t`${fromTokenSymbol} pool exceeded, try different token`,
              ErrorDisplayType.Modal,
              ErrorCode.MaxUSDP,
            ] satisfies ExchangeError;
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));

        if (toTokenInfo.maxAvailableLong && sizeUsd.gt(toTokenInfo.maxAvailableLong)) {
          return [t`Max ${toTokenSymbol} long exceeded`] satisfies ExchangeError;
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = BigNumber.from(0);
      if (fromTokenAddress !== collateralTokenAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdpSupply
        );
        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount ?? BigNumber.from(0))) {
          return [t`Insufficient liquidity`] satisfies ExchangeError;
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          return [t`Insufficient liquidity`, ErrorDisplayType.Modal, ErrorCode.Buffer] satisfies ExchangeError;
        }

        if (
          fromTokenInfo.maxUsdpAmount &&
          fromTokenInfo.maxUsdpAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdpAmount
        ) {
          const usdpFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDP_DECIMALS);
          const nextUsdpAmount = fromTokenInfo.usdpAmount.add(usdpFromAmount);
          if (nextUsdpAmount.gt(fromTokenInfo.maxUsdpAmount)) {
            return [
              t`${fromTokenSymbol} pool exceeded, try different token`,
              ErrorDisplayType.Modal,
              ErrorCode.MaxUSDP,
            ] satisfies ExchangeError;
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return [t`Fetching token info...`] satisfies ExchangeError;
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice ?? BigNumber.from(0));

      if (!toTokenInfo.maxAvailableShort) {
        return [t`Liquidity data not loaded`] satisfies ExchangeError;
      }

      if (toTokenInfo.maxAvailableShort && sizeUsd.gt(toTokenInfo.maxAvailableShort)) {
        return [t`Max ${toTokenSymbol} short exceeded`] satisfies ExchangeError;
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [t`Insufficient liquidity`] satisfies ExchangeError;
      }
    }

    return [null] satisfies ExchangeError;
  }, [
    chainId,
    appUpdate.haveAppUpdate,
    toAmount,
    infoTokens,
    toTokenAddress,
    fromTokenAddress,
    savedShouldDisableValidationForTesting,
    fromAmount,
    leverage,
    isMarketOrder,
    triggerPriceValue,
    triggerPriceUsd,
    hasExistingPosition,
    fromUsdMin,
    entryMarkPrice,
    isLong,
    isShort,
    swapOption,
    toTokenSymbol,
    fromTokenSymbol,
    collateralTokenAddress,
    shortCollateralToken,
    usdpSupply,
    maxLeverage,
  ]);

  const getToLabel = () => {
    if (isLong) {
      return t`Long`;
    }
    return t`Short`;
  };

  const isPrimaryEnabled = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (isStopOrder) {
      return true;
    }
    if (!active) {
      return true;
    }
    const [error, errorType] = getExchangeError();
    if (error && errorType !== ErrorDisplayType.Modal) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (!isWhitelisted) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (isStopOrder) {
      return t`Open a position`;
    }
    if (!active) {
      return t`Connect Wallet`;
    }
    if (!isSupportedChain(chainId)) {
      return t`Incorrect network`;
    }
    if (!isWhitelisted) {
      return t`Not whitelisted`;
    }
    const [error, errorType] = getExchangeError();
    if (error && errorType !== ErrorDisplayType.Modal) {
      return error;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return t`Enabling leverage...`;
    }
    if (isPositionRouterApproving) {
      return t`Enabling leverage...`;
    }
    if (needPositionRouterApproval) {
      return t`Enable leverage`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${fromTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${fromTokenSymbol}`;
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return t`Enabling orders...`;
    }
    if (isPluginApproving) {
      return t`Enabling orders...`;
    }
    if (needOrderBookApproval) {
      return t`Enable orders`;
    }

    if (!isMarketOrder) return t`Create "${orderOption!.charAt(0) + orderOption!.substring(1).toLowerCase()}" order`;

    if (isLong) {
      return t`Long ${toTokenSymbol}`;
    }

    return t`Short ${toTokenSymbol}`;
  };

  const onSelectFromToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
    setIsWaitingForApproval(false);
  };

  const onSelectToToken = (token) => {
    setToTokenAddress(swapOption, token.address);
  };

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress];

    const indexToken = getToken(chainId, indexTokenAddress);
    const successMsg = t`
      Created limit order for ${TokenUtils.getSymbol(indexToken)} ${isLong ? "Long" : "Short"}: ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD!
    `;
    return Api.createIncreaseOrder(
      chainId,
      library,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      toUsdMax,
      isLong,
      triggerPriceUsd,
      {
        pendingTxns,
        setPendingTxns,
        sentMsg: t`Limit order submitted!`,
        successMsg,
        failMsg: t`Limit order creation failed.`,
      }
    )
      .then(() => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  let referralCode = ethers.constants.HashZero;
  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode;
  }

  const increasePosition = async () => {
    setIsSubmitting(true);

    const refPrice = (isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice) ?? BigNumber.from(0);
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ?? BigNumber.from(0);

    let value = minExecutionFee;

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      helperToast.error(
        t`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`
      );
      return;
    }

    const contractAddress = getContractAddress(chainId, "PositionRouter");
    const contract = new ethers.Contract(contractAddress, PositionRouterAbi.abi, library.getSigner());
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = TokenUtils.getSymbol(indexToken);
    const longOrShortText = isLong ? t`Long` : t`Short`;
    const successMsg = t`Requested increase of ${tokenSymbol} ${longOrShortText} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2
    )} USD.`;

    callContract(
      chainId,
      contract as PositionRouter,
      "createIncreasePosition",
      [
        indexTokenAddress, // _indexToken
        boundedFromAmount, // _amountIn
        0, // _minOut
        toUsdMax ?? BigNumber.from(0), // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
        AddressZero, // _callbackTarget
      ],
      {
        value,
        setPendingTxns,
        sentMsg: `${longOrShortText} submitted.`,
        failMsg: `${longOrShortText} failed.`,
        successMsg,
        // for Arbitrum, sometimes the successMsg shows after the position has already been executed
        // hide the success message for Arbitrum as a workaround
        hideSuccessMsg: chainId === ARBITRUM,
      }
    )
      .then(async () => {
        setIsConfirming(false);

        const collateralTokenAddress = getCollateralTokenAddress(chainId);

        const key = getPositionKey(account, collateralTokenAddress, indexTokenAddress, isLong);
        let nextSize = toUsdMax;
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax);
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onSwapOptionChange = (opt) => {
    setSwapOption(opt);
    if (orderOption === OrderOption.Stop) {
      setOrderOption(OrderOption.Market);
    }
  };

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet();
      return;
    }

    if (needOrderBookApproval) {
      approveOrderBook();
      return;
    }

    setIsPendingConfirmation(true);

    if (orderOption === OrderOption.Limit) {
      createIncreaseOrder();
      return;
    }

    increasePosition();
  };

  function approveFromToken() {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
  }

  const onClickPrimary = () => {
    if (isStopOrder) {
      setOrderOption(OrderOption.Market);
      return;
    }

    if (!active) {
      props.connectWallet();
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: t`Enable leverage sent.`,
        failMsg: t`Enable leverage failed.`,
      });
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    setIsConfirming(true);
    setIsHigherSlippageAllowed(false);
  };

  const canOpenConfirmingModal = React.useMemo(() => {
    if (orderOption !== OrderOption.Market) {
      return false;
    }
    if (!active) {
      return false;
    }
    if (needPositionRouterApproval || needApproval || needOrderBookApproval) {
      return false;
    }
    if (isConfirming) {
      return false;
    }
    const haveError = !!getExchangeError()[0];

    return !haveError;
  }, [
    orderOption,
    active,
    needPositionRouterApproval,
    needApproval,
    needOrderBookApproval,
    isConfirming,
    getExchangeError,
  ]);

  const [, setCanOpenConfirmingModal] = useAtom(canOpenConfirmingModalAtom);

  useEffect(() => {
    setCanOpenConfirmingModal(canOpenConfirmingModal);
  }, [setCanOpenConfirmingModal, canOpenConfirmingModal]);

  const isStopOrder = orderOption === OrderOption.Stop;
  const showFromAndToSection = !isStopOrder;
  const showTriggerPriceSection = !isMarketOrder && !isStopOrder;

  let feeBps;
  let positionFeeUsd;

  if (toUsdMax) {
    positionFeeUsd = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdpSupply
    );
    feeBps = feeBasisPoints;
  }

  const leverageMarks = leverageMarksMap[maxLeverage] || leverageMarksMap["50"];

  if (!fromToken || !toToken) {
    return null;
  }

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromBalance) {
      return;
    }

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(BigNumber.from(DUST_BNB).mul(2)) : fromBalance;
    setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals));
    setAnchorOnFromAmount(true);
  }

  function shouldShowMaxButton() {
    if (!fromToken || !fromBalance) {
      return false;
    }
    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(BigNumber.from(DUST_BNB).mul(2)) : fromBalance;
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  }
  const ERROR_TOOLTIP_MSG = {
    [ErrorCode.PoolExceeded]: t`GLP doesn't accept this amount of ${fromTokenSymbol}.`,
  };

  const SWAP_LABELS = {
    [LONG]: t`Long`,
    [SHORT]: t`Short`,
  };

  function renderPrimaryButton() {
    const [errorMessage, errorType, errorCode] = getExchangeError();
    const primaryTextMessage = getPrimaryText();
    if (
      errorType === ErrorDisplayType.Tooltip &&
      errorMessage === primaryTextMessage &&
      errorCode &&
      ERROR_TOOLTIP_MSG[errorCode]
    ) {
      return (
        <Tooltip renderContent={() => ERROR_TOOLTIP_MSG[errorCode]}>
          <Button
            variant={isLong ? "green" : "red"}
            className="w-100"
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {primaryTextMessage}
          </Button>
        </Tooltip>
      );
    }
    return (
      <>
        {!active ? (
          <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={true}>
            Visit perp.foxify.trade to trade
          </Button>
        ) : (
          <Button variant="primary-action" className="w-100" onClick={onClickPrimary} disabled={true}>
            Visit perp.foxify.trade to trade
          </Button>
        )}
      </>
    );
  }

  const totalFees = (currentExecutionFeeUsd ?? BigNumber.from(0)).add(positionFeeUsd ?? BigNumber.from(0));

  return (
    <div className={cx("Exchange-swap-box tailwind")}>
      <div>
        <ExchangeTab
          icons={SWAP_ICONS}
          options={SWAP_OPTIONS}
          optionLabels={SWAP_LABELS}
          option={swapOption}
          onChange={onSwapOptionChange}
          className="Exchange-swap-option-tabs"
        />
      </div>
      <div className="Exchange-swap-box-inner">
        <div>
          {flagOrdersEnabled && (
            <Tab
              options={orderOptions}
              optionLabels={orderOptionLabels}
              className="Exchange-swap-order-type-tabs transition-effect"
              type="order"
              option={orderOption}
              onChange={onOrderOptionChange}
            />
          )}
        </div>
        {showFromAndToSection && (
          <React.Fragment>
            <div
              className={cx("Exchange-swap-section transition-effect", {
                inputActive: inputActive === "usdt-swapbox",
              })}
            >
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {fromUsdMin && (
                    <div className="Exchange-swap-usd">
                      <Trans>Pay: {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD</Trans>
                    </div>
                  )}
                  {!fromUsdMin && t`Pay`}
                </div>
                {fromBalance && (
                  <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                    <Trans>Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}</Trans>
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={onFromValueChange}
                    onFocus={() => setInputActive("usdt-swapbox")}
                    onBlur={() => setInputActive("")}
                  />
                  {shouldShowMaxButton() && (
                    <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                      <Trans>Max</Trans>
                    </div>
                  )}
                </div>
                <TokenSelector
                  label={t`Pay`}
                  chainId={chainId}
                  tokenAddress={fromTokenAddress}
                  onSelectToken={onSelectFromToken}
                  tokens={fromTokens}
                  infoTokens={infoTokens}
                  showTokenImgInDropdown={true}
                />
              </div>
            </div>
            <div
              className={cx("Exchange-swap-section", {
                inputActive: inputActive === "currency-swapbox",
              })}
            >
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {toUsdMax && (
                    <div className="Exchange-swap-usd">
                      {getToLabel()}: {formatAmount(toUsdMax, USD_DECIMALS, 2, true)} USD
                    </div>
                  )}
                  {!toUsdMax && getToLabel()}
                </div>
                {(isLong || isShort) && hasLeverageOption && (
                  <div className="muted align-right">
                    <Trans>Leverage</Trans>: {parseFloat(leverageOption).toFixed(2)}x
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div>
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={toValue}
                    onChange={onToValueChange}
                    onFocus={() => setInputActive("currency-swapbox")}
                    onBlur={() => setInputActive("")}
                  />
                </div>
                <TokenSelector
                  label={getTokenLabel()}
                  chainId={chainId}
                  tokenAddress={toTokenAddress}
                  onSelectToken={onSelectToToken}
                  tokens={toTokens}
                  infoTokens={infoTokens}
                  showTokenImgInDropdown={true}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {showTriggerPriceSection && (
          <div
            className={cx("Exchange-swap-section", {
              inputActive: inputActive === "markprice-swapbox",
            })}
          >
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <Trans>Price</Trans>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => {
                  setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2));
                }}
              >
                <Trans>Mark: {formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div className="Exchange-swap-input-container">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={triggerPriceValue}
                  onChange={onTriggerPriceChange}
                  onFocus={() => setInputActive("markprice-swapbox")}
                  onBlur={() => setInputActive("")}
                />
              </div>
              <div className="PositionEditor-token-symbol">USD</div>
            </div>
          </div>
        )}
        {(isLong || isShort) && !isStopOrder && (
          <div className="Exchange-leverage-box">
            <div className="Exchange-leverage-slider-settings">
              <div className="muted">Leverage slider</div>

              <div className="Indicator">
                <input
                  className="Indicator-input"
                  min={minLeverage}
                  max={maxLeverage}
                  value={leverageOption}
                  onChange={handleLeverageInput}
                  onBlur={handleLeverageBlur}
                />
                <span>x</span>
              </div>
            </div>
            <div
              className={cx("Exchange-leverage-slider", "App-slider", {
                positive: isLong,
                negative: isShort,
              })}
            >
              <Slider
                min={MIN_LEVERAGE}
                max={Number(maxLeverage)}
                step={0.1}
                marks={leverageMarks}
                handle={leverageSliderHandle}
                onChange={(value) => setLeverageOption(value.toFixed(2))}
                value={parseFloat(leverageOption)}
              />
            </div>

            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Leverage</Trans>
              </div>
              <div className="align-right gap-[0.5rem]">
                {hasExistingPosition && toAmount && toAmount.gt(0) && (
                  <div className="flex gap-[0.5rem] muted items-center">
                    {formatAmount(existingPosition.leverage, 4, 2)}x
                    <BsArrowRight className="transition-arrow" />
                  </div>
                )}
                {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                {!toAmount && leverage && leverage.gt(0) && `-`}
                {leverage && leverage.eq(0) && `-`}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Entry Price</Trans>
              </div>
              <div className="align-right gap-[0.5rem]">
                {hasExistingPosition && toAmount && toAmount.gt(0) && (
                  <div className="flex gap-[0.5rem] muted items-center">
                    ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                    <BsArrowRight className="transition-arrow" />
                  </div>
                )}
                {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, toToken?.priceDecimals, true)}`}
                {!nextAveragePrice && `-`}
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Liq. Price</Trans>
              </div>
              <div className="align-right gap-[0.5rem]">
                {hasExistingPosition && toAmount && toAmount.gt(0) && (
                  <div className="flex gap-[0.5rem] muted items-center">
                    ${formatAmount(existingLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                    <BsArrowRight className="transition-arrow" />
                  </div>
                )}
                {toAmount &&
                  displayLiquidationPrice &&
                  `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, toToken?.priceDecimals, true)}`}
                {!toAmount && displayLiquidationPrice && `-`}
                {!displayLiquidationPrice && `-`}
              </div>
            </div>
            <ExchangeInfoRow label={t`Fees`}>
              <div>
                {totalFees.eq(0) && "-"}
                {totalFees.gt(0) && (
                  <FeesTooltip
                    totalFees={totalFees}
                    executionFees={{
                      fee: currentExecutionFee,
                      feeUSD: currentExecutionFeeUsd,
                    }}
                    positionFee={positionFeeUsd}
                  />
                )}
              </div>
            </ExchangeInfoRow>
          </div>
        )}
        {isStopOrder && (
          <div className="Exchange-swap-section Exchange-trigger-order-info">
            <div className="Exchange-trigger-order-info-tooltip">
              <HiInformationCircle />
            </div>
            <div>
              <Trans>
                Currently, take-profit and stop-loss orders can be set after the position is opened. <br /> <br />
                There will be a "ADD TP/SL" button on each position row, clicking this will display the option to set
                Take-profit and Stop-loss orders. <br /> <br />
                We are working to enable take-profit and stop-loss without opening a position beforehand. <br /> <br />
                For screenshots and more information, please see the{" "}
                <ExternalLink href="https://docs.palmswap.org/protocol/exchange/use-orders">docs</ExternalLink>.
              </Trans>
            </div>
          </div>
        )}
        <div className="Exchange-swap-button-container">{renderPrimaryButton()}</div>
      </div>

      {(isLong || isShort) && !isMobile && (
        <div className="Exchange-swap-market-box relative">
          <div className="Exchange-swap-market-box-title">
            {isLong ? t`Long` : t`Short`}&nbsp;{toTokenSymbol}
          </div>

          <div className="Swap-info-collapsible">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Entry Price</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  renderContent={() => {
                    return (
                      <div>
                        <Trans>
                          The position will be opened at{" "}
                          {formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)} USD with a max
                          slippage of {(savedSlippageAmount / 100.0).toFixed(2)}%.
                          <br />
                          <br />
                          The slippage amount can be configured under Settings, found by clicking on your address at the
                          top right of the page after connecting your wallet.
                          <br />
                          <br />
                          <ExternalLink href="https://docs.palmswap.org/">More Info</ExternalLink>
                        </Trans>
                      </div>
                    );
                  }}
                >
                  <span className="underline">
                    ${formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Exit Price</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  renderContent={() => {
                    return (
                      <div>
                        <Trans>
                          If you have an existing position, the position will be closed at{" "}
                          {formatAmount(entryMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)} USD.
                          <br />
                          <br />
                          This exit price will change with the price of the asset.
                          <br />
                          <br />
                          <ExternalLink href="https://docs.palmswap.org/">More Info</ExternalLink>
                        </Trans>
                      </div>
                    );
                  }}
                >
                  <span className="underline">
                    ${formatAmount(exitMarkPrice, USD_DECIMALS, toToken?.priceDecimals, true)}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label underline">
                <Trans>Available Liquidity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  renderContent={() => (
                    <>
                      <StatsTooltipRow
                        label={isLong ? t`Max long capacity` : t`Max short capacity`}
                        value={formatAmount(
                          isLong ? toTokenInfo.maxGlobalLongSize : toTokenInfo.maxGlobalShortSize,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      />
                      <StatsTooltipRow
                        label={isLong ? t`Current long` : t`Current shorts`}
                        value={formatAmount(
                          isLong ? toTokenInfo.globalLongSize : toTokenInfo.globalShortSize,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      />
                    </>
                  )}
                >
                  <span className={`underline`}>
                    $
                    {formatAmount(
                      isLong ? toTokenInfo.maxAvailableLong : toTokenInfo.maxAvailableShort,
                      USD_DECIMALS,
                      2,
                      true
                    )}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isConfirming && (
        <ConfirmationBox
          library={library}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          orders={orders}
          isLong={isLong}
          isMarketOrder={isMarketOrder}
          orderOption={orderOption}
          isShort={isShort}
          fromToken={fromToken}
          fromTokenInfo={fromTokenInfo}
          toToken={toToken}
          toTokenInfo={toTokenInfo}
          toAmount={toAmount}
          fromAmount={fromAmount}
          feeBps={feeBps}
          onConfirmationClick={onConfirmationClick}
          setIsConfirming={setIsConfirming}
          hasExistingPosition={hasExistingPosition}
          shortCollateralAddress={collateralTokenAddress}
          shortCollateralToken={shortCollateralToken}
          leverage={leverage}
          existingPosition={existingPosition}
          existingLiquidationPrice={existingLiquidationPrice}
          displayLiquidationPrice={displayLiquidationPrice}
          nextAveragePrice={nextAveragePrice}
          triggerPriceUsd={triggerPriceUsd}
          triggerRatio={triggerRatio}
          positionFeeUsd={positionFeeUsd}
          isSubmitting={isSubmitting}
          isPendingConfirmation={isPendingConfirmation}
          fromUsdMin={fromUsdMin}
          toUsdMax={toUsdMax}
          infoTokens={infoTokens}
          chainId={chainId}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          entryMarkPrice={entryMarkPrice}
        />
      )}
    </div>
  );
}
