import React, { useState, useEffect, useMemo, useCallback } from "react";
import { get, keys } from "lodash";
import "./History.css";
import Select from "react-select";
import PaginationRightIcon from "img/PaginationRight.svg";
import PaginationLeftIcon from "img/PaginationLeft.svg";
import cx from "classnames";
import { useClosedPositions } from "components/Exchange/hooks/useClosedPositions";
import { useTradeHistory } from "components/Exchange/hooks/useTradeHistory";
import { ReactComponent as DownloadCSVIcon } from "img/DownloadCSV.svg";
import ClosedPositionsList from "components/Exchange/ClosedPositionsList";
import TradeHistory from "components/Exchange/TradeHistory";
import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import { ReactComponent as Filtericon } from "img/Filtericon.svg";
import { CSVLink } from "react-csv";
import { getTokenInfo } from "domain/tokens";
import { getAddress } from "ethers/lib/utils";
import { formatAmount } from "lib/numbers";
import { USD_DECIMALS } from "lib/legacy";
import { fromUnixTime, format } from "date-fns";
import { TokenUtils } from "components/TokenUtils";

const History = ({ account, infoTokens, connectWallet, whitelistedTokens }) => {
  const [selectedToken, setSelectedToken] = useState("All");
  const [selectedIsLong, setSelectedIsLong] = useState("All");
  const [activeTab, setActiveTab] = useState("Closed Positions");
  const [currentPage, setCurrentPage] = useState(1);
  const closedPositionsPerPage = 10;
  const tradeHistoryPerPage = 10;

  const ClosedPositions = useClosedPositions(account);
  const tradeHistory = useTradeHistory(account);

  const [isFilterVisible, setFilterVisible] = useState(false);
  const [firstFilterActive, setFirstFilterActive] = useState(0);
  const [secondFilterActive, setSecondFilterActive] = useState(0);
  const [filterCount, setFilterCount] = useState(0);

  const isActive = (tab) => {
    return activeTab === tab;
  };

  const tabsOptions = useMemo(() => ["Closed Positions", "Trade Activity"], []);

  useEffect(() => {
    setFilterCount(firstFilterActive + secondFilterActive);
  }, [selectedToken, selectedIsLong, firstFilterActive, secondFilterActive]);

  useEffect(() => {
    setSelectedToken("All");
    setSelectedIsLong("All");
  }, [activeTab]);

  const filteredClosedPositions = useMemo(() => {
    return ClosedPositions.filter((position) => {
      if (selectedToken !== "All" && position.token !== selectedToken) {
        return false;
      }
      if (selectedIsLong !== "All" && position.isLong !== (selectedIsLong === "true")) {
        return false;
      }
      return true;
    });
  }, [ClosedPositions, selectedIsLong, selectedToken]);

  const filteredTradeHistory = useMemo(() => {
    return tradeHistory.filter((trade) => {
      const tradeToken = trade.token.toLowerCase();
      const selectedTokenLower = selectedToken.toLowerCase();

      if (selectedTokenLower !== "all" && tradeToken !== selectedTokenLower) {
        return false;
      }

      if (selectedIsLong !== "All" && trade.isLong !== (selectedIsLong === "true")) {
        return false;
      }

      return true;
    });
  }, [selectedIsLong, selectedToken, tradeHistory]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedToken, selectedIsLong]);

  const totalClosedPositions = filteredClosedPositions.length;
  const totalTradeHistory = filteredTradeHistory.length;

  const totalClosedPositionPages = useMemo(() => {
    if (totalClosedPositions > 0) {
      return Math.ceil(totalClosedPositions / closedPositionsPerPage);
    } else {
      return 1;
    }
  }, [totalClosedPositions]);

  const totalTradeHistoryPages = useMemo(() => {
    if (totalTradeHistory > 0) {
      return Math.ceil(totalTradeHistory / tradeHistoryPerPage);
    } else {
      return 1;
    }
  }, [totalTradeHistory]);

  const handleTokenChange = (selectedOption) => {
    if (selectedOption.value === "All") {
      setFirstFilterActive(0);
    } else {
      setFirstFilterActive(1);
    }
    setSelectedToken(selectedOption?.value || "All");
  };

  const handleIsLongChange = (selectedOption) => {
    if (selectedOption.value === "All") {
      setSecondFilterActive(0);
    } else {
      setSecondFilterActive((prevCount) => prevCount + 1);
    }
    setSelectedIsLong(selectedOption?.value || "All");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastClosedPosition = currentPage * closedPositionsPerPage;
  const indexOfFirstClosedPosition = indexOfLastClosedPosition - closedPositionsPerPage;
  const closedPositionsForCurrentPage = filteredClosedPositions.slice(
    indexOfFirstClosedPosition,
    indexOfLastClosedPosition
  );

  const indexOfLastTradeHistory = currentPage * tradeHistoryPerPage;
  const indexOfFirstTradeHistory = indexOfLastTradeHistory - tradeHistoryPerPage;
  const tradeHistoryForCurrentPage = filteredTradeHistory.slice(indexOfFirstTradeHistory, indexOfLastTradeHistory);

  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);

  const options = [
    { value: "All", label: "All" },
    ...indexTokens.map((token, index) => ({
      value: token.address,
      label: token.name,
    })),
  ];

  const isLongOptions = [
    { value: "All", label: "All" },
    { value: "true", label: "Long" },
    { value: "false", label: "Short" },
  ];

  const pageOptionsClosedPosition = Array.from({ length: totalClosedPositionPages }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const pageOptionsTradeHistory = Array.from({ length: totalTradeHistoryPages }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const handlePageSelect = (selectedOption) => {
    setCurrentPage(selectedOption?.value || 1);
  };

  const prepareCSVData = useCallback(
    (data) => {
      return data.map((item) => {
        const indexToken = getTokenInfo(infoTokens, getAddress(item.token));
        const side = item.isLong ? "Long" : "Short";
        const size = formatAmount(item.size, USD_DECIMALS, 4, true);
        if (activeTab === tabsOptions[0]) {
          const exitPrice = formatAmount(item.exitPrice, USD_DECIMALS, 2, true);
          const entryPrice = formatAmount(item.entryPrice, USD_DECIMALS, 2, true);
          const PnL = formatAmount(item.realisedPnl, USD_DECIMALS, 2, true);

          return {
            Position: TokenUtils.getSymbol(indexToken),
            Side: side,
            Size: size,
            "Closing Price": exitPrice,
            "Entry Price": entryPrice,
            PnL: PnL,
          };
        } else if (activeTab === tabsOptions[1]) {
          const action = item.action;
          const type = item.type;
          const price = formatAmount(item.price, USD_DECIMALS, 2, true);
          const date = format(fromUnixTime(item.timestamp), "PPpp");

          return {
            Position: TokenUtils.getSymbol(indexToken),
            Side: side,
            Action: action,
            Type: type,
            Price: price,
            Size: size,
            Date: date,
          };
        }
        return {};
      });
    },
    [activeTab, infoTokens, tabsOptions]
  );

  const fileName = useMemo(() => {
    return activeTab === tabsOptions[0] ? "Palmswap_ClosedPositions" : "Palmswap_TradeHistory";
  }, [activeTab, tabsOptions]);

  const csvData = useMemo(() => {
    let dataToDownload = [];
    if (activeTab === tabsOptions[0]) {
      dataToDownload = prepareCSVData(ClosedPositions);
    } else if (activeTab === tabsOptions[1]) {
      dataToDownload = prepareCSVData(tradeHistory);
    }
    return dataToDownload;
  }, [ClosedPositions, tradeHistory, tabsOptions, activeTab, prepareCSVData]);

  return (
    <div className="default-container tailwind w-full pt-[5rem] flex flex-col pb-[10rem] gap-[2rem] h-full overflow-y-auto">
      <div className="w-full flex justify-center">
        <div className="border rounded-sm dark:bg-white/[0.05] bg-black/[0.05] p-[0.5rem] flex items-center gap-[0.5rem] text-textColor w-fit mb-[2rem]">
          {tabsOptions.map((opt) => (
            <div
              className={cx("rounded-sm w-[13rem] h-[4rem] flex items-center justify-center cursor-pointer", {
                "bg-white text-black cursor-unset": isActive(opt),
              })}
              onClick={() => setActiveTab(opt)}
              key={opt}
            >
              {opt}
            </div>
          ))}
        </div>
      </div>

      {activeTab === tabsOptions[0] && (
        <div className="flex justify-between">
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2.4rem] text-textColor">Closed Positions</span>
            <span className="muted">View your open Closed Positions.</span>
          </div>
        </div>
      )}

      {activeTab === tabsOptions[1] && (
        <div className="flex justify-between">
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-[2.4rem] text-textColor">Trade Activity</span>
            <span className="muted">View your Trade Activity.</span>
          </div>
        </div>
      )}

      <div className="flex flex-row justify-between">
        <div className="hidden md:flex flex-row gap-[1rem]">
          <Select
            value={options.find((option) => option.value === selectedToken)}
            onChange={handleTokenChange}
            options={options}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "16.3rem",
                height: "3.5rem",
                borderRadius: "var(--border-radius-sm)",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-border)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColor",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",

                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          />
          <Select
            value={isLongOptions.find((option) => option.value === selectedIsLong)}
            onChange={handleIsLongChange}
            options={isLongOptions}
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "16.3rem",
                height: "3.5rem",
                borderRadius: "var(--border-radius-sm)",
                backgroundColor: "var(--pallete-background-3)",
                border: "1px solid var(--pallete-border)",
                color: "var(--pallete-border)",
                boxShadow: "none",
              }),

              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-3)",
                color: "textColors",
                border: "1px solid var(--pallete-border)",
              }),
              option: (provided) => ({
                ...provided,
                backgroundColor: "var(--pallete-background-2)",

                color: "textColor",
                cursor: "pointer",
                "&:active": {
                  backgroundColor: "var(--pallete-background-3)",
                  outline: "none",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "var(--pallete-inactive)",
              }),
            }}
          />
        </div>

        <Modal
          className="Connecting-wallet-modal"
          isVisible={isFilterVisible}
          setIsVisible={setFilterVisible}
          label={t`Filters`}
        >
          <div className="flex flex-col gap-[1rem] min-h-[25rem]">
            <span className="muted">Market</span>
            <Select
              value={options.find((option) => option.value === selectedToken)}
              onChange={handleTokenChange}
              options={options}
              components={{ IndicatorSeparator: () => null }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                  height: "3.5rem",
                  borderRadius: "var(--border-radius-sm)",
                  backgroundColor: "var(--pallete-background-3)",
                  border: "1px solid var(--pallete-border)",
                  color: "var(--pallete-border)",
                  boxShadow: "none",
                }),

                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-3)",
                  color: "textColor",
                  border: "1px solid var(--pallete-border)",
                }),
                option: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-2)",

                  color: "textColor",
                  cursor: "pointer",
                  "&:active": {
                    backgroundColor: "var(--pallete-background-3)",
                    outline: "none",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "var(--pallete-inactive)",
                }),
              }}
            />
            <span className="muted">Side</span>
            <Select
              value={isLongOptions.find((option) => option.value === selectedIsLong)}
              onChange={handleIsLongChange}
              options={isLongOptions}
              components={{ IndicatorSeparator: () => null }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                  height: "3.5rem",
                  borderRadius: "var(--border-radius-sm)",
                  backgroundColor: "var(--pallete-background-3)",
                  border: "1px solid var(--pallete-border)",
                  color: "var(--pallete-border)",
                  boxShadow: "none",
                }),

                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-3)",
                  color: "textColor",
                  border: "1px solid var(--pallete-border)",
                }),
                option: (provided) => ({
                  ...provided,
                  backgroundColor: "var(--pallete-background-2)",
                  color: "textColor",
                  cursor: "pointer",
                  "&:active": {
                    backgroundColor: "var(--pallete-background-3)",
                    outline: "none",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "var(--pallete-inactive)",
                }),
              }}
            />
          </div>
        </Modal>

        <div className="flex flex-row w-full md:w-[14rem] gap-[1rem] ">
          <div className="flex md:hidden w-full">
            <button
              className="w-full bg-background-3 h-[4rem] rounded-full flex flex-row items-center px-[2rem] justify-between"
              onClick={() => {
                setFilterVisible(true);
              }}
            >
              <span className="muted">{filterCount} Filtes Applied</span>
              <Filtericon className="fill-inactive h-[1.4rem]" />
            </button>
          </div>

          <CSVLink
            data={csvData}
            headers={keys(get(csvData, "0"))}
            filename={fileName}
            className="flex flex-row w-[14rem] text-[1.2rem] h-[3.5rem] items-center justify-center px-[1rem] gap-[1rem] whitespace-nowrap rounded-full border-none  dark:bg-white bg-black hover:dark:bg-[#dedede] hover:bg-[#212121] dark:text-black text-white transition-effect"
          >
            <DownloadCSVIcon className="dark:fill-black fill-white h-[1.4rem]" />
            <span> Download CSV</span>
          </CSVLink>
        </div>
      </div>

      {activeTab === tabsOptions[0] && (
        <>
          <ClosedPositionsList
            account={account}
            infoTokens={infoTokens}
            connectWallet={connectWallet}
            positions={closedPositionsForCurrentPage}
            className="ClosedPositionList"
          />
          <div className="w-full flex flex-row items-center justify-between">
            <span className="muted">Total: {totalClosedPositionPages} pages</span>

            <div className="flex gap-[1rem]">
              <div className="flex items-center gap-[1rem]">
                <span className="muted hidden md:flex">The page you are on</span>
                <Select
                  value={pageOptionsClosedPosition.find((option) => option.value === currentPage)}
                  onChange={handlePageSelect}
                  options={pageOptionsClosedPosition}
                  components={{ IndicatorSeparator: () => null }}
                  className="hidden md:flex"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "7rem",
                      height: "2.5rem",
                      backgroundColor: "var(--pallete-background-1)",
                      border: "1px solid var(--pallete-border)",
                      borderRadius: "var(--border-radius-sm)",
                      color: "var(--pallete-border)",
                      boxShadow: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                      backgroundColor: "var(--pallete-background-1)",
                      cursor: "pointer",
                      color: "textColor",

                      "&:active": {
                        backgroundColor: "var(--pallete-background-1)",
                        outline: "none",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "var(--pallete-background-1)",
                      color: "textColor",
                      border: "1px solid var(--pallete-border)",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "var(--pallete-inactive)",
                    }),
                  }}
                />
              </div>

              <div className="flex items-center gap-[1rem]">
                <button
                  className="bg-transparent border-none"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <img src={PaginationLeftIcon} alt="pagination" />
                </button>
                <span>{currentPage}</span>
                <button
                  className="bg-transparent border-none"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalClosedPositionPages}
                >
                  <img src={PaginationRightIcon} alt="pagination" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {activeTab === tabsOptions[1] && (
        <>
          <TradeHistory
            account={account}
            infoTokens={infoTokens}
            connectWallet={connectWallet}
            tradeHistory={tradeHistoryForCurrentPage}
            className="TradeHistoryList"
          />
          <div className="w-full flex flex-row items-center justify-between">
            <span className="muted">Total: {totalTradeHistoryPages} pages</span>
            <div className="flex gap-[1rem]">
              <div className="flex items-center gap-[1rem]">
                <span className="muted hidden md:flex">The page you are on</span>
                <Select
                  value={pageOptionsTradeHistory.find((option) => option.value === currentPage)}
                  onChange={handlePageSelect}
                  className="hidden md:flex"
                  options={pageOptionsTradeHistory}
                  components={{ IndicatorSeparator: () => null }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "7rem",
                      height: "2.5rem",
                      backgroundColor: "var(--pallete-background-1)",
                      border: "1px solid var(--pallete-border)",
                      borderRadius: "var(--border-radius-sm)",
                      color: "var(--pallete-border)",
                      boxShadow: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "var(--pallete-border)" : "var(--pallete-background-1)",
                      cursor: "pointer",
                      color: "textColor",
                      "&:active": {
                        backgroundColor: "var(--pallete-background-1)",
                        outline: "none",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "var(--pallete-background-1)",
                      color: "textColor",
                      border: "1px solid var(--pallete-border)",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "var(--pallete-inactive)",
                    }),
                  }}
                />
              </div>

              <div className="flex items-center gap-[1rem]">
                <button
                  className="bg-transparent border-none"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <img src={PaginationLeftIcon} alt="pagination" />
                </button>
                <span>{currentPage}</span>
                <button
                  className="bg-transparent border-none"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalTradeHistoryPages}
                >
                  <img src={PaginationRightIcon} alt="pagination" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default History;
