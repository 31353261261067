import { useEvent, useLocalStorage } from "react-use";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export function useLocalStorageByChainId<T>(
  chainId: number,
  key: string,
  defaultValue: T
): [T | undefined, (value: T) => void] {
  const [internalValue, setInternalValue] = useLocalStorage(key, {});

  const setValue = useCallback(
    (value) => {
      setInternalValue((internalValue) => {
        if (typeof value === "function") {
          value = value(internalValue?.[chainId] || defaultValue);
        }

        const newInternalValue = {
          ...internalValue,
          [chainId]: value,
        };
        return newInternalValue;
      });
    },
    [chainId, setInternalValue, defaultValue]
  );

  let value;

  if (internalValue && chainId in internalValue) {
    value = internalValue[chainId];
  } else {
    value = defaultValue;
  }

  return [value, setValue];
}

export function useLocalStorageSerializeKey<T>(
  key: string | any[],
  value: T,
  opts?:
    | {
        raw: true;
      }
    | {
        raw: false;
        serializer: (value: T) => string;
        deserializer: (value: string) => T;
      }
    | undefined
) {
  key = JSON.stringify(key);

  return useLocalStorage<T>(key, value, opts);
}

/**
 * By default, useLocalStorage doesn't propagate the changes across multiple components that refer to the same key.
 * This method allows listening to the changes in the local storage.
 * @param key
 * @param initialValue
 * @param options
 */
export function useLocalStorageReactive<T>(
  key: string,
  initialValue?: T | undefined,
  options?:
    | {
        raw: true;
      }
    | {
        raw: false;
        serializer: (value: T) => string;
        deserializer: (value: string) => T;
      }
    | undefined
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const [value, set, remove] = useLocalStorage<T>(key, initialValue, options);

  const [reactiveValue, setReactiveValue] = useState(value);
  const eventKey = `${key}-event`;

  useEvent(eventKey, (e: CustomEvent) => {
    setReactiveValue(e.detail);
  });

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(eventKey, {
        detail: value,
      })
    );
  }, [value, eventKey]);

  return [reactiveValue, set, remove];
}
