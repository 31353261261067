import React, { useEffect, useRef } from "react";
import { Time, createChart } from "lightweight-charts";
import { t } from "@lingui/macro";
import { useElementSize } from "lib/useElementSize";

const LightweightChart = (
  {
    historicalData,
    isError,
    isLoading,
    setHoveredPrice,
    lastPrice,
  }: {
    historicalData: {
      time: number,
      value: number,
    }[],
    isError: boolean,
    isLoading: boolean,
    setHoveredPrice?: (price: number | undefined) => any,
    lastPrice: number,
  }
) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartContainerRef)

  useEffect(() => {
    if (!chartContainerRef.current) {
      return
    }
    if (!size) {
      return
    }
    const chart = createChart(chartContainerRef.current, {
      width: 0,
      height: 279.5,
      rightPriceScale: {
        visible: false,
        scaleMargins: {
          top: 0.1,
          bottom: 0.2,
        },
      },
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: "center",
        vertAlign: "center",
        color: "rgba(171, 71, 188, 0.5)",
        text: "",
      },
      layout: {
        background: {
          color: "transparent",
        },
        textColor: "#ffffff",
      },
      crosshair: {
        mode: 0,
        vertLine: {
          width: 2,
          color: "#0f1014",
          style: 2,
          labelBackgroundColor: "#7993D0",
        },
        horzLine: {
          color: "#0f1014",
          labelBackgroundColor: "#0f1014",
        },
      },
      grid: {
        horzLines: {
          color: "transparent",
        },
        vertLines: {
          color: "transparent",
        },
      },
      handleScale: false,
      handleScroll: false,
    });

    const areaSeries = chart.addAreaSeries({
      topColor: "rgba(121, 147, 208, 0.56)",
      bottomColor: "rgba(121, 147, 208, 0.04)",
      lineColor: "#7993D0",
      lineWidth: 2,
    });

    if (historicalData.length > 0 && !isLoading && !isError) {
      areaSeries.setData(historicalData as { time: Time, value: number }[]);
      chart.timeScale().fitContent()
    }

    const onCrosshairMove = (param) => {
      let nextPrice: number | undefined = undefined;

      if (param.time && param.seriesData && param.seriesData.size > 0) {
        const seriesDataEntry = param.seriesData.entries().next().value;
        const value = seriesDataEntry[1].value;

        if (value) {
          nextPrice = value;
        } else {
          nextPrice = lastPrice;
        }
      } else {
        nextPrice = lastPrice;
      }

      setHoveredPrice?.(nextPrice)
    };
    chart.subscribeCrosshairMove(onCrosshairMove);

    return () => {
      chart.unsubscribeCrosshairMove(onCrosshairMove);
      chart.remove();
    };
  }, [historicalData, isLoading, isError, lastPrice, setHoveredPrice, size]);

  return (
    <div className="flex gap-10 relative">
      <div ref={chartContainerRef} style={{ width: "100%" }} />

      {
        isLoading && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none text-inactive">
            {t`Loading...`}
          </div>
        )
      }
    </div>
  );
};

export default LightweightChart;
