import { getContractAddress } from "config/contracts";
import useSWR from "swr";
import { useQuickContracts, useQuickMulticall } from "lib/contracts";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDP_AMOUNT,
  MAX_ALLOWED_LEVERAGE,
  MAX_PRICE_DEVIATION_BASIS_POINTS,
  USD_DECIMALS,
} from "lib/legacy";
import { InfoTokens, Token, TokenInfo } from "./types";
import { BigNumber } from "ethers";
import { bigNumberify, expandDecimals } from "lib/numbers";
import { getToken, getTokens, getWhitelistedTokens } from "config/tokens";
import { getSpread, getTokenInfo } from "./utils";
import { useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { VaultReader } from "typechain";
import isEqual from "lodash/isEqual";
import { usePythSreaming } from "../tradingview/pythStreaming";
import { parseUnits } from "ethers/lib/utils";
import { getSymbolUsdId } from "../tradingview/usePythDatafeed";
import useSWRImmutable from "swr/immutable";

function useInfoTokensInternal(
  chainId: number,
  isActive: boolean,
  tokenBalances?: BigNumber[],
  fundingRateInfo?: BigNumber[],
  tokenPrices?: BigNumber[],
  vaultPropsLength?: number
) {
  const tokens = getTokens(chainId);
  const vaultAddress = getContractAddress(chainId, "Vault");
  const positionRouterAddress = getContractAddress(chainId, "PositionRouter");
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const collateralTokenAddress = useCollateralTokenAddress();
  const quickContracts = useQuickContracts();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const { data: vaultTokenInfo } = useSWR(
    ["VaultReader", "getVaultTokenInfo", isActive, chainId],
    () =>
      quickContracts.fetch("VaultReader", "getVaultTokenInfo", [
        vaultAddress,
        positionRouterAddress,
        nativeTokenAddress,
        whitelistedTokenAddresses,
      ]),
    {
      compare: isEqual,
    }
  );

  const { data: vaultMulticallConfig = [] } = useSWRImmutable(
    ["Vault", "allWhitelistedTokens", isActive, chainId],
    async () => {
      const vaultWhitelistedTokens: any[] = [];
      const tokensLength = await quickContracts.fetch("Vault", "allWhitelistedTokensLength", []);
      for (let i = 0; i < tokensLength.toNumber(); i++) {
        const token = { contractName: "Vault", method: "allWhitelistedTokens", params: [i] };
        vaultWhitelistedTokens.push(token);
      }
      return vaultWhitelistedTokens;
    }
  );

  const { data: vaultTokenAddresses = [] } = useQuickMulticall("allWhitelistedTokens", vaultMulticallConfig);

  const infoTokens = getInfoTokens(
    tokens,
    tokenBalances,
    whitelistedTokens,
    vaultTokenInfo,
    fundingRateInfo,
    tokenPrices,
    vaultPropsLength,
    {},
    nativeTokenAddress,
    collateralTokenAddress,
    chainId
  );

  const offChainTokenPrices = vaultTokenAddresses.reduce((acc, token) => {
    const info = getTokenInfo(infoTokens, token);
    if (info && info?.maxPrice) {
      return { ...acc, [token]: info.maxPrice };
    } else {
      return acc;
    }
  }, {});

  return {
    infoTokens,
    usdpAmount: vaultTokenInfo?.usdpAmount ?? BigNumber.from(0),
    maxUsdpAmount: vaultTokenInfo?.maxUsdpAmount ?? BigNumber.from(0),
    offChainTokenPrices,
  };
}

function getInfoTokens(
  tokens: Token[],
  tokenBalances: BigNumber[] | undefined,
  whitelistedTokens: Token[],
  vaultTokenInfo: Awaited<ReturnType<VaultReader["getVaultTokenInfo"]>> | undefined,
  fundingRateInfo: BigNumber[] | undefined,
  tokenPrices: BigNumber[] | undefined,
  vaultPropsLength: number | undefined,
  indexPrices: { [address: string]: BigNumber },
  nativeTokenAddress: string,
  collateralTokenAddress: string,
  chainId: number
): InfoTokens {
  if (!vaultPropsLength) {
    vaultPropsLength = 6;
  }
  const infoTokens: InfoTokens = {};
  const collateralToken = getToken(chainId, collateralTokenAddress);

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i] as TokenInfo;

    if (tokenBalances) {
      token.balance = tokenBalances.length === 1 ? tokenBalances[0] : tokenBalances[i];
    }

    infoTokens[token.address] = token;
  }

  for (let i = 0; i < whitelistedTokens.length; i++) {
    const token = whitelistedTokens[i] as TokenInfo;
    if (vaultTokenInfo && tokenPrices && vaultTokenInfo.amounts) {
      const amounts = vaultTokenInfo.amounts as BigNumber[];

      token.poolAmount = vaultTokenInfo.poolAmount as BigNumber;
      token.reservedAmount = amounts[i * vaultPropsLength].add(amounts[i * vaultPropsLength + 1]);
      token.availableAmount = token.poolAmount;
      token.usdpAmount = vaultTokenInfo.usdpAmount as BigNumber;
      token.bufferAmount = BigNumber.from(0);
      token.maxUsdpAmount = vaultTokenInfo.maxUsdpAmount as BigNumber;
      token.globalShortSize = amounts[i * vaultPropsLength + 2];
      token.globalLongSize = amounts[i * vaultPropsLength + 3];
      token.maxGlobalShortSize = amounts[i * vaultPropsLength + 4];
      token.maxGlobalLongSize = amounts[i * vaultPropsLength + 5];
      token.minPrice = tokenPrices[i];
      token.maxPrice = tokenPrices[i];
      token.spread = getSpread({
        minPrice: token.minPrice,
        maxPrice: token.maxPrice,
      });
      token.guaranteedUsd = BigNumber.from(0);
      token.maxPrimaryPrice = token.maxPrice;
      token.minPrimaryPrice = token.minPrice;

      // save minPrice and maxPrice as setTokenUsingIndexPrices may override it
      token.contractMinPrice = token.minPrice;
      token.contractMaxPrice = token.maxPrice;

      if (!token.maxLeverage) {
        token.maxLeverage = BigNumber.from(MAX_ALLOWED_LEVERAGE);
      }

      if (token.maxUsdpAmount.eq(0)) {
        token.maxUsdpAmount = DEFAULT_MAX_USDP_AMOUNT;
      }

      token.availableUsd = token.isStable
        ? token.poolAmount.mul(expandDecimals(1, USD_DECIMALS)).div(expandDecimals(1, collateralToken.decimals))
        : token.availableAmount.mul(expandDecimals(1, USD_DECIMALS)).div(expandDecimals(1, collateralToken.decimals));

      token.managedUsd = token.availableUsd.add(token.guaranteedUsd);
      token.managedAmount = token.minPrice.eq(0)
        ? BigNumber.from(0)
        : token.managedUsd.mul(expandDecimals(1, token.decimals)).div(token.minPrice);

      token.maxAvailableLong = (() => {
        if (!token.maxGlobalLongSize || !token.globalLongSize) {
          return BigNumber.from(0);
        }

        return token.maxGlobalLongSize.gt(token.globalLongSize)
          ? token.maxGlobalLongSize.sub(token.globalLongSize)
          : BigNumber.from(0);
      })();
      token.hasMaxAvailableLong = token.maxAvailableLong.eq(token.maxGlobalLongSize ?? BigNumber.from(0));

      token.maxAvailableShort = (() => {
        if (!token.maxGlobalShortSize || !token.globalShortSize) {
          return BigNumber.from(0);
        }

        return token.maxGlobalShortSize.gt(token.globalShortSize)
          ? token.maxGlobalShortSize.sub(token.globalShortSize)
          : BigNumber.from(0);
      })();
      token.hasMaxAvailableShort = token.maxAvailableShort.eq(token.maxGlobalShortSize ?? BigNumber.from(0));

      setTokenUsingIndexPrices(token, indexPrices, nativeTokenAddress);
    }

    if (fundingRateInfo) {
      const fundingRatePropsLength = 4;

      token.fundingRateLong = fundingRateInfo[i * fundingRatePropsLength];
      token.cumulativeFundingRateLong = fundingRateInfo[i * fundingRatePropsLength + 2];

      token.fundingRateShort = fundingRateInfo[i * fundingRatePropsLength + 1];
      token.cumulativeFundingRateShort = fundingRateInfo[i * fundingRatePropsLength + 3];
    }

    if (infoTokens[token.address]) {
      token.balance = infoTokens[token.address].balance;
    }

    infoTokens[token.address] = token;
  }

  return infoTokens;
}

function setTokenUsingIndexPrices(
  token: TokenInfo,
  indexPrices: { [address: string]: BigNumber },
  nativeTokenAddress: string
) {
  if (!indexPrices) {
    return;
  }

  const tokenAddress = token.isNative ? nativeTokenAddress : token.address;

  const indexPrice = indexPrices[tokenAddress];

  if (!indexPrice) {
    return;
  }

  const indexPriceBn = bigNumberify(indexPrice)!;

  if (indexPriceBn.eq(0)) {
    return;
  }

  const spread = token.maxPrice!.sub(token.minPrice!);
  const spreadBps = spread.mul(BASIS_POINTS_DIVISOR).div(token.maxPrice!.add(token.minPrice!).div(2));

  if (spreadBps.gt(MAX_PRICE_DEVIATION_BASIS_POINTS - 50)) {
    // only set one of the values as there will be a spread between the index price and the Chainlink price
    if (indexPriceBn.gt(token.minPrimaryPrice!)) {
      token.maxPrice = indexPriceBn;
    } else {
      token.minPrice = indexPriceBn;
    }
    return;
  }

  const halfSpreadBps = spreadBps.div(2).toNumber();
  token.maxPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR + halfSpreadBps).div(BASIS_POINTS_DIVISOR);
  token.minPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR - halfSpreadBps).div(BASIS_POINTS_DIVISOR);
}

export const useInfoTokens = () => {
  const { chainId } = useChainId();
  const { isActive, account = "" } = useWeb3React();
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const vaultAddress = getContractAddress(chainId, "Vault");
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const collateralTokenAddress = useCollateralTokenAddress();
  const quickContracts = useQuickContracts();

  const { data: tokenBalances } = useSWR(
    ["Reader", "getTokenBalances", isActive, chainId, account],
    () => quickContracts.fetch("Reader", "getTokenBalances", [account, [collateralTokenAddress]]),
    {
      compare: isEqual,
      isPaused: () => !isActive,
    }
  );

  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const { data: fundingRateInfo } = useSWR(
    ["Reader", "getFundingRates", isActive, chainId],
    () =>
      quickContracts.fetch("Reader", "getFundingRates", [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
    {
      compare: isEqual,
    }
  );

  const whitelistedTickers = whitelistedTokens.map((t) => getSymbolUsdId(t.baseSymbol || t.symbol));
  const tokenPricesStream = usePythSreaming(whitelistedTickers);
  const tokenPrices = whitelistedTickers.map((id) => {
    const priceItem = tokenPricesStream && tokenPricesStream[id];
    if (!priceItem) {
      return BigNumber.from("0");
    }
    return parseUnits(String(priceItem.price), USD_DECIMALS);
  });

  return useInfoTokensInternal(chainId, isActive, tokenBalances, fundingRateInfo, tokenPrices);
};
