import { BigNumber, BigNumberish } from "ethers";
import { t } from "@lingui/macro";
import { getPalmSubgraph } from "lib/subgraph";
import { useChainId } from "lib/chains";
import { ApolloQueryResult, gql } from "@apollo/client";
import useSWR from "swr";
import { bigNumberify, expandDecimals } from "lib/numbers";

type HistoryItem = {
  id: string;
  token: string;
  isLong: boolean;
  leverage: BigNumberish | null;
  action: string;
  type: string;
  price: BigNumberish;
  size: BigNumberish;
  timestamp: number;
};

type TraderHistoryItem = {
  account: string;
  action: string;
  type: string;
  timestamp: number;
  id: string;
  price: string;
  collateral: string;
  size: string;
  token: string;
  isLong: boolean;
};

type TradeHistoryData = {
  traderHistoryItems: TraderHistoryItem[];
};

const TRADE_TYPES = {
  market: t`Market Order`,
  limit: t`Limit Order`,
  liquidation: t`Liquidation`,
};
const ACTION_TYPES = {
  increase: t`Open Position`,
  decrease: t`Close Position`,
};

const PRECISION_DECIMALS = 4;
const PRECISION = expandDecimals(1, PRECISION_DECIMALS);

export const useTradeHistory = (account: string = "", afterId: string = "0"): HistoryItem[] => {
  const { chainId } = useChainId();

  const fetcher = async (): Promise<HistoryItem[]> => {
    const query = gql`
      query tradeHistoryByUser($account: String!, $afterId: String!) {
        traderHistoryItems(
          first: 1000
          where: { account: $account, id_gt: $afterId }
          orderBy: timestamp
          orderDirection: desc
        ) {
          id
          account
          token
          isLong
          collateral
          action
          type
          price
          size
          timestamp
        }
      }
    `;
    const { data }: ApolloQueryResult<TradeHistoryData> = await getPalmSubgraph(chainId, "stats").query({
      query,
      variables: { account: account.toLowerCase(), afterId },
    });
    return data.traderHistoryItems.map((item) => {
      const size = bigNumberify(item.size) || BigNumber.from(0);
      const collateral = bigNumberify(item.collateral);
      return {
        id: item.id,
        token: item.token,
        isLong: item.isLong,
        leverage: collateral?.gt(0) ? size.mul(PRECISION).div(collateral) : null,
        action: ACTION_TYPES[item.action],
        type: TRADE_TYPES[item.type],
        price: bigNumberify(item.price) || BigNumber.from(0),
        size,
        timestamp: item.timestamp,
      };
    });
  };

  const { data = [] } = useSWR<Awaited<ReturnType<typeof fetcher>>>(
    [`Exchange:tradeHistory:${account}:${afterId}`, chainId, account, afterId],
    fetcher,
    {
      refreshInterval: 10 * 1000,
    }
  );

  return data;
};
