import {
  formatAmount as _formatAmount,
  formatBigAmount as _formatBigAmount,
} from 'lib/formatAmount';

/**
 *  @deprecated import `lib/formatAmount` instead
 */
export const formatAmount = _formatAmount
/**
 *  @deprecated import `lib/formatAmount` instead
 */
export const formatBigAmount = _formatBigAmount
