import React, { useState, useEffect } from "react";
import "./Overview.css";
import cx from "classnames";
import { ReactComponent as ArrowIcon } from "img/Arrow.svg";
import ColleteralIcon from "img/Colleteral.svg";
import TotalTradesIcon from "img/TotalTrades.svg";
import TotalVolumeIcon from "img/TotalVolume.svg";
import PalmToken from "img/PalmToken.svg";
import PlpToken from "img/plpToken.png";
import tetherIcon from "img/tetherIcon.png";
import PositionsList from "components/Exchange/PositionsList";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import Reader from "abis/Reader.json";
import { getContractAddress } from "config/contracts";
import { getPositionQuery } from "../../Exchange/getPositionQuery";
import { getWhitelistedTokens } from "config/tokens";
import { useLocalStorageByChainId } from "lib/localStorage";
import { useInfoTokens } from "domain/tokens";
import { getPositions } from "pages/Exchange/getPositions";
import { formatAmount } from "lib/numbers";
import { USD_DECIMALS, GLP_DECIMALS, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useStakesStats } from "domain/stats/useStakesStats";
import { BigNumber } from "ethers";
import { usePlpBalance } from "pages/Event/hooks/blockchain/usePlpBalance";
import { useTradeHistory } from "components/Exchange/hooks/useTradeHistory";
import { useStakeEarnStats } from "domain/stats/useStakeEarnStats";
import PlpRewardTracker from "abis/PlpRewardTracker.json";
import PalmRewardTracker from "abis/PalmRewardTracker.json";

const Overview = ({
  savedIsPnlInLeverage,
  savedShowPnlAfterFees,
  connectWallet,
  setActivePage,
  setActiveFilter,
  setMarket,
}) => {
  const [updatedPositions] = useState({});

  const [pendingPositions, setPendingPositions] = useState({});
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false);
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false);
  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const POSITIONS = "Positions";
  const ORDERS = "Orders";
  const TRADES = "Trades";

  const flagOrdersEnabled = true;

  const { isActive, account, provider } = useWeb3React();
  const { chainId } = useChainId();

  const readerAddress = getContractAddress(chainId, "Reader");
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const vaultAddress = getContractAddress(chainId, "Vault");
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const { palmStakeStats } = useStakesStats();

  const { userStaked: palmUserStaked } = palmStakeStats;

  const plpBalance = usePlpBalance();

  const { infoTokens, offChainTokenPrices } = useInfoTokens();
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress, offChainTokenPrices);

  const feePalmTrackerAddress = getContractAddress(chainId, "FeePalmTracker");
  const feePlpTrackerAddress = getContractAddress(chainId, "FeePlpTracker");

  const { data: claimablePalm } = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, feePalmTrackerAddress, "claimable", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PalmRewardTracker, [])
  );

  const { data: claimablePlp } = useSWR(
    [`StakeV3:stakingInfo:${isActive}`, chainId, feePlpTrackerAddress, "claimable", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PlpRewardTracker, [])
  );

  const { palmClaimedUser, plpClaimedUser } = useStakeEarnStats();

  const userPalmTotalEarned = palmClaimedUser.add(claimablePalm || 0);
  const userPlpTotalEarned = plpClaimedUser.add(claimablePlp || 0);

  let totalEarned = BigNumber.from(0);
  if (userPalmTotalEarned) {
    totalEarned = totalEarned.add(userPalmTotalEarned);
  }

  if (userPlpTotalEarned) {
    totalEarned = totalEarned.add(userPlpTotalEarned);
  }

  const { data: positionData, error: positionDataError } = useSWR(
    isActive &&
      positionQuery.offChainTokenPrices.filter((p) => !!p).length > 0 && [
        isActive,
        chainId,
        readerAddress,
        "getPositions",
        vaultAddress,
        account,
      ],
    contractFetcher(provider, Reader, [
      positionQuery.indexTokens,
      positionQuery.offChainTokenPrices,
      positionQuery.isLong,
    ])
  );

  const positionsDataIsLoading = isActive && !positionData && !positionDataError;

  const LIST_SECTIONS = [POSITIONS, flagOrdersEnabled && ORDERS, TRADES].filter(Boolean);

  let [, setListSection] = useLocalStorageByChainId(chainId, "List-section-v2", LIST_SECTIONS[0]);

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  const longPositions = positions.filter((position) => position.isLong);
  const shortPositions = positions.filter((position) => !position.isLong);

  const setFilter = (value) => {
    setActiveFilter(value);
    setActivePage("position");
  };

  const totalSizeLong = longPositions
    .map((longPosition) => BigNumber.from(longPosition.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const totalSizeShort = shortPositions
    .map((shortPosition) => BigNumber.from(shortPosition.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const totalTrades = useTradeHistory(account);

  const totalVolume = totalTrades
    .map((trade) => BigNumber.from(trade.size))
    .reduce((accumulator, size) => accumulator.add(size), BigNumber.from(0));

  const colleteralUsage = positions
    .map((position) => BigNumber.from(position.collateralAfterFee))
    .reduce((accumulator, collateralAfterFee) => accumulator.add(collateralAfterFee), BigNumber.from(0));

  const totalNetValue = positions
    .map((position) => BigNumber.from(position.netValue))
    .reduce((accumulator, netValue) => accumulator.add(netValue), BigNumber.from(0));

  const calcCurrentPnL = positions
    .map((position) => parseFloat(position.deltaStr.replace("$", "")))
    .reduce((accumulator, delta) => accumulator + delta, 0);

  const currentPnL = calcCurrentPnL.toFixed(2);

  const [currentPnLPercentage, setCurrentPnLPercentage] = useState(0);

  const totalNetValueNumber = formatAmount(totalNetValue, USD_DECIMALS, 2, true);

  useEffect(() => {
    const totalNetValueNumeric = parseFloat(totalNetValueNumber.replace(/[^0-9.-]+/g, ""));
    const percentage = ((currentPnL / totalNetValueNumeric) * 100).toFixed(2);

    setCurrentPnLPercentage(percentage);
  }, [currentPnL, totalNetValueNumber]);

  return (
    <div className="default-container tailwind w-full pt-[5rem]  flex flex-col pb-[10rem] gap-[2rem]">
      <div className="flex flex-col gap-[0.5rem]">
        <span className="text-textColor text-[2.4rem]">Overview</span>
        <span className="muted">{currentDate}</span>
      </div>
      <div className="flex flex-col md:flex-row gap-[2rem]">
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">Current PnL</span>
            <span className="text-[2.4rem] leading-[1.4em]">${currentPnL} USDT</span>

            {currentPnLPercentage !== 0 && positions.length >= 1 && (
              <div
                className={cx(
                  "w-[9rem] h-[3rem] px-[1rem] flex flex-row items-center rounded-sm justify-between",
                  currentPnL > 0 ? "bg-green/[0.1] text-green" : "bg-red/[0.1] text-red"
                )}
              >
                <ArrowIcon
                  className={cx("h-7", currentPnL > 0 ? "fill-green" : "fill-red", {
                    "transform rotate-90": currentPnL < 0,
                  })}
                />
                <span className="text-[12px]">{`${currentPnLPercentage}%`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">Total Open Longs</span>
            <span className="text-[2.4rem] leading-[1.4em]">${formatAmount(totalSizeLong, USD_DECIMALS, 2, true)}</span>
            <div>
              <span className="cursor-pointer underline-offset-1" onClick={() => setFilter("long")}>
                {longPositions.length === 1
                  ? `${longPositions.length} Position (View)`
                  : `${longPositions.length} Positions (View)`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">Total Open Shorts</span>
            <span className="text-[2.4rem] leading-[1.4em]">
              ${formatAmount(totalSizeShort, USD_DECIMALS, 2, true)}
            </span>
            <div>
              <span className="cursor-pointer underline-offset-1" onClick={() => setFilter("short")}>
                {shortPositions.length === 1
                  ? `${shortPositions.length} Position (View)`
                  : `${shortPositions.length} Positions (View)`}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-[2rem]">
        <div className="flex md:hidden flex-col gap-[0.5rem]">
          <span className="text-textColor text-[2.4rem] ">Stats</span>
        </div>
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">Collateral Usage</span>
            <span className="text-[2.4rem] leading-[1.4em]">
              {formatAmount(colleteralUsage, USD_DECIMALS, 2, true)} USDT
            </span>
          </div>
          <div className="w-[5rem] h-[5rem] flex items-center justify-center bg-main/[0.2] border border-main rounded-full">
            <img src={ColleteralIcon} alt="icon" />
          </div>
        </div>
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">My Total Trades</span>
            <span className="text-[2.4rem] leading-[1.4em]">{totalTrades.length}</span>
          </div>
          <div className="w-[5rem] h-[5rem] flex items-center justify-center bg-main/[0.2] border border-main rounded-full">
            <img src={TotalTradesIcon} alt="icon" />
          </div>
        </div>
        <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
          <div className="flex flex-col gap-[1rem]">
            <span className="muted">My Total Volume</span>
            <span className="text-[2.4rem] leading-[1.4em]">
              {formatAmount(totalVolume, USD_DECIMALS, 2, true)} USDT
            </span>
          </div>
          <div className="w-[5rem] h-[5rem] flex items-center justify-center bg-main/[0.2] border border-main rounded-full">
            <img src={TotalVolumeIcon} alt="icon" />
          </div>
        </div>
      </div>
      <div className="mt-[2rem] gap-[3rem] flex flex-col">
        <div className="flex flex-col gap-[0.5rem]">
          <span className="text-textColor text-[2.4rem]">Earnings</span>
        </div>

        <div className="flex flex-col md:flex-row gap-[2rem]">
          <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">Staked PLP</span>
              <span className="text-[2.4rem] leading-[1.4em]">
                {formatAmount(plpBalance.amount, GLP_DECIMALS, 2, false)} PLP
              </span>
              <span className="muted">Rewards {formatAmount(claimablePlp, GLP_DECIMALS, 4, false)} USDT</span>
            </div>
            <div>
              <img className="h-[5rem] w-auto" src={PlpToken} alt="" />
            </div>
          </div>
          <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">Staked PALM</span>
              <span className="text-[2.4rem] leading-[1.4em]">
                {formatAmount(palmUserStaked, GLP_DECIMALS, 2, false)} PALM
              </span>
              <span className="muted">Rewards {formatAmount(claimablePalm, GLP_DECIMALS, 4, false)} USDT</span>
            </div>
            <div>
              <img className="h-[5rem] w-auto" src={PalmToken} alt="" />
            </div>
          </div>
          <div className="flex h-auto w-full md:w-[36rem] items-center justify-between bg-background-5-v2-only rounded-md p-[2.5rem] border border-border">
            <div className="flex flex-col gap-[1rem]">
              <span className="muted">Total Earnings</span>
              <span className="text-[2.4rem] leading-[1.4em]">
                {formatAmount(totalEarned, GLP_DECIMALS, 4, false)} USDT
              </span>
              <span className="muted">Rewards {formatAmount(totalEarned, GLP_DECIMALS, 4, false)} USDT</span>
            </div>
            <div>
              <img className="h-[5rem] w-auto" src={tetherIcon} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[3rem] gap-[3rem] flex flex-col">
        <div className="flex flex-col gap-[0.5rem]">
          <div className="flex gap-[0.5rem] items-center">
            <span className="text-textColor text-[2.4rem]">Open Positions</span>
            {positions.length > 0 && (
              <div className="h-[2.2rem] min-w-[2.2rem] bg-background-3 rounded-full text-center items-center flex justify-center text-sm muted">
                <span>{positions.length}</span>
              </div>
            )}
          </div>

          <span className="muted">View your open positions</span>
        </div>
        <PositionsList
          positionsDataIsLoading={positionsDataIsLoading}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setListSection={setListSection}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          positions={positions}
          positionsMap={positionsMap}
          infoTokens={infoTokens}
          active={isActive}
          account={account}
          library={provider}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          showPnlAfterFees={savedShowPnlAfterFees}
          connectWallet={connectWallet}
          setMarket={setMarket}
        />
      </div>
    </div>
  );
};

export default Overview;
