import { useChainId } from "lib/chains";
import React, { useCallback } from "react";
import { t } from "@lingui/macro";
import { useEpochesHistoryQuery } from "pages/Event/events/TradeToEarnEvent/hooks/useEpochesHistoryQuery";
import { Epoche } from "pages/Event/events/TradeToEarnEvent/types";
import { BigNumber } from "@ethersproject/bignumber";
import { mutate } from "swr";
import { useWeb3React } from "@web3-react/core";
import { AddressZero } from "@ethersproject/constants";
import { useQuickContracts } from "lib/contracts/useQuickContracts";

export const useEventClaim = () => {
  const [isMutating, setIsMutating] = React.useState(false);
  const epochesHistoryQuery = useEpochesHistoryQuery();
  const { isActive, account } = useWeb3React();
  const usedAccount = account ?? AddressZero;

  const quickContracts = useQuickContracts();

  const { chainId } = useChainId();

  const updateHistory = useCallback(async () => {
    await mutate([`TradeToEarnEvent:epoches-history:${isActive}`, chainId, usedAccount]);
  }, [chainId, isActive, usedAccount]);

  const claim = useCallback(
    async (epoche: Epoche, successCb?: () => void) => {
      setIsMutating(true);

      return quickContracts
        .call("Trade2Earn", "claim", [epoche.index], {
          txnSuccessCallback: async () => {
            await updateHistory();
            setIsMutating(false);
            successCb && successCb();
          },
        })
        .catch(() => {
          setIsMutating(false);
        });
    },
    [quickContracts, updateHistory]
  );

  const claimAll = useCallback(
    async (successCb?: () => void) => {
      setIsMutating(true);

      try {
        const notClaimedIndexes = (epochesHistoryQuery.data?.endedEpoches ?? [])
          .filter((el) => el.rewardsClaimablePalm.gt(0))
          .map((el) => BigNumber.from(el.index));

        if (notClaimedIndexes.length === 0) {
          throw new Error("No indexes to claim");
        }

        return await quickContracts.call("Trade2Earn", "batchClaim", [notClaimedIndexes], {
          sentMsg: t`Claim all epoches submitted.`,
          failMsg: t`Claim all epoches failed.`,
          txnSuccessCallback: async () => {
            await updateHistory();
            setIsMutating(false);
            successCb && successCb();
          },
        });
      } catch (error) {
        setIsMutating(false);
      }
    },
    [epochesHistoryQuery.data?.endedEpoches, quickContracts, updateHistory]
  );

  const result = React.useMemo(
    () => ({
      isMutating,
      claim,
      claimAll,
    }),
    [claim, claimAll, isMutating]
  );

  return result;
};
