import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { Web3ReactHooks } from "@web3-react/core";
import { AddEthereumChainParameter, Connector, Web3ReactStore } from "@web3-react/types";
import { NETWORK_METADATA, isSupportedChain } from "config/chains";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY } from "config/localStorage";
import React, { useEffect } from "react";
import { helperToast } from "lib/helperToast";
import { useShowUnsupportedNetworkToast } from "lib/wallets/useShowUnsupportedNetworkToast";
import { buildInjectedConnector } from "./connectors/injected";
import { buildCoinbaseWalletConnector } from "./connectors/coinbaseWallet";
import { assertSupportedWalletConnectChain, buildWalletConnectConnector } from "./connectors/walletConnect";

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

export enum ConnectionType {
  INJECTED = "INJECTED",
  COINBASE_WALLET = "COINBASE_WALLET",
  WALLET_CONNECT = "WALLET_CONNECT",
}

/**
 *  @description use only within this module to ensure existing connection
 */
const PRIORITIZED_CONNECTORS: {
  [key in ConnectionType]: Connection;
} = {
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  [ConnectionType.COINBASE_WALLET]: buildCoinbaseWalletConnector(),
  [ConnectionType.WALLET_CONNECT]: buildWalletConnectConnector(),
};

export const connectors = Object.values(PRIORITIZED_CONNECTORS).map((el) => {
  return [el.connector, el.hooks];
}) satisfies [Connector, Web3ReactHooks][] | [Connector, Web3ReactHooks, Web3ReactStore][];

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find((connection) => connection.connector === c);
    if (!connection) {
      throw Error("Unsupported Connector");
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.INJECTED:
        return PRIORITIZED_CONNECTORS[ConnectionType.INJECTED];
      case ConnectionType.COINBASE_WALLET:
        return PRIORITIZED_CONNECTORS[ConnectionType.COINBASE_WALLET];
      case ConnectionType.WALLET_CONNECT:
        return PRIORITIZED_CONNECTORS[ConnectionType.WALLET_CONNECT];
      default:
        throw Error("Unsupported Connector");
    }
  }
}

export function getIsConnectionSupported(c: Connector | ConnectionType | null | undefined): boolean {
  if (!c) {
    return false;
  }
  try {
    getConnection(c);
    return true;
  } catch {
    return false;
  }
}

export function getConnectionLabel(c: Connector | ConnectionType): string {
  const connection = getConnection(c);

  switch (connection.type) {
    case ConnectionType.INJECTED: {
      return "Metamask";
    }
    case ConnectionType.COINBASE_WALLET: {
      return "Coinbase wallet";
    }
    case ConnectionType.WALLET_CONNECT: {
      return "Wallet connect";
    }
  }
}

export const switchNetwork = async (chainId: number, c: Connector | ConnectionType | null) => {
  if (!c) {
    return;
  }

  try {
    let connector: Connector;

    if (typeof c === "object") {
      connector = c;
    } else {
      connector = getConnection(c).connector;
    }

    if (getConnection(connector).type === ConnectionType.WALLET_CONNECT) {
      assertSupportedWalletConnectChain(chainId);
      await connector.activate(chainId);
      return;
    }

    const chainInfo = NETWORK_METADATA[chainId];
    const addChainParameter: AddEthereumChainParameter = {
      chainId,
      chainName: chainInfo.chainName,
      rpcUrls: chainInfo.rpcUrls.http,
      nativeCurrency: chainInfo.nativeCurrency,
      blockExplorerUrls: chainInfo.blockExplorerUrls,
    };
    await connector.activate(addChainParameter);
    helperToast.dismiss();
  } catch (error) {
    throw error;
  }
};

function clearConnectionStorage() {
  localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
  localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
}

/**
 *  @description use `useTryActivateConnector` in other modules to display dynamic unsopported network errors
 */
const tryActivateConnector = async (connector: Connector, eagerly = false): Promise<ConnectionType | undefined> => {
  if (typeof connector.connectEagerly === "function" && eagerly) {
    await connector.connectEagerly();
  } else {
    await connector.activate();
  }

  const connectionType = getConnection(connector).type;

  localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, "true");
  localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, connectionType);

  return connectionType;
};

export function useTryActivateConnector() {
  const { chainId, rawChainId } = useChainId();

  useEagerConnect();
  useUnsupportedChainWatcher();

  return async (connector: Connector) => {
    try {
      if (getConnection(connector).type === ConnectionType.INJECTED && !isSupportedChain(rawChainId)) {
        await switchNetwork(chainId, connector);
      }

      if (getConnection(connector).type === ConnectionType.WALLET_CONNECT) {
        assertSupportedWalletConnectChain(chainId);
      }

      await tryActivateConnector(connector);
    } catch (error) {
      helperToast.error(error.message);
      return;
    }
  };
}

let haveUnsupportedChainWatcher = false;

export function useUnsupportedChainWatcher() {
  const { rawChainId } = useChainId();
  const { isActive, connector } = useWeb3React();
  const [isOnlyInstance] = React.useState(!haveUnsupportedChainWatcher);
  const showUnsupportedNetworkToast = useShowUnsupportedNetworkToast();

  useEffect(() => {
    if (isOnlyInstance) {
      haveUnsupportedChainWatcher = true;
    }
    return () => {
      if (isOnlyInstance) {
        haveUnsupportedChainWatcher = false;
      }
    };
  }, [isOnlyInstance]);

  useEffect(() => {
    if (!isActive || !isOnlyInstance) {
      return;
    }

    if (getConnection(connector).type === ConnectionType.INJECTED && !isSupportedChain(rawChainId)) {
      tryDeactivateConnector(connector);
      showUnsupportedNetworkToast();
    }
  }, [connector, isOnlyInstance, rawChainId, isActive, showUnsupportedNetworkToast]);
}

async function tryDeactivateConnector(connector: Connector): Promise<null | undefined> {
  localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
  localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);

  connector.deactivate?.();
  connector.resetState();
  return null;
}

export function useTryDeactivateConnector() {
  return tryDeactivateConnector;
}

let haveEagerConnect = false;
let isEagerConnecting = false;

export function useEagerConnect() {
  const [tried, setTried] = React.useState(false);
  const [isOnlyInstance] = React.useState(!haveEagerConnect);

  React.useEffect(() => {
    if (isOnlyInstance) {
      haveEagerConnect = true;
    }
    return () => {
      if (isOnlyInstance) {
        haveEagerConnect = false;
      }
    };
  }, [isOnlyInstance]);

  React.useEffect(() => {
    if (!isOnlyInstance || isEagerConnecting || tried) {
      return;
    }
    async function tryToConnect() {
      isEagerConnecting = true;

      const shouldEagerConnect = localStorage.getItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY) === "true";
      const connectionType = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
      const isConnectionSupported = getIsConnectionSupported((connectionType as ConnectionType) ?? null);

      if (!shouldEagerConnect || !isConnectionSupported) {
        clearConnectionStorage();
        return;
      }

      try {
        await tryActivateConnector(PRIORITIZED_CONNECTORS[connectionType as ConnectionType].connector, true);
      } catch {
        clearConnectionStorage();
      } finally {
        isEagerConnecting = false;
      }
    }
    setTried(true);
    tryToConnect();
  }, [isOnlyInstance, tried]);
}
