import { useChainId } from "lib/chains";
import { useQuickContracts } from "lib/contracts/useQuickContracts";
import parse from "parse-duration";
import useSWR from "swr";

export const usePlpUnstakeCooldown = () => {
  const { chainId } = useChainId();
  const quickContracts = useQuickContracts();

  const fetcher = async () => {
    const [cooldownPeriod] = await Promise.all([quickContracts.fetch("FeePlpTracker", "cooldownPeriod", [])]);

    return parse(`${cooldownPeriod.toNumber()} sec`, "ms") ?? 0;
  };

  const query = useSWR([`plpUnstakeCooldown`, chainId], fetcher, {
    refreshInterval: parse("1 min"),
  });

  return query.data ?? 0;
};
