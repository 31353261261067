import cx from "classnames";
import { Trans } from "@lingui/macro";

export default function GasModalContent({ gasPreference, setGasPreference }) {
  const handleGasPreferenceChange = (preference: string) => {
    setGasPreference(preference);
  };

  return (
    <>
      <div
        onClick={() => handleGasPreferenceChange("standard")}
        className={cx("network-dropdown-menu-item  menu-item gas-modal-item", {
          active: gasPreference === "standard",
        })}
      >
        <div className="menu-item-group">
          <span className="network-dropdown-item-label menu-item-label">
            <Trans>Standard</Trans>
          </span>
        </div>
        <div className="gas-dropdown-menu-item">
          <Trans>Standard network fees</Trans>
        </div>
      </div>
      <div
        onClick={() => handleGasPreferenceChange("fast")}
        className={cx("network-dropdown-menu-item  menu-item gas-modal-item", {
          active: gasPreference === "fast",
        })}
      >
        <div className="menu-item-group">
          <span className="network-dropdown-item-label menu-item-label">
            <Trans>Fast</Trans>
          </span>
        </div>
        <div className="gas-dropdown-menu-item">
          <Trans>Fast network fees</Trans>
        </div>
      </div>
      <div
        onClick={() => handleGasPreferenceChange("aggressive")}
        className={cx("network-dropdown-menu-item  menu-item gas-modal-item", {
          active: gasPreference === "aggressive",
        })}
      >
        <div className="menu-item-group">
          <span className="network-dropdown-item-label menu-item-label">
            <Trans>Aggressive</Trans>
          </span>
        </div>
        <div className="gas-dropdown-menu-item">
          <Trans>Scales above fast</Trans>
        </div>
      </div>
    </>
  );
}
