import { useIsDevelopment } from "lib/useIsDevelopment";

const lastDate = new Date('2023-09-24T00:00:00Z')

// TODO: remove after the 25 september 2023
export const useAreHardcodedZeroAprAndTotalReturn = () => {
  const isDevelopment = useIsDevelopment()
  const isAppToHardcode = !isDevelopment
  const isHardcodingTimeExpired = new Date().valueOf() > lastDate.valueOf()
  
  return isAppToHardcode && !isHardcodingTimeExpired
}
