import Modal from "components/Modal/Modal";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { INCREASE, DECREASE, USD_DECIMALS, getOrderError } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import Button from "components/Button/Button";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { TokenUtils } from "components/TokenUtils";

const OrderDetailsModal = ({
  open,
  setIsVisible,
  order,
  infoTokens,
  account,
  positionsMap,
  getOrderTitle,
  onEditClick,
  onCancelClick,
}) => {
  let indexToken = {};
  let maximisePrice;
  let markPrice;
  let triggerPricePrefix;
  let indexTokenSymbol;
  let error;
  let orderTitle;
  if (order) {
    indexToken = getTokenInfo(infoTokens, order.indexToken);
    maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
    markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
    triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    indexTokenSymbol = TokenUtils.getSymbol(indexToken);
    error = getOrderError(account, order, positionsMap);
    orderTitle = getOrderTitle(order, indexTokenSymbol);
  }

  const orderText = (
    <>
      {error ? (
        <Tooltip
          className="order-error"
          handle={orderTitle}
          handleClassName="plain"
          renderContent={() => <span className="negative">{error}</span>}
        />
      ) : (
        orderTitle
      )}
    </>
  );

  if (order)
    return (
      <Modal
        isVisible={open}
        setIsVisible={setIsVisible}
        label={
          <div className="tailwind isolate flex items-center justify-center gap-2.5">
            <TokenUtils.Logo token={indexToken} className="w-[3rem]"></TokenUtils.Logo>

            <div className="flex flex-col items-start">
              <div className="text-[1.8rem] text-textColor font-bold">{TokenUtils.getSymbol(indexToken)}</div>
              {order.leverage && (
                <div className="text-[1.2rem] font-normal text-inactive">
                  {"Leverage " + formatAmount(order.leverage, 4, 2, true)}x&nbsp;
                </div>
              )}
            </div>
          </div>
        }
      >
        <div className="tailwind isolate flex flex-col gap-4 text-[1.4rem] text-inactive">
          <div className="h-px w-full bg-border mb-5" />
          <div className="flex items-center justify-between">
            <div>
              <Trans>Side</Trans>
            </div>
            <span className={cx({ long: order.isLong, short: !order.isLong })}>
              {order.isLong ? t`Long` : t`Short`}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Size</Trans>
            </div>
            <div className="text-textColor">
              {order.type === DECREASE ? (
                orderText
              ) : (
                <Tooltip
                  handle={orderText}
                  renderContent={() => {
                    const collateralTokenInfo = getTokenInfo(infoTokens, order.collateralToken);
                    const collateralUSD = getUsd(order.tokenAmount, order.collateralToken, false, infoTokens);
                    return (
                      <StatsTooltipRow
                        label={t`Collateral`}
                        value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                          order.tokenAmount,
                          collateralTokenInfo.decimals,
                          4,
                          true
                        )}
                      ${TokenUtils.getSymbol(collateralTokenInfo)})`}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Order Type</Trans>
            </div>
            <div className="flex items-center gap-2">{order.type === INCREASE ? t`Limit` : t`Trigger`}</div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Price</Trans>
            </div>
            <div className="text-textColor">
              {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Trans>Mark Price</Trans>
            </div>
            <div className="text-textColor">
              {formatAmount(markPrice, USD_DECIMALS, indexToken?.priceDecimals, true)}
            </div>
          </div>
          <div className="h-px w-full bg-border my-5" />
          <div className="flex items-center gap-3">
            <Button variant="minor-with-border" size="sm" onClick={() => onEditClick(order)} className="w-full">
              <Trans>Edit</Trans>
            </Button>
            <Button variant="minor-with-border" size="sm" onClick={() => onCancelClick(order)} className="w-full">
              <Trans>Cancel</Trans>
            </Button>
          </div>
        </div>
      </Modal>
    );

  return null;
};

export default OrderDetailsModal;
