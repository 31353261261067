export const ActionTab = {
  POSITIONS_TAB: "POSITIONS",
  ORDERS_TAB: "ORDERS",
  TRADES_TAB: "TRADES",
  CLOSED_POSITIONS_TAB: "CLOSED_POSITIONS",
} as const
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ActionTab = (typeof ActionTab)[keyof typeof ActionTab]

export const PENDING_POSITION_VALID_DURATION = 600 * 1000;
export const UPDATED_POSITION_VALID_DURATION = 60 * 1000;
