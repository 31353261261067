import { getConstant } from "config/chains";
import { getTokenBySymbol, getTokens, getWhitelistedTokens } from "config/tokens";
import { BigNumber, ethers } from "ethers";
import { atom, useAtom } from "jotai";
import { atomWithLocalStorage } from "lib/jotai/atomWithLocalStorage";
import { BASIS_POINTS_DIVISOR, MIN_ALLOWED_LEVERAGE, MAX_ALLOWED_LEVERAGE, LONG, SHORT } from "lib/legacy";
import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { useChainId } from "lib/chains";
import { TokenInfo } from "../../domain/tokens";

export const fromValueAtom = atom("");
export const toValueAtom = atom("");
export const anchorOnFromAmountAtom = atom(true);

export const useOnToValueChange = () => {
  const [, setToValue] = useAtom(toValueAtom);
  const [, setAnchorOnFromAmount] = useAtom(anchorOnFromAmountAtom);

  const onToValueChange = (e) => {
    setAnchorOnFromAmount(false);
    setToValue(e.target.value);
  };

  return onToValueChange;
};

export const useOnFromValueChange = () => {
  const [, setFromValue] = useAtom(fromValueAtom);
  const [, setAnchorOnFromAmount] = useAtom(anchorOnFromAmountAtom);

  const onFromValueChange = (e) => {
    setAnchorOnFromAmount(true);
    setFromValue(e.target.value);
  };

  return onFromValueChange;
};

export const isConfirmingAtom = atom(false);

const DEFAULT_LEVERAGE = "2";
const MIN_LEVERAGE = MIN_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR;

const leverageAtom = atomWithLocalStorage<Partial<Record<number, string>>>("exchange-leverages", {});

export const useLeverageOption = () => {
  const { chainId } = useChainId();
  const [leverages, setLeverages] = useAtom(leverageAtom);

  const leverage = leverages[chainId] ?? DEFAULT_LEVERAGE;
  const setLeverage = (nextLeverage: string): void => {
    setLeverages({ ...leverages, [chainId]: nextLeverage });
  };

  return [leverage, setLeverage] as const;
};

export const useLeverageOptionInput = (tokenInfo: TokenInfo) => {
  const [leverage, setLeverage] = useLeverageOption();

  const maxLeverageBn = tokenInfo.maxLeverage || BigNumber.from(MAX_ALLOWED_LEVERAGE);

  function handleLeverageInput(event) {
    let value = event.target.value;

    // Otherwise, use the parsed float value
    if (parseFloat(value) > maxLeverageBn.toNumber() / BASIS_POINTS_DIVISOR) {
      setLeverage((maxLeverageBn.toNumber() / BASIS_POINTS_DIVISOR).toFixed(2));
    } else {
      setLeverage(value);
    }
  }

  function handleLeverageBlur() {
    // Check if leverageOption is a number string
    const parsed = parseFloat(leverage);

    if (isNaN(parsed)) {
      setLeverage(MIN_LEVERAGE.toFixed(2));
      return;
    }

    // If value is empty, undefined, 0, or NaN, set it to MIN_LEVERAGE
    if (!parsed || parsed < MIN_LEVERAGE) {
      setLeverage(MIN_LEVERAGE.toFixed(2));
      return;
    }

    setLeverage(parsed.toFixed(2));
  }

  const hasLeverageOption = React.useMemo(() => {
    return !isNaN(parseFloat(leverage));
  }, [leverage]);

  const minLeverage = String(MIN_LEVERAGE);
  const maxLeverage = String(maxLeverageBn.toNumber() / BASIS_POINTS_DIVISOR);

  return {
    handleLeverageInput,
    handleLeverageBlur,
    hasLeverageOption,
    minLeverage,
    maxLeverage,
  };
};

export const isChartFullScreenAtom = atomWithLocalStorage("is-chart-fullscreen", false);

export const isChartTradingEnabledAtom = atomWithLocalStorage("is-quick-trade-enabled", true);

export const isChartTradingEnabledOnMobileAtom = atomWithLocalStorage("is-quick-trade-enabled", false);

export const isAdvancedChartEnabledAtom = atomWithLocalStorage("advanced-chart", true);

export const canOpenConfirmingModalAtom = atom(false);

type TokenSelectionDirection = { from: string; to: string };

type TokenSelection = {
  Long: TokenSelectionDirection;
  Short: TokenSelectionDirection;
};

const tokenSelectionsAtom = atomWithLocalStorage<Partial<Record<number, TokenSelection>>>(
  "exchange-token-selection",
  {}
);

export const useTokenSelection = () => {
  const { chainId } = useChainId();
  const [tokenSelections, setTokenSelections] = useAtom(tokenSelectionsAtom);

  const { AddressZero } = ethers.constants;
  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");

  const defaultTokenSelection = React.useMemo(
    () =>
      ({
        [LONG]: {
          from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
          to: AddressZero,
        } satisfies TokenSelectionDirection,
        [SHORT]: {
          from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
          to: AddressZero,
        } satisfies TokenSelectionDirection,
      } satisfies TokenSelection),
    [chainId, defaultCollateralSymbol, AddressZero]
  );

  const tokenSelection = tokenSelections[chainId] ?? defaultTokenSelection;
  const setTokenSelection = (nextTokenSelection: TokenSelection): void => {
    setTokenSelections({ ...tokenSelections, [chainId]: nextTokenSelection });
  };

  return [tokenSelection, setTokenSelection] as const;
};

type SwapOption = typeof LONG | typeof SHORT;

const swapOptionsAtom = atomWithLocalStorage<Partial<Record<number, SwapOption>>>("swap-options", {});

export const useSwapOption = () => {
  const { chainId } = useChainId();
  const [swapOptions, setSwapOptions] = useAtom(swapOptionsAtom);

  const swapOption = swapOptions[chainId] ?? LONG;
  const setSwapOption = (nextSwapOption: SwapOption): void => {
    setSwapOptions({ ...swapOptions, [chainId]: nextSwapOption });
  };

  return [swapOption, setSwapOption] as const;
};

type Market = readonly [SwapOption, string];

export const useMarket = () => {
  const [swapOption, setSwapOption] = useSwapOption();
  const [tokenSelection, setTokenSelection] = useTokenSelection();

  const market: Market = [swapOption, tokenSelection[swapOption].to];

  const setMarket = (selectedSwapOption: SwapOption, toTokenAddress: string) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = cloneDeep(tokenSelection);

    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
    }
    setTokenSelection(newTokenSelection);
  };

  return [market, setMarket] as const;
};

export const useFromTokenAddress = () => {
  const { chainId } = useChainId();
  const [swapOption] = useSwapOption();
  const [tokenSelection, setTokenSelection] = useTokenSelection();

  const tokens = getTokens(chainId);
  const stableTokens = tokens.filter((token) => {
    return token.isStable && !token.isTempHidden;
  });
  const fromTokens = stableTokens;
  const fromTokensAddresses = fromTokens.map((el) => el.address);

  const fromTokenAddress = React.useMemo(() => {
    const storedAddress = tokenSelection[swapOption].from;

    if (!fromTokensAddresses.includes(storedAddress)) {
      return fromTokensAddresses[0];
    }
    return storedAddress;
  }, [fromTokensAddresses, swapOption, tokenSelection]);

  const setFromTokenAddress = React.useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = cloneDeep(tokenSelection);
      newTokenSelection[selectedSwapOption].from = address;
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  return [fromTokenAddress, setFromTokenAddress] as const;
};

export const useToTokenAddress = () => {
  const { chainId } = useChainId();
  const [swapOption] = useSwapOption();
  const [tokenSelection, setTokenSelection] = useTokenSelection();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  const toTokens = (() => {
    if (swapOption === "Long") {
      return indexTokens;
    } else {
      return shortableTokens;
    }
  })();
  const toTokensAddresses = toTokens.map((el) => el.address);
  const toTokenAddress = React.useMemo(() => {
    const storedAddress = tokenSelection[swapOption].to;

    if (!toTokensAddresses.includes(storedAddress)) {
      return toTokensAddresses[0];
    }
    return storedAddress;
  }, [swapOption, toTokensAddresses, tokenSelection]);

  const setToTokenAddress = React.useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = cloneDeep(tokenSelection);
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection]
  );

  return [toTokenAddress, setToTokenAddress] as const;
};
