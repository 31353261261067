import upDownIcon from 'img/UpDownIcon.svg';

type SortableTableHeaderColumnProps = {
    onToggle?: () => void;
    children: React.ReactNode;
  };


  export const SortableTableHeader= ({ children, onToggle }: SortableTableHeaderColumnProps) => {
    return (
      <button
        className="flex items-center gap-1"
        onClick={() => onToggle?.()}
      >
        {children}
        
        <img
          src={upDownIcon}
          alt="sort"
          className="w-6 h-6 shrink-0"
        />
      </button>
    );
  };