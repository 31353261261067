import { gql } from "@apollo/client";
import { BigNumber } from "ethers";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";

import { getPalmSubgraph } from "lib/subgraph/clients";
import { bigNumberify } from "lib/numbers";
import { GlobalStakeInfos, StakeStats, UserStakesInfo } from "./types";
import { useChainId } from "lib/chains";
import { isFuture, fromUnixTime } from "date-fns";

export function useStakesStats() {
  const { chainId } = useChainId();
  const { account } = useWeb3React();

  async function totalFetcher() {
    try {
      const client = getPalmSubgraph(chainId, "raw");

      const query = gql`
        query {
          globalStakeInfos {
            stakedPalm
            stakedPlp
          }
        }
      `;

      const { data } = await client!.query<GlobalStakeInfos>({ query });

      const stakedPlps = data.globalStakeInfos[0].stakedPlp;
      const stakedPalms = data.globalStakeInfos[0].stakedPalm;

      let plpTotalStaked = BigNumber.from("0");
      let palmTotalStaked = BigNumber.from("0");

      if (stakedPlps) {
        const currentValue = bigNumberify(stakedPlps);

        if (currentValue) plpTotalStaked = currentValue;
      }

      if (stakedPalms) {
        const currentValue = bigNumberify(stakedPalms);

        if (currentValue) palmTotalStaked = currentValue;
      }

      return {
        plpTotalStaked,
        palmTotalStaked,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  const defaultStakeStats = {
    userStaked: BigNumber.from(0),
    userUnstaked: BigNumber.from(0),
    userCooldownPeriods: {
      active: 0,
      expired: 0,
      items: [],
    },
  };

  async function userFetcher() {
    const client = getPalmSubgraph(chainId, "raw");
    const accountString = account ? account.toLowerCase() : "";

    const query = gql`
      query stakesByUser($account: String!) {
        plpStake(id: $account) {
          staked
          unstaked
          redeemInfo {
            expire
            amount
            index
          }
        }
        palmStake(id: $account) {
          staked
          unstaked
          redeemInfo {
            expire
            amount
            index
          }
        }
      }
    `;

    const res = await client!.query<UserStakesInfo>({
      query,
      variables: { account: accountString },
    });

    const getUserStats = (stakeData: StakeStats) => {
      const { staked, unstaked, redeemInfo } = stakeData;

      const userStaked = bigNumberify(staked) || BigNumber.from("0");
      const userUnstaked = bigNumberify(unstaked) || BigNumber.from("0");
      const redeemableInfo = redeemInfo.filter((item) => item.amount && item.amount !== "0"); // filter out the already redeemed info

      const activeCooldownsCount = redeemableInfo.reduce(
        (acc, info) => (isFuture(fromUnixTime(Number(info.expire))) ? acc + 1 : acc),
        0
      );
      const expiredCooldownsCount = redeemableInfo.reduce(
        (acc, info) => (!isFuture(fromUnixTime(Number(info.expire))) ? acc + 1 : acc),
        0
      );
      return {
        userStaked,
        userUnstaked,
        userCooldownPeriods: {
          active: activeCooldownsCount,
          expired: expiredCooldownsCount,
          items: redeemableInfo,
        },
      };
    };
    const palmStakeStats = res?.data?.palmStake ? getUserStats(res?.data?.palmStake) : defaultStakeStats;
    const plpStakeStats = res?.data?.plpStake ? getUserStats(res?.data?.plpStake) : defaultStakeStats;

    return {
      palmStakeStats,
      plpStakeStats,
    };
  }

  const palmUserStakedData = useSWR<Awaited<ReturnType<typeof userFetcher>>>(
    ["Earn:user-staked", chainId, account],
    userFetcher
  );

  const totalStakedData = useSWR<Awaited<ReturnType<typeof totalFetcher>>>(
    ["Earn:total-staked", chainId, account],
    totalFetcher
  );

  return {
    plpTotalStaked: totalStakedData.data?.plpTotalStaked || BigNumber.from(0),
    palmTotalStaked: totalStakedData.data?.palmTotalStaked || BigNumber.from(0),
    palmStakeStats: palmUserStakedData.data?.palmStakeStats || defaultStakeStats,
    plpStakeStats: palmUserStakedData.data?.plpStakeStats || defaultStakeStats,
  };
}
