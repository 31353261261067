import React, { useState } from "react";
import "./StakeV3.scss";
import { HiExclamation, HiInformationCircle } from "react-icons/hi";
import Modal from "components/Modal/Modal";
import cx from "classnames";

export const PALM_TOKEN = "PALM_TOKEN";
export const PLP_TOKEN = "PLP_TOKEN";

const durations = [
  {
    monthNumber: 1,
    bonus: 0,
    apr: 16,
  },
  {
    monthNumber: 3,
    bonus: 30,
    apr: 26,
  },
  {
    monthNumber: 6,
    bonus: 50,
    apr: 40,
  },
  {
    monthNumber: 12,
    bonus: 90,
    apr: 60,
  },
];

const VestModal = ({ open, setOpen, token }) => {
  const [activeDuration, setDuration] = useState(0);
  const isActiveDuration = (opt) => {
    return opt === activeDuration;
  };
  const tokenName = token === PLP_TOKEN ? "PLP" : "PALM";
  const balanceLabel = `${tokenName} Staked Balance`;
  const infoText = `You will be able to withdraw the staked ${tokenName} and profit only when the staking position is unlocked`;

  return (
    <Modal className="Connect-wallet-modal" isVisible={open} setIsVisible={setOpen} label={`Vest ${tokenName}`}>
      <div className="Vest-modal">
        <div className="Stake-modal-top-section">
          <div className="inactive-text">Amount</div>
          <input className="Earn-input" />
          <div className="inactive-text">{balanceLabel}: 145,500.39 PALM</div>
          <div className="fraction-selector-wrapper">
            <div className="fraction-selector-item">10%</div>
            <div className="fraction-selector-item">25%</div>
            <div className="fraction-selector-item">50%</div>
            <div className="fraction-selector-item">75%</div>
            <div className="fraction-selector-item">100%</div>
          </div>
        </div>
        <div className="Card-divider"></div>
        <div className="Stake-modal-top-section">
          <div className="inactive-text">Select Duration</div>
          <div className="fraction-selector-wrapper">
            {durations.map((duration, i) => (
              <div
                className={cx("fraction-selector-item", { "active-duration": isActiveDuration(i) })}
                onClick={() => {
                  setDuration(i);
                }}
                key={i}
              >
                {duration.monthNumber} Month
              </div>
            ))}
          </div>
        </div>
        <div className="Stake-modal-center-section">
          {durations.map((duration, i) => (
            <div className={cx("label-value inactive-text", { "active-duration-label": isActiveDuration(i) })} key={i}>
              <div>{duration.monthNumber} Month</div>
              <div>{duration.bonus}% Bonus</div>
              <div className="gap-container">
                <div>{duration.apr}% APR*</div>
                <HiInformationCircle />
              </div>
            </div>
          ))}
        </div>
        <div className="Stake-modal-bottom-section">
          <div className="Stake-modal-info">
            <div>
              <HiExclamation />
            </div>
            <div>{infoText}</div>
          </div>
          <button className="button primary-action w-100">Vest {tokenName}</button>
        </div>
      </div>
    </Modal>
  );
};

export default VestModal;
