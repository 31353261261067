import React, { useState } from "react";
import "./Portfolio.css";
import Sidebar from "./Sidebar/Sidebar";
import Overview from "./Overview/Overview";
import Orders from "./Orders/Orders";
import History from "./History/History";
import Position from "./Position/Position";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getPositions } from "pages/Exchange/getPositions";
import { getPositionQuery } from "pages/Exchange/getPositionQuery";
import { getContractAddress } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import Reader from "abis/Reader.json";
import { useAccountOrders } from "lib/legacy";
import Vault from "abis/Vault.json";
import { useInfoTokens } from "domain/tokens";
import { useMarket } from "pages/Exchange/state";

const componentMap = {
  overview: Overview,
  position: Position,
  orders: Orders,
  history: History,
};

const Portfolio = ({
  savedIsPnlInLeverage,
  savedShowPnlAfterFees,
  connectWallet,
  savedShouldDisableValidationForTesting,
  pendingTxns,
  setPendingTxns,
  isCancelMultipleOrderProcessing,
  cancelOrderIdList,
  setCancelOrderIdList,
  onMultipleCancelClick,
}) => {
  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions] = useState({});
  const [activePage, setActivePage] = useState("overview");
  const [activeFilter, setActiveFilter] = useState("");
  const PortfolioActiveComponent = componentMap[activePage];

  const { chainId } = useChainId();
  const { isActive, account, provider } = useWeb3React();
  const [, setMarket] = useMarket();
  const { infoTokens, offChainTokenPrices } = useInfoTokens();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const readerAddress = getContractAddress(chainId, "Reader");
  const vaultAddress = getContractAddress(chainId, "Vault");
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress, offChainTokenPrices);

  const { data: positionData, error: positionDataError } = useSWR(
    isActive &&
      positionQuery.offChainTokenPrices.filter((p) => !!p).length > 0 && [
        isActive,
        chainId,
        readerAddress,
        "getPositions",
        vaultAddress,
        account,
      ],
    contractFetcher(provider, Reader, [
      positionQuery.indexTokens,
      positionQuery.offChainTokenPrices,
      positionQuery.isLong,
    ])
  );

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${isActive}`, chainId, vaultAddress, "totalTokenWeights"],
    contractFetcher(provider, Vault)
  );

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
  );

  const flagOrdersEnabled = true;
  const [orders] = useAccountOrders(flagOrdersEnabled);

  return (
    <div className="tailwind flex md:flex-row flex-col">
      <Sidebar setActivePage={setActivePage} activePage={activePage} />
      <div className="md:h-[calc(100vh-6.2rem)] flex-1 overflow-y-auto">
        <PortfolioActiveComponent
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          savedShowPnlAfterFees={savedShowPnlAfterFees}
          connectWallet={connectWallet}
          active={isActive}
          account={account}
          library={provider}
          chainId={chainId}
          positions={positions}
          positionsMap={positionsMap}
          nativeTokenAddress={nativeTokenAddress}
          infoTokens={infoTokens}
          whitelistedTokens={whitelistedTokens}
          positionData={positionData}
          positionDataError={positionDataError}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          orders={orders}
          totalTokenWeights={totalTokenWeights}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          isCancelMultipleOrderProcessing={isCancelMultipleOrderProcessing}
          cancelOrderIdList={cancelOrderIdList}
          setCancelOrderIdList={setCancelOrderIdList}
          onMultipleCancelClick={onMultipleCancelClick}
          setMarket={setMarket}
          setActivePage={setActivePage}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      </div>
    </div>
  );
};

export default Portfolio;
