import { Trans } from "@lingui/macro";
import { ReactComponent as Cross } from "img/cross.svg";
import { ReactComponent as Arrow } from "img/Arrow.svg";
import React from "react";
import { useMedia } from "react-use";

type WelcomeProps = {
  setWelcomeModalVisible: (arg: boolean) => void;
};

export const WelcomeBar = ({ setWelcomeModalVisible }: WelcomeProps) => {
  const handleClick = () => {
    setWelcomeModalVisible(false);
  };

  const isMobile = useMedia("(max-width: 1100px)");

  return (
    <div className="Welcome-wallet-bar">
      <span>
        <Trans>Palmswap is now part of Foxify, we merged and moved our product to foxify.trade.</Trans>
        <a href="https://perp.foxify.trade/" target="_blank" rel="noopener noreferrer">
          <Trans>Visit Foxify</Trans>
        </a>
        {!isMobile && <Arrow className="h-[1.4rem] " />}
      </span>

      {!isMobile && (
        <div onClick={handleClick} className={"cross"}>
          <Cross className="h-[2.1rem]" />
        </div>
      )}
    </div>
  );
};
