import { BigNumber, Contract } from "ethers";
import { helperToast } from "../helperToast";
import { ToastifyDebug } from "components/ToastifyDebug/ToastifyDebug";
import { extractError, NOT_ENOUGH_FUNDS, RPC_ERROR, SLIPPAGE, USER_DENIED } from "./transactionErrors";
import { getGasLimit, setGasPrice } from "./utils";
import { getExplorerUrl } from "config/chains";
import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { gasPreferenceAtom } from "lib/useGasPreference";
import { getDefaultStore } from "jotai";

export type CallContractOptions = {
  value?: BigNumber | number;
  sentMsg?: string;
  successMsg?: string;
  hideSuccessMsg?: boolean;
  failMsg?: string;
  setPendingTxns?: (txns: any) => void;
  txnSuccessCallback?: () => void;
};

export async function callContract<TContract extends Contract, TMethod extends keyof TContract>(
  chainId: number,
  contract: TContract,
  method: TMethod,
  params: Parameters<TContract[TMethod]>,
  opts?: CallContractOptions
) {
  const gasPreference = getDefaultStore().get(gasPreferenceAtom);

  try {
    // TODO: remove after the complete app migration to TypesSript
    if (!Array.isArray(params) && typeof params === "object" && opts === undefined) {
      opts = params;
      params = [] as any;
    }

    if (!opts) {
      opts = {};
    }

    const txnOpts: any = {};

    if (opts.value) {
      txnOpts.value = opts.value;
    }

    txnOpts.gasLimit = await getGasLimit(contract, method, params, txnOpts);
    // txnOpts.gasLimit = '1500000'

    await setGasPrice(txnOpts, contract.provider, chainId, gasPreference);

    const res = await contract[method](...params, txnOpts);
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
    const sentMsg = opts.sentMsg || t`Transaction sent.`;

    helperToast.info(
      <div className="info">
        <LoadingAnimation />
        <div>
          {sentMsg}{" "}
          <ExternalLink href={txUrl}>
            <Trans>View status.</Trans>
          </ExternalLink>
          <br />
        </div>
      </div>
    );

    if (opts.setPendingTxns && res.hash) {
      const message = opts.hideSuccessMsg ? undefined : opts.successMsg || t`Transaction completed!`;
      const pendingTxn = {
        hash: res.hash,
        message,
        successCallback: opts.txnSuccessCallback,
      };

      if (opts.txnSuccessCallback) {
        pendingTxn.successCallback = opts.txnSuccessCallback;
      }

      opts.setPendingTxns((pendingTxns) => [...pendingTxns, pendingTxn]);
    }

    return res;
  } catch (error) {
    let failMsg;

    let autoCloseToast: number | boolean = 5000;

    const [reason, message, type] = extractError(error);
    switch (type) {
      case NOT_ENOUGH_FUNDS:
        failMsg = <Trans>There is not enough balance in your account to send this transaction.</Trans>;
        break;
      case USER_DENIED:
        failMsg = t`Transaction was cancelled.`;
        break;
      case SLIPPAGE:
        failMsg = t`The mark price has changed, consider increasing your Allowed Slippage by clicking on the "..." icon next to your address.`;
        break;
      case RPC_ERROR:
        autoCloseToast = false;

        failMsg = (
          <div>
            <Trans>
              Transaction failed due to RPC error.
              <br />
              <br />
              Please try changing the RPC url in your wallet settings.
            </Trans>
            <br />
            {(reason || message) && <ToastifyDebug shortMessage={reason} fullMessage={message}></ToastifyDebug>}
          </div>
        );
        break;
      default:
        autoCloseToast = false;

        failMsg = (
          <div>
            {opts?.failMsg || t`Transaction failed`}
            <br />
            {(reason || message) && <ToastifyDebug shortMessage={reason} fullMessage={message}></ToastifyDebug>}
          </div>
        );
    }

    helperToast.error(failMsg, { autoClose: autoCloseToast });
    throw error;
  }
}
