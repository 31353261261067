import { t, Trans } from "@lingui/macro";
import copy from "img/ic_copy_16.svg";
import externalLink from "img/ic_new_link_16.svg";
import { helperToast } from "lib/helperToast";
import { shortenAddress, useENS } from "lib/legacy";
import { useCopyToClipboard } from "react-use";
import "./AddressDropdown.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import shevronDown from "img/shevron-down-circle.svg";
import { useWeb3React } from "@web3-react/core";
import { getConnectionLabel } from "lib/wallets/connections";
import { Avatar } from "components/Avatar";
import { Menu } from "components/MenuDropdown";
import cx from "classnames";
import bridgeIcon from "img/bridge.svg";
import { useAtom } from "jotai";
import { isBridgeModalVisibleAtom } from "components/Bridge/state";

type Props = {
  account: string;
  accountUrl: string;
  disconnectAccountAndCloseSettings: () => void;
  className?: string;
};

export function AddressDropdown({ account, accountUrl, disconnectAccountAndCloseSettings, className }: Props) {
  const [, copyToClipboard] = useCopyToClipboard();
  const { ensName } = useENS(account);
  const { isActive, connector } = useWeb3React();

  const [, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);

  return (
    <div className={cx("relative", className)}>
      {isActive && (
        <div className="max-lg:block hidden">
          <div className="flex">
            <div className="max-sm:rounded-sm rounded-sm px-4 h-[3.6rem] border flex items-center">
              <AvatarAndAddress account={account} ensName={ensName} className="flex-1" />
            </div>
          </div>
        </div>
      )}

      <div className="hidden lg:block">
        <div className="flex">
          <Menu.Menu placement="bottom-end">
            <Menu.Button>
              {({ open, ref }) => (
                <button
                  ref={ref}
                  className={cx("rounded-sm px-4 h-[3.6rem] border inline-flex items-center transition gap-4", {
                    "bg-background-3": open,
                    "hover:bg-background-3": !open,
                  })}
                >
                  {isActive && <AvatarAndAddress account={account} ensName={ensName} className="flex-1" />}

                  <div className="w-[1.8rem] h-[1.8rem] rounded-full border-0 bg-background-3 flex items-center justify-center shrink-0">
                    <img src={shevronDown} alt="" />
                  </div>
                </button>
              )}
            </Menu.Button>

            <Menu.Items className="w-[34.7rem] !p-[1.5rem] ![p-2rem]">
              <div className="flex flex-col items-stretch gap-[1.5rem]">
                <div className="flex justify-between items-center">
                  <p className="text-[1.4rem]">
                    <Trans>Account</Trans>

                    <span className="wallet-provider">
                      <Trans>({getConnectionLabel(connector)})</Trans>
                    </span>
                  </p>

                  <button onClick={disconnectAccountAndCloseSettings} className="disconnect-btn transition">
                    <Trans>Disconnect</Trans>
                  </button>
                </div>

                <div className="flex items-center gap-[1.5rem] shrink-0">
                  {isActive && <Avatar username={account} className="h-[4rem] aspect-square" />}

                  <div className="break-all">
                    <span className="text-textColor strong text-[1.4rem] leading-[2.4rem]">{ensName || account}</span>
                  </div>
                </div>

                <div className="flex items-center gap-[1.5rem] justify-items-start -m-[.8rem]">
                  <Menu.Item>
                    <ExternalLink href={accountUrl} className={"no-underline"}>
                      <div className="flex gap-[.3rem] items-center text-[1.4rem] text-inactive">
                        <span>
                          <Trans>View in Explorer</Trans>
                        </span>

                        <div className="h-[2rem] aspect-square flex items-center justify-center">
                          <img src={externalLink} alt="Open address in explorer" />
                        </div>
                      </div>
                    </ExternalLink>
                  </Menu.Item>

                  <Menu.Item>
                    <button
                      className={"!m-0"}
                      onClick={() => {
                        copyToClipboard(account as any);
                        helperToast.success(t`Address copied to your clipboard`);
                      }}
                    >
                      <div className="flex gap-[.3rem] items-center text-[1.4rem] text-inactive">
                        <p>
                          <Trans>Copy Address</Trans>
                        </p>

                        <div className="h-[2rem] aspect-square flex items-center justify-center">
                          <img src={copy} alt="Copy user address" />
                        </div>
                      </div>
                    </button>
                  </Menu.Item>
                </div>
                <div className="line"></div>

                <Menu.Item>
                  {({ close }) => (
                    <>
                      <button
                        className="w-full h-[4rem] bg-main rounded-[1rem] flex flex-row justify-between items-center px-[1rem]"
                        onClick={() => {
                          close();
                          setIsBridgeModalVisible(true);
                        }}
                      >
                        <div className="flex flex-row gap-[1rem] text-white text-[1.3rem] ">
                          <img src={bridgeIcon} alt="bridgeIcon" />
                          <p className="">
                            <Trans>Bridging (Cross-Chain Deposit)</Trans>
                          </p>
                        </div>
                        <div className="p-[0.5rem] bg-gradient-to-r from-[#D2CEFE] to-[#ECEAF7] text-main rounded-[4px] text-[1.2rem] ">
                          <p>New</p>
                        </div>
                      </button>
                    </>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu.Menu>
        </div>
      </div>
    </div>
  );
}

const AvatarAndAddress = ({
  account,
  ensName,
  className,
}: {
  account: string;
  ensName?: string;
  className?: string;
}) => {
  return (
    <div className={cx("flex items-center gap-[.5rem] select-none", className)}>
      <Avatar username={account} className="w-[2.5rem] aspect-square shrink-0 pointer-events-none" />

      <div>
        <span className="hidden sm:block">{ensName ?? shortenAddress(account, 13)}</span>
        <span className="max-sm:block hidden">{ensName ?? shortenAddress(account, 9)}</span>
      </div>
    </div>
  );
};
