import React from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";

import "./Actions.css";

import { getContractAddress } from "config/contracts";
import { useAccountOrders } from "lib/legacy";

import { getPositions } from "../Exchange/getPositions";
import { getPositionQuery } from "../Exchange/getPositionQuery";

import PositionsList from "components/Exchange/PositionsList";
import OrdersList from "components/Exchange/OrdersList";

import TradeHistory from "components/Exchange/TradeHistory";
import Reader from "abis/Reader.json";

import { Trans, t } from "@lingui/macro";
import { getServerBaseUrl } from "config/backend";
import { contractFetcher } from "lib/contracts";
import { useInfoTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import { getToken, getWhitelistedTokens } from "config/tokens";
import { useChainId } from "lib/chains";
import { TokenUtils } from "components/TokenUtils";

const USD_DECIMALS = 30;

export default function Actions({ savedIsPnlInLeverage, savedShowPnlAfterFees }) {
  const { account } = useParams<{ account: string }>();
  const { isActive, provider } = useWeb3React();

  const { chainId } = useChainId();
  const { infoTokens, offChainTokenPrices } = useInfoTokens();
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const vaultAddress = getContractAddress(chainId, "Vault");
  const readerAddress = getContractAddress(chainId, "Reader");

  const shouldShowPnl = false;

  let checkSummedAccount = "";
  if (ethers.utils.isAddress(account)) {
    checkSummedAccount = ethers.utils.getAddress(account);
  }
  const pnlUrl = `${getServerBaseUrl(chainId)}/pnl?account=${checkSummedAccount}`;
  const { data: pnlData } = useSWR<any[]>(pnlUrl, () => fetch(pnlUrl).then((res) => res.json() as Promise<any[]>));

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress, offChainTokenPrices);

  const { data: positionData } = useSWR(
    positionQuery.offChainTokenPrices.filter((p) => !!p).length > 0 && [
      isActive,
      chainId,
      readerAddress,
      "getPositions",
      vaultAddress,
      account,
    ],
    contractFetcher(provider, Reader, [
      positionQuery.indexTokens,
      positionQuery.offChainTokenPrices,
      positionQuery.isLong,
    ])
  );

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    checkSummedAccount,
    undefined,
    undefined
  );

  const flagOrdersEnabled = true;
  const [orders, updateOrders] = useAccountOrders(flagOrdersEnabled, checkSummedAccount);

  return (
    <div className="Actions">
      {checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <Trans>Account</Trans>: {checkSummedAccount}
        </div>
      )}
      {shouldShowPnl && (
        <div className="Actions-section">
          <div className="Actions-title">
            <Trans>PnL</Trans>
          </div>
          {(!pnlData || pnlData.length === 0) && (
            <div>
              <Trans>No PnLs found</Trans>
            </div>
          )}
          {pnlData &&
            pnlData.length > 0 &&
            pnlData.map((pnlRow, index) => {
              const token = getToken(chainId, pnlRow.data.indexToken);
              return (
                <div className="TradeHistory-row App-box App-box-border" key={index}>
                  <div>
                    {TokenUtils.getSymbol(token)} {pnlRow.data.isLong ? t`Long` : t`Short`} <Trans>Profit</Trans>:{" "}
                    {formatAmount(pnlRow.data.profit, USD_DECIMALS, 2, true)} USD
                  </div>
                  <div>
                    {TokenUtils.getSymbol(token)} {pnlRow.data.isLong ? t`Long` : t`Short`} <Trans>Loss</Trans>:{" "}
                    {formatAmount(pnlRow.data.loss, USD_DECIMALS, 2, true)} USD
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <div className="Actions-title">
            <Trans>Positions</Trans>
          </div>
          <PositionsList
            positions={positions}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            active={isActive}
            orders={orders}
            account={checkSummedAccount}
            library={provider}
            flagOrdersEnabled={false}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            showPnlAfterFees={savedShowPnlAfterFees}
            hideActions
          />
        </div>
      )}
      {flagOrdersEnabled && checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <div className="Actions-title">
            <Trans>Orders</Trans>
          </div>
          <OrdersList
            account={checkSummedAccount}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            chainId={chainId}
            orders={orders}
            updateOrders={updateOrders}
            hideActions
          />
        </div>
      )}
      <div className="Actions-section">
        <div className="Actions-title">
          <Trans>Actions</Trans>
        </div>
        <TradeHistory
          account={checkSummedAccount}
          infoTokens={infoTokens}
          getTokenInfo={getTokenInfo}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          shouldShowPaginationButtons={true}
        />
      </div>
    </div>
  );
}
