import Loader from "components/Common/Loader";

type LoadableEventLayoutProps = {
  children?: React.ReactNode
  isLoading: boolean
};

export const LoadableEventLayout = (props: LoadableEventLayoutProps) => {
  if (props.isLoading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {props.children}
    </>
  )
};
