import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { Connection, ConnectionType } from 'lib/wallets/connections'

export function buildInjectedConnector(): Connection {
  const [metaMask, hooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }))

  return {
    connector: metaMask,
    hooks,
    type: ConnectionType.INJECTED,
  }
}


