import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import { useAtom } from "jotai";
import { useState } from "react";
import Bridge from "./Bridge";
import { isBridgeModalVisibleAtom } from "./state";


export default function BridgeModal() {

  const [isBridgeModalVisible, setIsBridgeModalVisible] = useAtom(isBridgeModalVisibleAtom);

  const [isModalInputFocused, setModalInputFocused] = useState(false);

  // Handle input focus
  const handleInputFocus = () => {
    setModalInputFocused(true);
  };

  // Handle input blur
  const handleInputBlur = () => {
    setModalInputFocused(false);
  };

  return (
    <Modal
      className="Bridge-Modal"
      isVisible={isBridgeModalVisible}
      setIsVisible={setIsBridgeModalVisible}
      label={t`Bridge`}
      isInputFocused={isModalInputFocused}
    >
      <Bridge onFocus={handleInputFocus} onBlur={handleInputBlur} />
    </Modal>
  );
}
