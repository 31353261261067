import "./Loader.css";

export default function Loader() {
  return (
    <div className="loading_wrapper">
      <div className="column column_1"></div>
      <div className="column column_2"></div>
      <div className="column column_3"></div>
      <div className="column column_4"></div>
    </div>
  );
}
