import React from "react";
import cx from "classnames";

type Props = React.ComponentProps<"div"> & {
  children: React.ReactNode;
  className?: string;
};

const ExchangeRow = (props: Props) => <tr onClick={props.onClick} className={cx("Exchange-list-body", props.className)}>{props.children}</tr>;

export default ExchangeRow;
