import React, { useEffect, useState, useRef, useCallback, useMemo, memo } from "react";
import { Trans, t } from "@lingui/macro";
import { FiArrowUpRight } from "react-icons/fi";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import "./Buy.css";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import PancakeSwapLogo from "img/PancakeSwapLogo.png";
import MEXCLogo from "img/MEXCLogo.png";
import cx from "classnames";
import Button from "components/Button/Button";
import ExternalLink from "components/ExternalLink/ExternalLink";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import { getContractAddress } from "config/contracts";
import Modal from "components/Modal/Modal";
import { usePlpPrice } from "pages/Event/hooks/blockchain/usePlpPrice";
import {
  PLP_DECIMALS,
  PALM_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  GLP_COOLDOWN_DURATION,
  SECONDS_PER_YEAR,
  USDP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/constants";
import {
  getBuyGlpToAmount,
  getBuyGlpFromAmount,
  getSellGlpFromAmount,
  getSellGlpToAmount,
  adjustForDecimals,
} from "lib/legacy";
import ReaderAbi from "abis/Reader.json";
import RewardReaderAbi from "abis/RewardReader.json";
import PlpManagerAbi from "abis/PlpManager.json";
import PlpRewardTrackerAbi from "abis/PlpRewardTracker.json";
import TokenAbi from "abis/common/Token.json";
import { TESTNET, getChainName, IS_NETWORK_DISABLED } from "config/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { expandDecimals, formatAmountFree, formatKeyAmount, parseValue } from "lib/numbers";
import { getToken, getVisibleTokens } from "config/tokens";
import AfterBuyPlpModal from "./AfterBuyPlpModal";
import { useStakesStats } from "domain/stats/useStakesStats";
import { useTokenBalance } from "pages/Event/hooks/blockchain/useTokenBalance";
import { useCollateralTokenAddress } from "lib/useCollateralTokenAddress";
import { useChainId } from "lib/chains";
import { PalmChart } from "pages/Buy/PalmChart";
import { PlpChart } from "pages/Buy/PlpChart";
import PlpRewardRouterAbi from "abis/PlpRewardRouter.json";
import { type RewardReader } from "typechain";
import isEqual from "lodash/isEqual";
import { atom, useAtom } from "jotai";
import { AnimatePresence, motion } from "framer-motion";
import { usePlpRedeem } from "pages/Buy/hooks/usePlpRedeem";
import { useCurrentSecond } from "lib/useCurrentSecond";
import { formatAmount, formatBigAmount } from "lib/formatAmount";
import { useGetUsdAmountFromPlpAmount } from "lib/api/useTokenPrices";
import Loader from "components/Common/Loader";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { TipIcon } from "components/TipIcon";
import format from "date-fns/format";
import { usePlpUnstakeCooldown } from "lib/usePlpUnstakeCooldown";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { useAreHardcodedZeroAprAndTotalReturn } from "lib/useAreHardcodedZeroAprAndTotalReturn";
import { TokenUtils } from "components/TokenUtils";
import usePythService from "../../hooks/usePythService";

const { AddressZero } = ethers.constants;
const savedShouldDisableValidationForTesting = false;

function getStakingData(stakingInfo: Awaited<ReturnType<RewardReader["getStakingInfo"]>> | undefined) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return;
  }

  const keys = ["feeGlpTracker", "feePalmTracker"] as const;

  type DataKey = (typeof keys)[number];

  type ParsedStakingData = {
    claimable: BigNumber;
    tokensPerInterval: BigNumber;
    averageStakedAmounts: BigNumber;
    cumulativeRewards: BigNumber;
    totalSupply: BigNumber;
  };
  const propsLength = 5;

  const data: Partial<Record<DataKey, ParsedStakingData>> = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    };
  }

  return data as Record<DataKey, ParsedStakingData>;
}

const tabsOptions = ["PLP", "PALM"] as const;
const plpActions = ["buy", "sell"] as const;
const buyPALMOptions = [
  {
    title: "Pancakeswap",
    image: PancakeSwapLogo,
    link: "https://pancakeswap.finance/swap?inputCurrency=0x29745314B4D294B7C77cDB411B8AAa95923aae38&outputCurrency=0x55d398326f99059fF775485246999027B3197955",
  },
  {
    title: "MEXC",
    image: MEXCLogo,
    link: "https://www.mexc.com/de-DE/exchange/PALM_USDT?_from=search",
  },
];

export default function Buy(props) {
  const [activeTab, setActiveTab] = useState<(typeof tabsOptions)[number]>("PLP");
  const [activeAction, setActiveAction] = useState<(typeof plpActions)[number]>("sell");
  const isBuying = activeAction === "buy";
  const [showMore, setShowMore] = useState(false);
  const [buyPLPModalVisible, setBuyPLPModalVisible] = useState(false);
  const [buyPALMModalVisible, setBuyPALMModalVisible] = useState(false);
  const areHardcodedZeroAprAndTotalReturn = useAreHardcodedZeroAprAndTotalReturn();

  const showMoreText = !showMore
    ? t`...`
    : t` is directly linked to the value of USDT. PLP can be bought and sold on the Palmswap platform, and can be redeemed for USDT at any time. The PLP price may differ from USDT, a price difference occurs when traders make profits or losses, because the liquidity pool is used as a counterparty of traders.`;
  const handleShowMoreClick = () => setShowMore(!showMore);
  const showMoreButtonText = !showMore ? t`Show more` : t`Hide`;

  let { isActive, account = PLACEHOLDER_ACCOUNT, provider } = useWeb3React();
  const { chainId } = useChainId();
  const tokenList = getVisibleTokens(chainId);
  const visibleTokens = tokenList.filter((t) => t.name === "Tether");
  const [swapValue, setSwapValue] = useState("");
  const [plpValue, setPlpValue] = useState("");
  const collateralTokenAddress = useCollateralTokenAddress();

  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true);
  const [feeBasisPoints, setFeeBasisPoints] = useState(0);
  const [, setModalError] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);

  const buyInputAmountRef = useRef(null);
  const sellInputAmountRef = useRef(null);

  const readerAddress = getContractAddress(chainId, "Reader");
  const rewardReaderAddress = getContractAddress(chainId, "RewardReader");
  const plpAddress = getContractAddress(chainId, "GLP");
  const feePlpTrackerAddress = getContractAddress(chainId, "FeePlpTracker");
  const feePalmTrackerAddress = getContractAddress(chainId, "FeePalmTracker");

  const plpManagerAddress = getContractAddress(chainId, "PlpManager");

  const plpRewardRouterAddress = getContractAddress(chainId, "PlpRewardRouter");

  const tokensForBalanceAndSupplyQuery = [plpAddress];

  const palmTokenAddress = getContractAddress(chainId, "PALM");

  const palmTokenBalance = useTokenBalance(palmTokenAddress, account);

  const plpRedeem = usePlpRedeem();

  const { priceFeedsUpdateData, updateFee } = usePythService();

  const { data: balancesAndSupplies } = useSWR(
    [
      `PlpSwap:getTokenBalancesWithSupplies:${isActive}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    contractFetcher(provider, ReaderAbi, [tokensForBalanceAndSupplyQuery]),
    {
      compare: isEqual,
    }
  );

  const { data: totalSupply = BigNumber.from(0) } = useSWR<BigNumber>(
    [`BuyPage:getTokenSupply:${palmTokenAddress}`, chainId, palmTokenAddress, "totalSupply"],
    contractFetcher(provider, TokenAbi),
    {
      compare: isEqual,
    }
  );

  const { data: tokenAllowance = BigNumber.from(0) } = useSWR<BigNumber>(
    [isActive, chainId, collateralTokenAddress, "allowance", account || PLACEHOLDER_ACCOUNT, plpManagerAddress],
    contractFetcher(provider, TokenAbi),
    {
      compare: isEqual,
    }
  );

  const { data: lastPurchaseTime = BigNumber.from(0) } = useSWR<BigNumber>(
    [`PlpSwap:lastPurchaseTime:${isActive}`, chainId, plpManagerAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PlpManagerAbi),
    {
      compare: isEqual,
    }
  );

  const { data: plpBalance = BigNumber.from(0) } = useSWR<BigNumber>(
    [`PlpSwap:plpBalance:${isActive}`, chainId, feePlpTrackerAddress, "stakedAmounts", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, PlpRewardTrackerAbi),
    {
      compare: isEqual,
    }
  );

  const rewardTrackersForStakingInfo = [feePlpTrackerAddress, feePalmTrackerAddress];

  const { data: stakingInfo } = useSWR<Awaited<ReturnType<RewardReader["getStakingInfo"]>>>(
    [`PlpSwap:stakingInfo:${isActive}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    contractFetcher(provider, RewardReaderAbi, [rewardTrackersForStakingInfo]),
    {
      compare: isEqual,
    }
  );

  const stakingData = getStakingData(stakingInfo);

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined;
  const inCooldownWindow = redemptionTime && BigNumber.from(Math.floor(Date.now() / 1000)).lt(redemptionTime);

  const plpSupply = balancesAndSupplies ? balancesAndSupplies[1] : BigNumber.from(0);
  const plpPriceHookInstance = usePlpPrice();
  const plpPrice = React.useMemo(() => plpPriceHookInstance.amount, [plpPriceHookInstance.amount]);
  const plpSupplyUsd = plpSupply.mul(plpPrice).div(expandDecimals(1, PLP_DECIMALS));

  let maxSellAmount = plpBalance;

  const { infoTokens, usdpAmount: usdpSupply } = useInfoTokens();
  const collateralToken = getToken(chainId, collateralTokenAddress);
  const collateralTokenInfo = getTokenInfo(infoTokens, collateralTokenAddress);

  const collateralTokenBalance =
    collateralTokenInfo && collateralTokenInfo.balance ? collateralTokenInfo.balance : BigNumber.from(0);

  const swapAmount = parseValue(swapValue, collateralToken && collateralToken.decimals) ?? BigNumber.from(0);
  const plpAmount = parseValue(plpValue, PLP_DECIMALS) ?? BigNumber.from(0);

  const needApproval = React.useMemo(() => {
    if (!isBuying) {
      return false;
    }
    if (collateralTokenAddress === AddressZero) {
      return false;
    }
    return tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance);
  }, [collateralTokenAddress, isBuying, swapAmount, tokenAllowance]);

  const swapUsdMin = getUsd(swapAmount, collateralTokenAddress, false, infoTokens);
  const plpUsdMax = plpAmount && plpPrice ? plpAmount.mul(plpPrice).div(expandDecimals(1, PLP_DECIMALS)) : undefined;

  let isCollateralTokenCapReached: boolean = true;

  const { palmStakeStats } = useStakesStats();
  const { userStaked: palmUserStaked } = palmStakeStats;

  if (collateralTokenInfo.managedUsd && collateralTokenInfo.maxUsdpAmount) {
    isCollateralTokenCapReached = collateralTokenInfo.managedUsd.gt(
      adjustForDecimals(collateralTokenInfo.maxUsdpAmount, USDP_DECIMALS, USD_DECIMALS)
    );
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true);
    setSwapValue(e.target.value);
  };

  const onPlpValueChange = (e) => {
    setAnchorOnSwapAmount(false);
    setPlpValue(e.target.value);
  };

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setPlpValue("");
          setFeeBasisPoints(0);
          return;
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpToAmount(
            swapAmount,
            collateralTokenAddress,
            infoTokens,
            plpPrice,
            usdpSupply
          );
          const nextValue = formatAmountFree(nextAmount, PLP_DECIMALS, PLP_DECIMALS);
          setPlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpFromAmount(
            swapAmount,
            collateralTokenAddress,
            infoTokens,
            plpPrice,
            usdpSupply
          );
          const nextValue = formatAmountFree(nextAmount, PLP_DECIMALS, PLP_DECIMALS);
          setPlpValue(nextValue);
          setFeeBasisPoints(feeBps);
        }

        return;
      }

      if (!plpAmount) {
        setSwapValue("");
        setFeeBasisPoints(0);
        return;
      }

      if (collateralToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpFromAmount(
            plpAmount,
            collateralTokenAddress,
            infoTokens,
            plpPrice,
            usdpSupply
          );
          const nextValue = formatAmountFree(nextAmount, collateralToken.decimals, collateralToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpToAmount(
            plpAmount,
            collateralTokenAddress,
            infoTokens,
            plpPrice,
            usdpSupply
          );

          const nextValue = formatAmountFree(nextAmount, collateralToken.decimals, collateralToken.decimals);
          setSwapValue(nextValue);
          setFeeBasisPoints(feeBps);
        }
      }
    };

    updateSwapAmounts();
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    plpAmount,
    collateralToken,
    collateralTokenAddress,
    infoTokens,
    plpPrice,
    usdpSupply,
  ]);

  const fillMaxAmount = useCallback(() => {
    if (isBuying) {
      setAnchorOnSwapAmount(true);
      setSwapValue(formatAmountFree(collateralTokenBalance, collateralToken.decimals, collateralToken.decimals));
      return;
    }

    setAnchorOnSwapAmount(false);
    setPlpValue(formatAmountFree(maxSellAmount, PLP_DECIMALS, PLP_DECIMALS));
  }, [collateralToken.decimals, collateralTokenBalance, isBuying, maxSellAmount]);

  const getError = useCallback(() => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isBuying) return [t`PLP buy disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`PLP sell disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (!isBuying && inCooldownWindow) {
      return [t`Redemption time not yet reached`];
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return [t`Enter an amount`];
    }
    if (!plpAmount || plpAmount.eq(0)) {
      return [t`Enter an amount`];
    }

    if (isBuying) {
      const collateralTokenInfo = getTokenInfo(infoTokens, collateralTokenAddress);
      if (
        !savedShouldDisableValidationForTesting &&
        collateralTokenInfo &&
        collateralTokenInfo.balance &&
        swapAmount &&
        swapAmount.gt(collateralTokenInfo.balance)
      ) {
        return [t`Insufficient ${TokenUtils.getSymbol(collateralTokenInfo)} balance`];
      }

      if (collateralTokenInfo.maxUsdpAmount && collateralTokenInfo.usdpAmount && swapUsdMin) {
        const usdpFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDP_DECIMALS);
        const nextUsdpAmount = collateralTokenInfo.usdpAmount.add(usdpFromAmount);
        if (collateralTokenInfo.maxUsdpAmount.gt(0) && nextUsdpAmount.gt(collateralTokenInfo.maxUsdpAmount)) {
          return [t`${TokenUtils.getSymbol(collateralTokenInfo)} pool exceeded, try different token`, true];
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && plpAmount && plpAmount.gt(maxSellAmount)) {
        return [t`Insufficient PLP balance`];
      }

      const collateralTokenInfo = getTokenInfo(infoTokens, collateralTokenAddress);
      if (
        collateralTokenInfo &&
        collateralTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(collateralTokenInfo.availableAmount)
      ) {
        return [t`Insufficient liquidity`];
      }
    }

    return [false];
  }, [
    chainId,
    collateralTokenAddress,
    inCooldownWindow,
    infoTokens,
    isBuying,
    maxSellAmount,
    plpAmount,
    swapAmount,
    swapUsdMin,
  ]);

  const isPrimaryEnabled = useMemo(() => {
    if (IS_NETWORK_DISABLED[chainId]) {
      return false;
    }
    if (!isActive) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (isBuying && isCollateralTokenCapReached) {
      return false;
    }

    return true;
  }, [
    chainId,
    getError,
    isActive,
    isApproving,
    isBuying,
    isCollateralTokenCapReached,
    isSubmitting,
    isWaitingForApproval,
    needApproval,
  ]);

  const collateralTokenSymbol = TokenUtils.getSymbol(collateralToken);
  const primaryText = useMemo(() => {
    if (!isActive) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }
    if (isBuying && isCollateralTokenCapReached) {
      return t`Max Capacity for ${collateralTokenSymbol} Reached`;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${collateralTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${collateralTokenSymbol}`;
    }

    if (isSubmitting) {
      return isBuying ? t`Buying...` : t`Unstaking...`;
    }

    return isBuying ? t`Buy & Stake PLP` : t`Unstake PLP`;
  }, [
    collateralTokenSymbol,
    getError,
    isActive,
    isApproving,
    isBuying,
    isCollateralTokenCapReached,
    isSubmitting,
    isWaitingForApproval,
    needApproval,
  ]);

  const approveFromToken = useCallback(() => {
    approveTokens({
      setIsApproving,
      library: provider!,
      tokenAddress: collateralToken.address,
      spender: plpManagerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
      },
      infoTokens,
      getTokenInfo,
    });
  }, [chainId, collateralToken.address, infoTokens, plpManagerAddress, provider]);

  const buyAndStakePlp = useCallback(() => {
    setIsSubmitting(true);

    const minPlp = plpAmount.mul(BASIS_POINTS_DIVISOR - props.savedSlippageAmount).div(BASIS_POINTS_DIVISOR);

    const contract = new ethers.Contract(plpRewardRouterAddress, PlpRewardRouterAbi.abi, provider!.getSigner());
    const method = "mintAndStakePlp";
    const params = [priceFeedsUpdateData, swapAmount, 0, minPlp];
    const setPendingTxns = props.setPendingTxns;
    callContract(chainId, contract, method, params, {
      value: updateFee,
      sentMsg: t`Buy and stake submitted.`,
      failMsg: t`Buy and stake failed.`,
      successMsg: t`${formatBigAmount(plpAmount, PLP_DECIMALS, undefined, {
        mantissa: 4,
      })} PLP bought and staked with ${formatBigAmount(
        swapAmount,
        collateralTokenInfo.decimals
      )} ${collateralTokenSymbol}!`,
      setPendingTxns,
      txnSuccessCallback: () => {
        setModalSuccessOpen(true);
        setIsSubmitting(false);
        setPlpValue("");
        setSwapValue("");
      },
    }).catch(() => {
      setIsSubmitting(false);
    });
  }, [
    chainId,
    collateralTokenInfo.decimals,
    collateralTokenSymbol,
    plpAmount,
    plpRewardRouterAddress,
    props.savedSlippageAmount,
    props.setPendingTxns,
    provider,
    swapAmount,
    priceFeedsUpdateData,
    updateFee,
  ]);

  const unstakePlp = useCallback(() => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(plpRewardRouterAddress, PlpRewardRouterAbi.abi, provider!.getSigner());
    const method = "unstakePlp";
    const params = [plpAmount];
    const setPendingTxns = props.setPendingTxns;

    callContract(chainId, contract, method, params, {
      sentMsg: t`Unstake submitted!`,
      failMsg: t`Unstake failed.`,
      successMsg: t`${formatBigAmount(plpAmount, PLP_DECIMALS, undefined, { mantissa: 4 })} PLP unstaked!`,
      setPendingTxns,
      txnSuccessCallback: () => {
        setPlpValue("");
        setSwapValue("");
        setIsSubmitting(false);
        setModalSuccessOpen(true);
      },
    }).catch(() => {
      setIsSubmitting(false);
    });
  }, [chainId, plpAmount, plpRewardRouterAddress, props.setPendingTxns, provider]);

  const onClickPrimary = useCallback(() => {
    if (!isActive) {
      props.connectWallet();
      return;
    }

    if (needApproval) {
      approveFromToken();
      return;
    }

    const [, modal] = getError();

    if (modal) {
      setModalError(true);
      return;
    }

    if (isBuying) {
      buyAndStakePlp();
    } else {
      unstakePlp();
    }
  }, [approveFromToken, buyAndStakePlp, getError, isActive, isBuying, needApproval, props, unstakePlp]);

  let payLabel = t`Pay`;
  let receiveLabel = t`Receive`;
  let payBalance = "$0.00";
  let receiveBalance = "$0.00";
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatBigAmount(swapUsdMin, USD_DECIMALS, undefined, { mantissa: 2, trimMantissa: false })}`;
    }
    if (plpUsdMax) {
      receiveBalance = `$${formatBigAmount(plpUsdMax, USD_DECIMALS, undefined, { mantissa: 2, trimMantissa: false })}`;
    }
  } else {
    if (plpUsdMax) {
      payBalance = `$${formatBigAmount(plpUsdMax, USD_DECIMALS, undefined, { mantissa: 2, trimMantissa: false })}`;
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatBigAmount(swapUsdMin, USD_DECIMALS, undefined, { mantissa: 2, trimMantissa: false })}`;
    }
  }

  let feePercentageText = formatBigAmount(feeBasisPoints, 2, "-", { mantissa: 2, trimMantissa: false });
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += "%";
  }

  // const { price: palmPrice } = useTokenPrices("PALM");
  // const palmPriceBigNumUsd = BigNumber.from(parseValue(String(palmPrice), USD_DECIMALS));

  let totalPalmApr = BigNumber.from(0);

  let totalPlpApr = BigNumber.from(0);

  // if (
  //   stakingData &&
  //   stakingData.feePalmTracker &&
  //   stakingData.feePalmTracker.totalSupply &&
  //   stakingData.feePalmTracker.tokensPerInterval &&
  //   collateralTokenInfo &&
  //   collateralTokenInfo.minPrice &&
  //   !areHardcodedZeroAprAndTotalReturn
  // ) {
  //   const feePalmSupply = stakingData.feePalmTracker.totalSupply;
  //   const feePalmSupplyUsd = feePalmSupply.mul(palmPriceBigNumUsd).div(expandDecimals(1, PALM_DECIMALS));
  //   const feePalmTrackerAnnualRewardsUsd = stakingData.feePalmTracker.tokensPerInterval
  //     .mul(SECONDS_PER_YEAR)
  //     .mul(collateralTokenInfo.minPrice)
  //     .div(expandDecimals(1, 18));

  //   const palmAprForNativeToken =
  //     feePalmSupplyUsd && feePalmSupplyUsd.gt(0)
  //       ? feePalmTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(feePalmSupplyUsd)
  //       : BigNumber.from(0);

  //   totalPalmApr = totalPalmApr.add(palmAprForNativeToken);
  // }

  if (
    stakingData &&
    stakingData.feeGlpTracker &&
    stakingData.feeGlpTracker.tokensPerInterval &&
    collateralTokenInfo &&
    collateralTokenInfo.minPrice &&
    plpSupplyUsd &&
    plpSupplyUsd.gt(0) &&
    !areHardcodedZeroAprAndTotalReturn
  ) {
    const feePlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(collateralTokenInfo.minPrice)
      .div(expandDecimals(1, 18));
    const feePlpTrackerApr = feePlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(plpSupplyUsd);

    totalPlpApr = totalPlpApr.add(feePlpTrackerApr);
  }

  const BuyAndSellPalmSection = useCallback(() => {
    return (
      <>
        {buyPALMOptions.map((option) => (
          <div
            className="w-full h-fit border bg-background-5-v2-only relative overflow-hidden rounded-[2.5rem] p-[2rem] flex place-content-between"
            key={option.title}
          >
            <div className="flex gap-[2rem] items-center ">
              <img src={option.image} alt="logo" className="h-[4rem] w-auto" />

              <span className="font-base leading-4 ">{option.title}</span>
            </div>

            <Button to={option.link} variant="primary-action" size="lg">
              <Trans>Buy</Trans>
            </Button>
          </div>
        ))}
      </>
    );
  }, []);

  return (
    <SEO title={getPageTitle("Buy PALM or PLP")}>
      <div className="Buy page-layout mb-[6rem] md:mb-0 tailwind">
        <AfterBuyPlpModal open={modalSuccessOpen} action={isBuying ? "buy" : "sell"} setOpen={setModalSuccessOpen} />

        <div className="Buy-container default-container">
          <div className="Tab-container dark:bg-white/[.05] bg-black/[.05]">
            <div className={cx("Tab-switch", { left: activeTab === "PLP", right: activeTab === "PALM" })}>
              {tabsOptions.map((opt) => (
                <div
                  className={cx("Tab-option", { active: activeTab === opt })}
                  onClick={() => setActiveTab(opt)}
                  key={opt}
                >
                  Buy {opt}
                </div>
              ))}
            </div>
          </div>
          {activeTab === "PALM" && (
            <>
              <div className="w-full mt-[6.6rem] gap-[2rem] flex h-full isolate">
                <div className="w-full flex flex-col gap-[2.5rem] min-w-0">
                  <PalmChart />

                  <div className="flex flex-col">
                    <div className="text-[2.4rem] font-semibold mb-[1.4rem]">Stats</div>

                    <div className="flex-col flex md:flex-row gap-[3.2rem] mb-[2.6rem]">
                      <div className="flex gap-[3.2rem]">
                        <div className="flex-col flex gap-2 whitespace-nowrap	">
                          <p className="text-[1.4rem] text-inactive">
                            <Trans>Wallet</Trans>
                          </p>
                          <p className="text-[2.4rem]">
                            {formatBigAmount(palmTokenBalance.balance, PALM_DECIMALS, undefined, { mantissa: 2 })} PALM
                          </p>{" "}
                        </div>

                        <div className="flex-col flex gap-2 whitespace-nowrap	">
                          <p className="text-[1.4rem] text-inactive">
                            <Trans>Staked</Trans>
                          </p>

                          <p className="text-[2.4rem]">
                            {formatBigAmount(palmUserStaked, PALM_DECIMALS, undefined, { mantissa: 2 })} PALM
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[3.2rem]">
                        <div className="flex-col flex gap-2">
                          <p className="text-[1.4rem] text-inactive">
                            <Trans>APR</Trans>
                          </p>
                          <p className="text-[2.4rem]">
                            {formatBigAmount(totalPalmApr, 2, undefined, { mantissa: 2 })}%
                          </p>
                        </div>
                        <div className="flex-col flex gap-2 whitespace-nowrap	">
                          <p className="text-[1.4rem] text-inactive">
                            <Trans>Total Supply</Trans>
                          </p>
                          <p className="text-[2.4rem]">
                            {formatBigAmount(totalSupply, PALM_DECIMALS, undefined, { mantissa: 0 })} PALM
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mb-[2.6rem] gap-3 items-start justify-start">
                      <a
                        className="rounded-full text-[1.4rem] flex items-center"
                        href="https://bscscan.com/address/0x29745314B4D294B7C77cDB411B8AAa95923aae38"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex items-center gap-1 px-[2.6rem] w-full dark:bg-white/5 bg-black/5 py-[1rem] rounded-full m-0 transition-effect hover:dark:bg-white hover:bg-black hover:text-opposite-text-color">
                          BscScan
                          <FiArrowUpRight />
                        </div>
                      </a>
                      <a
                        className="h-[3.5rem] rounded-full text-[1.4rem]  flex items-center"
                        href="https://docs.palmswap.org/ecosystem/liquidity-providing-plp"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex items-center gap-1 px-[2.6rem] w-full dark:bg-white/5 bg-black/5 py-[1rem] rounded-full m-0 transition-effect hover:dark:bg-white hover:bg-black hover:text-opposite-text-color">
                          Documentation
                          <FiArrowUpRight />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-[35rem] flex-grow-0 flex-shrink-0 hidden md:flex h-fit flex-col relative overflow-hidden gap-[1rem]">
                  <BuyAndSellPalmSection />
                </div>
              </div>
            </>
          )}
          {activeTab === "PLP" && (
            <div className="w-full mt-[6.6rem] gap-[2rem] flex h-full isolate">
              <div className="w-full flex flex-col gap-[2.5rem] min-w-0">
                <PlpChart />

                <div className="flex flex-col">
                  <div className="text-[2.4rem] font-semibold mb-[1.4rem]">Stats</div>
                  <div className="flex-col flex md:flex-row gap-[3.2rem] mb-[2.6rem]">
                    <div className="flex gap-[3.2rem]">
                      <div className="flex-col flex gap-2">
                        <p className="text-[1.4rem] text-inactive ">
                          <Trans>Wallet</Trans>
                        </p>
                        <p className="text-[2.4rem] whitespace-nowrap">
                          {formatBigAmount(plpBalance, PLP_DECIMALS, undefined, { mantissa: 2 })} PLP
                        </p>
                      </div>
                      <div className="flex-col flex gap-2">
                        <p className="text-[1.4rem] text-inactive">
                          <Trans>Staked</Trans>
                        </p>
                        <p className="text-[2.4rem] whitespace-nowrap">
                          {formatBigAmount(plpBalance, PLP_DECIMALS, undefined, { mantissa: 2 })} PLP
                        </p>
                      </div>
                    </div>

                    <div className="flex  gap-[3.2rem]">
                      <div className="flex-col flex gap-2">
                        <p className="text-[1.4rem] text-inactive">
                          <Trans>APR</Trans>
                        </p>
                        <p className="text-[2.4rem] whitespace-nowrap">
                          {formatBigAmount(totalPlpApr, 2, undefined, { mantissa: 2 })}%
                        </p>
                      </div>
                      <div className="flex-col flex gap-2">
                        <p className="text-[1.4rem] text-inactive">
                          <Trans>Total Supply</Trans>
                        </p>
                        <p className="text-[2.4rem] whitespace-nowrap">
                          {formatBigAmount(plpSupply, PLP_DECIMALS, undefined, { mantissa: 2 })} PLP
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 mb-[2.6rem]">
                    <a
                      className="h-[3.5rem] rounded-full text-[1.4rem]  flex items-center"
                      href={`https://${chainId === TESTNET ? "testnet." : ""}bscscan.com/address/${plpAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex items-center gap-1 w-full dark:bg-white/5 bg-black/5 py-[1rem] px-[1.5rem] rounded-full  m-0 transition-effect hover:dark:bg-white hover:bg-black hover:text-opposite-text-color">
                        Bscscan
                        <FiArrowUpRight />
                      </div>
                    </a>
                    <a
                      className="h-[3.5rem] text-[1.4rem]  rounded-full flex items-center"
                      href="https://docs.palmswap.org/ecosystem/liquidity-providing-plp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex items-center gap-1 w-full dark:bg-white/5 bg-black/5 py-[1rem] px-[1.5rem] rounded-full m-0 transition-effect hover:dark:bg-white hover:bg-black hover:text-opposite-text-color">
                        Documentation
                        <FiArrowUpRight />
                      </div>
                    </a>
                  </div>
                  <div className="flex w-full h-px bg-border"></div>
                </div>
                <div className="flex flex-col">
                  <div className="text-[2.4rem] font-semibold mb-[1.4rem]">Liquidity</div>
                  <p className="text-[1.4rem] text-inactive mb-[2.4rem]">
                    <Trans>Fees are paid when PLP is bought or sold.</Trans>
                  </p>
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="h-[4rem] text-[1.2rem] text-inactive bg-background-3 ">
                        <th className="rounded-l-[1rem] text-left md:text-center pl-[1rem] md:pl-0">Token</th>
                        <th>Price</th>
                        <th className="hidden md:table-cell">Available</th>
                        <th className="hidden md:table-cell">Wallet</th>
                        <th>Fees</th>
                        <th className="rounded-r-[1rem] text-background-3">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visibleTokens.map((token) => {
                        let tokenFeeBps;
                        if (isBuying) {
                          const { feeBasisPoints: feeBps } = getBuyGlpFromAmount(
                            plpAmount,
                            token.address,
                            infoTokens,
                            plpPrice,
                            usdpSupply
                          );
                          tokenFeeBps = feeBps;
                        } else {
                          const { feeBasisPoints: feeBps } = getSellGlpToAmount(
                            plpAmount,
                            token.address,
                            infoTokens,
                            plpPrice,
                            usdpSupply
                          );
                          tokenFeeBps = feeBps;
                        }
                        const tokenInfo = getTokenInfo(infoTokens, token.address);
                        let managedUsd;
                        if (tokenInfo && tokenInfo.managedUsd) {
                          managedUsd = tokenInfo.managedUsd;
                        }

                        let balanceUsd;
                        if (tokenInfo && tokenInfo.minPrice && tokenInfo.balance) {
                          balanceUsd = tokenInfo.balance.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals));
                        }
                        let isCapReached = (tokenInfo.managedAmount ?? BigNumber.from(0)).gt(
                          tokenInfo.maxUsdpAmount ?? 0
                        );

                        function renderFees() {
                          const swapUrl = `https://app.1inch.io/#/${chainId}/swap/`;
                          switch (true) {
                            case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
                              return (
                                <Tooltip
                                  renderContent={() => (
                                    <div className="text-white">
                                      <Trans>
                                        Max pool capacity reached for {TokenUtils.getSymbol(tokenInfo)}
                                        <br />
                                        <br />
                                        Please mint PLP using another token
                                      </Trans>
                                      <br />
                                      <p>
                                        <ExternalLink href={swapUrl}>
                                          <Trans> Swap {TokenUtils.getSymbol(tokenInfo)} on 1inch</Trans>
                                        </ExternalLink>
                                      </p>
                                    </div>
                                  )}
                                >
                                  NA
                                </Tooltip>
                              );
                            case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
                              return `${formatBigAmount(tokenFeeBps, 2, "-", { mantissa: 2 })}${
                                tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? "%" : ""
                              }`;
                            default:
                              return "";
                          }
                        }

                        return (
                          <tr className="h-[6.5rem]" key={TokenUtils.getSymbol(token)}>
                            <td>
                              <div className="w-full h-full flex items-center justify-start ml-[1rem] gap-3">
                                <TokenUtils.Logo token={token} className="w-[4rem]"></TokenUtils.Logo>

                                <div className="flex-col text=[1.4rem] gap-1">
                                  <TokenUtils.Name token={token}></TokenUtils.Name>
                                  <TokenUtils.Symbol token={token} className="text-inactive"></TokenUtils.Symbol>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="flex items-center justify-center">
                                ${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}
                              </div>
                            </td>
                            <td className="hidden md:table-cell">
                              <div className="flex items-center justify-center">
                                {plpSupplyUsd && plpSupplyUsd.lt(0)
                                  ? "$0.00"
                                  : `$${formatBigAmount(plpSupplyUsd, USD_DECIMALS, undefined, { mantissa: 2 })}`}
                              </div>
                            </td>
                            <td className="hidden md:table-cell">
                              <div className="flex items-center justify-center">
                                {formatKeyAmount(tokenInfo, "balance", tokenInfo.decimals, 2, true)}{" "}
                                {TokenUtils.getSymbol(tokenInfo)} ($
                                {formatBigAmount(balanceUsd, USD_DECIMALS, undefined, { mantissa: 2 })})
                              </div>
                            </td>
                            <td>
                              <div className="flex items-center justify-center">{renderFees()}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="flex w-full h-px bg-border"></div>
                </div>
                <div className="flex flex-col">
                  <div className="text-[2.4rem] font-semibold mb-[1.4rem]">What is PLP?</div>
                  <p className="text-[1.4rem] text-inactive mb-3">
                    <Trans>
                      PLP is the liquidity provider token of the Palmswap Exchange platform, a decentralized exchange
                      built on the Binance Smart Chain. As a liquidity provider token, PLP represents a share of the
                      liquidity pool on the platform. Holders of PLP can earn a share of the trading fees generated on
                      the platform, in proportion to the amount of PLP they hold.
                      <br />
                      <br />
                      PLP is an index token that tracks the price of USDT, the stablecoin that is used as a base
                      currency on the Palmswap platform. This means that the price of PLP{showMoreText}
                    </Trans>
                  </p>
                  <div className="no-underline text-[1.4rem] text-main cursor-pointer" onClick={handleShowMoreClick}>
                    {showMoreButtonText}
                  </div>
                </div>
              </div>

              <div className="w-[35rem] flex-grow-0 flex-shrink-0 hidden md:flex md:flex-col gap-[1.5rem] h-fit">
                <div
                  className={cx([
                    "border bg-background-5-v2-only relative overflow-hidden rounded-[2.5rem] p-[2.5rem]",
                  ])}
                >
                  <BuyStakeAndUnstakePlpSection
                    collateralTokenDecimals={collateralToken.decimals}
                    collateralTokenBalance={collateralTokenBalance}
                    feePercentageText={feePercentageText}
                    fillMaxAmount={fillMaxAmount}
                    primaryText={primaryText}
                    activeAction={activeAction}
                    isBuying={isBuying}
                    isCollateralTokenCapReached={isCollateralTokenCapReached}
                    isPrimaryEnabled={isPrimaryEnabled}
                    maxSellAmount={maxSellAmount}
                    onClickPrimary={onClickPrimary}
                    payBalance={payBalance}
                    payLabel={payLabel}
                    plpBalance={plpBalance}
                    plpValue={plpValue}
                    receiveBalance={receiveBalance}
                    receiveLabel={receiveLabel}
                    swapValue={swapValue}
                    setActiveAction={setActiveAction}
                    onSwapValueChange={onSwapValueChange}
                    buyInputAmountRef={buyInputAmountRef}
                    onPlpValueChange={onPlpValueChange}
                    sellInputAmountRef={sellInputAmountRef}
                  />
                </div>

                {activeAction === "sell" && (
                  <div className={cx(["border bg-background-5-v2-only rounded-[2.5rem] p-[2.5rem]"])}>
                    <SellPlpSection
                      feePercentageText={feePercentageText}
                      isCollateralTokenCapReached={isCollateralTokenCapReached}
                      plpRedeem={plpRedeem}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {activeTab === "PALM" && (
          <>
            <div className="fixed bottom-0 left-0 w-full md:hidden h-[7rem] bg-background-2 dark:bg-background-5-v2-only rounded-t-[--border-radius-md] flex border border-solid border-border items-center px-[1.5rem]">
              <Button
                variant="primary-action"
                className="w-full capitalize"
                onClick={() => setBuyPALMModalVisible(true)}
                disabled={false}
              >
                <Trans>Buy PALM</Trans>
              </Button>
            </div>
            <Modal
              className="Connecting-wallet-modal "
              isVisible={buyPALMModalVisible}
              setIsVisible={setBuyPALMModalVisible}
              label={t`Buy PALM`}
            >
              <div className="h-fit flex flex-col relative overflow-hidden gap-[1rem] flex-grow-0 flex-shrink-0">
                <BuyAndSellPalmSection />
              </div>
            </Modal>
          </>
        )}
        {activeTab === "PLP" && (
          <>
            <div className="fixed bottom-0 left-0 w-full md:hidden h-[7rem] bg-background-2 dark:bg-background-5-v2-only  rounded-t-[--border-radius-md] flex border border-solid border-border items-center px-[1.5rem]">
              <Button
                variant="primary-action"
                className="w-full capitalize"
                onClick={() => setBuyPLPModalVisible(true)}
                disabled={false}
              >
                <Trans>Buy/Sell PLP</Trans>
              </Button>
            </div>
            <Modal
              className="Connecting-wallet-modal"
              isVisible={buyPLPModalVisible}
              setIsVisible={setBuyPLPModalVisible}
              label={t`Buy/Sell PLP`}
            >
              <div className="flex flex-col gap-[1.5rem]">
                <BuyStakeAndUnstakePlpSection
                  collateralTokenDecimals={collateralToken.decimals}
                  collateralTokenBalance={collateralTokenBalance}
                  feePercentageText={feePercentageText}
                  fillMaxAmount={fillMaxAmount}
                  primaryText={primaryText}
                  activeAction={activeAction}
                  isBuying={isBuying}
                  isCollateralTokenCapReached={isCollateralTokenCapReached}
                  isPrimaryEnabled={isPrimaryEnabled}
                  maxSellAmount={maxSellAmount}
                  onClickPrimary={onClickPrimary}
                  payBalance={payBalance}
                  payLabel={payLabel}
                  plpBalance={plpBalance}
                  plpValue={plpValue}
                  receiveBalance={receiveBalance}
                  receiveLabel={receiveLabel}
                  swapValue={swapValue}
                  setActiveAction={setActiveAction}
                  onSwapValueChange={onSwapValueChange}
                  buyInputAmountRef={buyInputAmountRef}
                  onPlpValueChange={onPlpValueChange}
                  sellInputAmountRef={sellInputAmountRef}
                />

                {activeAction === "sell" && (
                  <SellPlpSection
                    className="mt-[2rem]"
                    feePercentageText={feePercentageText}
                    isCollateralTokenCapReached={isCollateralTokenCapReached}
                    plpRedeem={plpRedeem}
                  />
                )}
              </div>
            </Modal>
          </>
        )}
      </div>
    </SEO>
  );
}

export const isCooldownListCollapsedAtom = atom(false);

type SellPlpSectionProps = {
  className?: string;
  feePercentageText: string;
  isCollateralTokenCapReached: boolean;
  plpRedeem: ReturnType<typeof usePlpRedeem>;
};
const SellPlpSection = memo((props: SellPlpSectionProps) => {
  const { plpRedeem } = props;
  const redeemUnits = plpRedeem.plpStake?.redeemInfo ?? [];

  const timestamp = useCurrentSecond();

  const activeCooldowns = redeemUnits.filter((el) => el.expire >= timestamp).length;
  const overCooldowns = redeemUnits.filter((el) => el.expire < timestamp).length;
  const totalCooldowns = activeCooldowns + overCooldowns;
  const totalCooldownsOldRef = useRef(totalCooldowns);

  const getUsdAmountFromPlpAmount = useGetUsdAmountFromPlpAmount();

  const [isCollapsed, setIsCollapsed] = useAtom(isCooldownListCollapsedAtom);

  useEffect(() => {
    if (totalCooldowns > totalCooldownsOldRef.current && isCollapsed) {
      setIsCollapsed(false);
    }
    totalCooldownsOldRef.current = totalCooldowns;
  }, [isCollapsed, setIsCollapsed, totalCooldowns]);

  const cooldownPeriodsCountersText = useMemo(() => {
    return [activeCooldowns && t`${activeCooldowns} Active`, overCooldowns && t`${overCooldowns} Over`]
      .filter(Boolean)
      .join(", ");
  }, [activeCooldowns, overCooldowns]);

  return (
    <>
      {
        <div className={cx("relative", props.className)}>
          <div className="flex justify-between items-center">
            <p className="text-[2.4rem] leading-[1.4] font-medium">
              <Trans>Sell Cooldown</Trans>
            </p>

            <button
              className={cx(
                "h-[3rem] aspect-square rounded-full bg-white/10 hover:bg-white/20 active:bg-white/5 transition-[colors,transform] duration-200 flex items-center justify-center",
                { "-rotate-180": isCollapsed }
              )}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <div className="h-[1.4rem]">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                  <path
                    d="M 2.625 9.188 L 7 4.813 L 11.375 9.188"
                    fill="transparent"
                    stroke="rgb(108, 114, 132)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
          </div>

          <AnimatePresence initial={false}>
            {!isCollapsed && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  visible: { opacity: 1, height: "auto" },
                  hidden: { opacity: 0, height: "0" },
                }}
                transition={{ duration: 0.15, ease: "easeInOut" }}
                className="overflow-hidden"
              >
                {plpRedeem.plpStake === undefined ? (
                  <div className="mt-[20px] h-[6rem] relative">
                    <Loader />
                  </div>
                ) : activeCooldowns + overCooldowns >= 1 ? (
                  <div className="mt-[20px]">
                    <p className="text-[1.4rem] leading-[1.5] text-inactive">
                      <Trans>Cooldown Periods ({cooldownPeriodsCountersText})</Trans>
                    </p>

                    <ul className="mt-[1rem] flex flex-col items-stretch gap-4">
                      {redeemUnits.map((el) => {
                        return (
                          <li className="flex justify-between items-start gap-4" key={el.id}>
                            <div className="flex flex-col gap-2">
                              <div className="text-[2rem] leading-[1.5] font-medium">
                                {formatBigAmount(el.amount, PLP_DECIMALS)} PLP
                              </div>

                              <div className="text-[1.4rem] leading-[1.5] text-inactive">
                                ~{formatAmount(getUsdAmountFromPlpAmount(el.amount), { mantissa: 2 })} USDT
                              </div>
                            </div>

                            {el.expire > timestamp ? (
                              <Tooltip renderContent={() => format(el.expire, `MMM d, yyyy HH:mm O`)}>
                                <Button variant="minor" className="min-w-[6.6rem]" disabled colorfulDisabled>
                                  {formatDistanceToNowStrict(el.expire, { roundingMethod: "ceil" })}
                                  <TipIcon />
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button
                                variant="red"
                                className="min-w-[6.6rem]"
                                disabled={plpRedeem.isMutating}
                                onClick={() => plpRedeem.redeemAndSell([el.index])}
                              >
                                <Trans>Sell</Trans>
                              </Button>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="mt-[15px] flex justify-between items-center text-[1.2rem] leading-[2.4rem]">
                      <div className="text-inactive">
                        <Trans>Selling PLP Fees</Trans>
                      </div>

                      <div>{props.isCollateralTokenCapReached ? "NA" : props.feePercentageText}</div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-[20px] relative justify-center items-center">
                    <p className="text-[1.4rem] leading-[1.5] text-inactive">
                      <Trans>No cooldown periods</Trans>
                    </p>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      }
    </>
  );
});

const BuyStakeAndUnstakePlpSection = ({
  collateralTokenDecimals,
  collateralTokenBalance,
  feePercentageText,
  fillMaxAmount,
  primaryText,
  activeAction,
  isBuying,
  isCollateralTokenCapReached,
  isPrimaryEnabled,
  maxSellAmount,
  onClickPrimary,
  payBalance,
  payLabel,
  plpBalance,
  plpValue,
  receiveBalance,
  receiveLabel,
  swapValue,
  setActiveAction,
  onSwapValueChange,
  buyInputAmountRef,
  onPlpValueChange,
  sellInputAmountRef,
}) => {
  const plpUnstakeCooldown = usePlpUnstakeCooldown();
  const plpUnstakeCooldownText = formatDistanceStrict(plpUnstakeCooldown, 0, { roundingMethod: "ceil" });

  return (
    <>
      <div className="flex justify-between h-[3.5rem] bg-background-3 rounded-[1rem]">
        {plpActions.map((action) => (
          <div
            key={action}
            onClick={() => setActiveAction("sell")}
            className={cx("flex w-full items-center justify-center rounded-[1rem] cursor-pointer select-none", {
              "bg-background-6": activeAction === action,
              "cursor-not-allowed": action === "buy",
            })}
          >
            <p className="text-[1.4rem] capitalize">
              <Trans>{action} PLP</Trans>
            </p>
          </div>
        ))}
      </div>
      <div className="mt-4 flex flex-col items-stretch">
        <form onSubmit={(e) => e.preventDefault()} noValidate>
          <fieldset className="flex flex-col items-stretch Buy-fieldset border-none">
            {isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={t`Balance:`}
                tokenBalance={`${formatBigAmount(collateralTokenBalance, collateralTokenDecimals, undefined, {
                  mantissa: 4,
                })}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                showMaxButton={
                  swapValue !==
                  formatAmountFree(collateralTokenBalance, collateralTokenDecimals, collateralTokenDecimals)
                }
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                balance={payBalance}
                ref={buyInputAmountRef}
              >
                <div className="selected-token">USDT</div>
              </BuyInputSection>
            )}
            {!isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={t`Available:`}
                tokenBalance={`${formatBigAmount(maxSellAmount, PLP_DECIMALS)}`}
                inputValue={plpValue}
                onInputValueChange={onPlpValueChange}
                showMaxButton={plpValue !== formatAmountFree(maxSellAmount, PLP_DECIMALS, PLP_DECIMALS)}
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                balance={payBalance}
                ref={sellInputAmountRef}
              >
                <div className="selected-token">PLP</div>
              </BuyInputSection>
            )}

            <div className="flex justify-center items-center -mt-[3rem] pointer-events-none">
              <button
                className="mt-[0em] w-[3rem] aspect-square rounded-full bg-main hover:bg-main-hover active:bg-main-active transition-colors flex items-center justify-center translate-y-[calc(50%-0.5rem)] pointer-events-auto"
                onClick={() => {
                  setActiveAction(isBuying ? "sell" : "buy");
                }}
                type="button"
              >
                <div className="flex items-center">
                  <HiArrowNarrowUp className="-mr-1.5 mb-1" style={{ color: "var(--opposite-text-color)" }} />
                  <HiArrowNarrowDown className="-ml-1.5 mt-1" style={{ color: "var(--opposite-text-color)" }} />
                </div>
              </button>
            </div>

            {isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={t`Balance:`}
                tokenBalance={`${formatBigAmount(plpBalance, PLP_DECIMALS)}`}
                inputValue={plpValue}
                onInputValueChange={onPlpValueChange}
                balance={receiveBalance}
              >
                <div className="selected-token">PLP</div>
              </BuyInputSection>
            )}
            {!isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={t`Balance:`}
                tokenBalance={`${formatBigAmount(collateralTokenBalance, collateralTokenDecimals)}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                balance={receiveBalance}
              >
                <div className="selected-token">USDT</div>
              </BuyInputSection>
            )}

            {isBuying && (
              <div className="flex justify-between items-center text-[1.2rem] leading-[2.4rem] mb-[1rem]">
                <div className="text-inactive">
                  <Trans>Fees</Trans>
                </div>

                <div>{isCollateralTokenCapReached ? "NA" : feePercentageText}</div>
              </div>
            )}

            {!isBuying && (
              <div className="flex justify-between items-center text-[1.2rem] leading-[2.4rem] mb-[1rem]">
                <div className="text-inactive">
                  <Trans>Cooldown Period</Trans>
                </div>

                <div>{plpUnstakeCooldownText}</div>
              </div>
            )}

            <Button
              type="button"
              variant="primary-action"
              className="w-full capitalize"
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled}
            >
              <Trans>{primaryText}</Trans>
            </Button>
          </fieldset>
        </form>
      </div>
    </>
  );
};
