import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core'
import { Connection, ConnectionType } from 'lib/wallets/connections'
import { SUPPORTED_CHAIN_IDS, getRpcUrl } from 'config/chains'

export function buildCoinbaseWalletConnector(): Connection {
  const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: getRpcUrl(SUPPORTED_CHAIN_IDS[0])!,
          appName: 'Palmswap',
        },
      })
  )

  return {
    connector: coinbaseWallet,
    hooks,
    type: ConnectionType.COINBASE_WALLET,
  }
}


