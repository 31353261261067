import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

export enum AccountType {
  CONTRACT = "contract",
  EOA = "eoa",
}

export default function useAccountType() {
  const { isActive, account, provider } = useWeb3React();
  const [contractType, setContractType] = useState<AccountType | null>(null);

  useEffect(() => {
    if (!isActive || !provider || !account) return;

    (async function () {
      const code = await provider.getCode(account);
      const type = code === "0x" ? AccountType.EOA : AccountType.CONTRACT;
      setContractType(type);
    })();
  }, [account, provider, isActive]);

  return contractType;
}
