import "./Referrals.css";
import React from "react";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import { getPageTitle, isHashZero } from "lib/legacy";
import { useReferralsData, useCodeOwner, useReferrerTier, useUserReferralCode, useTiersInfo } from "domain/referrals";
import AffiliatesStats from "pages/Referrals/components/AffiliatesStats";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useChainId } from "lib/chains";
import { GradientContainer } from "components/GradientContainer";
import Button from "components/Button/Button";
import { CreateReferralCodeModal } from "pages/Referrals/components/CreateReferralCodeModal";
import { EnterReferralCodeModal } from "pages/Referrals/components/EnterReferralCodeModal";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { PageTabPanel, PageTabs } from "pages/Referrals/components/PageTabs";
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from "pages/Referrals/referralsHelper";
import TradersStats from "pages/Referrals/components/TradersStats";
import { REFERRAL_TAB_KEY } from "config/localStorage";
import EnterReferralCode from "pages/Referrals/components/EnterReferralCode";

const TAB_IDS = {
  TRADER: "Trader",
  REFERRER: "Referrer",
} as const;

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { isActive, account: walletAccount, provider } = useWeb3React();

  const [isEnterReferralCodeModalVisible, setIsEnterReferralCodeModalVisible] = React.useState(false);

  const { account: queryAccount } = useParams<{ account: string }>();

  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const { chainId } = useChainId();

  const referralsDataQuery = useReferralsData(chainId, account);
  const referralsData = referralsDataQuery.data;

  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    raw: false,
    serializer: JSON.stringify,
    deserializer: deserializeSampleStats,
  });
  const { userReferralCode, userReferralCodeString } = useUserReferralCode(provider, chainId, account);

  const tiersInfo = useTiersInfo(provider, chainId, account);

  const haveUserReferralCode = React.useMemo(() => {
    return !(isHashZero(userReferralCode) || !userReferralCode);
  }, [userReferralCode]);

  const { codeOwner } = useCodeOwner(provider, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(provider, chainId, codeOwner);

  const [isCreateReferralCodeModalVisible, setIsCreateReferralCodeModalVisible] = React.useState(false);

  const isReferralCodeAvailable =
    referralsData?.codes?.length > 0 || (recentlyAddedCodes ?? []).filter(isRecentReferralCodeNotExpired).length > 0;

  function ReferrerTabContent() {
    return (
      <>
        <header className="">
          <div className="flex flex-col gap-4">
            <h1 className="text-[2.4rem] leading-[1.4] font-medium">
              <Trans>Referral Rewards</Trans>
            </h1>

            <p className="text-[1.4rem] leading-[1.5] text-inactive inline">
              <span>
                <Trans>Earn rewards by inviting traders to trade on Palmswap.</Trans>
              </span>

              <span>&#32;</span>

              <ExternalLink
                href="https://docs.palmswap.org/protocol/referral-program"
                custom
                className="underline hover:no-underline"
              >
                <Trans>Referral program details.</Trans>
              </ExternalLink>
            </p>
          </div>
        </header>

        {isReferralCodeAvailable ? (
          <>
            <AffiliatesStats
              referralsData={referralsData}
              onCreateReferralCode={() => setIsCreateReferralCodeModalVisible(true)}
              setRecentlyAddedCodes={setRecentlyAddedCodes}
              recentlyAddedCodes={recentlyAddedCodes}
              tiersInfo={tiersInfo.data}
            />
          </>
        ) : (
          <>
            <GradientContainer className="mt-8 @container">
              <div className="flex gap-4 p-8 flex-col items-start @[60rem]:flex-row @[60rem]:items-center">
                <div className="flex flex-col flex-1">
                  <p className="text-[2.4rem] text-white leading-[1.4] font-extrabold font-mazzard">
                    <Trans>Generate Referral Code</Trans>
                  </p>

                  <p className="text-[1.4rem] leading-[1.4] text-white">
                    <Trans>Looks like you don't have a referral code to share.</Trans>
                  </p>
                </div>

                <div className="">
                  {isActive ? (
                    <Button
                      variant="white"
                      onClick={() => setIsCreateReferralCodeModalVisible(true)}
                      className="min-w-[17rem] ]"
                    >
                      <Trans>Create Referral Code</Trans>
                    </Button>
                  ) : (
                    <Button variant="white" onClick={connectWallet} className="min-w-[17rem]">
                      <Trans>Connect Wallet</Trans>
                    </Button>
                  )}
                </div>
              </div>
            </GradientContainer>
          </>
        )}
      </>
    );
  }

  const TraderTabContent = () => {
    return (
      <>
        <header className="">
          <div className="flex flex-col gap-4">
            <h1 className="text-[2.4rem] leading-[1.4] font-medium">
              <Trans>Fee Discount</Trans>
            </h1>

            <p className="text-[1.4rem] leading-[1.5] text-inactive inline">
              <span>
                <Trans>Enjoy up to a 10% discount on trading fees when using a referral code.</Trans>
              </span>

              <span>&#32;</span>

              <ExternalLink
                href="https://docs.palmswap.org/protocol/referral-program"
                custom
                className="underline hover:no-underline"
              >
                <Trans>Referral program details.</Trans>
              </ExternalLink>
            </p>
          </div>
        </header>

        {isHashZero(userReferralCode) || !account || !userReferralCode ? (
          <EnterReferralCode
            connectWallet={connectWallet}
            active={isActive}
            onCreateReferralCode={() => setIsEnterReferralCodeModalVisible(true)}
          />
        ) : (
          <TradersStats
            userReferralCodeString={userReferralCodeString ?? ""}
            chainId={chainId}
            referralsData={referralsData}
            traderTier={traderTier}
            onCreateReferralCode={() => setIsEnterReferralCodeModalVisible(true)}
            tiersInfo={tiersInfo.data}
          />
        )}
      </>
    );
  };

  const TAB_OPTION_LABELS = {
    [TAB_IDS.TRADER]: {
      label: t`As Trader`,
    },
    [TAB_IDS.REFERRER]: {
      label: t`As Referrer`,
    },
  };

  return (
    <SEO title={getPageTitle("Referral")}>
      <div className="tailwind isolate mx-8 my-16 flex flex-col items-stretch">
        <div className="mx-auto w-[114rem] max-w-full">
          <PageTabs
            tabsIds={[TAB_IDS.REFERRER, TAB_IDS.TRADER]}
            tabsDetails={TAB_OPTION_LABELS}
            storageKey={REFERRAL_TAB_KEY}
          >
            <PageTabPanel tabId={TAB_IDS.REFERRER}>
              <ReferrerTabContent />
            </PageTabPanel>

            <PageTabPanel tabId={TAB_IDS.TRADER}>
              <TraderTabContent />
            </PageTabPanel>
          </PageTabs>

          <CreateReferralCodeModal
            isVisible={isCreateReferralCodeModalVisible}
            setIsVisible={setIsCreateReferralCodeModalVisible}
            pendingTxns={pendingTxns}
            callAfterSuccess={() => {
              setIsCreateReferralCodeModalVisible(false);
            }}
          />

          <EnterReferralCodeModal
            isVisible={isEnterReferralCodeModalVisible}
            setIsVisible={setIsEnterReferralCodeModalVisible}
            type={haveUserReferralCode ? "edit" : "join"}
            userReferralCodeString={userReferralCodeString ?? ""}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            callAfterSuccess={() => {
              setIsEnterReferralCodeModalVisible(false);
            }}
          />
        </div>
      </div>
    </SEO>
  );
}

export default Referrals;
