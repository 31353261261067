import { useEffect, useMemo, useState } from "react";
import { EvmPriceServiceConnection, Price } from "@pythnetwork/pyth-evm-js";
import { MAINNET, TESTNET } from "config/chains";
import { useChainId } from "../lib/chains";
import { getWhitelistedTokens } from "../config/tokens";
import { Token } from "../domain/tokens";
import { useQuickContracts } from "../lib/contracts";
import useSWR from "swr";
import isEqual from "lodash/isEqual";

const PYTH_CONNECTION_URLS = {
  [MAINNET]: "https://hermes.pyth.network",
  [TESTNET]: "	https://hermes-beta.pyth.network",
};

type TokenPrices = {
  [key: string]: Price | undefined;
};

export default function usePythService() {
  const { chainId } = useChainId();
  const [tokenPrices, setTokenPrices] = useState<TokenPrices>({});
  const quickContracts = useQuickContracts();
  const whitelistedTokens: Token[] = getWhitelistedTokens(chainId);
  const service = useMemo(() => new EvmPriceServiceConnection(PYTH_CONNECTION_URLS[chainId]), [chainId]);
  const priceIds = useMemo(() => whitelistedTokens.map((token) => token.pythPriceId), [whitelistedTokens]);

  const { data: priceFeedsUpdateData = [] } = useSWR(["getPriceFeedsUpdateData", priceIds, service], ([, priceIds]) =>
    service.getPriceFeedsUpdateData(priceIds)
  );

  const { data: updateFee } = useSWR(
    ["PythPriceFeed", "getUpdateFee"],
    () => quickContracts.fetch("PythPriceFeed", "getUpdateFee", [priceFeedsUpdateData]),
    {
      compare: isEqual,
    }
  );

  useEffect(() => {
    service.subscribePriceFeedUpdates(priceIds, (priceFeed) => {
      setTokenPrices((prev) => ({
        ...prev,
        [`0x${priceFeed.id}`]: priceFeed.getPriceNoOlderThan(60),
      }));
    });

    return () => {
      service.unsubscribePriceFeedUpdates(priceIds);
    };
  }, [service, priceIds]);

  const tokenPricesArray = useMemo(
    () => whitelistedTokens.map((token) => tokenPrices[token.pythPriceId]?.price),
    [tokenPrices, whitelistedTokens]
  );

  return {
    service,
    priceFeedsUpdateData,
    tokenPrices,
    tokenPricesArray,
    updateFee,
  };
}
