import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useIsTradePage = () => {
  const location = useLocation();
  const [isTradePage, setIsTradePage] = useState(false)

  useEffect(() => {
    if (location.pathname === '/trade') {
      setIsTradePage(true);
    } else {
      setIsTradePage(false);
    }
  }, [location.pathname]);

  return isTradePage
}
