import { subscribeOnStream, unsubscribeFromStream } from "./pythStreaming";
import { useRef } from "react";
import { TokenUtils } from "components/TokenUtils";
import { useChainId } from "lib/chains";

export const PYTH_BENCHMARKS_TV_API = "https://benchmarks.pyth.network/v1/shims/tradingview";

type Bar = {
  time: number;
  low: number;
  high: number;
  open: number;
  close: number;
};

/**
 *  @description use this method to specify precision for the data feed
 *  @returns 100 for precision equal to 2 (which is used for prices like 12.34)
 */
const getPriceScaleByPrecision = (precision: number) => {
  return Math.pow(10, precision)
}

export const getSymbolUsdId = (symbol) => `Crypto.${symbol.toUpperCase()}/USD`;

export const usePythDatafeed = () => {
  // Use it to keep a record of the most recent bar on the chart
  const lastBarsCache = useRef<Map<string, object>>(new Map());
  const { chainId } = useChainId()

  return {
    resetCache: () => {
      lastBarsCache.current = new Map();
    },
    datafeed: {
      onReady: (callback) => {
        // console.log("[onReady]: Method call");
        fetch(`${PYTH_BENCHMARKS_TV_API}/config`).then((response) => {
          response.json().then((configurationData) => {
            setTimeout(() => callback(configurationData));
          });
        });
      },
      searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
        // console.log("[searchSymbols]: Method call");
        fetch(`${PYTH_BENCHMARKS_TV_API}/search?query=${userInput}`).then((response) => {
          response.json().then((data) => {
            onResultReadyCallback(data);
          });
        });
      },
      resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        // console.log("[resolveSymbol]: Method call", symbolName);
        const apiSymbol = symbolName.includes("Crypto") ? symbolName : getSymbolUsdId(symbolName);
        fetch(`${PYTH_BENCHMARKS_TV_API}/symbols?symbol=${apiSymbol}`).then((response) => {
          response
            .json()
            .then((symbolInfo: any) => {
              if (symbolInfo) {
                const { exchange, ...newSymbolInfo } = symbolInfo;
                onSymbolResolvedCallback({
                  ...newSymbolInfo,
                  pricescale: getPriceScaleByPrecision(
                    TokenUtils.getPriceDecimals(symbolName, chainId)
                  )
                });
              }
            })
            .catch((error) => {
              // console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
              onResolveErrorCallback("Cannot resolve symbol");
              return;
            });
        });
      },
      getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        // console.log("[getBars]: Method call", symbolInfo, resolution, from, to);
        fetch(
          `${PYTH_BENCHMARKS_TV_API}/history?symbol=${symbolInfo.ticker}&from=${from}&to=${to}&resolution=${resolution}`
        ).then((response) => {
          response
            .json()
            .then((data: any) => {
              if (!data) {
                return;
              }
              if (data.t.length === 0) {
                onHistoryCallback([], { noData: true });
                return;
              }
              const bars: Bar[] = [];
              for (let i = 0; i < data.t.length; ++i) {
                bars.push({
                  time: data.t[i] * 1000,
                  low: data.l[i],
                  high: data.h[i],
                  open: data.o[i],
                  close: data.c[i],
                });
              }
              if (firstDataRequest) {
                lastBarsCache.current.set(symbolInfo.ticker, {
                  ...bars[bars.length - 1],
                });
              }
              onHistoryCallback(bars, { noData: false });
            })
            .catch((error) => {
              // console.log("[getBars]: Get error", error);
              onErrorCallback(error);
            });
        });
      },
      subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // console.log("[subscribeBars]: Method call with subscriberUID:", subscriberUID);
        subscribeOnStream(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscriberUID,
          onResetCacheNeededCallback,
          lastBarsCache.current.get(symbolInfo.ticker)
        );
      },
      unsubscribeBars: (subscriberUID) => {
        // console.log("[unsubscribeBars]: Method call with subscriberUID:", subscriberUID);
        unsubscribeFromStream(subscriberUID);
      },
    },
  };
};
