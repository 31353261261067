import { Trans } from "@lingui/macro";
import "./SocialsDropdown.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import dots from "img/Dots.svg";
import docsIcon from "img/Docs_icon.svg";
import { ReactComponent as BlogIcon } from "img/Blog_icon.svg";
import academyIcon from "img/Academy_icon.svg";
import twitterIcon from "img/Twitter_icon.svg";
import discordIcon from "img/Discord_icon.svg";
import shareIcon from "img/ShareArrow.svg"
import { ReactComponent as TelegramIcon} from "img/Telegram_icon.svg";

import { HeaderIconButton } from "components/Header/HeaderIconButton";
import { Menu } from "components/MenuDropdown";
import cx from "classnames";

function SocialsDropdown() {
  return (
    <>
      <Menu.Menu placement="bottom-end">
        <Menu.Button>
          {
            ({ open, ref }) => (
              <HeaderIconButton ref={ref} iconSrc={dots} alt="more" active={open}></HeaderIconButton>
            )
          }
        </Menu.Button>

        <Menu.Items className="!min-w-[196px] bg-background-1">
          <Menu.Label>
            <Trans>Explore Community</Trans>
          </Menu.Label>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://docs.palmswap.org/"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Docs</Trans>
                  </p>

                  <img src={docsIcon} alt="Docs" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://blog.palmswap.org/"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Blog</Trans>
                  </p>

                  <BlogIcon className="fill-white dark:fill-black"/>
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://academy.palmswap.org/"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Academy</Trans>
                  </p>

                  <img src={academyIcon} alt="Academy" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://twitter.com/palmswaporg"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Twitter</Trans>
                  </p>

                  <img src={twitterIcon} alt="Twitter" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://discord.com/invite/B2EyhkQSZR"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Discord</Trans>
                  </p>

                  <img src={discordIcon} alt="Discord" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://palmswap.rapidr.io/"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Feedback</Trans>
                  </p>

                  <img src={shareIcon} alt="Share" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://t.me/PalmswapAnn"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Telegram Ann</Trans>
                  </p>

                  <TelegramIcon className="fill-white dark:fill-black" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>

          <Menu.Item>
            {({ className }) => <>
              <ExternalLink
                href="https://t.me/Palmswapchat"
                className={cx(className, 'no-underline')}
                target="_self"
              >
                <div className="flex-auto flex justify-between items-center">
                  <p className="text-textColor">
                    <Trans>Telegram</Trans>
                  </p>

                  <TelegramIcon className="fill-white dark:fill-black" />
                </div>
              </ExternalLink>
            </>}
          </Menu.Item>
        </Menu.Items>
      </Menu.Menu>
    </>
  );
}

export default SocialsDropdown;
